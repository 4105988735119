import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";

/**
 * CheckoutInputContainer
 * Renders the interactive container for the right container in checkout page
 * TODO TEST:
 *  - renders correctly (container, title, subtitle)
 *  - renders children
 */

const Container = styled.div`
  background: ${Colors.WHITE};
  height: 100%;
  padding: 40px;
  border-radius: 10px;
`;

const Title = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 20px;
  font-weight: 500;
`;

interface Props {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

const CheckoutInputContainer = (props: Props) => {
  const { title, children } = props;
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

export default CheckoutInputContainer;
