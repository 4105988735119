/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Tag,
  Popconfirm,
  Form,
  TreeSelect,
  Modal,
  Typography,
  Button,
  message,
} from "antd";
import { FormInstance } from "antd/lib/form";
import {
  updateProductTemplate,
  createProductTemplate,
  getProductTemplates,
  deleteProductTemplate,
} from "../../redux/actions/productTemplateActions";
// import { products } from "../../redux/actions/productActions";
import { AppDispatch } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ProductTemplate } from "../../redux/reducers/ProductTemplateReducer";
import { Inventory } from "../../redux/reducers/InventoryReducer";

interface Props {
  tableData: ProductTemplate[];
  loading: boolean;
  savedLoading: boolean;
  inventories: Inventory[];
}

interface Item {
  key: string;
  title: string;
  brand: string;
  category: string;
  option1: string;
  option2: string;
  option3: string;
  option1Values: [];
  option2Values: [];
  option3Values: [];
  weight: number;
  gender: string;
}

export interface ProductTemplateItem {
  id: any;
  title: string;
  brand: string;
  category: string;
  option1: string;
  option2: string;
  option3: string;
  option1Values: [];
  option2Values: [];
  option3Values: [];
  weight: number;
  gender: string;
}

const onUpdate = async (
  dispatch: AppDispatch,
  productTemplate: ProductTemplateItem,
  id: string
) => {
  dispatch(await updateProductTemplate(id, productTemplate));
};

const onSave = async (
  dispatch: AppDispatch,
  productTemplate: ProductTemplateItem
) => {
  dispatch(await createProductTemplate(productTemplate));
};

const ProductTemplateTable = (props: Props) => {
  const { tableData, loading, savedLoading, inventories } = props;
  const dispatch = useAppDispatch();

  const formatedData = tableData.map((item) => ({
    key: item.id.toString(),
    title: item.title,
    brand: item.brand,
    category: item.category,
    option1: item.option1,
    option2: item.option2,
    option3: item.option3,
    option1Values: item.option1Values,
    option2Values: item.option2Values,
    option3Values: item.option3Values,
    weight: item.weight,
    gender: item.gender,
  }));

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState(formatedData.length > 0 ? formatedData : []);
  const [editingKey, setEditingKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAdding, setIsAdding] = useState(true);
  const [option2Exist, setOption2Exist] = useState(true);
  const [option3Exist, setOption3Exist] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form2.resetFields();
    setIsModalVisible(false);
    setOption2Exist(true);
    setOption3Exist(true);
    setIsAdding(true);
    setEditingKey("");
  };

  const handleAddModel = () => {
    setIsAdding(true);
    showModal();
  };

  useEffect(() => {
    if (formatedData.length > 0) {
      setData(formatedData);
    }
  }, [tableData]);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    if (!record.option2 && record.option2Values?.length === undefined) {
      setOption2Exist(false);
    }
    if (!record.option3 && record.option3Values?.length === undefined) {
      setOption3Exist(false);
    }
    form2.setFieldsValue({
      id: record.key,
      title: "",
      brand: "",
      category: "",
      option1: "",
      option2: "",
      option3: "",
      option1Values: "",
      option2Values: "",
      option3Values: "",
      weight: "",
      gender: "",
      ...record,
    });
    setEditingKey(record.key);
    setIsAdding(false);
    showModal();
  };

  const handleSave = (values: any) => {
    const savedItem = {
      id: values.id,
      title: values.title,
      brand: values.brand,
      category: values.category,
      option1: values.option1,
      option2: values.option2,
      option3: values.option3,
      weight: values.weight,
      gender: values.gender,
      option1Values: Array.isArray(values.option1Values)
        ? values.option1Values
        : values.option1Values.split(",").map((item: any) => item.trim()),
      option2Values:
        values.option2Values !== null
          ? Array.isArray(values.option2Values)
            ? values.option2Values
            : values.option2Values.split(",").map((item: any) => item.trim())
          : null,
      option3Values:
        values.option3Values !== null
          ? Array.isArray(values.option3Values)
            ? values.option3Values
            : values.option3Values.split(",").map((item: any) => item.trim())
          : null,
    };

    onUpdate(dispatch, savedItem, values.id.toString());
    if (savedLoading === false) {
      setTimeout(() => dispatch(getProductTemplates()), 500);
    }
    form2.resetFields();
    setIsModalVisible(false);
  };

  const handleAdd = (values: any) => {
    if (values.option2Values !== undefined && values.option2 === undefined) {
      return message.warning(
        "Option 2 has values, please enter title for option 2."
      );
    }
    if (values.option3Values !== undefined && values.option3 === undefined) {
      return message.warning(
        "Option 3 has values, please enter title for option 3."
      );
    }
    const savedItem = {
      id: "",
      title: values.title,
      brand: values.brand,
      category: values.category,
      option1: values.option1,
      option2: values.option2,
      option3: values.option3,
      weight: values.weight,
      gender: values.gender,
      option1Values: values.option1Values
        .split(",")
        .map((item: any) => item.trim()),
      // option2Values: values.option2Values
      //   .split(",")
      //   .map((item: any) => item.trim()),
      option2Values: values.option2Values
        ? values.option2Values.split(",").map((item: any) => item.trim())
        : null,
      option3Values: values.option3Values
        ? values.option3Values.split(",").map((item: any) => item.trim())
        : null,
    };
    onSave(dispatch, savedItem);
    //wait for callback from save then dispatch getConsignerTypes
    if (savedLoading === false) {
      setTimeout(() => dispatch(getProductTemplates()), 500);
    }
    form2.resetFields();
    setIsModalVisible(false);
  };

  const handleDelete = (value: any) => {
    //check if productTemplate exists in products
    const foundInventories = inventories.filter(
      (item) => item.productTemplateId == value
    );

    if (foundInventories.length === 0) {
      setData(data.filter((item) => item.key !== value));
      dispatch(deleteProductTemplate(value));
    } else {
      message.error("This template is in use and cannot be deleted.");
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      editable: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      editable: true,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      editable: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      editable: true,
    },
    {
      title: "Weight (g)",
      dataIndex: "weight",
      editable: true,
    },
    {
      title: "Option 1",
      dataIndex: "option1",
      editable: true,
      render: (option1: any) => (
        <>
          {option1 ? (
            <Tag color={"green"} key={option1}>
              {option1.toUpperCase()}
            </Tag>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Option 2",
      dataIndex: "option2",
      editable: true,
      render: (option2: any) => (
        <>
          {option2 ? (
            <Tag color={"green"} key={option2}>
              {option2.toUpperCase()}
            </Tag>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Option 3",
      dataIndex: "option3",
      editable: true,
      render: (option3: any) => (
        <>
          {option3 ? (
            <Tag color={"green"} key={option3}>
              {option3.toUpperCase()}
            </Tag>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "operation",
      // width: "20%",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return (
          <div>
            <Typography.Link
              // disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <br />
            {data.length > 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.key)}
              >
                <a>Delete</a>
              </Popconfirm>
            ) : null}
          </div>
        );
      },
    },
  ];

  const { TextArea } = Input;

  return (
    <div>
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={handleAddModel}
      >
        Add product template
      </Button>
      <Modal
        title={isAdding ? "Add product template" : "Edit product template"}
        visible={isModalVisible}
        onOk={form2.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={form2.submit}
          >
            {isAdding ? "Submit" : "Save"}
          </Button>,
        ]}
      >
        <Form
          form={form2}
          onFinish={isAdding ? handleAdd : handleSave}
          layout="vertical"
        >
          <Form.Item name="id" hidden={true}></Form.Item>
          <Form.Item
            label="Title"
            name="title"
            key="title"
            rules={[{ required: true, message: "Title required" }]}
          >
            <Input placeholder={"Title"} />
          </Form.Item>
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: "Category required" }]}
          >
            <Input placeholder={"Category"} />
          </Form.Item>
          <Form.Item
            label="Brand"
            name="brand"
            rules={[{ required: true, message: "Brand required" }]}
          >
            <Input placeholder={"Brand"} />
          </Form.Item>

          <Form.Item label="Gender/Age" name="gender" key="gender">
            <Input placeholder={"Gender"} />
          </Form.Item>

          <Form.Item
            label="Option 1"
            name="option1"
            rules={[{ required: true, message: "Option 1 required" }]}
          >
            <Input disabled={!isAdding} placeholder={"Example: Size"} />
          </Form.Item>
          <Form.Item
            label="Option 1 Values (please separate values with a comma)"
            name="option1Values"
            rules={[{ required: true, message: "Option 1 values required" }]}
          >
            <TextArea rows={4} placeholder={"9, 10, 11, etc."} />
          </Form.Item>

          <Form.Item
            label="Option 2"
            name="option2"
            // rules={[{ required: true, message: "Option 2 required" }]}
          >
            <Input
              disabled={!isAdding}
              // onChange={(e) => setOption2Text(e.target.value)}
              placeholder={"Example: Condition"}
            />
          </Form.Item>
          <Form.Item
            label="Option 2 Values (please separate values with a comma)"
            name="option2Values"
            // rules={[{ required: true, message: "Option 2 values required" }]}
          >
            <TextArea
              rows={4}
              disabled={!option2Exist}
              placeholder={"Example: new old stock, new, used, etc."}
            />
          </Form.Item>

          <Form.Item
            label="Option 3"
            name="option3"
            // rules={[{ required: true, message: "Option 3 required" }]}
          >
            <Input disabled={!isAdding} placeholder={"Example: Box"} />
          </Form.Item>
          <Form.Item
            label="Option 3 Values (please separate values with a comma)"
            name="option3Values"
            // rules={[{ required: true, message: "Option 3 values required" }]}
          >
            <TextArea
              rows={4}
              disabled={!option3Exist}
              placeholder={"Example: with box, no box, etc."}
            />
          </Form.Item>
          <Form.Item label="Weight (g)" name="weight" key="weight">
            <Input placeholder={"Enter weight in grams"} />
          </Form.Item>
        </Form>
      </Modal>

      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          loading={loading}
          rowClassName="editable-row"
        />
      </Form>
    </div>
  );
};

export default ProductTemplateTable;
