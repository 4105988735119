import React from "react";
import styled from "styled-components";
import { Col, Input, Row, Switch } from "antd";
import { ConsignerDetailTab } from "../../consignerScreens/Settings";
/**
 * Consigner Profile Form
 */

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 100%;
  }
`;

const FormInput = styled(Input)`
  display: flex;
  width: 100%;
  height: 65px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  margin: 24px 0;
  font-size: 16px;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
`;

const CustomRow = styled(Row)`
  display: flex;
  width: 100%;
  height: 65px;
  padding: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 24px 0;
  font-size: 16px;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
`;

interface Props {
  email: string;
  password: string;
  phone: string;
  firstName: string;
  lastName: string;
  address: string;
  idNumber: string;
  storeId: string;
  chargeTax?: boolean;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAddress: (value: string) => void;
  setPhone: (value: string) => void;
  setIdNumber: (value: string) => void;
  setStoreId: (value: string) => void;
  setChargeTax: (value: boolean) => void;
  showPasswordInput: boolean;
}

const ConsignerProfileForm = (props: Props): JSX.Element => {
  const {
    email,
    password,
    firstName,
    lastName,
    phone,
    address,
    idNumber,
    storeId,
    chargeTax,
    setEmail,
    setPassword,
    setFirstName,
    setLastName,
    setAddress,
    setIdNumber,
    setPhone,
    setStoreId,
    setChargeTax,
    showPasswordInput,
  } = props;
  return (
    <Container>
      <div>
        <ConsignerDetailTab
          firstName={firstName}
          lastName={lastName}
          idNumber={idNumber}
        />
        <FormInput
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <FormInput
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <FormInput
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {showPasswordInput && (
          <>
            <FormInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}
        <FormInput
          placeholder="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <FormInput
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <CustomRow>
          <Switch
            onChange={(checked) => {
              console.log(checked);
              setChargeTax(checked);
            }}
            defaultChecked={chargeTax}
          />
          <label>Charge Tax?</label>
        </CustomRow>
      </div>
    </Container>
  );
};

export default ConsignerProfileForm;
