import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Content from "../../constants/Content";
import Fonts from "../../constants/Fonts";
import { useAppSelector } from "../../redux/hooks";

/*
    Footer Component
    Goes on every page
    No functionality, renders a footer at the bottom at 100% width with company info
*/

//styles
const Container = styled.div`
  background: ${Colors.WHITE};
  padding: 20px;
  padding-left: 100px;
  width: 100%;
`;
const Text = styled.p`
  color: ${Colors.GREY};
  font-family: ${Fonts.PRIMARY};
`;

const Footer = () => {
  // const { store } = useAppSelector((state) => state.StoreReducer);

  return (
    <Container>
      <Text data-testid="footerText">
        {/* Shop with {store?.name} © {new Date().getFullYear()} */}
        {process.env.REACT_APP_TYPE != "consigner" && Content.FOOTER_TEXT}
      </Text>
    </Container>
  );
};

export default Footer;
