import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProducts } from "../redux/actions/productActions";
import ProductList from "../components/Common/ProductList";
import { Link, Redirect } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import ProductTemplates from "./ProductTemplates";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import RedesignStyling from "../constants/RedesignStyling";
const { Search } = Input;

/*
Products Component
- products are fetched from getProducts API (redux/actions/productActions) and added into the Products Reducer 
- products fetch on load and everytime search is updated
Returns Component with
- Search Component
- List of Products
*/

//styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

//types
export interface ProductsProps {}

export default function Products({}: ProductsProps): JSX.Element {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();

  const { products, updatedProduct, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );
  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  useEffect(() => {
    dispatch(getProducts(search));
    //get products here any time search updates
  }, [search]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container data-testid="ProductsScreen">
      <Search
        data-testid="search"
        placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onSearch={searchProducts}
      />
      <ProductList
        data-testid="productList"
        products={products}
        loading={productsLoading}
        productTemplates={productTemplates}
        updatedProduct={updatedProduct!}
        dispatch={dispatch}
      />
      <Link data-testid="createProductLink" to="/products/create">
        Create New Product
      </Link>
    </Container>
  );
}
