import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InputNumber, Select, Spin } from "antd";
import {
  InventoryFee,
  InventoryFilterOptions,
} from "../../redux/reducers/InventoryReducer";
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { DropdownSelectorItem } from "../Common/HorizontalPageCard";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getSubLocations } from "../../redux/actions/subLocationActions";

const { Option } = Select;
/**
 * CheckoutInfo
 * Renders a checkout page where user can input price and quantity and renders the fees for the use
 * TODO Tests:
 */

const Container = styled.div``;
const Price = styled(InputNumber)`
  width: 100%;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  font-family: ${Fonts.PRIMARY};
  font-size: 600;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const PriceLabelContainer = styled.div`
  padding: 20px;
  height: 60px;
  background: ${Colors.BACKGROUND};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceLabel = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 24px;
  font-weight: 600;
`;
const Quantity = styled(InputNumber)``;
const QuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const QuantityLabel = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 18px;
`;
const FeeContainer = styled.div`
  margin-top: 40px;
`;
const FeeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const FeeItemLabel = styled.p`
  font-family: ${Fonts.PRIMARY};
  margin-bottom: 0px;
`;
const FeeItemValue = styled.p`
  margin-bottom: 0px;
`;
const FeeItemQuantityWarning = styled.p`
  margin-left: 10px;
  font-size: 10px;
  margin-bottom: 0px;
  color: ${Colors.GREY};
`;
const FeeItemValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FeeTitle = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-weight: bold;
  font-size: 16px;
`;

const Seperator = styled.div`
  width: 100%;
  background: ${Colors.INPUT_BORDER};
  height: 1px;
  margin-bottom: 10px;
`;

interface Props {
  inventoryFilterOptions: InventoryFilterOptions;
  onItemClick: (value: string) => void;
  onItemClickTwo: (value: string) => void;
  location: any;
  subLocation: any;
}

const CheckoutAcceptInventory = (props: Props) => {
  const { inventoryFilterOptions, onItemClick, onItemClickTwo } = props;
  const dispatch = useAppDispatch();

  const { store } = useAppSelector((state) => state.StoreReducer);

  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState<any>(null);
  const [filteredSubLocations, setFilteredSubLocations] = useState<any>([]);

  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  useEffect(() => {
    let data;
    if (selectedLocation) {
      data = subLocations.filter(
        (item: any) => item.locationName == selectedLocation
      );
      setFilteredSubLocations(data);
    }
  }, [selectedLocation]);

  const onLocationSelect = (value: any) => {
    onItemClick(value);
    setSelectedLocation(value);
  };

  const onSubLocationSelect = (value: any) => {
    onItemClickTwo(value);
    setSelectedSubLocation(value);
  };

  if (!inventoryFilterOptions) return <Spin />;

  return (
    <Container>
      <Select
        allowClear
        placeholder="Select location"
        // showSearch
        style={{ width: 200 }}
        value={selectedLocation}
        onChange={(value: string) => onLocationSelect(value)}
        filterOption={(input, option) =>
          String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        getPopupContainer={(node) => node.parentNode}
      >
        {inventoryFilterOptions.locations?.map((location: any) => (
          <Option key={location.id} value={location.label}>
            {location.label}
          </Option>
        ))}
      </Select>

      <Select
        allowClear
        placeholder="Select sublocation"
        showSearch
        style={{ width: 200 }}
        value={selectedSubLocation}
        onChange={(value: string) => onSubLocationSelect(value)}
        filterOption={(input, option) =>
          String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        getPopupContainer={(node) => node.parentNode}
      >
        {filteredSubLocations &&
          filteredSubLocations.length > 0 &&
          filteredSubLocations.map((sublocation: any) => (
            <Select.Option key={sublocation.name} value={sublocation.name}>
              {sublocation.name}
            </Select.Option>
          ))}
      </Select>
    </Container>
  );
};

export default CheckoutAcceptInventory;
