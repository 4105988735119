import { Inventory } from "../redux/reducers/InventoryReducer";
import { Consigner } from "../redux/reducers/ConsignerReducer";

export const getInventoriesPayoutTotal = (inventories: Inventory[]) => {
  return inventories
    .reduce((total: number, item) => {
      return total + Number(item.payoutAmount);
    }, 0)
    .toFixed(2);
};

export const getInventoriesByConsigner = (inventories: Inventory[]) => {
  const consigners = inventories.map((item) => item.consigner);

  const uniqueConsigners = consigners.reduce(
    (uConsigners: Consigner[], consigner: Consigner) => {
      if (
        consigner &&
        consigner.id &&
        !uConsigners.find((uC) => uC.id === consigner.id)
      ) {
        return [...uConsigners, consigner];
      }
      return uConsigners;
    },
    []
  );

  return uniqueConsigners.reduce(
    (
      allConsigners: { consigner: Consigner; inventories: Inventory[] }[],
      consigner: Consigner
    ) => {
      const consignerInventories = inventories.filter(
        (inventory) =>
          inventory.consigner && inventory.consigner.id === consigner.id
      );

      return [
        ...allConsigners,
        { consigner, inventories: consignerInventories },
      ];
    },
    []
  );
};
