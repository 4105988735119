import {
  GET_SCAN,
  GET_SCANS,
  UPDATE_SCAN,
  CREATE_SCAN,
} from "../actions/types";
import { Inventory } from "./InventoryReducer";

export type Scan = any;

export interface ScanState {
  scans: Scan[];
  scansLoading: boolean;
  scan: Scan | null;
  scanLoading: boolean;
  updatedScan: Scan | null;
  updatedScanLoading: boolean;
  createdScan: Scan | null;
  createdScanLoading: boolean;
  inventories: Inventory[];
  scannedInventories: Inventory[];
}

const defaultState = {
  scans: [],
  scansLoading: false,
  scan: null,
  scanLoading: false,
  updatedScan: null,
  updatedScanLoading: false,
  createdScan: null,
  createdScanLoading: false,
  inventories: [],
  scannedInventories: [],
};

export const DefaultScanState = defaultState;

export type ScanAction = {
  type: string;
  scans: Scan[];
  scansLoading: boolean;
  scan: Scan | null;
  scanLoading: boolean;
  updatedScan: Scan | null;
  updatedScanLoading: boolean;
  createdScan: Scan | null;
  createdScanLoading: boolean;
  inventories: Inventory[];
  scannedInventories: Inventory[];
};

export default (state: ScanState = defaultState, action: ScanAction) => {
  switch (action.type) {
    case GET_SCANS:
      return {
        ...state,
        scans: action.scans,
        scansLoading: action.scansLoading,
      };
    case GET_SCAN:
      return {
        ...state,
        scan: action.scan,
        scanLoading: action.scanLoading,
        inventories: action.inventories,
        scannedInventories: action.scannedInventories,
      };
    case UPDATE_SCAN:
      return {
        ...state,
        updatedScan: action.updatedScan,
        updatedScanLoading: action.updatedScanLoading,
      };
    case CREATE_SCAN:
      return {
        ...state,
        createdScan: action.createdScan,
        createdScanLoading: action.createdScanLoading,
        inventories: action.inventories,
      };
    default:
      return state;
  }
};
