import firebase from "firebase";
import Cookies from "js-cookie";
import { GET_USER, LOGIN_LOADING } from "./types";
import { Consigner } from "../reducers/ConsignerReducer";
import { Employee } from "../reducers/EmployeeReducer";
import { AppDispatch } from "../store";
import { api } from "../../api";

const getUserLoading = (fbUser: firebase.User) => ({
  type: GET_USER,
  dbUser: null,
  loginLoading: true,
  fbUser,
  isLoggedIn: false,
});

const getUserError = (fbUser: firebase.User) => ({
  type: GET_USER,
  dbUser: null,
  fbUser,
  loginLoading: false,
  isLoggedIn: false,
});

const getUserSuccess = (
  fbUser: firebase.User,
  dbUser: Consigner | Employee
) => ({
  type: GET_USER,
  dbUser,
  fbUser,
  loginLoading: false,
  isLoggedIn: true,
});
export const getToken = async (): Promise<string> => {
  /*
      Get JWT for user
  */
  try {
    const user = await firebase.auth().currentUser;
    if (user) {
      return await user.getIdToken(false);
    } else throw new Error("No Sure");
  } catch (e) {
    //TODO: message error
    return "";
  }
};
const getUserFromAPI = async (fbUserId: string) => {
  // Switch based on if consigner or employee
  if (process.env.REACT_APP_TYPE === "consigner") {
    //added this because it was creating duplicate db entries of consigners
    setTimeout("", 3000);

    const { data, error } = await api.provide("get", "/api/consigners/:id", {
      id: fbUserId,
    });

    if (!data || error) {
      const consignerData = Cookies.get("newConsigner");
      if (consignerData) {
        const newConsigner = JSON.parse(consignerData);

        const { data: createdConsigner } = await api.provide(
          "post",
          "/api/consigners",
          {
            firstName: newConsigner.firstName,
            lastName: newConsigner.lastName,
            email: newConsigner.email,
            phone: newConsigner.phone,
            address: newConsigner.address,
            idNumber: newConsigner.idNumber,
            accountName: newConsigner.accountName,
            accountNumber: newConsigner.accountNumber,
            bank: newConsigner.bank,
            bankType: newConsigner.bankType,
            branchCode: newConsigner.branchCode,
            fbUserId,
            payoutData: newConsigner.payoutData,
            payoutType: newConsigner.payoutType,
            storeId: newConsigner.storeId,
          } as any
        );
        return createdConsigner;
      }
    }
    let userObj = {
      // @ts-ignore
      id: data?.id,
      // @ts-ignore
      name: (data?.firstName || "").concat(" ", data?.lastName || ""),
    };

    Cookies.set("loadedUser", JSON.stringify(userObj));
    return data;
  } else {
    const { data, error } = await api.provide("get", "/api/employees/:id", {
      id: fbUserId,
    });

    if (error) throw error;

    let userObj = {
      id: data.id,
      name: data.firstName.concat(" ", data.lastName),
    };
    Cookies.set("loadedUser", JSON.stringify(userObj));
    return data;
  }
};

export const getUser = (user: firebase.User) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getUserLoading(user));
    try {
      dispatch(getUserSuccess(user, await getUserFromAPI(user.uid)));
    } catch (e) {
      console.log(e);
      dispatch(getUserError(user));
    }
  };
};
export const setLoginLoading = (loginLoading: Boolean) => ({
  type: LOGIN_LOADING,
  loginLoading,
});
