// import type { InputRef } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Image,
  Select,
  InputNumber,
  message,
  Typography,
} from "antd";
import type { FormInstance } from "antd/lib/form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { AppDispatch } from "../redux/store";
import {
  InventoryFilterOption,
  InventoryState,
} from "../redux/reducers/InventoryReducer";
import {
  calculateFees,
  updateInventory,
  getInventory,
} from "../redux/actions/inventoryActions";
import { Product } from "../redux/reducers/ProductReducer";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import { ProductTemplate } from "../redux/reducers/ProductTemplateReducer";
import { Order } from "../redux/reducers/OrderReducer";
import { OptimizedImageUrl } from "../helperFunctions/optimizedImageUrl";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Props {
  tableData: any;
  inventoryStatuses: any;
  locations: any;
  subLocations: any;
  safeInventoryStatusMode?: boolean;
}

interface Item {
  key: React.Key;
  id: string;
  code: string;
  option1Value: any;
  option2Value: any;
  option3Value: any;
  location: string;
  consigner: Consigner;
  payoutFee: string;
  price: string;
  payout: string;
  packingSlip: string;
  product: Product;
  requestedPrice: string | null;
  order?: Order | null;
  subLocation: string;
  status: string;
  createdAt: Date;
  acceptedOn?: Date;
  productTemplateId: string;
  shopifyId?: string;
  productTemplate: ProductTemplate;
  notes: string;
  updatedAt?: Date;
  orderId?: string;
  discount?: string;
  productId?: string;
  category?: string;
  cost: string;
  trackingCode: string;
}

interface EditableRowProps {
  index: number;
}

interface DataType {
  key: React.Key;
  title: string;
  image: string;
  status: any;
  Item: any;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: any;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  index: number;
  handleSave: (record: Item) => void;
}

const onUpdate = async (dispatch: AppDispatch, inventory: Item, id: string) => {
  dispatch(await updateInventory(id, inventory));
  // dispatch(await getInventory(id));
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  index,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

const InventoryEditTable = (props: Props) => {
  const {
    tableData,
    inventoryStatuses,
    locations,
    subLocations,
    safeInventoryStatusMode,
  } = props;

  const {
    calculatedFees,
    createdInventory,
    inventory,
    updatedInventory,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const dispatch = useAppDispatch();
  const [updateCall, setUpdateCall] = useState<any>(null);

  const formatedData = tableData.map((item: any) => ({
    key: item.id.toString(),
    title: item.product.title,
    image: item.product.image,
    option1: item.productTemplate?.option1,
    option2: item.productTemplate?.option2,
    option3: item.productTemplate?.option3,
    ...item,
  }));

  const [data, setData] = useState(formatedData.length > 0 ? formatedData : []);

  useEffect(() => {
    if (formatedData.length > 0) {
      setData(formatedData);
    }
  }, [tableData]);

  //write function refresh inventory list after update or after pressing "Save edits" button

  const { Option } = Select;

  const [updatedValue, setUpdatedValue] = useState<any>({});
  const [variableChange, setVariableChange] = useState();

  useEffect(() => {
    if (data && data.length > 0 && updatedValue && updatedValue.price) {
      const newData = [...data];
      const index = newData.findIndex((item) => updatedValue.key === item.key);
      const item = newData[index];

      //re fetches fees on price and quantity changes

      //calculatedFees 1 step behind for some reason
      if (variableChange == "price") {
        if (
          calculatedFees &&
          calculatedFees.payout &&
          updatedValue.payout !== calculatedFees.payout
        ) {
          updatedValue.payout = String(calculatedFees?.payout);
        }
      }

      onUpdate(dispatch, updatedValue, updatedValue.id);

      newData.splice(index, 1, {
        ...item,
        ...updatedValue,
      });
      setData(newData);
    }
  }, [updatedValue, calculatedFees]);

  const handleChange = async (
    value: any,
    record: any,
    variableChanged: any
  ) => {
    const updateItem = {
      ...record,
      [variableChanged]: value,
    };

    let quantity = 1;
    let cost = 0;
    if (
      variableChanged == "price" &&
      updateItem.consigner.isStoreAccount !== true
    ) {
      dispatch(
        calculateFees(
          updateItem.id,
          // Number(updateItem.payoutFee),
          Number(updateItem.price),
          quantity,
          updateItem.option1Value,
          updateItem.option2Value,
          updateItem.option3Value,
          Number(cost),
          updateItem.productTemplate.category,
          JSON.stringify(updateItem.consigner)
        )
      );
    }

    try {
      if (updateItem.consigner && updateItem.consigner !== "") {
        const consigner = JSON.parse(updateItem.consigner);
        consigner.isStoreAccount === true
          ? (updateItem["cost"] = calculatedFees?.payout)
          : (updateItem["cost"] = null);
      }
    } catch (e) {
      updateItem["cost"] = null;
    }

    setVariableChange(variableChanged);
    setUpdatedValue(updateItem);
    setUpdateCall(true);
  };

  // console.log(calculatedFees);
  const { Search } = Input;

  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState<any>(null);
  const [filteredSubLocations, setFilteredSubLocations] = useState<any>([]);

  useEffect(() => {
    let data;
    if (selectedLocation) {
      data = subLocations.filter(
        (item: any) => item.locationName == selectedLocation
      );
      setFilteredSubLocations(data);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (updatedInventory && updatedInventory.length !== 0 && updateCall) {
      if (updatedInventory.error) {
        message
          .loading("Saving", 1)
          .then(() => message.error(updatedInventory.error));
      } else {
        message.loading("Saving", 1).then(() => message.success("Saved"));
      }
      setUpdateCall(null);
    }
  }, [updatedInventory]);

  const onLocationSelect = (value: any) => {
    setSelectedLocation(value);
  };

  const onSubLocationSelect = (value: any) => {
    setSelectedSubLocation(value);
  };

  let width = 500;
  let height = 500;

  const columns: ColumnsType<DataType> = [
    {
      title: "title",
      dataIndex: "title",
      width: 150,
      fixed: "left",
    },
    {
      title: "image",
      dataIndex: "image",
      width: 150,
      fixed: "left",
      render: (url: string) => <Image src={OptimizedImageUrl(url)} />,
    },
    {
      title: "consigner",
      dataIndex: "consigner",
      width: 200,
      render: (consigner: any) => {
        return (
          <div>
            {consigner.firstName} {consigner.lastName}
          </div>
        );
      },
    },
    {
      title: "status",
      dataIndex: "status",
      width: 200,
      render: (_: any, record: any) => {
        return (
          <Select
            style={{ width: "100%" }}
            onChange={(value: any) => handleChange(value, record, "status")}
            disabled={
              safeInventoryStatusMode &&
              (record.status === "Pending" ||
                record.status === "Sold" ||
                record.status === "PendingSale" ||
                record.status === "Paid")
                ? true
                : false
            }
            defaultValue={record.status}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {inventoryStatuses?.map((item: any, index: any) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "option1",
      dataIndex: "option1",
      width: 150,
    },
    {
      title: "option1Value",
      dataIndex: "option1Value",
      width: 150,
      render: (_: any, record: any) => (
        <>
          {record.productTemplate && record.productTemplate.option1Values ? (
            <Select
              style={{ width: "100%" }}
              onChange={(value) => handleChange(value, record, "option1Value")}
              defaultValue={record.option1Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {record.productTemplate.option1Values.map(
                (template: any, index: any) => (
                  <Option key={index} value={template}>
                    {template}
                  </Option>
                )
              )}
            </Select>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "option2",
      dataIndex: "option2",
      width: 150,
    },
    {
      title: "option2Value",
      dataIndex: "option2Value",
      width: 150,
      render: (_: any, record: any) => (
        <>
          {record.productTemplate && record.productTemplate.option2Values ? (
            <Select
              style={{ width: "100%" }}
              onChange={(value) => handleChange(value, record, "option2Value")}
              defaultValue={record.option2Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {record.productTemplate.option2Values.map(
                (template: any, index: any) => (
                  <Option key={index} value={template}>
                    {template}
                  </Option>
                )
              )}
            </Select>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "option3",
      dataIndex: "option3",
      width: 150,
      // editable: true,
    },
    {
      title: "option3Value",
      dataIndex: "option3Value",
      width: 150,
      render: (_: any, record: any) => (
        <>
          {record.productTemplate && record.productTemplate.option3Values ? (
            <Select
              style={{ width: "100%" }}
              onChange={(value) => handleChange(value, record, "option3Value")}
              defaultValue={record.option3Value}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {record.productTemplate.option3Values.map(
                (template: any, index: any) => (
                  <Option key={index} value={template}>
                    {template}
                  </Option>
                )
              )}
            </Select>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "location",
      dataIndex: "location",
      width: 200,
      render: (_: any, record: any) => {
        return (
          <Select
            style={{ width: "100%" }}
            onChange={(value: any) => (
              onLocationSelect(value), handleChange(value, record, "location")
            )}
            defaultValue={record.location}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations?.map((item: any, index: any) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "sublocation",
      dataIndex: "subLocation",
      width: 150,
      render: (_: any, record: any) => (
        <Select
          style={{ width: "100%" }}
          placeholder="sublocation"
          onChange={(value: any) => (
            onSubLocationSelect(value),
            handleChange(value, record, "subLocation")
          )}
          defaultValue={record.subLocation}
          filterOption={(input, option) =>
            String(option?.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {selectedLocation &&
          filteredSubLocations &&
          filteredSubLocations.length > 0 ? (
            <>
              {filteredSubLocations.map((item: any, index: any) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}{" "}
            </>
          ) : (
            <>
              {subLocations
                .filter((item: any) => item.locationName == record.location)
                .map((item: any, index: any) => (
                  <Option key={index} value={item.name}>
                    {item.name}
                  </Option>
                ))}{" "}
            </>
          )}
        </Select>
      ),
    },
    {
      title: "price",
      dataIndex: "price",
      width: 150,
      // editable: true,
      render: (_: any, record: any) => (
        <Search
          defaultValue={record.price}
          allowClear
          onSearch={(value: any) => handleChange(value, record, "price")}
          enterButton="Save"
        />
      ),
    },
    {
      title: "payout",
      dataIndex: "payout",
      fixed: "right",
      width: 150,
      render: (_: any, record: any) => (
        <InputNumber
          value={record.payout}
          onChange={(value: any) => handleChange(value, record, "payout")}
          precision={2}
        />
      ),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record: Item) => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave,
  //     }),
  //   };
  // });

  return (
    <div style={{ maxWidth: "100%" }}>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={data}
        columns={columns}
        size="small"
        scroll={{ x: 1500, y: 700 }}
      />
    </div>
  );
};
export default InventoryEditTable;
