import React from "react";
import styled from "styled-components";
import { Card, Tabs } from "antd";
import Colors from "../../constants/Colors";

/**
 * Consigner Profile Tabs
 */
const { TabPane } = Tabs;

const Container = styled(Card)`
  margin-top: 10px;
  .ant-card-body {
    padding: 0;
  }
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      margin: 0;
      gap: 8px;
      flex: 1 0 0;
      border: 1px solid var(--stroke-color, #e4e4e7);
      opacity: 0.30000001192092896;
      &:hover,
      &.ant-tabs-tab-active {
        opacity: 1;
        background: var(--brand-color, ${Colors.CONSIGNER_BLUE});
        div {
          color: #fff;
        }

        /* Drop shadow/XSmall */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
`;

interface ProfileTab {
  name: string;
  content: JSX.Element;
}

interface Props {
  tabs: ProfileTab[];
}

const ConsignerProfileTabs = (props: Props): JSX.Element => {
  const { tabs } = props;

  return (
    <Container>
      <CustomTabs defaultActiveKey="0">
        {tabs.map((tab, index) => (
          <TabPane tab={tab.name} key={index}>
            {tab.content}
          </TabPane>
        ))}
      </CustomTabs>
    </Container>
  );
};

export default ConsignerProfileTabs;
