import { Button, Image, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import {
  acceptRequestedInventoryPriceChangeFromAPI,
  rejectRequestedInventoryPriceChangeFromAPI,
} from "../redux/actions/inventoryActions";
import { Store } from "../redux/reducers/StoreReducer";
import styled from "styled-components";

interface RouteParams {
  action: string;
  code: string;
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;

export const EmailActionHandler = () => {
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  const { action, code }: RouteParams = useParams();
  const [message, setMessage] = useState("");
  const acceptPriceChange = async () => {
    try {
      await acceptRequestedInventoryPriceChangeFromAPI(code);
      setMessage("Store price change request successful!");
    } catch (e: any) {
      setMessage("Something went wrong. Your link might have expired already.");
    }
  };

  const rejectPriceChange = async () => {
    try {
      await rejectRequestedInventoryPriceChangeFromAPI(code);
      setMessage("Store price change request has been rejected!");
    } catch (e: any) {
      setMessage("Something went wrong. Your link might have expired already.");
    }
  };
  useEffect(() => {
    switch (action) {
      case "acceptPrice":
        acceptPriceChange();
        break;
      case "rejectPrice":
        rejectPriceChange();
        break;
    }
  }, []);

  if (message === "") return <Spin />;
  return (
    <Container>
      <Image style={{ maxHeight: 200, maxWidth: 200 }} src={store.logo} />
      <p>{message}</p>
      <Button type="primary" href={`//${store.redirectURL}`}>
        Go To Store
      </Button>
    </Container>
  );
};
