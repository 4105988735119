import React, { useState, useEffect } from "react";
import { Form, Select, Spin } from "antd";
import {
  InventoryFilterOption,
  InventoryFilterOptions,
} from "../../../redux/reducers/InventoryReducer";
import { ConsignerFormOption } from "../../../redux/reducers/PayoutReducer";
import { Consigner } from "../../../redux/reducers/ConsignerReducer";

interface Props {
  onSave: (values: any) => void;
  inventoryFilterOptions: InventoryFilterOptions;
  inventoryFilterOptionsLoading: boolean;
  setConsigner: (consigner: any) => void;
  setLocation: (location: any) => void;
  setSubLocation: (subLocation: any) => void;
}

const { Option } = Select;

const ScanBarcodeStep1: React.FC<Props> = ({
  onSave,
  inventoryFilterOptions,
  inventoryFilterOptionsLoading,
  setConsigner,
  setLocation,
  setSubLocation,
}) => {
  const [form] = Form.useForm();
  const [consignerOptions, setConsignerOptions] = useState<
    ConsignerFormOption[]
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    InventoryFilterOption[]
  >([]);
  const [subLocationOptions, setSubLocationOptions] = useState<
    InventoryFilterOption[]
  >([]);

  useEffect(() => {
    if (inventoryFilterOptions) {
      setConsignerOptions(inventoryFilterOptions.consigners);
      setLocationOptions(inventoryFilterOptions.locations);
      setSubLocationOptions(inventoryFilterOptions.subLocations);
    }
  }, [inventoryFilterOptions]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(values);
      form.resetFields();
    });
  };

  return (
    <div>
      {inventoryFilterOptionsLoading ? (
        <div className="loading-indicator">
          <Spin size="large" />
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item
            name="consigner"
            label="Consigner"
            rules={[{ required: true, message: "Please select a consigner" }]}
          >
            <Select
              // style={{ width: 200 }}
              showSearch
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                String(option?.value)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              data-testid="consigners"
              placeholder="Select consigner"
              allowClear
              onChange={(value) => setConsigner(value)}
            >
              {consignerOptions.map((consigner) => (
                <Select.Option
                  // this key is currently returning duplicate errors, so it has been temporarly commented
                  // key={consigner.label}
                  key={consigner.value.id}
                  value={JSON.stringify(consigner)}
                  title={consigner.value.email}
                >
                  {consigner.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: "Please select a location" }]}
          >
            <Select
              showSearch
              placeholder="Select a location"
              onChange={(value) => setLocation(value)}
              filterOption={(input, option) =>
                option && option.label
                  ? String(option.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {locationOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="subLocation"
            label="Sub-location"
            rules={[
              { required: false, message: "Please select a sub-location" },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a sub-location"
              onChange={(value) => setSubLocation(value)}
              filterOption={(input, option) =>
                option && option.label
                  ? String(option.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  : false
              }
            >
              {subLocationOptions &&
                subLocationOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default ScanBarcodeStep1;
