import {
  GET_EMPLOYEES,
  GET_EMPLOYEE,
  CREATE_EMPLOYEE,
  SEND_EMPLOYEE_INVITE,
  UPDATE_EMPLOYEE,
  GET_SHOP_REPORT,
  ACCEPT_EMPLOYEE,
  GET_CONSIGNER_SALE_PAYOUT,
  GET_DASHBOARD_REPORT,
} from "../actions/types";
import { Consigner } from "./ConsignerReducer";
import { Inventory } from "./InventoryReducer";
import { Store } from "./StoreReducer";

export type Employee = any;

export interface ShopReport {
  inventoriesByAge: Inventory[];
  inventoriesByCategory: Inventory[];
  inventoriesByLocation: Inventory[];
  inventoriesPendingPayouts: Inventory[];
  inventoriesPendingPriceRequests: Inventory[];
  inventoriesPendingWithdrawalRequests: Inventory[];
  consignersBySalesVolume: Consigner[];
  // inventoryStats: any;
  sales: number;
  salesGrowth: number;
  dailySales: number[];
  profit: number;
  profitGrowth: number;
  dailyProfits: number[];
  activeListings: number;
  activeListingsGrowth: number;
  dailyActiveListings: number[];
  newListings: number;
  newListingsGrowth: number;
  totalActiveListings: number;
  dailyTotalListings: number[];
  totalActiveListingsGrowth: number;
  dailyNewListings: number[];
  totalInventoryValue: number;
  totalInventoryValueGrowth: number;
  dailyInventoryValue: number[];
  totalPendingPayouts: number;
  totalPendingPayoutsGrowth: number;
  dailyPendingPayouts: number[] | null;
  storeAge?: number;
  allConsigners: Consigner[];
}

export interface StoreSalePayout {
  inventoriesPendingPayouts: Inventory[];
  profit: number;
  sales?: number;
  storeInventory?: number;
  consignerInventory?: number;
  activeInventory?: number;
  soldInventory?: number;
  totalConsigners?: number;
  newConsigners?: number;
  payouts?: number;
  consignerActiveInventory?: number;
}

export interface EmployeeState {
  employees: Employee[];
  employeesLoading: boolean;
  employee: Employee | null;
  employeeLoading: boolean;
  createEmployeeLoading: boolean;
  createdEmployee: Employee | null;
  acceptEmployeeLoading: boolean;
  acceptedEmployee: Employee | null;
  sendEmployeeInviteLoading: boolean;
  updateEmployeeLoading: boolean;
  updatedEmployee: Employee | null;
  shopReport: ShopReport;
  shopReportLoading: boolean;
  dashBoardLoading: boolean;
  storeSalePayout: StoreSalePayout;
  storeSalePayoutLoading: boolean;
  dashboardReport: any;
}

const defaultState = {
  employees: [],
  employeesLoading: false,
  employee: null,
  employeeLoading: false,
  createEmployeeLoading: false,
  createdEmployee: null,
  acceptEmployeeLoading: false,
  acceptedEmployee: null,
  updateEmployeeLoading: false,
  updatedEmployee: null,
  sendEmployeeInviteLoading: false,
  shopReportLoading: false,
  dashBoardLoading: false,
  dashboardReport: null,
  shopReport: {
    inventoriesByAge: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    inventoriesPendingPayouts: [],
    inventoriesPendingPriceRequests: [],
    inventoriesPendingWithdrawalRequests: [],
    inventoriesPendingRequestWithdrawals: [],
    consignersBySalesVolume: [],
    // inventoryStats: [],
    sales: 0,
    salesGrowth: 0,
    dailySales: [],
    profit: 0,
    profitGrowth: 0,
    dailyProfits: [],
    activeListings: 0,
    activeListingsGrowth: 0,
    totalActiveListings: 0,
    totalActiveListingsGrowth: 0,
    dailyTotalListings: [],
    dailyActiveListings: [],
    newListings: 0,
    newListingsGrowth: 0,
    dailyNewListings: [],
    totalInventoryValue: 0,
    totalInventoryValueGrowth: 0,
    allTimeInventoryValue: 0,
    dailyInventoryValue: [],
    totalPendingPayouts: 0,
    totalPendingPayoutsGrowth: 0,
    dailyPendingPayouts: [],
    allConsigners: [],
  },
  storeSalePayoutLoading: false,

  storeSalePayout: {
    inventoriesPendingPayouts: [],
    profit: 0,
    sales: 0,
    storeInventory: 0,
    consignerInventory: 0,
    activeInventory: 0,
    soldInventory: 0,
    totalConsigners: 0,
    newConsigners: 0,
    payouts: 0,
    consignerActiveInventory: 0,
  },
};

export const DefaultEmployeeState = defaultState;

export type EmployeeAction = {
  type: string;
  employees: Employee[];
  employeesLoading: boolean;
  employee: Employee | null;
  employeeLoading: boolean;
  createEmployeeLoading: boolean;
  createdEmployee: Employee | null;
  sendEmployeeInviteLoading: boolean;
  updateEmployeeLoading: boolean;
  updatedEmployee: Employee | null;
  shopReport: ShopReport;
  shopReportLoading: boolean;
  dashBoardLoading: boolean;
  acceptEmployeeLoading: boolean;
  acceptedEmployee: Employee | null;
  storeSalePayout: StoreSalePayout;
  storeSalePayoutLoading: boolean;
  dashboardReport: any;
};

export default (
  state: EmployeeState = defaultState,
  action: EmployeeAction
) => {
  switch (action.type) {
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.employees,
        employeesLoading: action.employeesLoading,
      };
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
        employeeLoading: action.employeeLoading,
      };
    case CREATE_EMPLOYEE:
      return {
        ...state,
        createdEmployee: action.createdEmployee,
        createEmployeeLoading: action.createEmployeeLoading,
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        updatedEmployee: action.updatedEmployee,
        updateEmployeeLoading: action.updateEmployeeLoading,
      };
    case SEND_EMPLOYEE_INVITE:
      return {
        ...state,
        sendEmployeeInviteLoading: action.sendEmployeeInviteLoading,
      };
    case GET_SHOP_REPORT:
      return {
        ...state,
        shopReport: action.shopReport,
        shopReportLoading: action.shopReportLoading,
      };
    case ACCEPT_EMPLOYEE:
      return {
        ...state,
        acceptedEmployee: action.acceptedEmployee,
        acceptEmployeeLoading: action.acceptEmployeeLoading,
      };
    case GET_CONSIGNER_SALE_PAYOUT:
      return {
        ...state,
        storeSalePayout: action.storeSalePayout,
        storeSalePayoutLoading: action.storeSalePayoutLoading,
      };
    case GET_DASHBOARD_REPORT:
      return {
        ...state,
        dashboardReport: action.dashboardReport,
        dashBoardLoading: action.dashBoardLoading
      };
    default:
      return state;
  }
};
