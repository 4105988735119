import { createStore, applyMiddleware, AnyAction } from "redux";
import thunk, { ThunkDispatch, ThunkMiddleware } from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

type State = { a: string };

const typedThunk: ThunkMiddleware<State, AnyAction> = thunk;

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(typedThunk))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
