import { Alert, Card, Input, Select } from "antd";
import React from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Content from "../../constants/Content";
import { useAppSelector } from "../../redux/hooks";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { ConsignerDetailTab } from "../../consignerScreens/Settings";

/**
 * Consigner Bank Form
 */
const { Option } = Select;

const Container = styled(Card)`
  width: 100%;
`;
const FormLabel = styled.p`
  margin-left: 1px;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${Colors.GREY};
  display: none;
`;

const FormInput = styled(Input)`
  display: flex;
  width: 100%;
  height: 65px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: #fff;
  color: var(--heading-color, #2e2e2e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 24px auto;
`;
const FormSelect = styled(Select)`
  display: flex;
  width: 100%;
  height: 65px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: #fff;
  color: var(--heading-color, #2e2e2e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 24px auto;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
`;
interface Props {
  accountName: string;
  accountNumber: string;
  swiftCode: string;
  ibanNumber: string;
  routingNumber: string;
  routingType: string;
  bank: string;
  bankType: string;
  payoutData: string;
  payoutType: string;
  branchCode: string;
  setSwiftCode: (value: string) => void;
  setIbanNumber: (value: string) => void;
  setRoutingNumber: (value: string) => void;
  setRoutingType: (value: string) => void;
  setPayoutData: (value: any) => void;
  setPayoutType: (value: any) => void;
  setAccountName: (value: string) => void;
  setBank: (value: any) => void;
  setAccountNumber: (value: string) => void;
  setBranchCode: (value: string) => void;
  setBankType: (value: any) => void;
  readOnly?: boolean;
  firstName?: string;
  lastName?: string;
  idNumber?: string;
}

const ConsignerBankForm = (props: Props): JSX.Element => {
  const {
    accountName,
    bank,
    accountNumber,
    ibanNumber,
    swiftCode,
    routingNumber,
    routingType,
    bankType,
    payoutData,
    payoutType,
    setIbanNumber,
    setSwiftCode,
    setRoutingNumber,
    setRoutingType,
    setPayoutData,
    setPayoutType,
    setAccountName,
    setBank,
    setAccountNumber,
    setBranchCode,
    setBankType,
    readOnly,
    firstName,
    lastName,
    idNumber,
  } = props;

  const { store } = useAppSelector((state) => state.StoreReducer);
  const { consigner } = useAppSelector((state) => state.ConsignerReducer);

  const handleRoutingChange = (value: any) => {
    setRoutingType(value);
  };

  return (
    <Container>
      <ConsignerDetailTab
        firstName={firstName}
        lastName={lastName}
        idNumber={idNumber}
      />
      {readOnly ? (
        <Alert
          message={Content.CONSIGNER_BANK_FORM_READ_ONLY_MESSAGE}
          description={`It must be done in person, or by emailing ${store?.supportEmail}`}
          type="warning"
          style={{ marginBottom: 10 }}
        />
      ) : (
        <Alert
          message={Content.CONSIGNER_BANK_FORM_MESSAGE}
          description={Content.CONSIGNER_BANK_FORM_DESC}
          type="info"
          style={{ marginBottom: 10 }}
        />
      )}
      <FormLabel>Payout Method</FormLabel>

      <FormSelect
        disabled={readOnly}
        placeholder="Select your payout type"
        onChange={(value) => setBankType(value)}
        value={bankType === "" ? undefined : bankType}
      >
        {/* {store?.payoutOptions.map((bankType) => (
          <Option key={bankType} value={bankType}>
            {bankType}
          </Option>
        ))} */}

        {store && store?.payoutOptions.length > 0
          ? store?.payoutOptions!.map((bankType: any, index: any) => (
              <Option key={index} value={bankType}>
                {bankType}
              </Option>
            ))
          : ""}
      </FormSelect>

      {bankType === "Bank" && (
        <FormSelect
          // disabled={readOnly}
          placeholder="Select your Routing type"
          onChange={handleRoutingChange}
          defaultValue={"abaNumber"}
          value={routingType || consigner?.bankData.routingType}
        >
          {Content.BANKROUTINGTYPE!.map((routingType, index) => (
            <Option key={index} value={routingType.value}>
              {routingType.label}
            </Option>
          ))}
        </FormSelect>
      )}

      {bankType === "Bank" && routingType === "abaNumber" ? (
        <div>
          <FormLabel>Account Name</FormLabel>
          <FormInput
            name="accountName"
            disabled={readOnly}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            placeholder="Enter account holders name"
          />
          <FormLabel>Account Number</FormLabel>
          <FormInput
            name="accountNumber"
            disabled={readOnly}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Enter your account number"
          />

          <FormLabel>Routing Number</FormLabel>
          <FormInput
            name="routingNumber"
            disabled={readOnly}
            value={routingNumber}
            onChange={(e) => setRoutingNumber(e.target.value)}
            placeholder="Enter your branch code number"
          />
        </div>
      ) : bankType === "Bank" && routingType === "swiftCode" ? (
        <div>
          <FormLabel>Account Name</FormLabel>
          <FormInput
            name="accountName"
            disabled={readOnly}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            placeholder="Enter account holders name"
          />
          <FormLabel>Account Number</FormLabel>
          <FormInput
            name="accountNumber"
            disabled={readOnly}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            placeholder="Enter your account number"
          />
          <FormLabel>Swift Code (BIC)</FormLabel>
          <FormInput
            name="swiftCode"
            disabled={readOnly}
            value={swiftCode}
            onChange={(e) => setSwiftCode(e.target.value)}
            placeholder="Enter your branch code number"
          />
        </div>
      ) : bankType === "Bank" && routingType === "ibanNumber" ? (
        <div>
          <FormLabel>Account Name</FormLabel>
          <FormInput
            disabled={readOnly}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            placeholder="Enter account holders name"
          />

          <FormLabel>IBAN Number</FormLabel>
          <FormInput
            disabled={readOnly}
            value={ibanNumber}
            onChange={(e) => setIbanNumber(e.target.value)}
            placeholder="Enter your IBAN Number or Account Number"
          />
        </div>
      ) : (
        <FormInput
          disabled={readOnly}
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          placeholder="Enter your Paypal email address"
        />
      )}

      {/* / Container to display information about different banking Types  / */}
      {bankType === "Bank" && routingType === "abaNumber" ? (
        <Container style={{ marginTop: "20px" }}>
          <Title level={5}>Bank Account Information</Title>
          <Paragraph>
            Where is the ABA routing number on my checks? You can find the ABA
            routing number at the bottom of your checks. <br></br>The ABA
            Routing Number is the left-most number, followed by your account
            number, and then by the number of the check (USA). Eg: 123456789{" "}
            <br></br>
            For Canada, the routing number = 0 + institution number + transit
            number. <br></br>
            Example: 001012345 where 010 is the institution number and 12345 is
            the transit number.
          </Paragraph>
        </Container>
      ) : bankType === "Bank" && routingType === "swiftCode" ? (
        <Container style={{ marginTop: "20px" }}>
          <Title level={5}>Bank Account Information</Title>
          <Paragraph>
            A Swift Code is a unique identifier for a bank or financial
            institution. Depending on your financial institution, you may have a
            Swift Code with an Account number or IBAN number.
            <br></br> For Example : <br></br>
            SWIFT / BIC CODE = AAAAAGAGXYZ <br></br>
            Account Number = 000123456789 or IBAN Number =
            BH29BMAG1299123456BH00
          </Paragraph>
        </Container>
      ) : bankType === "Bank" && routingType === "ibanNumber" ? (
        <Container style={{ marginTop: "20px" }}>
          <Title level={5}>Bank Account Information</Title>
          <Paragraph>
            An IBAN is an international bank account number. It is used to
            identify bank accounts across national borders. Please enter your
            IBAN Number or Account Number above. Some financial institutions
            have either a single long IBAN Number, or a single Account Number
            <br></br>
            For Example : <br></br>
            IBAN Number = BH29BMAG1299123456BH00 <br></br>
            Account Number = 0110000600000000000000
          </Paragraph>
        </Container>
      ) : (
        <Container style={{ marginTop: "20px" }}></Container>
      )}
    </Container>
  );
};

export default ConsignerBankForm;
