import React from "react";
import { Button, Card, Image, Modal } from "antd";
import styled from "styled-components";
import icon1 from "../assets/images/Splash1.png";
import icon2 from "../assets/images/Splash3.png";
import icon3 from "../assets/images/Splash2.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import RedesignStyling from "../constants/RedesignStyling";
import { AppState } from "../redux/reducers/AppReducer";
import { useAppSelector } from "../redux/hooks";

const SplashDiv = styled.div`
  height: 100%;
  width: 100% !important;
  display: flex;
  top: 0;
  left: 0;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  padding: 100px 0 60px;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    & > span {
      max-width: 1000px;
      flex: 1;
      color: var(--Heading-Color, #2e2e2e);
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      gap: 24px;
      // flex-direction: column;
      align-items: center;
      justify-content: center;
      .ant-card {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        height: 300px;
        .ant-image {
          max-width: 120px;
          max-height: 120px;
          margin-bottom: 50px;
        }
        b {
            color: #2E2E2E;
            width 100%;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        p {
            width: 100%;
            height: 50px;
            color: #7F8080;
            font-size: 14px;
            line-height: 20px;
        }
      }
    }
    .ant-btn {
      flex: 0 0 65px;
      width: 600px;
      margin: 40px 0;
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - 75px);
    flex: 1;
    padding: 8px 2px;
    & > div {
      padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
      .ant-btn {
        width: 100%;
      }
      & > span {
        flex-direction: column;
        text-align: left;
        .ant-card {
          div {
            align-items: flex-start;
            justify-content: flex-start;
          }
          .ant-image {
            max-width: 36px;
            max-height: 36px;
            margin-bottom: 20px;
            align-self: flex-start;
          }
        }
      }
      .ant-btn {
        margin: 8px 0;
        width: 95px;
        align-self:flex-start;
      }
    }
  }
`;

export const Splash = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: any;
}) => {
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);

  const handleClose = () => {
    setVisible(true);
    Cookies.set("seenBuySellSplash", "1");
  };

  return (
    <SplashDiv /* visible={visible} onCancel={handleClose} bodyStyle={{height: "100%", width: "100%", top: 0, left: 0}} footer={null} */
    >
      <div>
        <span>
          <Card>
            <Image src={icon1} preview={false} />
            <b>View Inventory</b>
            <p>List your items and price</p>
          </Card>
          <Card>
            <Image preview={false} src={icon2} />
            <b>Consign Or Sell</b>
            <p>We will either request price change or accept</p>
          </Card>
          <Card>
            <Image preview={false} src={icon3} />
            <b>Payouts Is Promised By The Store</b>
            <p>
              After you ship or drop off the item, we will instantly cash you
              out
            </p>
          </Card>
        </span>
        <Button
          type="primary"
          icon={isMobile ? <></> : <ArrowRightOutlined />}
          onClick={handleClose}
          style={{ backgroundColor: "#333" }}
        >
          Next
        </Button>
      </div>
    </SplashDiv>
  );
};
