import React, { useState, useEffect, Dispatch } from "react";
import styled from "styled-components";
import {
  message,
  Modal,
  Button,
  Select,
  Spin,
  Input,
  Row,
  Col,
  Divider,
  FormInstance,
} from "antd";
import Content from "../constants/Content";
import Colors from "../constants/Colors";
import Fonts from "../constants/Fonts";
import {
  clearUpdatedProduct,
  fetchProductLowestPricesFromAPI,
  updateProductFn,
} from "../redux/actions/productActions";
import { InventoryFormValues } from "../screens/AddInventory";
import GridSelector from "../components/Common/GridSelector";
import {
  createInventories,
  resetCreatedInventory,
  bulkAcceptInventory,
} from "../redux/actions/inventoryActions";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { useAppSelector } from "../redux/hooks";
import { AppDispatch } from "../redux/store";
import { getSubLocations } from "../redux/actions/subLocationActions";
import CategorySelector, {
  StyledSelect,
} from "../components/Common/InventoryAddModalTemplatePicker";
import InventoryAddModalTable from "../components/Common/InventoryAddModalTable";
import { AppState } from "../redux/reducers/AppReducer";
import InventoryBuyAndSellModalTable from "./InventoryBuyAndSellModalTable";

const DataContainer = styled.div`
  background: white;
`;

const ItemTitle = styled.p`
  margin-bottom: 0px;
  text-align: center;
  font-family: ${Fonts.PRIMARY};
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const Colcontainer = styled.div`
  vertical-align: middle;
  display: "inline-flex";
  justify-content: "center";
  align-items: "center";
`;

interface Props {
  product: any;
  id: any;
  productTemplates: any;
  updatedProduct: any;
  dispatch: any;
  setIsModalVisible: any;
  isModalVisible: any;
  isNewEntry: boolean;
  setNewInventory: any;
  inventories?: any;
  form?: FormInstance;
  setReload?: any;
  setInventoriesToAdd?: any;
}

interface AddInventoryFormValues {
  id: any;
  payout: number;
  total: number;
  size: string;
  isCopy: boolean;
  inventoryFormValues: InventoryFormValues;
}

const { Option } = Select;

const ConsignerAddInventoryAction = (
  inventoryList: any,
  setAddBulkInventory: any,
  product: any,
  id: string,
  productTemplate: any,
  setInventoriesToAdd: any,
  setIsModalVisible: any,
  setInventoryList: any
) => {
  const inventories = inventoryList.map((inventory: any) => ({
    key: inventory.key,
    price: inventory.price,
    payoutFee: inventory.payoutFee,
    quantity: inventory.quantity,
    option1Value: inventory.option1Value,
    option2Value: inventory.option2Value,
    option3Value: inventory.option3Value,
    cost: inventory.cost,
    consigner: inventory.consigner,
    category: inventory.category,
    productId: id,
    productName: product.title,
    productImage: product.image,
    payout: inventory.payout,
    total: inventory.total,
    sku: product.sku,
    template: productTemplate,
  }));

  if (validateCart(inventories, productTemplate)) {
    setInventoriesToAdd(inventories);
  } else {
    message.warning(
      `Please select ${
        productTemplate.option1 ? productTemplate.option1 + "," : ""
      } ${productTemplate.option2 ? productTemplate.option2 + "," : ""} ${
        productTemplate.option3 ? productTemplate.option3 + "," : ""
      } Price, and Quantity first`
    );
    setAddBulkInventory(false);
    return;
  }
  setIsModalVisible(false);
  setAddBulkInventory(false);
  setInventoryList([]);
};

export const handleConsignerAddInventory = async (
  inventories: any,
  setLoading: any,
  dispatch: AppDispatch
) => {
  setLoading(true);
  await dispatch(createInventories(inventories, inventories.id));
  setLoading(false);
  return;
};

const AddInventoryAction = (
  inventoryList: any,
  setLoading: any,
  setAddBulkInventory: any,
  dispatch: AppDispatch,
  product: any,
  id: string,
  productTemplate: any,
  location: string
) => {
  const inventories = inventoryList.map((inventory: any) => {
    const data: any = {
      quantity: inventory.quantity,
      option1Value: inventory.option1Value,
      option2Value: inventory.option2Value,
      option3Value: inventory.option3Value,
      cost: inventory.cost,
      consigner: inventory.consigner,
      category: inventory.category,
      status: inventory.status,
      location: location,
    };
    if (inventory.BuyAndSellPriceRangeUpper) {
      data.BuyAndSellPriceRange = [
        inventory.BuyAndSellPriceRange,
        inventory.BuyAndSellPriceRangeUpper,
      ];
    } else {
      data.price = inventory.BuyAndSellPriceRange;
      delete data.BuyAndSellPriceRange;
    }
    return data;
  });

  handleAddInventory(
    inventories,
    setLoading,
    setAddBulkInventory,
    dispatch,
    product,
    id,
    productTemplate
  );
};

const handleAddInventory = async (
  inventories: any,
  setLoading: any,
  setAddBulkInventory: any,
  dispatch: AppDispatch,
  product: any,
  id: string,
  productTemplate: any
) => {
  setLoading(true);
  if (validateCart(inventories, productTemplate)) {
    dispatch(await createInventories(inventories, id));
    return;
  } else {
    message.warning(
      `Please select ${
        productTemplate.option1 ? productTemplate.option1 + "," : ""
      } ${productTemplate.option2 ? productTemplate.option2 + "," : ""} ${
        productTemplate.option3 ? productTemplate.option3 + "," : ""
      } Price, and Quantity first`
    );
    setLoading(false);
    setAddBulkInventory(false);
  }
};

const validateCart = (values: InventoryFormValues[], productTemplate: any) => {
  let res: boolean;
  for (let i = 0; i < values.length; i++) {
    if (
      values[i].option1Value &&
      values[i].option1Value !== "" &&
      // values[i].price &&
      // values[i].price > 0 &&
      values[i].quantity &&
      values[i].quantity > 0 &&
      ((productTemplate.option2 &&
        productTemplate.option2.length > 0 &&
        values[i].option2Value &&
        values[i].option2Value.length > 0) ||
        ((productTemplate.option2 === null || productTemplate.option2 === "") &&
          values[i].option2Value === "")) &&
      ((productTemplate.option3 &&
        productTemplate.option3.length > 0 &&
        values[i].option3Value &&
        values[i].option3Value.length > 0) ||
        ((productTemplate.option3 === null || productTemplate.option3 === "") &&
          values[i].option3Value === ""))
    ) {
      res = true;
    } else {
      res = false;
      break;
    }
  }
  return res!;
};

const handleAcceptInventory = async (
  selectedLocation: any,
  selectedSubLocation: any,
  createdInventory: any,
  setLoading: (newLoadingState: boolean) => void,
  dispatch: AppDispatch
) => {
  setLoading(true);
  if (selectedLocation !== "" || null) {
    dispatch(
      await bulkAcceptInventory(
        createdInventory,
        selectedLocation,
        selectedSubLocation
      )
    );
  } else {
    alert("Please select a location");
    setLoading(false);
  }
};

const handleOk = (setAddBulkInventory: any) => {
  setAddBulkInventory(true); // this calls AddInventoryAction
};

const handleCancel = (
  setAddBulkInventory: any,
  setIsModalVisible: any,
  setOption1Value: any,
  setOption2Value: any,
  setOption3Value: any,
  setSelectedOption1Value: any,
  setSelectedProductTemplate: any,
  setInventoryList: any,
  setPrice: any,
  setQuantity: any,
  setCost: any,
  setSelectedConsigner: any,
  setSelectedId: any,
  setCategory: any,
  setLoading: any,
  setSelectedLocation: any,
  setSelectedSubLocation: any,
  product: any,
  isNewValue: boolean,
  dispatch: AppDispatch
) => {
  setIsModalVisible(false);
  setAddBulkInventory(false);
  dispatch(resetCreatedInventory());
  dispatch(clearUpdatedProduct());
  setOption1Value("");
  setOption2Value("");
  setOption3Value("");
  setSelectedLocation(null);
  setSelectedSubLocation(null);
  setInventoryList([]);
  setPrice(0);
  setQuantity(1);
  setCost(null);
  setSelectedId("");
  setAddBulkInventory(false);
  setSelectedOption1Value([]);
  setLoading(false);
  setSelectedConsigner("");
  setCategory("");
  // setSelectedProductTemplate("");
};

const Option1Selector = (props: any) => {
  const {
    selectedProductTemplate,
    setOption1Value,
    option1Value,
    productTemplates,
    loading,
  } = props;

  const inventories = props.inventoryList ?? [];

  if (props.loading) return <Spin />;
  return (
    <>
      {selectedProductTemplate && productTemplates && (
        <>
          <Row
            align="top"
            justify="center"
            style={{
              borderTop: "thin solid #d3d3d3",
              marginTop: "15px",
              backgroundColor: "white",
            }}
          >
            <Col flex="auto" style={{ backgroundColor: "white" }}>
              <Colcontainer style={{ backgroundColor: "white" }}>
                {productTemplates
                  .filter(
                    (template: any) =>
                      template.id === selectedProductTemplate.id
                  )
                  .map((item: any, index: number) => (
                    <GridSelector
                      key={index}
                      data={item.option1Values.map((option1Value: any) => {
                        return {
                          title: "",
                          subTitle: option1Value,
                          value: option1Value,
                        };
                      })}
                      title={"Select " + item.option1}
                      onItemClick={(value) => {
                        if (option1Value.includes(value))
                          setOption1Value([
                            ...option1Value.filter(
                              (optionValue) => optionValue !== value
                            ),
                          ]);
                        else setOption1Value((prev) => [...prev, value]);
                      }}
                      selectedItem={option1Value}
                      small
                      borderRadius={"5px"}
                      padding={"5px"}
                      background={Colors.WHITE}
                      borderColor={"#d3d3d3"}
                      borderStyle={"solid"}
                      borderWidth={"thin"}
                      classicVersion={false}
                      inventoryList={inventories}
                      productRequest={option1Value}
                    />
                  ))}
              </Colcontainer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const LocationSelector = (props: any) => {
  const {
    dispatch,
    selectedLocation,
    setSelectedLocation,
    selectedSubLocation,
    setSelectedSubLocation,
    filteredSubLocations,
    setFilteredSubLocations,
    // inventoryFilterOptions,
  } = props;

  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  const { inventoryFilterOptions }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  useEffect(() => {
    let data;
    if (selectedLocation) {
      data = subLocations.filter(
        (item: any) => item.locationName == selectedLocation
      );
      setSelectedSubLocation("");
      setFilteredSubLocations(data);
    }
  }, [selectedLocation]);

  const onLocationSelect = (value: any) => {
    setSelectedLocation(value);
  };

  const onSubLocationSelect = (value: any) => {
    setSelectedSubLocation(value);
  };

  return (
    <>
      {process.env.REACT_APP_TYPE !== "consigner" ? (
        <>
          <Divider />
          <Row align="middle" justify="end" gutter={34}>
            <Col style={{ display: "flex", flexDirection: "row" }}>
              <ItemTitle>{Content.ADD_LOCATION_LABEL}</ItemTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row" }}>
              <Select
                allowClear
                placeholder="Select location"
                size={"large"}
                style={{ width: 200, marginRight: 10 }}
                value={selectedLocation}
                onChange={(value: string) => {
                  onLocationSelect(value);
                }}
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(node) => node.parentNode}
              >
                {inventoryFilterOptions.locations?.map((location: any) => (
                  <Option key={location.id} value={location.label}>
                    {location.name}
                  </Option>
                ))}
              </Select>

              <Select
                allowClear
                placeholder="Select sublocation"
                showSearch
                size={"large"}
                style={{ width: 200 }}
                value={selectedSubLocation}
                onChange={(value: string) => onSubLocationSelect(value)}
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(node) => node.parentNode}
              >
                {filteredSubLocations &&
                  filteredSubLocations.length > 0 &&
                  filteredSubLocations.map((sublocation: any) => (
                    <Select.Option
                      key={sublocation.name}
                      value={sublocation.name}
                    >
                      {sublocation.name}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </>
      ) : (
        <div />
      )}
    </>
  );
};

const CustomModal = styled(Modal)`
  top: 10px;
  &.ant-modal {
    // width: 100%;
    max-width: 100vw;
    margin: auto;
    .ant-modal-content {
      // width: 999px;
    }
  }
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    .ant-modal-content {
      padding: 12px;
      width: 100%;
      min-height: 100vh;
      max-height: 100%;
      overflow-y: auto;
      .ant-modal-header {
        padding: 0 0 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        text-transform: capitalize;
      }
      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-footer {
        padding: 0;
        display: flex;
        margin: 24px auto;
        button {
          flex: 1;
        }
      }
    }
  }
`;

const InventoryBuyAndSellModal = (props: Props) => {
  const {
    product,
    id,
    productTemplates,
    updatedProduct,
    dispatch,
    setIsModalVisible,
    isModalVisible,
    isNewEntry,
    setNewInventory,
    inventories,
    form,
    setReload,
    setInventoriesToAdd,
  } = props;

  const [selectedProductTemplate, setSelectedProductTemplate] = useState<any>();
  const [option1Value, setOption1Value] = useState([]);
  const [option2Value, setOption2Value] = useState("");
  const [option3Value, setOption3Value] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(null);
  const [selectedConsigner, setSelectedConsigner] = useState("");
  const [category, setCategory] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedOption1Value, setSelectedOption1Value] = useState<string[]>(
    []
  );
  const [inventoryList, setInventoryList] = useState<AddInventoryFormValues[]>(
    []
  );
  const [addBulkInventory, setAddBulkInventory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState<any>(null);
  const [filteredSubLocations, setFilteredSubLocations] = useState<any>([]);
  const [createdInventory, setCreatedInventory] = useState();
  const { isMobile } = useAppSelector((state): AppState => state.AppReducer);

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  useEffect(() => {
    if (
      product &&
      product.productTemplateId &&
      productTemplates &&
      productTemplates.length > 0
    ) {
      setSelectedProductTemplate(
        productTemplates.filter(
          (template: any) => template.id === product.productTemplateId
        )[0]
      );
    } else {
      setSelectedProductTemplate(undefined);
    }
  }, [isModalVisible]);

  useEffect(() => {
    console.info(selectedProductTemplate);
  }, [selectedProductTemplate]);

  useEffect(() => {
    if (!option1Value || option1Value.length < 1) return;

    console.log(option1Value);
  }, [option1Value]);

  //update template selector when switching to new product page

  return (
    <Spin spinning={loading}>
      <CustomModal
        title="Add Inventory"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={1200}
        footer={[
          <Button
            key="back"
            style={{ padding: 8, height: 52 }}
            onClick={() => setIsModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ padding: 8, height: 52, backgroundColor: "#333" }}
            // loading={loading}
            onClick={() => {
              const newData = {
                quantity: 1,
                option1Value,
                option2Value: "New",
                option3Value:
                  selectedProductTemplate.option3 &&
                  selectedProductTemplate.option3 !== ""
                    ? ""
                    : undefined,
                status: "Requested",
                payoutFee: 0,
                price: 0,
                cost: 0,
                consigner: "",
                category: selectedProductTemplate.category,
                productId: id,
                product: { ...product },
              };
              setNewInventory(
                option1Value.map((option1ValueData, idx: number) => ({
                  ...newData,
                  option1Value: option1ValueData,
                }))
              );
              setOption1Value([]);
            }}
            disabled={
              option1Value.length < 1 || !option1Value || !id || id === ""
            }
          >
            Sell Item
          </Button>,
        ]}
      >
        <DataContainer>
          <CategorySelector
            product={product}
            updatedProduct={updatedProduct}
            productTemplates={productTemplates}
            setSelectedProductTemplate={setSelectedProductTemplate}
            selectedProductTemplate={selectedProductTemplate}
            setCategory={setCategory}
            dispatch={dispatch}
            isNewEntry={isNewEntry}
            inventories={inventories}
            form={form}
            updateProductFn={updateProductFn}
          />
          {selectedProductTemplate && (
            <Option1Selector
              selectedProductTemplate={selectedProductTemplate}
              productTemplates={productTemplates}
              setOption1Value={setOption1Value}
              option1Value={option1Value}
              inventoryList={inventoryList}
              loading={inventoryFilterOptionsLoading}
            />
          )}

          {/* </div> */}
        </DataContainer>
      </CustomModal>
    </Spin>
  );
};

export default InventoryBuyAndSellModal;
