import { Payout, PayoutFilterOptions } from "../reducers/PayoutReducer";
import { AppDispatch } from "../store";
import {
  EXPORT_PAYOUT_INVENTORIES,
  GET_CONSIGNER_PAYOUTS,
  GET_PAYOUT,
  GET_PAYOUT_FILTER_OPTIONS,
  GET_PAYOUTS,
  MARK_PAID_INVENTORIES,
  PAYOUT_INVENTORIES,
  RETRY_AUTO_PAYOUT,
} from "./types";
import { FormValues as PayoutFilterValues } from "../../components/Common/PayoutFilter";
import { Inventory } from "../reducers/InventoryReducer";
import { api } from "../../api";

const getPayoutFilterOptionsLoading = () => ({
  type: GET_PAYOUT_FILTER_OPTIONS,
  payoutFilterOptions: [],
  payoutFilterOptionsLoading: true,
});

const getPayoutFilterOptionsError = () => ({
  type: GET_PAYOUT_FILTER_OPTIONS,
  payoutFilterOptions: [],
  payoutFilterOptionsLoading: false,
});

const getPayoutFilterOptionsSuccess = (
  payoutFilterOptions: PayoutFilterOptions
) => ({
  type: GET_PAYOUT_FILTER_OPTIONS,
  payoutFilterOptions,
  payoutFilterOptionsLoading: false,
});

const getPayoutsLoading = () => ({
  type: GET_PAYOUTS,
  payouts: [],
  payoutsLoading: true,
});

const getPayoutsError = () => ({
  type: GET_PAYOUTS,
  payouts: [],
  payoutsLoading: false,
});

const getPayoutsSuccess = (payouts: Payout[]) => ({
  type: GET_PAYOUTS,
  payouts,
  payoutsLoading: false,
});

const getConsignerPayoutsLoading = () => ({
  type: GET_CONSIGNER_PAYOUTS,
  payouts: [],
  payoutsLoading: true,
});

const getConsignerPayoutsError = () => ({
  type: GET_CONSIGNER_PAYOUTS,
  payouts: [],
  payoutsLoading: false,
});

const getConsignerPayoutsSuccess = (payouts: Payout[]) => ({
  type: GET_CONSIGNER_PAYOUTS,
  payouts,
  payoutsLoading: false,
});

const getPayoutLoading = () => ({
  type: GET_PAYOUT,
  payout: null,
  payoutLoading: true,
});

const getPayoutError = () => ({
  type: GET_PAYOUT,
  payout: null,
  payoutLoading: false,
});

const getPayoutSuccess = (payout: Payout) => ({
  type: GET_PAYOUT,
  payout,
  payoutLoading: false,
});

const payoutInventoriesLoading = () => ({
  type: PAYOUT_INVENTORIES,
  payoutInventories: [],
  payoutInventoriesLoading: true,
});

const payoutInventoriesError = () => ({
  type: PAYOUT_INVENTORIES,
  payoutInventories: [],
  payoutInventoriesLoading: false,
});

const payoutInventoriesSuccess = (payoutInventories: Inventory[]) => ({
  type: PAYOUT_INVENTORIES,
  payoutInventories,
  payoutInventoriesLoading: false,
});

const exportPayoutInventoriesLoading = () => ({
  type: EXPORT_PAYOUT_INVENTORIES,
  exportedPayoutInventories: [],
  exportPayoutInventoriesLoading: true,
});

const exportPayoutInventoriesError = () => ({
  type: EXPORT_PAYOUT_INVENTORIES,
  exportedPayoutInventories: [],
  exportPayoutInventoriesLoading: false,
});

const exportPayoutInventoriesSuccess = (
  exportedPayoutInventories: Inventory[]
) => ({
  type: EXPORT_PAYOUT_INVENTORIES,
  exportedPayoutInventories,
  exportPayoutInventoriesLoading: false,
});
const markPaidInventoriesLoading = () => ({
  type: MARK_PAID_INVENTORIES,
  markedPaidInventories: [],
  markPaidInventoriesLoading: true,
});

const markPaidInventoriesError = () => ({
  type: MARK_PAID_INVENTORIES,
  markedPaidInventories: [],
  markPaidInventoriesLoading: false,
});

const markPaidInventoriesSuccess = (markedPaidInventories: Inventory[]) => ({
  type: MARK_PAID_INVENTORIES,
  markedPaidInventories,
  markPaidInventoriesLoading: false,
});

const retryAutoPayoutLoading = () => ({
  type: RETRY_AUTO_PAYOUT,
  retryPayout: null,
  payoutSuccesful: false,
  payoutError: null,
});

const retryAutoPayoutError = () => ({
  type: RETRY_AUTO_PAYOUT,
  retryPayout: null,
  payoutSuccesful: false,
  payoutError: true,
});

const retryAutoPayoutSuccess = (retryPayout: any) => ({
  type: RETRY_AUTO_PAYOUT,
  retryPayout: retryPayout.payoutId,
  payoutSuccesful: true,
  payoutError: false,
  // autoPayoutInventoriesLoading: false,
});

//api
const getPayoutFilterOptionsFromAPI =
  async (): Promise<PayoutFilterOptions> => {
    const { data, error } = await api.provide(
      "get",
      "/api/payouts/filterOptions",
      {}
    );
    if (error) throw error;
    return (data || []) as any;
  };

const getPayoutsFromAPI = async (
  search: string,
  filters: PayoutFilterValues
) => {
  const { data, error } = await api.provide("get", "/api/payouts", {
    search,
    consigner: filters.consigner,
    status: filters.status,
  });
  if (error) throw error;
  return (data?.payouts || []) as any;
};

const getConsignerPayoutsFromAPI = async (
  search: string,
  filters: PayoutFilterValues
) => {
  const { data, error } = await api.provide(
    "get",
    "/api/payouts/consigner/:id",
    {
      id: filters.consigner,
      search,
      status: filters.status,
    }
  );
  if (error) throw error;
  return (data?.payouts || []) as any;
};

const exportPayoutInventoriesFromAPI = async (
  inventoriesToExport: Inventory[]
) => {
  const { data, error } = await api.provide("post", "/api/payouts/export", {
    inventoriesToExport,
  });

  if (error) throw error;

  const pom = document.createElement("a");
  const blob = new Blob([data?.result], { type: "text/csv;charset=utf-8;" });
  pom.href = URL.createObjectURL(blob);
  pom.setAttribute("download", "payoutExport.csv");
  pom.click();
  return data?.result as any;
};

const getPayoutFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/payouts/:id", {
    id,
  });
  if (error) throw error;
  return { ...data?.payout, inventories: data?.inventories || [] } as any;
};
const markPaidInventoriesFromAPI = async (
  payoutId: string,
  consignersInventoryToBeMarkedAsPaid: any
) => {
  const { data, error } = await api.provide("post", "/api/payouts/paid/:id", {
    id: payoutId,
    consignersInventoryToBeMarkedAsPaid,
  });
  if (error) throw error;
  return data as any;
};

export const sendStoreRequestedItemsEmailFromAPI = async () => {
  const { data, error } = await api.provide(
    "post",
    "/api/consigners/sendStoreRequestedItemsEmail",
    {}
  );
  if (error) throw error;
  return data as any;
};

const payoutInventoriesFromAPI = async (
  inventoriesToPay: Inventory[],
  consignersAndInventories: any
) => {
  const { data, error } = await api.provide("post", "/api/payouts/pay", {
    inventoriesToPay,
    consignersAndInventories,
  });
  if (error) throw error;
  return data;
};
const retryAutoPayoutFromAPI = async (
  payoutId: any,
  consignersAndInventories: Inventory[]
) => {
  const { data, error } = await api.provide(
    "post",
    "/api/checkbook/retryAutoPayout",
    {
      payoutId,
      consignersAndInventories,
    }
  );
  if (error) throw error;
  return data;
};
//actions
export const getPayouts = (search: string, filters: PayoutFilterValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getPayoutsLoading());
    try {
      dispatch(getPayoutsSuccess(await getPayoutsFromAPI(search, filters)));
    } catch (e) {
      dispatch(getPayoutsError());
    }
  };
};
export const getConsignerPayouts = (
  search: string,
  filters: PayoutFilterValues
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignerPayoutsLoading());
    try {
      dispatch(
        getConsignerPayoutsSuccess(
          await getConsignerPayoutsFromAPI(search, filters)
        )
      );
    } catch (e) {
      dispatch(getConsignerPayoutsError());
    }
  };
};

export const getPayout = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getPayoutLoading());
    try {
      dispatch(getPayoutSuccess(await getPayoutFromAPI(id)));
    } catch (e) {
      dispatch(getPayoutError());
    }
  };
};

export const getPayoutFilterOptions = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getPayoutFilterOptionsLoading());
    try {
      dispatch(
        getPayoutFilterOptionsSuccess(await getPayoutFilterOptionsFromAPI())
      );
    } catch (e) {
      dispatch(getPayoutFilterOptionsError());
    }
  };
};

export const payoutInventories = (
  inventoriesToPay: Inventory[],
  consignersAndInventories: any
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(payoutInventoriesLoading());
    try {
      // @ts-ignore
      const { id, inventories } = await payoutInventoriesFromAPI(
        inventoriesToPay,
        consignersAndInventories
      );
      dispatch(payoutInventoriesSuccess(inventories));
      return id;
    } catch (e) {
      dispatch(payoutInventoriesError());
    }
  };
};

export const exportPayoutInventories = (inventoriesToExport: Inventory[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(exportPayoutInventoriesLoading());
    try {
      dispatch(
        exportPayoutInventoriesSuccess(
          await exportPayoutInventoriesFromAPI(inventoriesToExport)
        )
      );
    } catch (e) {
      dispatch(exportPayoutInventoriesError());
    }
  };
};

export const markPaidInventories = (
  payoutId: string,
  consignersInventoryToBeMarkedAsPaid: any
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(markPaidInventoriesLoading());
    try {
      dispatch(
        markPaidInventoriesSuccess(
          await markPaidInventoriesFromAPI(
            payoutId,
            consignersInventoryToBeMarkedAsPaid
          )
        )
      );
    } catch (e) {
      dispatch(markPaidInventoriesError());
    }
  };
};

export const retryAutoPayout = (
  payoutId: string,
  consignersAndInventories: Inventory[]
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(retryAutoPayoutLoading());
    try {
      dispatch(
        retryAutoPayoutSuccess(
          await retryAutoPayoutFromAPI(payoutId, consignersAndInventories)
        )
      );
    } catch (e) {
      dispatch(retryAutoPayoutError());
    }
  };
};
