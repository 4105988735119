import {
  StoreSalePayout,
  Employee,
  ShopReport,
} from "../reducers/EmployeeReducer";
import { AppDispatch } from "../store";
import {
  ACCEPT_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  GET_CONSIGNER_SALE_PAYOUT,
  GET_DASHBOARD_REPORT,
  GET_EMPLOYEE,
  GET_EMPLOYEES,
  GET_SHOP_REPORT,
  SEND_EMPLOYEE_INVITE,
  UPDATE_EMPLOYEE,
} from "./types";
import { FormValues as EmployeeFormValues } from "../../components/Common/EmployeeForm";
import { FormValues as InventoryFilterValues } from "../../components/Common/InventoryFilter";
import { message } from "antd";
import { EmployeeActive } from "../../components/Common/PeopleTable";
import { api } from "../../api";

const getEmployeesLoading = () => ({
  type: GET_EMPLOYEES,
  employees: [],
  employeesLoading: true,
});

const getEmployeesError = () => ({
  type: GET_EMPLOYEES,
  employees: [],
  employeesLoading: false,
});

const getEmployeesSuccess = (employees: Employee[]) => ({
  type: GET_EMPLOYEES,
  employees,
  employeesLoading: false,
});

const getEmployeeLoading = () => ({
  type: GET_EMPLOYEE,
  employee: null,
  employeeLoading: true,
});

const getEmployeeError = () => ({
  type: GET_EMPLOYEE,
  employee: null,
  employeeLoading: false,
});

const getEmployeeSuccess = (employee: Employee) => ({
  type: GET_EMPLOYEE,
  employee,
  employeeLoading: false,
});
const acceptEmployeeInviteLoading = () => ({
  type: ACCEPT_EMPLOYEE,
  acceptedEmployee: null,
  acceptEmployeeInviteLoading: true,
});

const acceptEmployeeInviteError = () => ({
  type: ACCEPT_EMPLOYEE,
  acceptedEmployee: null,
  acceptEmployeeInviteLoading: false,
});

const acceptEmployeeInviteSuccess = (acceptedEmployee: Employee) => ({
  type: ACCEPT_EMPLOYEE,
  acceptedEmployee,
  acceptEmployeeInviteLoading: false,
});
const createEmployeeLoading = () => ({
  type: CREATE_EMPLOYEE,
  createdEmployee: null,
  createEmployeeLoading: true,
});

const createEmployeeError = () => ({
  type: CREATE_EMPLOYEE,
  createdEmployee: null,
  createEmployeeLoading: false,
});

const createEmployeeSuccess = (createdEmployee: Employee) => ({
  type: CREATE_EMPLOYEE,
  createdEmployee,
  createEmployeeLoading: false,
});
const updateEmployeeLoading = () => ({
  type: UPDATE_EMPLOYEE,
  updatedEmployee: null,
  updateEmployeeLoading: true,
});

const updateEmployeeError = () => ({
  type: UPDATE_EMPLOYEE,
  updatedEmployee: null,
  updateEmployeeLoading: false,
});

const updateEmployeeSuccess = (updatedEmployee: EmployeeActive) => ({
  type: UPDATE_EMPLOYEE,
  updatedEmployee,
  updateEmployeeLoading: false,
});

const getShopReportLoading = () => ({
  type: GET_SHOP_REPORT,
  shopReport: {
    inventoriesByAge: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    inventoriesPendingPayouts: [],
    inventoriesPendingPriceRequests: [],
    inventoriesPendingWithdrawalRequests: [],
    consignersBySalesVolume: [],
    sales: 0,
    salesGrowth: 0,
    dailySales: [],
    profit: 0,
    profitGrowth: 0,
    dailyProfits: [],
    activeListings: 0,
    activeListingsGrowth: 0,
    dailyActiveListings: [],
    newListings: 0,
    newListingsGrowth: 0,
    dailyNewListings: [],
  },
  shopReportLoading: true,
});


const getDashboardLoading = () => ({
  type: GET_DASHBOARD_REPORT,
  dashBoardLoading: true,
});

const storeSalePayoutLoading = () => ({
  type: GET_CONSIGNER_SALE_PAYOUT,
  storeSalePayout: {
    inventoriesPendingPayouts: [],
    profit: 0,
  },
  storeSalePayoutLoading: true,
});

const getShopReportError = () => ({
  type: GET_SHOP_REPORT,
  shopReport: {
    inventoriesByAge: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    inventoriesPendingPayouts: [],
    inventoriesPendingPriceRequests: [],
    inventoriesPendingWithdrawalRequests: [],
    consignersBySalesVolume: [],
    sales: 0,
    salesGrowth: 0,
    dailySales: [],
    profit: 0,
    profitGrowth: 0,
    dailyProfits: [],
    activeListings: 0,
    activeListingsGrowth: 0,
    dailyActiveListings: [],
    newListings: 0,
    newListingsGrowth: 0,
    dailyNewListings: [],
  },
  shopReportLoading: false,
});

const storeSalePayoutError = () => ({
  type: GET_CONSIGNER_SALE_PAYOUT,
  storeSalePayout: {
    inventoriesPendingPayouts: [],
    profit: 0,
  },
  storeSalePayoutLoading: false,
});

const getShopReportSuccess = (shopReport: ShopReport) => ({
  type: GET_SHOP_REPORT,
  shopReport,
  shopReportLoading: false,
});

const getDashboardReportSuccess = (dashboardReport: any) => ({
  type: GET_DASHBOARD_REPORT,
  dashboardReport,
  dashboardLoading: false,
});
const storeSalePayoutSuccess = (storeSalePayout: StoreSalePayout) => ({
  type: GET_CONSIGNER_SALE_PAYOUT,
  storeSalePayout,
  storeSalePayoutLoading: false,
});
const sendEmployeeInviteLoading = () => ({
  type: SEND_EMPLOYEE_INVITE,
  sendEmployeeInviteLoading: true,
});

const sendEmployeeInviteError = () => ({
  type: SEND_EMPLOYEE_INVITE,
  sendEmployeeInviteLoading: false,
});

const sendEmployeeInviteSuccess = () => ({
  type: SEND_EMPLOYEE_INVITE,
  sendEmployeeInviteLoading: false,
});
const deleteEmployeeLoading = () => ({
  type: DELETE_EMPLOYEE,
  deleteEmployeeLoading: true,
});
const deleteEmployeeError = () => ({
  type: DELETE_EMPLOYEE,
  deleteEmployeeLoading: false,
});
const deleteEmployeeSuccess = (id: string) => ({
  type: DELETE_EMPLOYEE,
  id,
  deleteEmployeeLoading: false,
});

//api
const deleteEmployeeFromAPI = async (id: string, employee: Employee) => {
  await api.provide("post", "/api/employees/deleteEmployee/:id", { id });
  return id;
};

const getEmployeesFromAPI = async (search: string) => {
  const { data, error } = await api.provide("get", "/api/employees", {
    search,
  });
  if (error) throw error;
  return (data?.employees || []) as any;
};

const getEmployeeFromAPI = async (id: string, isOpenCall = false) => {
  const { data, error } = await api.provide(
    "get",
    isOpenCall ? "/open/employees/:id" : "/api/employees/:id",
    {
      id,
      type: "item",
    }
  );
  if (error) throw error;
  return data;
};

const sendEmployeeInviteFromAPI = async (employee: Employee) => {
  const { error } = await api.provide("post", "/api/employees/send/:id", {
    id: employee.id,
  });
  message.success(error ? "Error sending invite" : "Sent invite");
};

const createEmployeeFromAPI = async (
  employeeFormValues: EmployeeFormValues
) => {
  const { data, error } = await api.provide("post", "/api/employees", {
    email: employeeFormValues.email,
    firstName: employeeFormValues.firstName,
    lastName: employeeFormValues.lastName,
  });
  if (error) throw error;
  return data;
};

const acceptEmployeeInviteFromAPI = async (
  id: string,
  fbUserId: string,
  isOpenCall = false
) => {
  const { data, error } = await api.provide(
    "put",
    isOpenCall ? "/open/employees/accept/:id" : "/api/employees/accept/:id",
    {
      id,
      fbUserId,
    }
  );
  if (error) throw error;
  return data;
};

const updateEmployeeFromAPI = async (
  id: string,
  updatedEmployee: EmployeeActive
) => {
  const { data, error } = await api.provide("put", "/api/employees/:id", {
    id,
    accessControls: updatedEmployee.accessControls,
    ...(updatedEmployee.isActive !== undefined && {
      isActive: updatedEmployee.isActive,
    }),
  });
  if (error) throw error;
  return data as any;
};

const getShopReportFromAPI = async (
  inventoryFilter: InventoryFilterValues,
  dateRange: string[]
) => {
  const date = new Date().toISOString();
  var dateRangeEnd;

  if (date.substring(0, 10) === dateRange[1]) {
    var dateOneDayAhead = new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).toISOString();
    dateRangeEnd = dateOneDayAhead.substring(0, 10);
  } else {
    dateRangeEnd = dateRange[1];
  }

  const { data, error } = await api.provide(
    "get",
    "/api/inventories/getdasboardInventory",
    {
      dateRangeEnd: dateRangeEnd,
      dateRangeStart: dateRange[0],
    }
  );
  debugger;
  if (error) throw error;
  return data as any;
};

const getDashboardReportFromApi = async (dateRange: any) => {
  const { data, error } = await api.provide(
    "get",
    "/api/inventories/getdasboardInventory",
    {
      dateRangeStart: dateRange[0], // Assuming startDate is the start date property in your dateRange object
      dateRangeEnd: dateRange[1], // Assuming endDate is the end date property in your dateRange object
    }
  );

  if (error) throw error;
  return data as any;
};

const storeSalePayoutFromAPI = async (
  dateRange: string[],
  detailed?: number
) => {
  const date = new Date().toISOString();
  var dateRangeEnd;

  if (date.substring(0, 10) === dateRange[1]) {
    var dateOneDayAhead = new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).toISOString();
    dateRangeEnd = dateOneDayAhead.substring(0, 10);
  } else {
    dateRangeEnd = dateRange[1];
  }
  const params: any = {
    dateRangeEnd,
    dateRangeStart: dateRange[0],
  };

  if (detailed) params.detailed = detailed;

  const { data, error } = await api.provide(
    "get",
    "/api/employees/storeSaleSummary",
    params
  );
  if (error) throw error;
  return data as any;
};

//actions
export const getEmployee = (id: string, isOpenCall = false) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getEmployeeLoading());
    try {
      dispatch(getEmployeeSuccess(await getEmployeeFromAPI(id, isOpenCall)));
    } catch (e) {
      dispatch(getEmployeeError());
    }
  };
};

export const getEmployees = (search: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getEmployeesLoading());
    try {
      dispatch(getEmployeesSuccess(await getEmployeesFromAPI(search)));
    } catch (e) {
      dispatch(getEmployeesError());
    }
  };
};

export const createEmployee = (employeeFormValue: EmployeeFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createEmployeeLoading());
    try {
      dispatch(
        createEmployeeSuccess(await createEmployeeFromAPI(employeeFormValue))
      );
    } catch (e) {
      dispatch(createEmployeeError());
    }
  };
};

export const acceptEmployeeInvite = (
  id: string,
  fbUserId: string,
  isOpenCall = false
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(acceptEmployeeInviteLoading());
    try {
      dispatch(
        acceptEmployeeInviteSuccess(
          await acceptEmployeeInviteFromAPI(id, fbUserId, isOpenCall)
        )
      );
    } catch (e) {
      dispatch(acceptEmployeeInviteError());
    }
  };
};

export const updateEmployee = (id: string, updatedEmployee: EmployeeActive) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateEmployeeLoading());
    try {
      dispatch(
        updateEmployeeSuccess(await updateEmployeeFromAPI(id, updatedEmployee))
      );
    } catch (e) {
      dispatch(updateEmployeeError());
    }
  };
};

export const deleteEmployee = (id: string, employee: Employee) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteEmployeeLoading());
    try {
      dispatch(
        deleteEmployeeSuccess(await deleteEmployeeFromAPI(id, employee))
      );
    } catch (e) {
      dispatch(deleteEmployeeError());
    }
  };
};

export const getShopReport = (
  inventoryFilter: InventoryFilterValues,
  dateRange: string[]
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getShopReportLoading());
    try {
      dispatch(
        getShopReportSuccess(
          await getShopReportFromAPI(inventoryFilter, dateRange)
        )
      );
    } catch (e) {
      dispatch(getShopReportError());
    }
  };
};

export const getDashboardReport = (dateRange: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getDashboardLoading());
    try {
      dispatch(
        getDashboardReportSuccess(await getDashboardReportFromApi(dateRange))
      );
    } catch (e) {
      dispatch(getShopReportError());
    }
  };
};

export const getStoreSalesPayout = (dateRange: string[], detailed?: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(storeSalePayoutLoading());
    try {
      dispatch(
        storeSalePayoutSuccess(
          await storeSalePayoutFromAPI(dateRange, detailed)
        )
      );
    } catch (e) {
      dispatch(storeSalePayoutError());
    }
  };
};

export const sendEmployeeInvite = (employee: Employee) => {
  return async (dispatch: AppDispatch) => {
    dispatch(sendEmployeeInviteLoading());
    try {
      await sendEmployeeInviteFromAPI(employee);
      dispatch(sendEmployeeInviteSuccess());
    } catch (e) {
      dispatch(sendEmployeeInviteError());
    }
  };
};
