import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal, Select } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProducts, getStoreProducts } from "../redux/actions/productActions";
import ProductList from "../components/Common/ProductList";
import { Link, Redirect } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import {
  getConsignerStoreRequestedItemsFromAPI,
  getInventoryFilterOptions,
} from "../redux/actions/inventoryActions";
import RedesignStyling from "../constants/RedesignStyling";
import Colors from "../constants/Colors";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as FilterOutlined } from "../assets/images/svg/Location.svg";
const { Search } = Input;
const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${RedesignStyling.PAGE_PADDING};
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
  }

  span#header-span {
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      & > span {
        display: none;
      }
    }
  }

  div#storerequest-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      display: none;
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      button {
        display: inline-flex;
        flex-direction: column;
      }
    }
  }
`;

const Title = styled.p`
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  width: 100%;
  margin-top: 20px;
`;

const Counter = styled.span`
  color: var(--Text---Grey, #989898);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 8px 16px;
  // width: 100%;
  flex: 1;
  & > span {
    width: 900px;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.CONSIGNER_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;
    // order: 2;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

const SearchBoxWrapper = styled.div`
  position relative;
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    padding-left: 32px; // Adjust padding to make room for the icon
    &:hover {
      border-color: #40a9ff;
    }

    &:focus,
    &:active {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    z-index: 2;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

const SearchInput = styled(Input)`
  width: 100%;
  height: 32px; // Adjust the height as needed to match your screenshot

  .ant-input {
    height: 100%;
  }
`;

const SearchBoxCustom = ({
  placeholder,
  onSearch,
}: {
  placeholder: string;
  onSearch: any;
}) => {
  return (
    <SearchBoxWrapper>
      <SearchOutlined className="search-icon" />
      <SearchInput
        data-testid="search"
        placeholder={placeholder}
        allowClear
        size="large"
        onChange={(data) => onSearch(data.target.value)}
        style={{ flex: 1 }}
      />
    </SearchBoxWrapper>
  );
};

//types
export interface ProductsProps {}

export default function ConsignerStoreRequest({}: ProductsProps): JSX.Element {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [products, setProducts] = useState<any[]>([]);
  const [requestedInventories, setRequestedInventories] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [sale, setSale] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    acceptInventory,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  const onLocationSelect = (value: any) => {
    setSelectedLocation(value);
  };

  const onSaleSelect = (value: any) => {
    setSale(value);
  };

  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  const fetchConsignerItems = async (
    loc: string,
    search: string,
    saleType: string
  ) => {
    const data = await getConsignerStoreRequestedItemsFromAPI(
      dbUser.id,
      loc,
      search,
      saleType
    );
    setProducts(data.products);
    setRequestedInventories(data.inventories);
    setLoading(false);
  };

  useEffect(() => {
    //get products here any time search updates
    setLoading(true);
    fetchConsignerItems(selectedLocation, search, sale);
  }, [search, selectedLocation, acceptInventory, sale]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container data-testid="ProductsScreen">
      <span id="header-span">
        <Modal
          title="Filter"
          visible={showFilterModal}
          onCancel={() => setShowFilterModal(false)}
          footer={() => null}
        >
          <span>
            {inventoryFilterOptions && !inventoryFilterOptionsLoading && (
              <Select
                allowClear
                placeholder="Select location"
                size={"large"}
                style={{
                  width: 150,
                  marginRight: 10,
                  flex: "0 0 200px",
                  height: 40,
                }}
                value={selectedLocation}
                onChange={(value: string) => {
                  onLocationSelect(value);
                }}
                filterOption={(input, option) =>
                  String(option?.children)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(node) => node.parentNode}
              >
                <Option key="default" value="">
                  All locations
                </Option>
                {inventoryFilterOptions.locations?.map((location: any) => (
                  <Option key={location.id} value={location.label}>
                    {location.name}
                  </Option>
                ))}
              </Select>
            )}
            <Select
              allowClear
              placeholder="Type of sale"
              size={"large"}
              style={{
                width: 150,
                marginRight: 10,
                flex: "0 0 200px",
                height: 40,
              }}
              onChange={(value: string) => {
                onSaleSelect(value);
              }}
              getPopupContainer={(node) => node.parentNode}
            >
              <Option key="default" value="">
                All
              </Option>
              <Option key="buying" value="Buying">
                Buying
              </Option>
              <Option key="consignment" value="Consignment">
                Consignment
              </Option>
            </Select>
          </span>
        </Modal>
        <SearchBoxCustom
          placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          onSearch={searchProducts}
        />
        <span>
          {inventoryFilterOptions && !inventoryFilterOptionsLoading && (
            <Select
              allowClear
              placeholder="Select location"
              size={"large"}
              style={{
                width: 150,
                marginRight: 10,
                flex: "0 0 200px",
                height: 40,
              }}
              value={selectedLocation}
              onChange={(value: string) => {
                onLocationSelect(value);
              }}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(node) => node.parentNode}
            >
              <Option key="default" value="">
                All locations
              </Option>
              {inventoryFilterOptions.locations?.map((location: any) => (
                <Option key={location.id} value={location.label}>
                  {location.name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            allowClear
            placeholder="Type of sale"
            size={"large"}
            style={{
              width: 150,
              marginRight: 10,
              flex: "0 0 200px",
              height: 40,
            }}
            onChange={(value: string) => {
              onSaleSelect(value);
            }}
            getPopupContainer={(node) => node.parentNode}
          >
            <Option key="default" value="">
              All
            </Option>
            <Option key="buying" value="Buying">
              Buying
            </Option>
            <Option key="consignment" value="Consignment">
              Consignment
            </Option>
          </Select>
        </span>
      </span>
      <div id="storerequest-title">
        <Title>
          Store Request <Counter>{products.length}</Counter>
        </Title>
        <Button
          type="link"
          onClick={() => setShowFilterModal(true)}
          icon={<FilterOutlined />}
        />
      </div>
      <ProductList
        data-testid="productList"
        products={products}
        loading={loading}
        productTemplates={productTemplates}
        updatedProduct={null}
        dispatch={dispatch}
        selectedLocation={selectedLocation}
        storeRequest={true}
        requestedInventories={requestedInventories}
      />
    </Container>
  );
}
