import React, { useEffect, useState } from "react";
import "./ConsignerProducts.css";
import { getProducts } from "../redux/actions/productActions";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { UserState } from "../redux/reducers/UserReducer";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import Colors from "../constants/Colors";
import Content from "../constants/Content";
import ProductList from "../components/Common/ProductList";
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Select,
  InputNumber,
  message,
  Image,
  Checkbox,
  Collapse,
  Spin,
  Statistic,
  Tooltip,
} from "antd";
const { Search } = Input;
import {
  checkPreapprovalStatusFromAPI,
  getInventories,
  getInventoryFilterOptions,
  resetCreatedInventory,
  setInventoriesToAddAction,
} from "../redux/actions/inventoryActions";
import {
  Modal,
  Box,
  Typography,
  Slide,
  Link,
  ButtonGroup,
} from "@mui/material";
import { handleConsignerAddInventory } from "../components/Common/InventoryAddModal";
import { AppDispatch } from "../redux/store";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { useHistory } from "react-router-dom";
import RedesignStyling from "../constants/RedesignStyling";
import { AppState } from "../redux/reducers/AppReducer";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import SuccessImg from "../assets/images/Success.png";
import _ from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";
import { Footer } from "antd/lib/layout/layout";
import { MobileFooter, PriceChangeHandler } from "./ConsignerInventory";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { CustomStatus } from "../styles/ConsignerInventory";
import {
  InventoryProductMobileContainer,
  ProductDetailsPanel,
  ProductReviewSpan,
} from "../styles/ConsignerInventoryMobile";

const Logo = styled.img`
  height: 40px;
  width: auto;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${RedesignStyling.PAGE_BG};
  position: relative;
  width: 100%;
  padding: ${RedesignStyling.PAGE_PADDING};
  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
  width: 100%;
  & > span {
    width: 900px;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.CONSIGNER_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
    padding: ${RedesignStyling.BUTTON_PADDING};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

export const ActionItem = styled.span`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  p {
    font-size: 14px;
    color: #7f8080;
    margin: 0;
  }
  b {
    font-size: 20px;
    color: ${Colors.CONSIGNER_BLUE};
    text-align: center;
    font-weight: 500;
    line-height: 26px;
  }
  img {
    height: 30px;
    font-size: 30px;
    width: auto;
  }
  button {
    display: block;
    border: 0;
    padding: 16px;
    height: 52px;
    width: 100%;
  }
  @media (max-width: 768px) {
    height: 60px;
    justify-content: space-between;
    &:last-of-type {
      flex-basis: 100%;
      margin-top: 28px;
    }
  }
`;

export const FooterCustom = styled.div`
  display: block;
  padding: 8px ${process.env.REACT_APP_TYPE === "employee" ? "36px" : "8px"};
  background-color: #f9fafb;
  position: fixed;
  bottom: 0;
  left: ${process.env.REACT_APP_TYPE === "employee" ? "200px" : "0"};
  width: ${process.env.REACT_APP_TYPE === "employee"
    ? "calc(100% - 200px)"
    : "100%"};
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;
    background-color: ${Colors.WHITE};
    border: 0;
    box-shadow: ${Colors.INPUT_BORDER};
  }
  @media (max-width: 768px) {
    padding: 8px;
    max-width: 100vw;
    width: 100%;
    left: 0;
    div {
      flex-wrap: wrap;
      padding: 12px 24px;
    }
  }
`;

export const ModalHeadAction = styled.div`
  display: block;
  padding: 24px;
  background-color: ${Colors.WHITE};
  width: 100%;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;
    background-color: ${Colors.WHITE};
    border: 0;
    box-shadow: ${Colors.INPUT_BORDER};
  }
  @media (max-width: 768px) {
    padding: 0;
    div {
      flex-wrap: wrap;
      max-width: 100vw;
    }
  }
`;

const ReviewListingContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    order: 5;
    padding-bottom: 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;

export const Listing = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: #fff;
  .ant-image {
    flex: 0 0 85px;
    img {
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  label {
    flex: 0 0 35px;
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const ReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 400;
  position: relative;
  font-size: 11px;
  button {
    border: 0;
    color: ${Colors.CONSIGNER_BLUE};
  }
  b {
    font-size: 12px;
    max-width: 60%;
  }
  div {
    color: ${Colors.CONSIGNER_GREY};
    text-transform: uppercase;
  }
`;

const DetailsPanel = styled.div`
  display: flex;
  flex: 5 1 auto;
  padding: 4px 16px;
  flex-direction: column;
`;

const CustomHdr = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin: 50px 0;
  text-align: left;
`;

export const CollapsibleReviewListingView = (props: any) => {
  const { inventoryList, handleDelete, store } = props;
  const [activeKey, setActiveKey] = useState("");

  const CollapseHeader = (record: any) => {
    const { activeKey, image, title, keyItem, quantity } = record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 14px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 14px" }} />
      );
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img width={52.5} src={image} />
        <span
          style={{
            flex: 4,
            padding: "4px 0 4px 16px",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {title}
        </span>
        <span
          style={{
            flex: 2,
            padding: "4px 8px 4px 0",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {quantity} items
        </span>
        {expandIcon}
      </div>
    );
  };

  const handleCollapseChange = (key) => {
    console.log(key);
    setActiveKey(key);
  };
  // return <Spin />
  return (
    <ReviewListingContainer>
      <Collapse
        accordion
        expandIcon={() => null}
        activeKey={activeKey}
        onChange={handleCollapseChange}
      >
        {inventoryList?.map((inventory, key) => {
          1;
          const { inventories } = inventory;
          return (
            <CollapsePanel
              key={`${inventory.productId}`}
              header={
                <CollapseHeader
                  keyItem={`${inventory.productId}`}
                  activeKey={activeKey}
                  image={inventory.productImage}
                  title={inventory.productTitle}
                  quantity={inventories?.length ?? 1}
                />
              }
            >
              {inventories?.map((record, recordKey) => (
                <Listing key={recordKey}>
                  <Image
                    src={record?.product?.image ?? record.productImage}
                  ></Image>
                  <DetailsPanel>
                    <ReviewSpan>
                      <b>{record?.product?.title ?? record.productName}</b>
                      <b>
                        {getSymbolFromCurrency(store.currency)}{" "}
                        {record.status === "StoreRequestPriceChange"
                          ? record.requestedPrice
                          : record.price}
                      </b>
                    </ReviewSpan>
                    <ReviewSpan>
                      <div>
                        {record.code} |{record?.product?.sku ?? record.sku} |{" "}
                        {record.option1Value} | {record.option2Value}
                      </div>
                      <Link
                        target="_blank"
                        href={`/productItem/${record?.product.id}`}
                      >
                        <Button type="ghost" icon={<EditOutlined />}></Button>
                      </Link>
                    </ReviewSpan>
                  </DetailsPanel>
                </Listing>
              ))}
            </CollapsePanel>
          );
        })}
      </Collapse>
    </ReviewListingContainer>
  );
};

export const ReviewListingView = (props: any) => {
  const {
    inventoryList,
    handleDelete,
    store,
    isInventory,
    isDropOff,
    nestedView,
    groupedData,
    isPriceChange,
    handleAcceptInventory,
  } = props;
  const [splitInventory, setSplitInventory] = useState<any[]>([]);
  useEffect(() => {
    if (nestedView) return;
    if (isInventory) {
      setSplitInventory(
        inventoryList.map((inventory) => ({
          ...inventory,
          currency: store.currency,
        }))
      );
      return;
    }
    const inventoryArray: any[] = [];
    inventoryList.forEach((inventory: any) => {
      for (let i = 0; i < inventory.quantity; i++) {
        inventoryArray.push({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        });
      }
    });
    setSplitInventory(inventoryArray);
  }, [inventoryList, nestedView]);

  return (
    <>
      {nestedView ? (
        <CollapsibleReviewListingView
          inventoryList={groupedData} //  add currency
          store={store}
          handleDelete={null}
        />
      ) : (
        <ReviewListingContainer>
          {splitInventory.map((record, key) => (
            <Listing key={key}>
              {isDropOff && (
                <Checkbox
                  checked={props.selectedRowKeys.includes(record.id)}
                  onChange={(e) =>
                    props.handleSelect(e.target.checked, record.id)
                  }
                  style={{ padding: "0 10px" }}
                />
              )}
              <Image
                src={record?.product?.image ?? record.productImage}
              ></Image>
              <DetailsPanel>
                <ReviewSpan>
                  <b>{record?.product?.title ?? record.productName}</b>
                  <b>
                    {getSymbolFromCurrency(store.currency)}{" "}
                    {record.status === "StoreRequestPriceChange"
                      ? record.requestedPrice
                      : record.price}
                  </b>
                </ReviewSpan>
                <ReviewSpan>
                  <div>
                    {isInventory && <>{record.code} |</>}
                    {record?.product?.sku ?? record.sku} | {record.option1Value}{" "}
                    | {record.option2Value}
                  </div>
                  {isInventory ? (
                    isDropOff ? (
                      <Popconfirm
                        title="Do you want to cancel this Dropoff?"
                        onConfirm={() => handleDelete([record.id])}
                        okText="Confirm"
                        okButtonProps={{ type: "primary" }}
                        cancelButtonProps={{ type: "text" }}
                        icon={false}
                      >
                        <Button
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                        ></Button>
                        {/* <Button danger type="primary">Cancel</Button> */}
                      </Popconfirm>
                    ) : isPriceChange ? (
                      <ButtonGroup>
                        <Popconfirm
                          title="Do you want to accept this price change request?"
                          onConfirm={() => handleAcceptInventory(record)}
                          okText="Confirm"
                          okButtonProps={{ type: "primary" }}
                          cancelButtonProps={{ type: "text" }}
                          icon={false}
                        >
                          <Button type="text" icon={<CheckCircleOutlined />} />
                        </Popconfirm>
                        <Popconfirm
                          title="Do you want to cancel this Dropoff?"
                          onConfirm={() => handleDelete([record.id])}
                          okText="Confirm"
                          okButtonProps={{ type: "primary" }}
                          cancelButtonProps={{ type: "text" }}
                          icon={false}
                        >
                          <Button
                            type="text"
                            danger
                            icon={<DeleteOutlined />}
                          ></Button>
                        </Popconfirm>
                      </ButtonGroup>
                    ) : (
                      <Link
                        href={
                          process.env.REACT_APP_TYPE === "consigner"
                            ? `/productItem/${record?.product.id}`
                            : `/products/${record?.product.id}`
                        }
                      >
                        <Button type="ghost" icon={<EditOutlined />}></Button>
                      </Link>
                    )
                  ) : (
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => handleDelete(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Popconfirm>
                  )}
                </ReviewSpan>
              </DetailsPanel>
            </Listing>
          ))}
        </ReviewListingContainer>
      )}
    </>
  );
};

export const InventoryProductMobile = (props: any) => {
  const {
    inventoryList,
    handleDelete,
    store,
    isInventory,
    nestedView,
    groupedData,
  } = props;
  const [splitInventory, setSplitInventory] = useState<any[]>([]);
  useEffect(() => {
    if (nestedView) return;
    if (isInventory) {
      setSplitInventory(
        inventoryList.map((inventory) => ({
          ...inventory,
          currency: store.currency,
        }))
      );
      return;
    }
    const inventoryArray: any[] = [];
    inventoryList.forEach((inventory: any) => {
      for (let i = 0; i < inventory.quantity; i++) {
        inventoryArray.push({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        });
      }
    });
    setSplitInventory(inventoryArray);
  }, [inventoryList, nestedView]);

  const skuController = (sku: any) => {
    if (sku.length > 10) {
      return sku.slice(0, 10) + "...";
    } else {
      return sku;
    }
  };

  return (
    <>
      {nestedView ? (
        <CollapsibleReviewListingView
          inventoryList={groupedData} //  add currency
          store={store}
          handleDelete={null}
        />
      ) : (
        <InventoryProductMobileContainer>
          {splitInventory.map((record, key) => (
            <Listing key={key}>
              <div className="status-image-div">
                <CustomStatus
                  className={
                    record.status === "Sold" ? "bullet green" : "bullet red"
                  }
                >
                  {record?.status === "Pending"
                    ? "Drop off pending"
                    : record.status}
                </CustomStatus>
                <Image
                  src={record?.product?.image ?? record.productImage}
                ></Image>
              </div>
              <ProductDetailsPanel>
                <ProductReviewSpan>
                  <b>
                    {`${record?.product?.title} `}
                    <Tooltip
                      placement="right"
                      title={`Product ID: ${record.code}`}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </b>
                </ProductReviewSpan>
                <ProductReviewSpan>
                  <div className="product-features">
                    <Statistic
                      title="SKU"
                      value={skuController(record?.product?.sku ?? record.sku)}
                    />
                    <Statistic title="Size" value={record.option1Value} />
                    <Statistic title="Condition" value={record.option2Value} />
                    <Statistic
                      title="Payout"
                      value={store?.currency + " " + record?.payoutAmount}
                    />
                  </div>
                </ProductReviewSpan>
                <div className="price-change-request">
                  <PriceChangeHandler record={record} />
                  <Link
                    target="_blank"
                    href={`/productItem/${record.productId}`}
                  >
                    <Button
                      className="edit-product-icon"
                      icon={<EditOutlined />}
                    ></Button>
                  </Link>
                </div>
              </ProductDetailsPanel>
            </Listing>
          ))}
        </InventoryProductMobileContainer>
      )}
    </>
  );
};

interface ReviewModalProps {
  open: boolean;
  handleClose: any;
  inventoriesToAdd: Array<any>;
  addInventoriesLoading: boolean;
  setAddInventoriesLoading: any;
  dispatch: AppDispatch;
  setInventoriesToAdd: any;
}

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const ReviewModalTable = (props: any): JSX.Element => {
  const {
    inventoryList,
    setInventoryList,
    inventoriesLoading,
    header,
    store,
    isMobile,
  } = props;

  const handleDelete = (key: React.Key) => {
    const newData = inventoryList.filter((item: any) => item.key !== key);
    setInventoryList(newData);
  };

  const handleRemoveInventory = (inventory: any) => {
    const inventoryIndex = inventoryList.findIndex(
      (item) =>
        item.option1Value === inventory.option1Value &&
        item.option2Value === inventory.option2Value &&
        item.option3Value === inventory.option3Value &&
        item.productId === inventory.productId &&
        item.template.id === inventory.template.id
    );
    const targetItem = inventoryList[inventoryIndex];
    if (targetItem.quantity - 1 > 0) {
      let newInventory = {
        ...targetItem,
        quantity: targetItem.quantity - 1,
      };
      setInventoryList([
        ...inventoryList.filter((inv, idx) => idx !== inventoryIndex),
        newInventory,
      ]);
    } else {
      setInventoryList([
        ...inventoryList.filter((inv, idx) => idx !== inventoryIndex),
      ]);
    }
  };

  let visibleColumns: ColumnTypes[number][] = [
    {
      title: "Product",
      key: "productImage",
      dataIndex: "productImage",
      render: (_: any, record: any) => (
        <Image
          src={record.productImage}
          alt=""
          style={{ width: 50, height: "auto" }}
        ></Image>
      ),
    },
    {
      title: "Name",
      key: "productName",
      dataIndex: "productName",
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "sku",
    },
    {
      title: "Size",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value,
    },
    {
      title: "Condition",
      dataIndex: "option2Value",
      render: (_: any, record: any) => record?.option2Value,
    },
    {
      title: "Location",
      dataIndex: "option3Value",
      render: (_: any, record: any) => record?.option3Value,
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "Payout",
      key: "total",
      dataIndex: "total",
    },
    {
      title: "Delete",
      dataIndex: "operation",
      render: (_: any, record: any) =>
        inventoryList?.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  if (process.env.REACT_APP_TYPE === "employee") {
    visibleColumns.splice(5, 1, {
      title: "Consigner",
      dataIndex: "consigner",
      render: (_: any, record: any) => {
        const { consigner } = record;
        let consignerData = JSON.parse(consigner);
        return `${consignerData?.firstName} ${consignerData?.lastName}`;
      },
    });
  }

  return (
    <div className="reviewModalContainer" style={{ width: "100%" }}>
      {header && header !== "" && <CustomHdr>{header}</CustomHdr>}

      {isMobile ? (
        <ReviewListingView
          inventoryList={inventoryList}
          handleDelete={handleRemoveInventory}
          store={store}
        />
      ) : (
        <Table
          className="ReviewModalTable"
          dataSource={inventoryList}
          columns={visibleColumns as ColumnTypes}
          size="large"
          pagination={false}
          loading={inventoriesLoading}
          rowKey="id"
        />
      )}
    </div>
  );
};

export const CenteredModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-image {
    height: 67px;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 24px 0;
  }
  .MuiButtonGroup-root {
    display: flex;
    height: 65px;
    width: 100%;
  }
`;

const BGButton = styled(Button)`
  height: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

interface PromptModalProps {
  open: boolean;
  setOpen: any;
  heading: string;
  Img?: string;
}

export const PromptModal = (props: PromptModalProps) => {
  const { open, setOpen, heading } = props;
  let Img = props.Img ? props.Img : SuccessImg;

  return (
    <CenteredModal open={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          bgcolor: RedesignStyling.PAGE_BG,
          height: 370,
          width: 420,
        }}
      >
        <Image src={Img} />
        <h1>{heading}</h1>
        <ButtonGroup>
          {/* <BGButton type="ghost" onClick={()=>setOpen(false)}>Add More</BGButton> */}
          <BGButton type="primary" onClick={() => setOpen(false)}>
            Continue
          </BGButton>
        </ButtonGroup>
      </Box>
    </CenteredModal>
  );
};

export const ReviewModal = (props: ReviewModalProps): JSX.Element => {
  const history = useHistory();
  const {
    open,
    handleClose,
    inventoriesToAdd,
    addInventoriesLoading,
    setAddInventoriesLoading,
    dispatch,
    setInventoriesToAdd,
  } = props;
  const [preapprovedInventories, setPreapprovedInventories] = useState<any[]>(
    []
  );
  const [requestInventories, setRequestInventories] = useState<any[]>([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { createdInventory }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const isItemPreapproved = async (item) => {
    return await checkPreapprovalStatusFromAPI(
      item.productId,
      item.price,
      item.option1Value,
      item.option2Value,
      item.option3Value,
      item.consigner
    );
  };

  const assignInventoryItems = async () => {
    console.log("called");
    const approvals: any[] = [];
    const requests: any[] = [];
    setReadyToSubmit(false);
    for (const item of inventoriesToAdd) {
      const status = await isItemPreapproved(item);
      if (status.preapproval)
        approvals.push({
          ...item,
          preapprovalStatus: true,
        });
      else
        requests.push({
          ...item,
          preapprovalStatus: false,
        });
    }
    setPreapprovedInventories(approvals);
    setRequestInventories(requests);
    setReadyToSubmit(true);
  };

  useEffect(() => {
    if (open) {
      assignInventoryItems();
    }
  }, [open]);

  useEffect(() => {
    if (preapprovedInventories.length > 0 || requestInventories.length > 0) {
      console.log(preapprovedInventories, requestInventories);
      setInventoriesToAdd([...preapprovedInventories, ...requestInventories]);
    }
  }, [preapprovedInventories, requestInventories]);

  useEffect(() => {
    if (!_.isEmpty(createdInventory)) {
      console.log(createdInventory);
      setInventoriesToAdd([]);
      handleClose();
      setOpenSuccessModal(true);
      dispatch(resetCreatedInventory());
      setReadyToSubmit(false);
      setPreapprovedInventories([]);
      setRequestInventories([]);
      // history.push("/inventory");
    }
  }, [createdInventory]);
  const handleCancelAddInventory = () => {
    // setInventoriesToAdd([]);
    handleClose();
  };
  return (
    <div>
      <PromptModal
        heading="Your product has been added successfully"
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        Img={SuccessImg}
      />
      <Modal
        open={open}
        onClose={handleClose}
        style={{ zIndex: 99, top: 60 }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        hideBackdrop
      >
        <Slide direction="up" in={open}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              maxWidth: "100vw",
              height: "100%",
              bgcolor: RedesignStyling.PAGE_BG,
              p: 2,
              overflowY: "scroll",
            }}
          >
            <ModalHeadAction>
              <div>
                <ActionItem>
                  <p>
                    <Link
                      style={{ textDecoration: "none" }}
                      onClick={handleCancelAddInventory}
                    >
                      Reviews
                    </Link>
                  </p>
                  <img
                    style={{ transform: "rotateZ(180deg)" }}
                    src="/svg/CaretDown.svg"
                    alt=""
                    id="reviews"
                  />
                </ActionItem>
                <ActionItem>
                  <p>Added Items</p>
                  <b id="addedItems">
                    {inventoriesToAdd.reduce(
                      (sum, inventory: any) => sum + inventory.quantity,
                      0
                    )}
                  </b>
                </ActionItem>
                <ActionItem>
                  <p>Values</p>
                  <b id="values">
                    {getSymbolFromCurrency(store.currency)}
                    {inventoriesToAdd
                      .reduce(
                        (sum, inventory: any) =>
                          sum +
                          Number(inventory.payout ?? 0) * inventory.quantity,
                        0
                      )
                      .toFixed(2)}
                  </b>
                </ActionItem>
                <ActionItem style={{ flexDirection: "row" }}>
                  <Button
                    onClick={handleCancelAddInventory}
                    type="text"
                    style={{
                      width: 166,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="addInventoryBtn"
                    onClick={async () => {
                      await handleConsignerAddInventory(
                        [...preapprovedInventories, ...requestInventories],
                        setAddInventoriesLoading,
                        dispatch
                      );
                    }}
                    type="primary"
                    loading={addInventoriesLoading}
                    style={{
                      width: 166,
                    }}
                    disabled={!readyToSubmit}
                  >
                    Submit
                  </Button>
                </ActionItem>
              </div>
            </ModalHeadAction>
            {preapprovedInventories.length > 0 && (
              <ReviewModalTable
                inventoryList={preapprovedInventories}
                setInventoryList={setPreapprovedInventories}
                inventoriesLoading={addInventoriesLoading}
                header="Pre-Approved"
                store={store}
                isMobile={isMobile}
              />
            )}
            {!readyToSubmit && <Spin />}
            <br />
            {requestInventories.length > 0 && (
              <ReviewModalTable
                inventoryList={requestInventories}
                setInventoryList={setRequestInventories}
                inventoriesLoading={addInventoriesLoading}
                header="Request Drop Off"
                store={store}
                isMobile={isMobile}
              />
            )}
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

//types
export interface ConsignerProductsProps {
  isMobile?: boolean;
}

export default function ConsignerProducts(
  props: ConsignerProductsProps
): JSX.Element {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const { inventoriesToReview }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  const [inventoriesToAdd, setInventoriesToAdd] = useState<any[]>(
    inventoriesToReview ?? []
  );
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [addInventoriesLoading, setAddInventoriesLoading] = useState(false);

  const addToInventories = (items: any[]) => {
    setInventoriesToAdd([...inventoriesToAdd, ...items]);
  };

  const closeReviewModal = () => {
    setOpenReviewModal(false);
  };

  const handleOpenReviewModal = () => {
    if (inventoriesToAdd.length < 1) {
      message.warning("No item has been added for review");
      return;
    }
    window.scrollTo({ top: 0 });
    setOpenReviewModal(true);
  };

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  const { products, updatedProduct, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );
  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  const { store } = useAppSelector((state) => state.StoreReducer);

  useEffect(() => {
    dispatch(getProducts(search));
    //get products here any time search updates
  }, [search]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  useEffect(() => {
    dispatch(setInventoriesToAddAction(inventoriesToAdd));
  }, [inventoriesToAdd]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const searchProducts = (value: string) => {
    setSearch(value);
  };
  return (
    <Container>
      <ReviewModal
        open={openReviewModal}
        handleClose={closeReviewModal}
        inventoriesToAdd={inventoriesToAdd}
        setAddInventoriesLoading={setAddInventoriesLoading}
        addInventoriesLoading={addInventoriesLoading}
        dispatch={dispatch}
        setInventoriesToAdd={setInventoriesToAdd}
      />

      <SearchBox>
        <Search
          data-testid="search"
          placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          allowClear
          enterButton="Search"
          size="large"
          onSearch={searchProducts}
          style={{ fontSize: 16 }}
        />
      </SearchBox>
      <ProductList
        data-testid="productList"
        products={products}
        loading={productsLoading}
        productTemplates={productTemplates}
        updatedProduct={updatedProduct!}
        dispatch={dispatch}
        setInventoriesToAdd={addToInventories}
      />

      <FooterCustom>
        <div>
          <ActionItem>
            <p>
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleOpenReviewModal}
              >
                Reviews
              </Link>
            </p>
            <img src="/svg/CaretDown.svg" alt="" id="reviews" />
          </ActionItem>
          <ActionItem>
            <p>Added Items</p>
            <b id="addedItems">
              {inventoriesToAdd.reduce(
                (sum, inventory: any) => sum + inventory.quantity,
                0
              )}
            </b>
          </ActionItem>
          <ActionItem>
            <p>Values</p>
            <b id="values">
              {getSymbolFromCurrency(store.currency)}
              {inventoriesToAdd
                .reduce(
                  (sum, inventory: any) =>
                    sum + Number(inventory.payout ?? 0) * inventory.quantity,
                  0
                )
                .toFixed(2)}
            </b>
          </ActionItem>
          <ActionItem>
            <Button type="primary" onClick={handleOpenReviewModal}>
              Review Items
            </Button>
          </ActionItem>
        </div>
      </FooterCustom>
    </Container>
  );
}
