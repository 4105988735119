export const convertValue = (value: number, unit: string) => {
  if (value == null) {
    return ""; // Or any default value you want to return for null values
  }

  const million = 1000000;
  const billion = 1000000000;
  const thousand = 1000;
  let formattedValue = "";

  if (Math.abs(value) >= billion) {
    formattedValue = (value / billion).toLocaleString("en-US") + "B";
  } else if (Math.abs(value) >= million) {
    formattedValue = (value / million).toLocaleString("en-US") + "M";
  }
  //  else if (Math.abs(value) >= thousand) {
  //   formattedValue = (value / thousand).toLocaleString("en-US") + "K";
  // }
  else {
    formattedValue = Math.round(value).toLocaleString("en-US");
  }

  return unit ? formattedValue : formattedValue;
};
