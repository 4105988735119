import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Input,
  Modal,
  Spin,
  Select,
  message,
  Switch,
  Pagination,
  BackTop,
} from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getOrders } from "../redux/actions/orderActions";
import OrderFilter from "../components/Common/OrderFilter";
import {
  getInventoryFilterOptions,
  getInventories,
  transferInventories,
  getPaginatedInventories,
} from "../redux/actions/inventoryActions";
import { RouteComponentProps, useHistory, Redirect } from "react-router";
import InventoryList from "../components/Common/InventoryList";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { AppDispatch } from "../redux/store";
import { UserState } from "../redux/reducers/UserReducer";
import InventoryFilter from "../components/Common/InventoryFilter";
import { getSubLocations } from "../redux/actions/subLocationActions";
import RedesignStyling from "../constants/RedesignStyling";

const { Option } = Select;

const { Search } = Input;

/**
 * Order Screen
 * Renders table of orders and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  padding-bottom: 100px;
`;
const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const ActionButtonsContainer = styled.div`
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const LabelTitle = styled.p`
  margin-top: 10px;
`;
const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};

const transferInventoriesData = async (
  dispatch: AppDispatch,
  selectedInventories: Inventory[],
  setShowTransferModal: (show: boolean) => void,
  location: string,
  shouldOverrideLocationOption: boolean,
  locationOption: string
) => {
  if (location && location !== "" && location !== "undefined") {
    //transfer inventories
    await dispatch(
      transferInventories(
        selectedInventories,
        location,
        shouldOverrideLocationOption,
        locationOption
      )
    );
    setShowTransferModal(false);
  } else {
    message.error("Please select a location");
  }
};

const Transfers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedInventories, setSelectedInventories] = useState<
    Inventory[] | never
  >([]);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [filters, setFilters] = useState<any>({
    printed: undefined,
    status: undefined,
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    consigner: undefined,
  });
  const [shouldOverrideLocationOption, setShouldOverrideLocationOption] =
    useState(false);
  const [locationOption, setLocationOption] = useState("option3Value");

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    paginatedInventories,
    inventoriesLoading,
    transferInventoryLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  let activeInventory: Inventory[] = [];
  if (paginatedInventories && paginatedInventories.rows) {
    activeInventory = paginatedInventories.rows.filter(
      (inventory) => inventory.status === "Active"
    );
  }

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //get products here any time id changes
    dispatch(
      getPaginatedInventories(
        search,
        { ...filters, status: "Active" },
        currentPage,
        pageSize
      )
    );
  }, [search, filters]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!inventoryFilterOptions || inventoryFilterOptionsLoading) return <Spin />;

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.TRANSFER_SEARCH}
        allowClear
        enterButton="Search"
        size="large"
        onSearch={searchProducts}
      />
      <ActionBar>
        <InventoryFilter
          onFilter={(values) => setFilters(values)}
          formOptions={inventoryFilterOptions}
          showConsignerFilter
          currentFilters={filters}
          subLocations={subLocations}
          option1Values={inventoryFilterOptions.option1Values}
          option2Values={inventoryFilterOptions.option2Values}
        />

        <ActionButtonsContainer>
          <Button
            type="ghost"
            onClick={() => setSelectedInventories(activeInventory)}
          >
            {Content.READY_TO_PAY_ACTION_BUTTON_TEXT}
          </Button>
          <Button type="primary" onClick={() => setShowTransferModal(true)}>
            {Content.TRANSFER_INVENTORY_ACCEPT_BUTTON_TEXT}
          </Button>
        </ActionButtonsContainer>
      </ActionBar>
      <InventoryList
        inventories={paginatedInventories.rows}
        loading={inventoriesLoading}
        onAccept={(inventory: Inventory) =>
          goToInventoryPage(history, inventory)
        }
        acceptButtonText={Content.ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT}
        onSelect={(newSelectedInventories) =>
          setSelectedInventories(newSelectedInventories)
        }
        selected={selectedInventories}
      />
      <Pagination
        current={currentPage}
        total={paginatedInventories.count}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          dispatch(
            getPaginatedInventories(
              search,
              { ...filters, status: "Active" },
              currentPage,
              pageSize
            )
          );
        }}
        pageSizeOptions={[10, 20, 50, 100, 250, 500]}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        showQuickJumper // allows user to type in page number
        showSizeChanger // allows user to change number of items per page
      />
      <BackTop // back to top button
        visibilityHeight={5000} // height to show button
        style={{ right: 50 }}
      />
      <Modal
        title="Set New Location"
        visible={showTransferModal}
        okButtonProps={{ loading: transferInventoryLoading }}
        onOk={() =>
          transferInventoriesData(
            dispatch,
            selectedInventories,
            setShowTransferModal,
            selectedLocation,
            shouldOverrideLocationOption,
            locationOption
          )
        }
        onCancel={() => setShowTransferModal(false)}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          value={selectedLocation}
          onChange={(value: string) => setSelectedLocation(value)}
          placeholder="Select Location"
        >
          {inventoryFilterOptions.locations.map(
            (item: { label: string; value: string }, index: number) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            )
          )}
        </Select>
        <LabelTitle>
          Do you want replace any inventory options with the new location?
        </LabelTitle>
        <Switch
          checked={shouldOverrideLocationOption}
          onChange={(checked) => setShouldOverrideLocationOption(checked)}
        />
        {shouldOverrideLocationOption && (
          <>
            <LabelTitle>Select Which Option To Overide</LabelTitle>
            <Select
              showSearch
              style={{ width: 200 }}
              value={locationOption}
              onChange={(value: string) => setLocationOption(value)}
              placeholder="Select Which Option To Overide"
            >
              {["option1Value", "option2Value", "option3Value"].map(
                (item: string, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                )
              )}
            </Select>
          </>
        )}
      </Modal>
    </Container>
  );
};

export default Transfers;
