import styled from "styled-components";
import Colors from "../constants/Colors";
import { Button, Card } from "antd";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0;
    div.MuiButtonGroup-root {
      display: flex;
      gap: 16px;
      .ant-btn {
        display: flex;
        height: 68px;
        padding: 11.5px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
    .ant-card {
      border-radius: 0;
      //   background: #fbfcff;
      margin-bottom: 13px;
      &.selected {
        outline: 1px solid #40a9ff;
      }
    }
    .ant-form {
      span {
        label {
          display: flex;
          width: 100%;
          color: #989898;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 8px;
        }
        .ant-input {
          display: flex;
          width: 100%;
          height: 47px;
          padding: 15px 17px;
          align-items: center;
          flex-shrink: 0;
          margin-bottom: 13px;
          font-size: 16px;
        }
      }
    }
  }
`;

export const AddMoreButton = styled(Button)`
  color: ${Colors.CONSIGNER_BLUE};
  border: 1px solid ${Colors.CONSIGNER_BLUE};
  padding: 10px 12px;
  border-radius: 10px;
  width: fit-content;
  align-self: center;
  height: auto;
`;

export const ProductItemList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  gap: 16px;
  width: 100%;
  max-width: 768px;
  align-self: center;
  .card-selected {
    background-color: #fff;
  }
  .add-product-btn {
    color: ${Colors.CONSIGNER_BLUE};
    border: 1px solid ${Colors.CONSIGNER_BLUE};
    padding: 10px 12px;
    border-radius: 10px;
    width: fit-content;
    align-self: center;
    height: auto;
  }
`;

export const ProductItemCard = styled(Card)`
  display: flex;
  height: auto;
  // width: 820px;
  cursor: pointer;
  border-radius: 10px;
  background-color: ${Colors.SMOKE_GREY};
  .ant-card-body {
    display: flex;
    padding: 9px;
    justify-content: space-between;
    .ant-image {
      max-height: 100%;
      object-fit: contain;
      border-radius: 10px;
      img {
        max-height: 100%;
        border-radius: 5px;
      }
    }
    div {
      flex: 1;
      flex-direction: column;
      display: flex;
      justify-content: center;
      // align-items: center;
      label {
        color: #989898;
        font-size: 14px;
      }
      b {
        font-weight: 500;
      }
    }
  }
`;

export const PriceInput = styled.div`
  margin: 17px 0 45px;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #fff;
  gap: 20px;
  width: 100%;
  position: relative;
  :before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 70%; /* or 100px */
    left: 50%; /* Start at the center */
    transform: translateX(-50%);
    bottom: -30px;
    border-bottom: 1px solid #dddddd;
  }
  .price-input {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    display: flex;
    align-items: center;
    span {
      padding: 0 11px;
    }
  }
  .ant-input-number {
    height: 45px;
  }
  & > span {
    flex: 1;
  }
  .ant-btn {
    flex: 0 0 80px;
    align-self: stretch;
    height: 100%;
  }
  .ant-input,
  .ant-input-number,
  .ant-input-prefix {
    height: 45px;
    color: var(--Light---Black, #292d32);
    border-radius: 8px;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    b {
      flex: 1;
    }
  }
  .ant-input {
    text-align: left;
  }
  .ant-input-number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    input {
      text-align: center;
    }
  }
  .ant-input-number-handler-wrap {
    border-radius: 10px;
    fill: black;
    .ant-input-number-handler-up {
      border-bottom: 1px solid #d9d9d9;
    }
    svg {
    }
  }
  .ant-input-prefix {
    text-align: right;
  }
`;

export const PaySummary = styled.span`
  display: flex;
  width: 320px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 45px;
  label,
  b {
    flex: 0 0 auto;
    display: inline;
  }
  div {
    flex: 0 0 212px;
    border-bottom: 1px dotted gray;
  }
`;

export const CustomBadge = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  min-width: auto;
  width: 25px;
  height: 25px;
  line-height: 1;
}
`;

export const ItemNextButton = styled(Button)`
  background-color: ${Colors.SMOKE_GREY};
  padding: 12px 10px;
  color: #33333;
  border-radius: 10px;
  border: 1px solid #dddddd66;
  font-weight: 600;
`;

export const PayCard = styled(Card)`
  flex: 1;
  margin: 24px 0;
  cursor: pointer;
  background-color: ${Colors.SMOKE_GREY};
  border-radius: 10px !important;
  border: 1px solid #dddddd66;
  &.selected {
    outline: 1px solid #40a9ff;
  }
  .ant-card-body {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    & > .ant-image {
      margin: 0 24px;
      width: 32.128px;
      height: 31.019px;
      flex-shrink: 0;
      font-size: 24px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      b {
        align-self: center;
      }
      .ant-btn {
        border-radius: 8px;
        display: flex;
        // width: 82.218px;
        // height: 29.772px;
        padding: 18px 30px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        color: #333333;
        border: 1px solid #dddddd66;
        font-weight: 600;
      }
    }
    // flex-direction: column;
  }
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

export const LocationSelector = styled.div`
  width: 100%;
  max-width: 768px;
  align-self: center;
  span {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  @media (max-width: 768px) {
    width: 100%;
    span {
      flex-direction: column;
      gap: 0;
    }
  }
`;

export const PaymentTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

export const PayHeader = styled.div`
  display: flex;
  flex-direction: column;
  b {
    font-size: 20px;
  }
  label {
    color: #989898;
  }
`;

export const ProductItemCardMobile = styled(Card)`
  cursor: pointer;
  // &:not(:first-of-type) {
  //   border-top: 1px solid #262626;
  // }
  .ant-card-body {
    gap: 8px;
    display: flex;
    flex-direction: column;
    // border: 1px solid #dddddd99;
    // background: ${Colors.SMOKE_GREY};
    border-radius: 10px;
    padding: 10px 15px;
  }
  .ant-image {
    width: 100px;
  }
  .product-properties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .ant-btn-dangerous.ant-btn-text {
    color: #747474;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #dddddd99;
  }
  div {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    // align-items: center;
    label {
      color: #989898;
      font-size: 14px;
    }
    b {
      font-weight: 500;
    }
  }
  span {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
      margin-bottom: 4px;
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    b {
      label {
        display: block;
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .ant-btn {
      color: #868383;
      text-align: center;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; /* 173.333% */
      text-transform: capitalize;
      padding: 0;
      display: flex;
      align-items: start;
      justify-content: center;
    }
    & > [class^="ant-input-number"],
    .ant-select {
      font-size: 16px;
      flex: 1;
      text-align: left;
      border: 1px solid var(--Stroke-Color, #e4e4e7);
      background: var(--White, #fff);
      display: flex;
      height: 52px;
      padding: 16px 12px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      .ant-select-selector {
        border: none;
      }
    }
    .ant-input-number {
      font-size: 16px;
    }
  }
`;

export const MobileViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
