import styled from "styled-components";

const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
`;

const TitleDiv = styled.div`
  padding: 0rem 1rem;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  opacity: 0.9;
`;

const TotalAmountDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
`;

const InflationPercentage = styled.div`
  color: #009521;
  font-size: 14px;
  font-weight: semibold;
  font-family: "Inter", sans-serif;
`;

export { StyledToolbar, TitleDiv, TotalAmountDiv, InflationPercentage };
