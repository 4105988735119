import firebase from "firebase";
import React, { ReactElement, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
//Redux Stuff
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AuthService from "../../services/auth";
//components
import Topbar from "../../components/Layout/Topbar";
import Footer from "../../components/Layout/Footer";
import EmailCollectionCard from "../../components/Auth/EmailCollectionCard";
//constants
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { consignerNavItems, navItems } from "../../nav/navData";
import { message, Card } from "antd";
import { UserState } from "../../redux/reducers/UserReducer";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import Fonts from "../../constants/Fonts";

/*
SendEmailVerfication Component using Firebase Auth
When a user logges is, firebase sign in is called
User is redirected to secret routes via a firebase onAuthStateChanged listener
onAuthStateChanged fires when user sendEmailVerfication states is changed from logged in to logged off
The logic for this function is found in ./redux/actions/users/setUserAuthState.ts
*/
//styles
const Container = styled.div`
  background: ${Colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;
const Container2 = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
`;

const ContentContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoutLink = styled.a`
  color: #a3a3a3;
  margin-top: 20px;
`;

const Title = styled.h2`
  font-family: ${Fonts.PRIMARY};
  text-algin: left;
  margin-bottom: 20px;
`;
const Message = styled.p`
  font-family: ${Fonts.PRIMARY};
  text-algin: center;
  margin-bottom: 40px;
`;

//types

export default function DisabeldAccount(): JSX.Element {
  //global state / redux

  const { fbUser, dbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );
  const auth = AuthService();
  const { store } = useAppSelector((state) => state.StoreReducer);

  //component
  if (!fbUser) return <Redirect data-testid="dashboardRedirect" to="/login" />;

  return (
    <Container data-testid="sendEmailVerfication">
      <Topbar
        logo={store?.logo!}
        backText={Content.TOPBAR_BACK_TEXT}
        backLink={`https://${store?.redirectURL!}`}
        nav={
          process.env.REACT_APP_TYPE === "consigner"
            ? consignerNavItems
            : navItems
        }
      />
      <ContentContainer>
        <Container2>
          <Title data-testid="title">{"Were currently in maitenance"}</Title>
          <Message>{`Please contact ${store?.supportEmail} to get more info.`}</Message>
        </Container2>

        <LogoutLink onClick={() => auth.logout()}>Logout</LogoutLink>
      </ContentContainer>

      <Footer />
    </Container>
  );
}
