import React, { useEffect, useState } from "react";
import { Steps, Button, Spin, message } from "antd";
import ScanBarcodeStep1 from "../components/Common/GTIN/ScanBarcodeStep1";
import ScanBarcodeStep2 from "../components/Common/GTIN/ScanBarcodeStep2";
import {
  bulkAcceptInventory,
  calculateFees,
  createInventories,
  getInventoryFilterOptions,
  scanGTINInventory,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { InventoryState, Inventory } from "../redux/reducers/InventoryReducer";
import styled from "styled-components";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import MultiItemsPickerModal from "../components/Common/GTIN/MultiItemsPickerModal";
import { InventoryFormValues } from "./AddInventory";
import AddNewItemModal from "../components/Common/GTIN/AddNewItemModal";
import AttachProductModal from "../components/Common/GTIN/AttachProductModal";
import { createProduct, updateProduct } from "../redux/actions/productActions";
import { Product, ProductState } from "../redux/reducers/ProductReducer";
import { useInterval } from "../helperFunctions/useInterval";

const Container = styled.div``;

const { Step } = Steps;

const ScanGTIN: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentScan, setCurrentScan] = useState<string>("");
  const [formData, setFormData] = useState<Record<string, any>>({});

  //Main start
  const [scannedItems, setScannedItems] = useState<Inventory[]>([]);
  const [selectedConsigner, setSelectedConsigner] = useState<string | null>(
    null
  );
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  //Main end

  const [attachProductModalVisible, setAttachProductModalVisible] =
    useState(false);
  const [multiItemsPickerModalVisible, setMultiItemsPickerModalVisible] =
    useState(false);
  const [addNewItemModalVisible, setAddNewItemModalVisible] = useState(false);
  const [newItemPayoutFee, setNewItemPayoutFee] = useState<number>(0);
  const [newItemPrice, setNewItemPrice] = useState<number>(0);
  const [newItemPayout, setNewItemPayout] = useState<number>(0);
  const [newItemOption2, setNewItemOption2] = useState<string | null>(null);
  const [newItemOption3, setNewItemOption3] = useState<string | null>(null);
  const [newItemQuantity, setNewItemQuantity] = useState<number>(1);
  const [barcode, setBarcode] = useState<string>("");
  const [scannedBarcode, setScannedBarcode] = useState<string>("");

  const dispatch = useAppDispatch();

  const {
    inventoryFilterOptionsLoading,
    inventoryFilterOptions,
    scanGTINInventories,
    scanGTINInventoriesLoading,
    scanGTINProduct,
    scanGTINProductTemplate,
    gtinSize,
    calculatedFees,
    createdInventoryLoading,
    createdInventory,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  const { updatedProduct, createdProduct }: ProductState = useAppSelector(
    (state) => state.ProductReducer
  );

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    if (currentScan !== "") {
      if (scanGTINProduct) {
        if (scanGTINInventories) {
          const filteredInventory = scanGTINInventories.filter(
            (item) =>
              !scannedItems.find((scannedItem) => scannedItem.id === item.id)
          );
          // The scanGTINInventories state has changed, do something with the data
          if (filteredInventory.length > 1) {
            //need to pick which on too add
            setMultiItemsPickerModalVisible(true);
          } else if (filteredInventory.length === 1) {
            //TODO: add the item to the scanned items
            addToScannedItems(filteredInventory[0]);
          } else if (filteredInventory.length === 0) {
            //TODO:no items found, so we need to add a new item and open price / payout modal
            setAddNewItemModalVisible(true);
          }
        }
      } else {
        //TODO: Handle when no product was found too
        if (!scanGTINInventoriesLoading) {
          setAttachProductModalVisible(true);
        }
      }
    }
  }, [
    scanGTINInventories,
    scanGTINProduct,
    currentScan,
    scanGTINInventoriesLoading,
  ]);

  useEffect(() => {
    //calcualtes fees
    if (selectedConsigner && newItemPrice && gtinSize && scanGTINProduct) {
      dispatch(
        calculateFees(
          "id",
          // newItemPayoutFee,
          newItemPrice,
          1,
          gtinSize,
          newItemOption2,
          newItemOption3,
          newItemPayout,
          scanGTINProduct.category,
          JSON.stringify(JSON.parse(selectedConsigner).value)
        )
      );
    }
  }, [
    scanGTINProduct,
    gtinSize,
    newItemPrice,
    newItemPayout,
    newItemOption2,
    newItemOption3,
  ]);

  useEffect(() => {
    if (createdInventory) {
      const itemsCreatedNotInScannedItem = createdInventory.filter(
        (cItem) => !scannedItems.find((sItem) => sItem.id === cItem.id)
      );

      if (itemsCreatedNotInScannedItem) {
        setScannedItems([...itemsCreatedNotInScannedItem, ...scannedItems]);
      }
    }
  }, [createdInventory]);

  useEffect(() => {
    if (createdProduct) {
      onScan(currentScan);
    }
  }, [createdProduct]);

  useEffect(() => {
    if (updatedProduct) {
      onScan(currentScan);
    }
  }, [updatedProduct]);

  useEffect(() => {
    window.addEventListener("keydown", handleScannerKeyPress);

    return () => {
      window.removeEventListener("keydown", handleScannerKeyPress);
    };
  }, []);

  useEffect(() => {
    if (
      barcode.includes("Enter") &&
      currentStep === 1 &&
      !attachProductModalVisible &&
      !addNewItemModalVisible &&
      !multiItemsPickerModalVisible
    ) {
      const barcodeToScan = barcode.replace("Enter", "");
      setScannedBarcode(barcodeToScan);
      onScan(barcodeToScan);
      setBarcode("");
    }
  }, [barcode]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBarcode("");
    }, 500);

    return () => clearTimeout(timeout);
  }, [barcode]);

  useInterval(() => {
    if (barcode !== "") {
      setBarcode("");
    }
  }, 3000);

  const handleNext = (values: Record<string, any>) => {
    setFormData({ ...formData, ...values });
    setCurrentStep(currentStep + 1);
  };

  const resetStates = () => {
    setCurrentStep(0);
    setCurrentScan("");
    setFormData({});
    setScannedItems([]);
    setSelectedConsigner(null);
    setSelectedLocation(null);
    setSelectedSubLocation(null);
    setAttachProductModalVisible(false);
    setMultiItemsPickerModalVisible(false);
    setAddNewItemModalVisible(false);
    setNewItemPrice(0);
    setNewItemPayoutFee(0);
    setNewItemPayout(0);
    setNewItemOption2(null);
    setNewItemOption3(null);
    setNewItemQuantity(1);
    setBarcode("");
    setScannedBarcode("");
  };

  const handleSave = async (values: Record<string, any>) => {
    if (!selectedLocation) {
      message.error("Please select a location");
      return;
    }
    if (!selectedConsigner) {
      message.error("Please select a consigner");
      return;
    }

    dispatch(
      await bulkAcceptInventory(
        scannedItems,
        selectedLocation,
        selectedSubLocation || ""
      )
    );
    setFormData({ ...formData, ...values });

    resetStates();
  };

  const toScanPage = () => {
    if (!selectedConsigner && !selectedLocation) {
      message.error("Please select a location and a consigner");
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const addToScannedItems = (inventory: Inventory) => {
    if (scannedItems.find((item) => item.id === inventory.id)) {
      message.error("Item already scanned");
      return;
    }

    setScannedItems([...scannedItems, inventory]);
  };

  const removeScannedItem = (inventory: Inventory) => {
    const filteredItems = scannedItems.filter(
      (item) => item.id !== inventory.id
    );
    setScannedItems(filteredItems);
  };

  const handleScannerKeyPress = (event: KeyboardEvent) => {
    if (
      attachProductModalVisible ||
      addNewItemModalVisible ||
      multiItemsPickerModalVisible
    ) {
      return;
    }

    setBarcode((prevBarcodeValue) => prevBarcodeValue + event.key);
  };

  const onScan = (scannedItem: string) => {
    if (currentScan !== scannedItem) {
      setCurrentScan(scannedItem);
    }
    //Call the scan api
    const scanData = {
      consigner:
        selectedConsigner && selectedConsigner
          ? JSON.parse(selectedConsigner).value.id
          : "",
      location: selectedLocation || "",
      gtin: scannedItem,
    };
    dispatch(scanGTINInventory(scanData));
  };

  const isOptionValid = (
    option: string | null,
    optionSelectedValue: string | null
  ) => {
    if (option && optionSelectedValue) return true;
    if (!option && !optionSelectedValue) return true;
    if (!option && !optionSelectedValue) return true;
    if (option && !optionSelectedValue) return false;
  };

  const addInventory = async () => {
    if (
      scanGTINProduct &&
      scanGTINProduct.id &&
      selectedConsigner &&
      scanGTINProductTemplate &&
      gtinSize
    ) {
      if (!newItemPrice) {
        message.error("Please enter a price");
        return;
      }

      if (!calculatedFees) {
        message.error("Please wait to calculate fees");
        return;
      }

      if (!newItemQuantity) {
        message.error("Please enter a quantity");
        return;
      }

      if (!isOptionValid(scanGTINProductTemplate.option2, newItemOption2)) {
        message.error("Please select a valid option 2");
      }

      if (!isOptionValid(scanGTINProductTemplate.option3, newItemOption3)) {
        message.error("Please select a valid option 3");
      }

      if (
        newItemPrice &&
        isOptionValid(scanGTINProductTemplate.option2, newItemOption2) &&
        isOptionValid(scanGTINProductTemplate.option3, newItemOption3) &&
        calculatedFees
      ) {
        const inventoryForm = {
          option1Value: gtinSize,
          price: newItemPrice,
          option2Value: newItemOption2,
          option3Value: newItemOption3,
          cost: calculatedFees?.payout,
          quantity: newItemQuantity,
          consigner: JSON.stringify(JSON.parse(selectedConsigner).value),
          category: scanGTINProduct.category,
        };
        dispatch(await createInventories([inventoryForm], scanGTINProduct.id));
        setAddNewItemModalVisible(false);
      }
    }
  };

  const attachProductToGTIN = async (
    product: Product,
    option1Value: string,
    GTIN: { size: string; gtin: string }[] | null
  ) => {
    if (product && option1Value && scannedBarcode) {
      let gtin = GTIN;
      if (!GTIN || GTIN.length === 0) {
        gtin = [{ size: option1Value, gtin: scannedBarcode }];
      } else {
        const gtinToUpdate = GTIN.find((g) => g.size === option1Value);
        if (gtinToUpdate) {
          gtin = GTIN.map((g) => {
            if (g.size === option1Value) {
              return {
                size: option1Value,
                gtin: scannedBarcode,
              };
            }
            return g;
          });
        } else {
          gtin = [...GTIN, { size: option1Value, gtin: scannedBarcode }];
        }
      }
      dispatch(
        updateProduct(product.id, {
          title: product.title,
          sku: product.sku,
          stockXId: String(product.stockXId),
          shopifyId: String(product.shopifyId),
          brand: product.brand,
          category: product.category,
          images: product.image,
          image: product.image,
          productTemplateId: product.productTemplateId,
          gender: String(product.gender),
          GTIN: gtin,
        })
      );
      setAttachProductModalVisible(false);
    }
  };

  if (inventoryFilterOptionsLoading)
    return (
      <Container>
        <Spin />
      </Container>
    );

  return (
    <Container>
      <Steps current={currentStep}>
        <Step title="Select Consigner / Location" />
        <Step title="Scan Items" />
      </Steps>

      <AttachProductModal
        visible={attachProductModalVisible}
        onCancel={() => {
          setAttachProductModalVisible(false);
          setCurrentScan("");
        }}
        onUpdate={(product, option1Value) =>
          attachProductToGTIN(product, option1Value, null)
        }
        onCreate={(
          product: Product,
          option1Value,
          gtin: { size: string; gtin: string }
        ) => attachProductToGTIN(product, option1Value, [gtin])}
        gtin={currentScan}
        loading={false}
      />

      {scanGTINProductTemplate && selectedConsigner && (
        <AddNewItemModal
          visible={addNewItemModalVisible}
          onCancel={() => setAddNewItemModalVisible(false)}
          onCreate={() => addInventory()}
          onCostChange={(value: number) => setNewItemPayout(value)}
          onPayoutFeeChange={(value: number) => setNewItemPayoutFee(value)}
          onPriceChange={(value: number) => setNewItemPrice(value)}
          onOption2Change={(value: string) => setNewItemOption2(value)}
          onOption3Change={(value: string) => setNewItemOption3(value)}
          costValue={newItemPayout}
          payoutFeeValue={newItemPayoutFee}
          priceValue={newItemPrice}
          option2Value={newItemOption2}
          option3Value={newItemOption3}
          productTemplate={scanGTINProductTemplate}
          productTitle={scanGTINProduct && scanGTINProduct.title}
          gtinSize={gtinSize}
          payout={calculatedFees?.payout || null}
          consigner={JSON.parse(selectedConsigner).value as Consigner}
          loading={createdInventoryLoading}
          quantityValue={newItemQuantity}
          onQuantityChange={(value: number) => setNewItemQuantity(value)}
        />
      )}

      {scanGTINInventories && (
        <MultiItemsPickerModal
          inventories={scanGTINInventories}
          isOpen={multiItemsPickerModalVisible}
          closeModal={() => setMultiItemsPickerModalVisible(false)}
          onSelect={(inventory) => addToScannedItems(inventory)}
        />
      )}

      {currentStep === 0 && (
        <ScanBarcodeStep1
          onSave={handleNext}
          inventoryFilterOptions={inventoryFilterOptions}
          inventoryFilterOptionsLoading={inventoryFilterOptionsLoading}
          setConsigner={setSelectedConsigner}
          setLocation={setSelectedLocation}
          setSubLocation={setSelectedSubLocation}
        />
      )}
      {currentStep === 0 && (
        <Button type="primary" onClick={() => toScanPage()}>
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <ScanBarcodeStep2
          scannedItems={scannedItems}
          onScan={(barcode) => onScan(barcode)}
          removeScannedItem={removeScannedItem}
          selectedConsigner={selectedConsigner}
          selectedLocation={selectedLocation}
          setBarcode={setBarcode}
          barcode={barcode}
        />
      )}
      {currentStep === 1 && (
        <Button type="primary" onClick={() => setCurrentStep(currentStep - 1)}>
          Previous
        </Button>
      )}
      {currentStep === 1 && (
        <Button type="primary" onClick={() => handleSave(formData)}>
          Save
        </Button>
      )}
    </Container>
  );
};

export default ScanGTIN;
