/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Modal,
  Typography,
  Button,
  Tag,
  Space,
  Switch,
  Select,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { SubLocation } from "../../redux/reducers/SubLocationReducer";
import {
  updateSubLocation,
  createSubLocation,
  getSubLocations,
} from "../../redux/actions/subLocationActions";
import styled from "styled-components";

import { AppDispatch } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getInventoryFilterOptions } from "../../redux/actions/inventoryActions";
import {
  Inventory,
  InventoryState,
} from "../../redux/reducers/InventoryReducer";

const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

interface Props {
  tableData: SubLocation[];
  loading: boolean;
  savedLoading: boolean;
}

interface Item {
  key: string;
  name: string;
  locationName: string;
}

export interface SubLocationItem {
  id: any;
  name: string;
  locationName: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const onUpdate = async (
  dispatch: AppDispatch,
  subLocation: SubLocationItem,
  id: string
) => {
  dispatch(await updateSubLocation(id, subLocation));
};

const onSave = async (dispatch: AppDispatch, subLocation: SubLocationItem) => {
  dispatch(await createSubLocation(subLocation));
};

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SubLocationsTable = (props: Props) => {
  const { tableData, loading, savedLoading } = props;
  const dispatch = useAppDispatch();

  const {
    inventoryFilterOptionsLoading,
    inventoryFilterOptions,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  const formatedData = tableData.map((item: any) => ({
    key: item.id.toString(),
    name: item.name,
    locationName: item.locationName,
  }));

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [data, setData] = useState(formatedData.length > 0 ? formatedData : []);
  const [editingKey, setEditingKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [location, setLocation] = useState<any>(
    inventoryFilterOptions &&
      inventoryFilterOptions.locations &&
      inventoryFilterOptions.locations.length > 0
      ? inventoryFilterOptions.locations[0].label
      : null
  );

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    let filteredData;
    if (formatedData && formatedData.length > 0 && location) {
      filteredData = formatedData.filter(
        (item: any) => item.locationName == location
      );
      setData(filteredData);
    }
  }, [location, tableData, inventoryFilterOptions]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form2.resetFields();
    setIsModalVisible(false);
  };

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({
      name: "",
      locationName: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      const savedItem = {
        id: key,
        name: row.name,
        locationName: row.locationName,
      };
      // if id is blank, create new item
      if (key === "") {
        onSave(dispatch, savedItem);
      }
      //else update existing item
      onUpdate(dispatch, savedItem, key.toString());
      //

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (values: any) => {
    const savedItem = {
      id: "",
      name: values.name,
      locationName: values.location,
    };
    onSave(dispatch, savedItem);
    //wait for callback from save then dispatch getConsignerTypes
    if (savedLoading === false) {
      setTimeout(() => dispatch(getSubLocations()), 500);
    }
    form2.resetFields();
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Sublocation",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "20%",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div>
      <Space align="start">
        <Button type="primary" onClick={showModal}>
          Add sublocation
        </Button>
        <Form onValuesChange={(values) => setLocation(values.location)}>
          <Form.Item name="location">
            <Select
              allowClear
              placeholder="Select location"
              data-testid="locations"
              style={{ width: 200 }}
              showSearch
              defaultValue={location}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {inventoryFilterOptions?.locations?.map((location: any) => (
                <Select.Option key={location.label} value={location.label}>
                  {location.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Space>
      <Modal
        title="Add sublocation"
        visible={isModalVisible}
        onOk={form2.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={form2.submit}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form2} onFinish={handleAdd}>
          <Form.Item
            name="location"
            rules={[{ required: true, message: "Location required" }]}
          >
            <Select
              allowClear
              placeholder="Select location"
              data-testid="location"
              style={{ width: 200 }}
              showSearch
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {inventoryFilterOptions &&
                inventoryFilterOptions.locations &&
                inventoryFilterOptions.locations.map((location: any) => (
                  <Select.Option key={location.label} value={location.label}>
                    {location.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Sublocation required" }]}
          >
            <Input placeholder={"Sublocation"} />
          </Form.Item>
        </Form>
      </Modal>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          loading={loading}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};

export default SubLocationsTable;
