export default {
  PAGE_PADDING_MOBILE: "8px 16px",
  PAGE_PAD_X_MOBILE: "8px",
  PAGE_PAD_Y_MOBILE: "16px",
  PAGE_PADDING: "17px 24px",
  PAGE_PADDING_ADMIN: "16px",
  PAGE_PAD_X: "24px",
  PAGE_PAD_Y: "17px",
  SEARCH_BUTTON_WIDTH: "150px",
  SEARCH_BUTTON_WIDTH_MOBILE: "100px",
  SEARCH_HEIGHT: "52px",
  BUTTON_PADDING: "16px",
  BUTTON_PADDING_MOBILE: "22px 34px",
  PAGE_BG: "#fff",
  HEADER_HEIGHT: "60px",
};
