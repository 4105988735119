import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ActionBar,
  CustomB,
  InventoryTable,
  InventoryTableStyled,
  MobileFooter,
  PageContainer,
} from "./ConsignerInventory";
import InventoryFilter from "../components/Common/InventoryFilter";
import {
  getInventoryFilterOptions,
  getInventories,
  cancelDropoff,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  Button,
  Table,
  Image,
  Checkbox,
  Popconfirm,
  Spin,
  Tooltip,
  Input,
  Menu,
  Dropdown,
  Statistic,
} from "antd";
import "./ConsignerInventory.css";
import ButtonGroup from "antd/lib/button/button-group";
import RedesignStyling from "../constants/RedesignStyling";
import {
  InventoryProductMobile,
  Listing,
  PromptModal,
  ReviewListingView,
} from "./ConsignerProducts";
import { AppState } from "../redux/reducers/AppReducer";
import { Store, StoreState } from "../redux/reducers/StoreReducer";
import jsPDF from "jspdf";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import * as qrCode from "qrcode";
import Colors from "../constants/Colors";
import "./ConsignerDropOff.css";
import getSymbolFromCurrency from "currency-symbol-map";
const { Search } = Input;
import { ReactComponent as SearchIcon } from "../assets/images/svg/Search.svg";
import moment from "moment";
import {
  CustomStatus,
  InventoryListStyled,
  SearchBox,
} from "../styles/ConsignerInventory";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Content from "../constants/Content";
import { ReactComponent as DownArrowIcon } from "../assets/images/svg/DownArrow.svg";
import Title from "antd/lib/typography/Title";
import {
  InventoryProductMobileContainer,
  ProductDetailsPanel,
  ProductReviewSpan,
} from "../styles/ConsignerInventoryMobile";

const CustomBtnGroup = styled(ButtonGroup)`
  order: 6;
  width: 100%;
  display: flex;
  justify-content: end;
  padding: ${RedesignStyling.PAGE_PADDING};
  background-color: ${Colors.WHITE};
  gap: 10px;
  button {
    padding: ${RedesignStyling.BUTTON_PADDING};
    height: auto;
  }
  @media (max-width: 768px) {
    justify-content: center;
    button {
      flex: 1;
    }
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const printManifest = async (
  inventories: Inventory[],
  dbUser: Consigner,
  store: Store
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: [8.5, 11],
  });

  try {
    const img = document.createElement("img");
    const opts: qrCode.QRCodeToDataURLOptions = {
      type: "image/png",
      margin: 1,
      errorCorrectionLevel: "H",
    };
    const qrDataUrl: string = await new Promise((resolve, reject) =>
      qrCode.toDataURL(inventories[0].code, opts, (err, url) => {
        resolve(url);
      })
    );
    img.src = qrDataUrl;
    console.info(qrDataUrl);

    doc.addImage(qrDataUrl, "PNG", 1, 1, 2, 2);
  } catch (e) {
    console.warn(e);
  }
  doc.setFontSize(10);

  doc.text("Consigner:", 1, 1);
  doc.text(`ID: ${dbUser.id}`, 1, 1.25);
  doc.text(`Name: ${dbUser.firstName} ${dbUser.lastName}`, 1, 1.5);
  doc.text(`Email: ${dbUser.email}`, 1, 1.75);
  doc.text("Inventory:", 1, 2.5);
  let position = 2.75;
  for (let i = 0; i < inventories.length; i++) {
    const inventory = inventories[i];

    doc.text(`ID: ${inventory.id}`, 1, position);
    doc.text(`QR: ${inventory.code}`, 1, position + 0.25);
    doc.text(inventory.product.title, 1, position + 0.5);
    doc.text(inventory.product.sku, 1, position + 0.75);
    doc.text(`${store?.currency} ${inventory.product.price}`, 1, position + 1);
    doc.text(
      `Size: ${inventory.option1Value} / ${inventory.option2Value} / ${inventory.option3Value}`,
      1,
      position + 1.25
    );
    position += 1.5;
  }
  doc.save("manifest.pdf");
};

const PrintComponent = (props: any): JSX.Element => {
  const { inventories } = props;
  const cols = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (_: any, record: any) => `${record.code}`,
    },
    {
      title: "PRODUCT",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <Image
          src={record.product.image}
          alt=""
          style={{ width: 100, height: "auto" }}
        ></Image>
      ),
    },
    {
      title: "NAME",
      key: "productName",
      dataIndex: "product.title",
      render: (_: any, record: any) => record.product.title,
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => (
        <span style={{ fontSize: 11 }}>{record.product.sku}</span>
      ),
    },
    {
      title: "LOCATION",
      key: "option3Value",
      dataIndex: "option3Value",
      render: (_: any, record: any) => record?.option3Value ?? null,
    },
    {
      title: "SIZE",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "CONDITION",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => record?.option2Value ?? null,
    },
    {
      title: "PRICE",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency)}${record.price}`,
    },
    {
      title: "PAYOUT",
      key: "total",
      dataIndex: "total",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency)}${Number(
          record.payoutAmount
        ).toFixed(2)}`,
    },
  ];
  return (
    <Table
      className="printable"
      columns={cols}
      dataSource={inventories}
      rowKey="id"
      pagination={false}
    />
  );
};

export const RequestedInventoryMobile = (props: any) => {
  const {
    inventoryList,
    handleDelete,
    store,
    isInventory,
    nestedView,
    groupedData,
    selectedRowKeys,
    handleSelect,
  } = props;
  const [splitInventory, setSplitInventory] = useState<any[]>([]);
  useEffect(() => {
    if (nestedView) return;
    if (isInventory) {
      setSplitInventory(
        inventoryList.map((inventory) => ({
          ...inventory,
          currency: store.currency,
        }))
      );
      return;
    }
    const inventoryArray: any[] = [];
    inventoryList.forEach((inventory: any) => {
      for (let i = 0; i < inventory.quantity; i++) {
        inventoryArray.push({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        });
      }
    });
    setSplitInventory(inventoryArray);
  }, [inventoryList, nestedView]);

  return (
    <>
      <InventoryProductMobileContainer>
        {splitInventory.map((record, key) => (
          <Listing key={key} style={{ display: "block" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Checkbox
                checked={selectedRowKeys.includes(record.id)}
                onChange={(e) => handleSelect(e.target.checked, record.id)}
                style={{ padding: "0 10px" }}
              />
              <Popconfirm
                title="Do you want to cancel this Dropoff?"
                onConfirm={() => handleDelete([record.id])}
                okText="Confirm"
                okButtonProps={{ type: "primary" }}
                cancelButtonProps={{ type: "text" }}
                icon={false}
              >
                <Button
                  className="edit-product-icon"
                  icon={<CloseOutlined />}
                ></Button>
              </Popconfirm>
            </div>
            <div className="requested-inventory-mobile-detail">
              <div className="status-image-div">
                <CustomStatus
                  className={
                    record.status === "Sold" ? "bullet green" : "bullet red"
                  }
                >
                  {record?.status === "Pending"
                    ? "Drop off pending"
                    : record.status}
                </CustomStatus>
                <Image
                  src={record?.product?.image ?? record.productImage}
                ></Image>
              </div>
              <ProductDetailsPanel style={{ marginTop: "0" }}>
                <ProductReviewSpan>
                  <b>{record?.product?.title ?? record.productName}</b>
                </ProductReviewSpan>
                <ProductReviewSpan>
                  <div className="product-features">
                    <Statistic title="Size" value={record.option1Value} />
                    <Statistic title="Condition" value={record.option2Value} />
                    <Statistic
                      title="SKU"
                      value={record?.product?.sku ?? record.sku}
                    />
                    <Statistic
                      title="Price"
                      value={store?.currency || "$" + record?.price}
                    />
                    <Statistic
                      title="Payout"
                      value={store?.currency || "$" + record?.payoutAmount}
                    />
                  </div>
                </ProductReviewSpan>
                {/* <div className="price-change-request">
                <PriceChangeHandler record={record} />
              </div> */}
              </ProductDetailsPanel>
            </div>
          </Listing>
        ))}
      </InventoryProductMobileContainer>
    </>
  );
};

interface StatusSelectorProps {
  filters: any;
  setFilters: any;
  inventoryFilterOptions: any;
}

const StatusSelectorDropDown = (props: StatusSelectorProps) => {
  const { filters, setFilters, inventoryFilterOptions } = props;
  const { locations } = inventoryFilterOptions;
  const consignerLocations = locations?.map((location) => ({
    value: location.value,
    label: location.label,
    id: location.id,
  }));
  consignerLocations?.unshift({
    label: "All",
    value: "",
  });
  const [location, setLocation] = useState(filters.locations);

  useEffect(() => {
    console.log(location, "this is the location");
    setFilters({ ...filters, location });
  }, [location]);

  const menu = (
    <Menu>
      {consignerLocations?.map((location) => (
        <Menu.Item
          key={location.value}
          onClick={() => setLocation(location.value)}
        >
          {location.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button className="dropdown-btn-label" style={{ marginLeft: 8 }}>
        Filter by location{" "}
        <DownArrowIcon className="statuses-drop-icon-arrow" />
      </Button>
    </Dropdown>
  );
};

const ConsignerDropOff = (props: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const [dataSource, setDataSource] = useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    printed: "",
    status: props?.status ?? "Pending", // only display Pending inventories
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    sortOption: "newestUpdated",
    location: undefined,
  });

  useEffect(() => {
    setDataSource(
      inventories.map((inventory) => ({
        ...inventory,
        currency: store.currency,
      }))
    );
  }, [inventories]);

  type EditableTableProps = Parameters<typeof Table>[0];

  type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

  const handleCancel = async (id: number[]) => {
    await dispatch(await cancelDropoff(id, dbUser.id));
    await dispatch(getInventories("", filters));
    setShowSuccessModal(true);
  };

  const handleSelect = (checked: boolean, rowKey: React.Key) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, rowKey]);
    } else {
      setSelectedRowKeys([...selectedRowKeys.filter((key) => key !== rowKey)]);
    }
  };

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    const newSelectedRowKeys = checked
      ? inventories.map((record) => record.id)
      : [];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const visibleColumns: ColumnTypes[number][] = [
    {
      title: () => (
        <Checkbox
          checked={selectedRowKeys.length === inventories.length}
          onChange={handleSelectAll}
          style={{ padding: "0 10px" }}
        />
      ),
      dataIndex: "checkbox",
      render: (_, record: any) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.id)}
          onChange={(e) => handleSelect(e.target.checked, record.id)}
          style={{ padding: "0 10px" }}
        />
      ),
    },
    {
      title: "Product",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <Image
          src={record.product.image}
          alt=""
          style={{ width: 100, height: "auto" }}
        ></Image>
      ),
    },
    {
      title: "Name",
      key: "productName",
      dataIndex: "product.title",
      render: (_: any, record: any) => (
        <>
          {`${record.product.title} `}
          <Tooltip placement="right" title={`Product ID: ${record.code}`}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => (
        <span style={{ fontSize: 11 }}>{record.product.sku}</span>
      ),
    },
    {
      title: "Location",
      key: "option3Value",
      dataIndex: "option3Value",
      render: (_: any, record: any) => record.location || record?.option3Value,
    },
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "Condition",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => record?.option2Value ?? null,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_: any, record: any) => (
        <CustomStatus
          className={record.status === "Sold" ? "bullet green" : "bullet red"}
        >
          {record?.status === "Pending" ? "Drop off pending" : record.status}
        </CustomStatus>
      ),
    },
    {
      title: "Date",
      key: "acceptedOn",
      dataIndex: "acceptedOn",
      render: (_: any, record: any) =>
        moment(
          record.acceptedOn || record.updatedAt || record.createdAt
        ).format("YY/MM/DD"),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency) || "$"}${record.price}`,
    },
    {
      title: "Payout",
      key: "total",
      dataIndex: "total",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency) || "$"}${Number(
          record.payoutAmount
        ).toFixed(2)}`,
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_: any, record: any) => (
        <Popconfirm
          title="Do you want to cancel this Dropoff?"
          onConfirm={() => handleCancel([record.id])}
          okText="Confirm"
          okButtonProps={{ type: "primary" }}
          cancelButtonProps={{ type: "text" }}
          icon={false}
        >
          <Button
            className="edit-product-icon"
            icon={<CloseOutlined />}
          ></Button>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //refresh inventory list on filter or search change
    dispatch(getInventories(search, filters));
  }, [search, filters]);

  return (
    <PageContainer className="ConsignerDropOffPage">
      <div className="requested-page-title">
        <Title level={3} style={{ fontWeight: "400" }}>
          Requested Inventory
        </Title>
      </div>
      <PromptModal
        heading="Dropoff has been cancelled"
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
      />
      <SearchBox className="requested-page-searchbox">
        <div className="searchbox-statuses-filter">
          <Search
            data-testid="search"
            placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
            allowClear
            size="large"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            prefix={<SearchIcon className="product-search-icon" />}
          />
          <StatusSelectorDropDown
            filters={filters}
            setFilters={setFilters}
            inventoryFilterOptions={inventoryFilterOptions}
          />
        </div>
      </SearchBox>
      {isMobile ? (
        inventoriesLoading ? (
          <Spin />
        ) : (
          <RequestedInventoryMobile
            inventoryList={dataSource} //  add currency
            store={store}
            isInventory={isMobile}
            handleDelete={handleCancel}
            handleSelect={handleSelect}
            nestedView={false}
            selectedRowKeys={selectedRowKeys}
          />
        )
      ) : (
        <InventoryListStyled
          dataSource={dataSource}
          columns={visibleColumns}
          size="middle"
          pagination={{
            pageSize,
            // responsive: true,
            hideOnSinglePage: true,
            showSizeChanger: true,
            onShowSizeChange: handlePageSizeChange,
          }}
          loading={inventoriesLoading}
          rowKey="id"
        />
      )}
      <CustomBtnGroup>
        <Popconfirm
          title="Do you want to cancel all checked items?"
          onConfirm={() => {
            handleCancel(selectedRowKeys);
            setShowSuccessModal(true);
          }}
          okText="Confirm"
          okButtonProps={{ type: "primary" }}
          cancelButtonProps={{ type: "text" }}
          icon={false}
          disabled={selectedRowKeys.length < 1}
        >
          <Button
            type={selectedRowKeys.length ? "primary" : "ghost"}
            danger={selectedRowKeys.length > 0}
            disabled={selectedRowKeys.length < 1}
          >
            Cancel
          </Button>
        </Popconfirm>
        <Button
          onClick={() => window.print()}
          // onClick={() => printManifest(inventories.filter((inventory) => selectedRowKeys.includes(inventory.id)), dbUser, store)}
          type="primary"
          // disabled={selectedRowKeys.length < 1}
        >
          Print Manifest
        </Button>
      </CustomBtnGroup>
      <PrintComponent
        inventories={
          inventories.map((inventory) => ({
            ...inventory,
            currency: store.currency,
          })) /* .filter((inventory) =>
          selectedRowKeys.includes(inventory.id)
        ) */
        }
      />
    </PageContainer>
  );
};

export default ConsignerDropOff;
