import React, { FC, useState } from "react";
import { Modal, Input, Select, InputNumber } from "antd";
import { ProductTemplate } from "../../../redux/reducers/ProductTemplateReducer";
import { Consigner } from "../../../redux/reducers/ConsignerReducer";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onCreate: () => void;
  onCostChange: (value: number) => void;
  onPayoutFeeChange: (value: number) => void;
  onPriceChange: (value: number) => void;
  onOption2Change: (value: string) => void;
  onOption3Change: (value: string) => void;
  onQuantityChange: (value: number) => void;
  quantityValue: number;
  costValue: number;
  payoutFeeValue: number;
  priceValue: number;
  option2Value: string | null;
  option3Value: string | null;
  productTemplate: ProductTemplate;
  productTitle: string | null;
  gtinSize: string | null;
  payout: number | null;
  consigner: Consigner;
  loading: boolean;
}

const { Option } = Select;

const AddNewItemModal: FC<Props> = ({
  visible,
  onCancel,
  onCreate,
  onCostChange,
  onPayoutFeeChange,
  onPriceChange,
  onOption2Change,
  onOption3Change,
  onQuantityChange,
  costValue,
  payoutFeeValue,
  priceValue,
  quantityValue,
  productTemplate,
  option2Value,
  option3Value,
  gtinSize,
  productTitle,
  payout,
  consigner,
  loading,
}) => {
  const { option2, option3, option2Values, option3Values } = productTemplate;
  const [shouldOverridePayout, setShouldOverridePayout] = useState(false);

  return (
    <Modal
      visible={visible}
      title={`Add new inventory item ${productTitle} ${gtinSize}`}
      okText="Add Item"
      onCancel={onCancel}
      onOk={onCreate}
      confirmLoading={loading}
    >
      <InputNumber
        addonBefore="Enter Listing Price"
        onChange={(value) => onPriceChange(Number(value))}
        value={priceValue}
        placeholder="Enter price"
        style={{ width: "100%" }}
      />

      <br />
      <br />
      {payout && !consigner.isStoreAccount ? (
        <p>
          The payout will be ${payout}{" "}
          <a onClick={() => setShouldOverridePayout(true)}>Click here </a> to
          override this amount.
        </p>
      ) : (
        !consigner.isStoreAccount && <p>Enter price to calculate payout...</p>
      )}

      {(shouldOverridePayout || consigner.isStoreAccount) && (
        <InputNumber
          addonBefore="Cost"
          onChange={(value) => onCostChange(Number(value))}
          value={costValue}
          placeholder="Enter override cost"
          style={{ width: "100%" }}
        />
      )}
      <br />
      <br />
      <InputNumber
        addonBefore="Quantity"
        onChange={(value) => onQuantityChange(Number(value))}
        value={quantityValue}
        defaultValue={1}
        placeholder="Enter quantity"
        style={{ width: "100%" }}
      />
      {option2 ? (
        <Select
          placeholder={option2}
          onChange={onOption2Change}
          value={option2Value}
          style={{ width: "100%" }}
        >
          {option2Values?.map((value: any) => (
            <Option key={value}>{value}</Option>
          ))}
        </Select>
      ) : null}
      <br />
      <br />
      {option3 ? (
        <Select
          placeholder={option3}
          onChange={onOption3Change}
          value={option3Value}
          style={{ width: "100%" }}
        >
          {option3Values?.map((value: any) => (
            <Option key={value}>{value}</Option>
          ))}
        </Select>
      ) : null}
    </Modal>
  );
};

export default AddNewItemModal;
