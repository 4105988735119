import React from "react";
import styled from "styled-components";
import {
  Card,
  Form,
  Input,
  Button,
  FormInstance,
  Select,
  Typography,
} from "antd";
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { useAppSelector } from "../../redux/hooks";
import Paragraph from "antd/lib/typography/Paragraph";
import { BankData } from "../../screens/ConsignerItem";

/**
 * Consigner Bank Form
 */
const { Option } = Select;
const { Title } = Typography;

const Container = styled.div``;
const FormSelect = styled(Select)``;
const FormInput = styled(Input)``;
const FormLabel = styled.p`
  margin-left: 1px;

  margin-bottom: 5px;
`;
export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phone: string;
  idNumber: string;
  accountName: string;
  bank: string | undefined;
  accountNumber: string;
  branchCode: string;
  bankType: string | undefined;
  bankData: BankData;
  fbUserId: string;
  accountType: string;
  payoutData: string;
  payoutType: string;
  storeId: string;
  isActive?: any;
}
interface Props {
  form: FormInstance;
  onFinish?: (values: FormValues) => void;
  loading?: boolean;
}

const ConsignerForm = (props: Props): JSX.Element => {
  const { form, onFinish, loading } = props;
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [bank, setBank] = React.useState();
  const [routingType, setRoutingType] = React.useState("abaNumber");
  const handleBankChange = (value: any) => {
    setBank(value);
  };
  const handleRoutingChange = (value: any) => {
    setRoutingType(value);
  };

  return (
    <Card title={"Add new consigner"}>
      <Form
        data-testid="addConsignerForm"
        name="addConsignerForm"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="storeId"
          initialValue={store!.id}
          hidden={true}
        ></Form.Item>

        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter consigner first name"
            data-testid="addConsignerFormFirstName"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter consigner last name"
            data-testid="addConsignerFormLastName"
          />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input
            placeholder="Enter consigner email"
            data-testid="addConsignerFormEmail"
          />
        </Form.Item>
        <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
          <Input
            placeholder="Enter consigner phone (include area code)"
            data-testid="addConsignerFormPhone"
          />
        </Form.Item>
        <Form.Item name="address" label="Address" rules={[{ required: true }]}>
          <Input
            placeholder="Enter consigner address"
            data-testid="addConsignerFormAddress"
          />
        </Form.Item>

        <Title level={5}>Payout Method</Title>
        <Form.Item name="bankType" label="Payout type">
          <FormSelect
            // disabled={readOnly}
            placeholder="Select your payout type"
            onChange={handleBankChange}
            // value={bankType === "" ? undefined : bankType}
          >
            {store && store?.payoutOptions && store?.payoutOptions.length > 0
              ? store?.payoutOptions!.map((bankType: any, index: any) => (
                  <Option key={index} value={bankType}>
                    {bankType}
                  </Option>
                ))
              : ""}
          </FormSelect>
        </Form.Item>

        <Form.Item name="routingType" label="Bank Routing type">
          <FormSelect
            // disabled={readOnly}
            placeholder="Select your Routing type"
            onChange={handleRoutingChange}
            // value={routing}
          >
            {Content.BANKROUTINGTYPE!.map((routingType, index) => (
              <Option key={index} value={routingType.value}>
                {routingType.label}
              </Option>
            ))}
          </FormSelect>
        </Form.Item>

        {bank === "Bank" && routingType === "abaNumber" ? (
          <div>
            <Form.Item
              name="accountName"
              label="Account Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter account holders name" />
            </Form.Item>

            <Form.Item
              name="accountNumber"
              label="Account Number"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your account number" />
            </Form.Item>

            <Form.Item
              name="routingNumber"
              label="Routing Number"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your Routing Number" />
            </Form.Item>
          </div>
        ) : bank === "Bank" && routingType == "swiftCode" ? (
          <div>
            <Form.Item
              name="accountName"
              label="Account Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter account holders name" />
            </Form.Item>

            <Form.Item
              name="swiftCode"
              label="Swift Code (BIC)"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your Swift Code" />
            </Form.Item>

            <Form.Item
              name="ibanNumber"
              label="Account Number or IBAN"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your account number or IBAN number" />
            </Form.Item>
          </div>
        ) : bank === "Bank" && routingType == "ibanNumber" ? (
          <div>
            <Form.Item
              name="accountName"
              label="Account Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter account holders name" />
            </Form.Item>

            <Form.Item
              name="ibanNumber"
              label="IBAN Number"
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter your IBAN Number or Account Number" />
            </Form.Item>
          </div>
        ) : (
          <Form.Item
            name="accountNumber"
            label={`Enter your ${bank ? bank : ""} email`}
            rules={[{ required: true }]}
          >
            <Input placeholder={`Enter your ${bank ? bank : ""} email`} />
          </Form.Item>
        )}

        {/* / Container to display information about different banking Types  / */}
        {bank === "Bank" && routingType === "abaNumber" ? (
          <Container style={{ marginTop: "20px" }}>
            <Title level={5}>Bank Account Information</Title>
            <Paragraph>
              Where is the ABA routing number on my checks? You can find the ABA
              routing number at the bottom of your checks. <br></br>The ABA
              Routing Number is the left-most number, followed by your account
              number, and then by the number of the check (USA). Eg: 123456789{" "}
              <br></br>
              For Canada, the routing number = 0 + institution number + transit
              number. <br></br>
              Example: 001012345 where 010 is the institution number and 12345
              is the transit number.
            </Paragraph>
          </Container>
        ) : bank === "Bank" && routingType === "swiftCode" ? (
          <Container style={{ marginTop: "20px" }}>
            <Title level={5}>Bank Account Information</Title>
            <Paragraph>
              A Swift Code is a unique identifier for a bank or financial
              institution. Depending on your financial institution, you may have
              a Swift Code with an Account number or IBAN number.
              <br></br> For Example : <br></br>
              SWIFT / BIC CODE = AAAAAGAGXYZ <br></br>
              Account Number = 000123456789 or IBAN Number =
              BH29BMAG1299123456BH00
            </Paragraph>
          </Container>
        ) : bank === "Bank" && routingType === "ibanNumber" ? (
          <Container style={{ marginTop: "20px" }}>
            <Title level={5}>Bank Account Information</Title>
            <Paragraph>
              An IBAN is an international bank account number. It is used to
              identify bank accounts across national borders. Please enter your
              IBAN Number or Account Number above. Some financial institutions
              have either a single long IBAN Number, or a single Account Number
              <br></br>
              For Example : <br></br>
              IBAN Number = BH29BMAG1299123456BH00 <br></br>
              Account Number = 0110000600000000000000
            </Paragraph>
          </Container>
        ) : (
          <Container style={{ marginTop: "20px" }}></Container>
        )}

        {onFinish && (
          <Form.Item>
            <Button
              loading={loading}
              onSubmit={() => onFinish(form.getFieldsValue())}
              type="primary"
              htmlType="submit"
              data-testid="addConsignerFormSubmitButton"
            >
              Invite
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ConsignerForm;
