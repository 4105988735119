import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import RedesignStyling from "../../constants/RedesignStyling";

/**
 * GridSelector
 * Renders a grid to let users select from a grid options
 * TODO Tests:
 *  - renders correctly (container, grid)
 *  - onClick selects
 */

const Container = styled.div`
  // @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
  // * {
  //   font-family: 'Inter', sans-serif;
  // }
`;
const Title = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin: 24px 0;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
  }
`;
const RowContainer = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  justify-content: flex-start;
`;
const Item = styled(Col)`
  background: ${(props: ItemProps) =>
    props.classicVersion
      ? props.selected
        ? Colors.BLUE_MAGENTA
        : props.background
      : props.background}; //props.selected ? Colors.BLUE_MAGENTA : props.background
  color: ${(props: ItemProps) =>
    props.classicVersion
      ? props.selected
        ? Colors.WHITE
        : Colors.BLACK
      : Colors.BLACK}; //props.selected ? Colors.WHITE :
  margin-bottom: 10px !important;
  padding: ${(props: ItemProps) => props.padding};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px !important;
  border: ${(props: ItemProps) => props.borderWidth + " " + props.borderStyle};
  border-radius: ${process.env.REACT_APP_TYPE !== "consigner"
    ? (props: ItemProps) => props.borderRadius
    : "normal"};
  cursor: pointer;
  border-color: ${(props: ItemProps) => props.borderColor};

  @media (max-width: 768px) {
    border-color: ${(props: ItemProps) =>
      props.selected ? Colors.CONSIGNER_BLUE : props.borderColor}; //#d3d3d3;
  }
`;
const ItemTitle = styled.p`
  margin-bottom: 0px;
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const ItemSubTitle = styled.p`
  margin-bottom: 0px;
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 12px;
`;
interface GridItem {
  title: string;
  subTitle: string;
  value: string;
}

interface Props {
  data?: GridItem[];
  title: string;
  onItemClick: (value: string) => void;
  selectedItem: string;
  small?: boolean;
  borderRadius: string;
  borderWidth: string;
  borderColor: string;
  borderStyle: string;
  padding: string;
  background: string;
  classicVersion: boolean;
  inventoryList?: any[];
  productRequest?: any[];
}

interface ItemProps {
  borderRadius: string;
  borderWidth: string;
  borderColor: string;
  borderStyle: string;
  selected: boolean;
  padding: string;
  background: string;
  classicVersion: boolean;
}

const GridSelector = (props: Props) => {
  const {
    data,
    onItemClick,
    title,
    selectedItem,
    small,
    borderRadius,
    borderColor,
    borderStyle,
    borderWidth,
    padding,
    background,
    classicVersion,
    inventoryList,
    productRequest,
  } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <RowContainer>
        {data?.map((item, index) => (
          <Item
            onClick={() => onItemClick(item.value!)}
            key={index}
            xs={5}
            sm={24}
            md={small ? 4 : 8}
            lg={small ? 4 : 6}
            xl={small ? 4 : 6}
            // selected={selectedItem === item.value}
            selected={inventoryList?.find(
              (inventoryItem) => inventoryItem.option1Value === item.value
            )}
            borderRadius={borderRadius}
            borderColor={
              productRequest?.includes(item.value)
                ? `${Colors.CONSIGNER_BLUE}`
                : borderColor
            }
            borderStyle={borderStyle}
            borderWidth={borderWidth}
            padding={padding}
            background={background}
            classicVersion={classicVersion}
          >
            <ItemTitle>{item.title}</ItemTitle>
            <ItemSubTitle>{item.subTitle}</ItemSubTitle>
          </Item>
        ))}
      </RowContainer>
    </Container>
  );
};

export default GridSelector;
