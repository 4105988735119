import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { Redirect } from "react-router";
import Content from "../constants/Content";
import PeopleTable from "../components/Common/PeopleTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getEmployees } from "../redux/actions/employeeActions";
import { Link } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import RedesignStyling from "../constants/RedesignStyling";

const { Search } = Input;

/**
 * Empployee Screen
 * Renders table of employee and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs employee
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const Employees = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const { employees, employeesLoading } = useAppSelector(
    (state) => state.EmployeeReducer
  );
  useEffect(() => {
    //get products here any time id changes
    dispatch(getEmployees(search));
  }, [search]);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Employees")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.EMPLOYEE_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <PeopleTable
        link="/employees"
        employees={employees}
        loading={employeesLoading}
        store={null}
      />
      <Link data-testid="addEmployeeLink" to="/addEmployee">
        Add Employee
      </Link>
    </Container>
  );
};

export default Employees;
