import React, { useEffect } from "react";
import { Form, Input, Card, Button, Upload, FormInstance, Image } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";

/**
 * Create Product Form
 * Takes in a form instance and onFinish callback
 * Renders a Form to Create Products
 * WARNING: There is no unit test coverage on this componenet b/c couldn't mock useForm instance to pass in. Decided this was fine since it is stricly antd UI library implementation
 * TODO Implementation:
 *  - Upload Image API
 *
 */

export interface FormValues {
  images: [];
}

interface Props {
  form: FormInstance;
  onFinish?: (values: FormValues) => void;
  logo: string;
  uploadedLogo: (values: any) => void;
}

const ImageUpload = (props: Props) => {
  const { form, onFinish, logo, uploadedLogo } = props;
  // const formValues = form.getFieldsValue();
  // const imagePreview = formValues.images;
  const [imagePreview, setImagePreview] = React.useState("");

  useEffect(() => {
    if (logo !== null) setImagePreview(logo);
  }, [logo]);

  const uploadImage = async (form: FormInstance) => {
    //@ts-ignore
    if (window && window.cloudinary) {
      //@ts-ignore
      window.cloudinary.openUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY,
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD,
        },
        (error: ErrorEvent, result: any) => {
          if (result && result.event === "success") {
            //Get Image uploaded
            const image = result.info.secure_url;

            setImagePreview(image);
            uploadedLogo(image);
            //Add contracts to state
            form.setFieldsValue({
              images: image,
            });
          }
        }
      );
    }
  };

  return (
    <Card>
      <Form
        data-testid="productForm"
        name="productForm"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="images" label="Images" hidden={true}>
          <Input />
        </Form.Item>
        {imagePreview ? (
          <div>
            <Image width={200} src={OptimizedImageUrl(imagePreview)} />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}
        {/* {imagePreview
          ? // <div>
            //   <Image width={200} src={imagePreview} />
            //   <br />
            //   <br />
            // </div>
            imagePreview.map((image: any, index: any) => (
              <div key={index}>
                <Image width={200} src={image.url} />
              </div>
            ))
          : ""} */}
        <Button onClick={() => uploadImage(form)} icon={<UploadOutlined />}>
          Click to upload
        </Button>
        <br />
        <br />
        {/* show list of uploaded images */}

        {onFinish && (
          <Form.Item>
            <Button
              // loading={loading}
              onSubmit={() => onFinish(form.getFieldsValue())}
              type="primary"
              htmlType="submit"
              data-testid="productFormSubmitButton"
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ImageUpload;
