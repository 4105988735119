import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";

/**
 * Checkout Bottom Bar
 * - renders next btn
 * TODO Tests:
 *  - should render (bar, btn)
 *  - should render btn txt
 *  - should have different onClick
 *  - should load state on loading
 */

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${Colors.BACKGROUND};
  padding: 20px;
  background: ${Colors.WHITE};
  bottom: 0px;

  @media (max-width: 400px) {
    position: fixed;
    width: 100%;
  }
`;
const LargeButton = styled(Button)`
  height: 60px;
  width: auto;
  background: ${Colors.BLUE_MAGENTA} !important;
  color: ${Colors.WHITE} !important;
  border: none;
  font-family: ${Fonts.PRIMARY};
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
`;

interface Props {
  buttonText: string;
  onClick: () => void;
  loading: boolean;
}

const CheckoutBottomBar = (props: Props) => {
  const { buttonText, onClick, loading } = props;
  return (
    <Container>
      <LargeButton loading={loading} onClick={onClick}>
        {buttonText}
      </LargeButton>
    </Container>
  );
};

export default CheckoutBottomBar;
