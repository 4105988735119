import React, { useEffect, useState } from "react";
import { Spin, Modal, message } from "antd";
import { useHistory, useParams, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { jsPDF } from "jspdf";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";
import { getProduct, updateProduct } from "../redux/actions/productActions";
import {
  acceptInventoryPrice,
  bulkPrintInventory,
  cancelDropoff,
  deleteInventory,
  getInventories,
  getInventoryFilterOptions,
  requestInventoryPriceChange,
  requestInventoryWithdrawal,
  updateInventory,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Content from "../constants/Content";
import { Product } from "../redux/reducers/ProductReducer";
import EditableInventoryTable from "../components/Common/EditableInventoryTable";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
import logo from "../assets/images/logo.png";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import moment from "moment";
import { Store } from "../redux/reducers/StoreReducer";
import { OptimizedImageUrl } from "../helperFunctions/optimizedImageUrl";
import RedesignStyling from "../constants/RedesignStyling";
/**
 * Product Item Screen
 * Used to handle product level update by the use
 * Features:
 *  - Navigate to add inventory for this specific product
 *  - Update values of the product
 *  - View and edit inventory of the product
 * TODO: Testing
 *  - On filter update re calls get Inventory API with correct filter items
 *  - on load inventory filter
 */

//styles
const Container = styled.div`
  background-color: ${RedesignStyling.PAGE_BG};
  padding: ${RedesignStyling.PAGE_PADDING};
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;
interface RouteParams {
  id: string;
}

const goToAddInventory = (
  history: RouteComponentProps["history"],
  id: string
) => {
  history.push(`/addInventory/${id}`);
};

const onInventoryItemAction = (
  inventory: Inventory,
  history: RouteComponentProps["history"]
) => {
  //go to inventory page
  history.push(`/inventory/${inventory.id}`);
};

export const printLabel = (
  inventories: Inventory[],
  dbUser: Consigner,
  store: Store
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: [8.5, 11],
  });
  // doc.addImage(logo, "png", 1, 1, 2, 4);
  doc.setFontSize(10);

  doc.text("Consigner:", 1, 1);
  doc.text(`ID: ${dbUser.id}`, 1, 1.25);
  doc.text(`Name: ${dbUser.firstName} ${dbUser.lastName}`, 1, 1.5);
  doc.text(`Email: ${dbUser.email}`, 1, 1.75);
  doc.text("Inventory:", 1, 2.5);
  let position = 2.75;
  for (let i = 0; i < inventories.length; i++) {
    const inventory = inventories[i];

    doc.text(`ID: ${inventory.id}`, 1, position);
    doc.text(`QR: ${inventory.code}`, 1, position + 0.25);
    doc.text(inventory.product.title, 1, position + 0.5);
    doc.text(inventory.product.sku, 1, position + 0.75);
    doc.text(`${store?.currency} ${inventory.product.price}`, 1, position + 1);
    doc.text(
      `Size: ${inventory.option1Value} / ${inventory.option2Value} / ${inventory.option3Value}`,
      1,
      position + 1.25
    );
    position += 1.5;
  }
  doc.save("inventory_label.pdf");
};
const ProductItem = () => {
  const { id }: RouteParams = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);

  const [filters, setFilters] = useState({
    printed: "",
    status: "",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    productId: id,
  });
  const { product, productLoading, updateProductLoading } = useAppSelector(
    (state) => state.ProductReducer
  );

  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProduct(id));
  }, [id]);

  useEffect(() => {
    //get inventory on filter updates
    dispatch(getInventories("", filters));
  }, [filters]);

  const handleCancelDropoff = async (id: number[]) => {
    await dispatch(await cancelDropoff(id, dbUser.id));
    dispatch(getInventories("", filters));
  };

  const handleWithdrawInventory = async (inventory: Inventory) => {
    if (inventory.status === "Pending") {
      //Show a modal with a delete warning and call delete inventory
      Modal.confirm({
        title: "Are you sure you want to withdraw this item?",
        content:
          "This item is currently pending and withdrawing it will change it's status.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          await dispatch(await requestInventoryWithdrawal(inventory, filters));
          dispatch(getInventories("", filters));
        },
      });
    } else if (
      inventory.status === "Active" ||
      inventory.status === "RequestPriceChange" ||
      inventory.status === "InTransit" ||
      inventory.status === "WithdrawalDeclined"
    ) {
      //Show a modal with withdrawal warning
      // //Check if we are adding a fee
      const acceptedOnDate = moment(inventory.acceptedOn);
      let current = moment();
      const daysSinceAccept = current.diff(acceptedOnDate, "days");

      let isWithdrawnEarly = false;
      let withdrawalFee = 0;

      if (daysSinceAccept <= Content.WITHDRAW_EARLY_AMOUNT_OF_DAYS) {
        //There is a withdrawal fee
        withdrawalFee = Content.WITHDRAW_FEE;
        isWithdrawnEarly = true;
      }
      Modal.confirm({
        title: "Are you sure you want to request withdrawal of this item?",
        content: `${
          false
            ? `You are within the cutoff of ${Content.WITHDRAW_EARLY_AMOUNT_OF_DAYS} days and will be charged a fee of ${store?.currency}${withdrawalFee} on your future payouts if withdrawn`
            : ""
        }`,
        okText: "Okay",
        onOk: async () => {
          await dispatch(await requestInventoryWithdrawal(inventory, filters));
          dispatch(getInventories("", filters));
        },
      });
    }
  };
  if (
    productLoading ||
    !product ||
    !inventoryFilterOptions ||
    inventoryFilterOptionsLoading
  ) {
    return (
      <Container data-testid="ProductScreenLoadingContainer">
        <Spin data-testid="ProductScreenLoadingSpinner" />
      </Container>
    );
  }
  return (
    <Container data-testid="ProductItemScreen">
      <HorizontalPageCard
        image={product.image}
        title={product.title}
        subtitle={product.sku}
        primaryActionText={`${inventories.length} listings`}
        primaryAction={() => console.log()}
      />
      <EditableInventoryTable
        inventories={inventories}
        loading={inventoriesLoading}
        onFilter={setFilters}
        inventoryFilterOptions={inventoryFilterOptions}
        onInventoryItemAction={(inventory: Inventory) =>
          printLabel([inventory], dbUser as Consigner, store as Store)
        }
        onInventoryItemDropoffAction={(inventory: Inventory) =>
          handleCancelDropoff([inventory.id])
        }
        onInventoryItemDangerAction={(inventory: Inventory) =>
          handleWithdrawInventory(inventory)
        }
        onInventoryItemPrimaryAction={(
          newPrice: string,
          inventory: Inventory
        ) => {
          if (newPrice !== "") {
            dispatch(
              requestInventoryPriceChange(
                {
                  ...inventory,
                  requestedPrice: newPrice,
                },
                filters
              )
            );
          } else {
            message.warning("Please enter a price");
          }
        }}
      />
    </Container>
  );
};

export default ProductItem;
