import React from "react";
import { Card, Input, Button } from "antd";
import { CardProps } from "antd/lib/card";
import Content from "../../constants/Content";
import styled from "styled-components";
import Fonts from "../../constants/Fonts";
import Colors from "../../constants/Colors";

/*
  Reset Password Card
  Used to login the user into the app
  
*/
//styles
export const Container = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
`;
const Title = styled.h2`
  font-family: ${Fonts.PRIMARY};
  text-algin: left;
  margin-bottom: 20px;
`;
const Message = styled.p`
  font-family: ${Fonts.PRIMARY};
  text-algin: center;
  margin-bottom: 40px;
`;
const AuthInput = styled(Input)`
  margin-bottom: 10px;
  padding: 10px;
  background: ${Colors.INPUT_BACKGROUND};
  border: ${Colors.INPUT_BORDER} solid 1px;
`;

//types
interface Props {
  onSubmit(email: string): void;
  email: string;
  onEmailChange(newEmail: string): void;
  loading: boolean;
  message: string;
  title: string;
  buttonText: string;
  showConfirm?: boolean;
}

const ResetPasswordCard = (props: Props): JSX.Element => {
  const {
    email,
    onEmailChange,
    onSubmit,
    loading,
    title,
    message,
    buttonText,
    showConfirm,
  } = props;
  return (
    <Container hoverable>
      <Title data-testid="title">{title}</Title>
      <Message>{message}</Message>
      <AuthInput
        data-testid="email"
        value={email}
        type="email"
        onChange={(e) => onEmailChange(e.target.value)}
        placeholder="Enter Email"
      />
      <Button
        data-testid="emailCollection"
        onClick={() => onSubmit(email)}
        type="primary"
        loading={loading}
      >
        {buttonText}
      </Button>
      {showConfirm && (
        <Button
          onClick={() => window.location.reload()}
          type="default"
          loading={loading}
        >
          Confirm
        </Button>
      )}
    </Container>
  );
};

export default ResetPasswordCard;
