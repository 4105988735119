import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Col, DatePicker, Row, Spin, message } from "antd";
import styled from "styled-components";
import LineChart from "../components/Charts/lineChart";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getDashboardReport } from "../redux/actions/employeeActions";
import DashboardAdminCard from "../components/Cards/DashboardAdminCard";
import { EmployeeState } from "../redux/reducers/EmployeeReducer";

const Container = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1rem;
`;

const SpinnerDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  textalign: center;
`;
const ChartContainer = styled.div`
  background-color: white; /* Set the desired background color for the chart container */
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
`;
const DashboardTitle = styled.h1`
  font-weight: semibold; /* Make the text bold */
  font-size: 20px; /* Increase font size */
  width: fit-content; /* Set width to fit the content */
`;
const { RangePicker } = DatePicker;

export default function DashboardAdmin() {
  const dispatch = useAppDispatch();
  const { dashboardReport, dashBoardLoading }: EmployeeState = useAppSelector(
    (state) => state.EmployeeReducer
  );

  const dashboardStats = dashboardReport?.dashboardStats;
  const [dateRange, setDateRange] = useState([
    moment().subtract(29, "days"),
    moment(),
  ]);

  useEffect(() => {
    const formattedDateRange = dateRange.map((date) =>
      date.format("YYYY-MM-DD")
    );
    dispatch(getDashboardReport(formattedDateRange));
  }, [dispatch, dateRange]);

  // Function to handle date range selection
  const handleDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    } else {
      setDateRange([moment().subtract(29, "days"), moment()]);
    }
  };

  return (
    <>
      {/* {dashBoardLoading && (
        <SpinnerDiv>
          <Spin size="large" />
          <p style={{ marginTop: "10px" }}>Loading...</p>
        </SpinnerDiv>
      )} */}
      {/* {!dashBoardLoading && ( */}
      <Container>
        <Row style={{ justifyContent: "space-between" }}>
          <DashboardTitle>Dashboard</DashboardTitle>
          <RangePicker
            onChange={handleDateChange}
            defaultValue={[moment().subtract(29, "days"), moment()]}
            allowClear={false}
            style={{ marginBottom: 20, backgroundColor: "#F4F4F4" }}
            placeholder={["Start Date", "End Date"]}
          />
        </Row>
        <Row style={{ marginBottom: "0.1rem" }}>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Revenue"}
                currentMonth={dashboardStats?.searchMonthRevenue}
                previousMonth={dashboardStats?.previousMonthRevenue}
                totalAmount={dashboardStats?.searchMonthRevenueTotal}
                previousMonthTotalAmount={
                  dashboardStats?.previousMonthRevenueTotal
                }
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Total Cost"}
                currentMonth={dashboardStats?.searchMonthCost}
                previousMonth={dashboardStats?.previousMonthCost}
                totalAmount={dashboardStats?.searchMonthCostTotal}
                previousMonthTotalAmount={
                  dashboardStats?.previousMonthCostTotal
                }
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Profit"}
                currentMonth={dashboardStats?.searctMonthProfit}
                previousMonth={dashboardStats?.previousMonthProfit}
                totalAmount={
                  Number(dashboardStats?.searchMonthRevenueTotal) &&
                  Number(dashboardStats?.searchMonthCostTotal)
                    ? Number(dashboardStats?.searchMonthRevenueTotal) -
                      Number(dashboardStats?.searchMonthCostTotal)
                    : 0
                }
                previousMonthTotalAmount={
                  Number(dashboardStats?.searchMonthRevenueTotal) -
                  Number(dashboardStats?.previousMonthCostTotal)
                }
              />
            </ChartContainer>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0.1rem" }}>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <DashboardAdminCard
                title={"Total Value"}
                totalValue={dashboardStats?.activeInventoryAmountTotal}
                previousMonthTotal={
                  dashboardStats?.activePrevInventoryAmountTotal
                }
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <DashboardAdminCard
                title={"Total Pending Payouts"}
                totalValue={dashboardStats?.payoutPendingAmountTotal}
                previousMonthTotal={
                  dashboardStats?.payoutPrevPendingAmountTotal
                }
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <DashboardAdminCard
                title={"Total Payouts Paid"}
                totalValue={dashboardStats?.payoutPaidAmountTotal}
                previousMonthTotal={dashboardStats?.payoutPrevPaidAmountTotal}
              />
            </ChartContainer>
          </Col>
        </Row>
        <Row style={{ marginBottom: "0.1rem" }}>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Total Owned Inventory Amount"}
                currentMonth={dashboardStats?.previousMonthOwnedAmount}
                previousMonth={dashboardStats?.searchMonthOwnedAmount}
                totalAmount={
                  dashboardStats?.searchMonthStoreOwnedInventoryTotal
                }
                previousMonthTotalAmount={
                  dashboardStats?.previousMonthStoreOwnedInventoryTotal
                }
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Total Inventory Units"}
                currentMonth={dashboardStats?.inventorySearchCount}
                previousMonth={dashboardStats?.inventoryPrevCount}
                totalAmount={dashboardStats?.searchMonthCount}
                unit={true}
                previousMonthTotalAmount={dashboardStats?.previousMonthCount}
              />
            </ChartContainer>
          </Col>
          <Col span={8} xs={24} sm={8} lg={8}>
            <ChartContainer>
              <LineChart
                title={"Total Purchases"}
                currentMonth={dashboardStats?.searchMonthOwnedCost}
                previousMonth={dashboardStats?.previousMonthOwnedCost}
                totalAmount={dashboardStats?.searchMonthStoreOwnedInventoryCost}
                previousMonthTotalAmount={
                  dashboardStats?.previousMonthStoreOwnedInventoryCost
                }
              />
            </ChartContainer>
          </Col>
        </Row>
      </Container>
      {/* )} */}
    </>
  );
}
