import React from "react";
import { Button, Select } from "antd";
import styled from "styled-components";
import { Product } from "../../redux/reducers/ProductReducer";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";
import Colors from "../../constants/Colors";
import RedesignStyling from "../../constants/RedesignStyling";
const { Option } = Select;
/**
 * Horizontal Product Card
 * Used to show information about the product and take action on the product item page
 *  Params: Product, Actions
 *
 * TODO TESTS:
 *  - options values rendering bold text, image, secondary action, dropdown
 */

//styles
const Container = styled.div`
  display: flex;
  background-color: ${RedesignStyling.PAGE_BG};
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 40px;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 10px;
  }
`;
const Data = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    // flex-wrap: wrap;
    padding-bottom: 20px;
  }
`;
const TextContainer = styled.div`
  margin-left: 40px;
  @media (max-width: 800px) {
    margin-left: 5px;
    padding: 5px;
    display: inline;
    gap: 5px;
    // justify-content: space-evenly;
    // flex-direction: column;
  }
`;
const Title = styled.p`
  color: #2e2e2e;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  @media (max-width: 800px) {
    flex: 1;
    margin: 0;
    text-align: center;
    display: inline;
  }
`;
const SubText = styled.p`
  color: #2e2e2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  opacity: 0.6;
  @media (max-width: 800px) {
    flex: 1;
    margin: 0;
    text-align: center;
    display: inline;
    margin-left: 5px;
  }
`;
const BoldText = styled.p`
  margin-bottom: 5px;
  font-weight: 600;
`;
const Image = styled.img`
  width: 150px;
  @media (max-width: 768px) {
    width: 75px;
  }
`;
const Actions = styled.div`
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }
`;
const PrimaryAction = styled(Button)`
  margin-left: 10px;
  background-color: ${Colors.CONSIGNER_BLUE};
  height: auto;
  @media (max-width: 800px) {
    font-size: 16px;
    width: 100%;
    margin-left: 0;
    padding: 16px 28px;
  }
`;
const SecondayAction = styled(Button)``;

export interface DropdownSelectorItem {
  label: string;
  value: string;
}
interface Props {
  image?: string;
  title: string;
  subtitle: string;
  boldText?: string;
  dropdownSelectorItems?: DropdownSelectorItem[];
  dropdownSelectorValue?: string;
  onDropdownChange?: (dropdownValue: string) => void;
  dropdownSelectorItemsTwo?: DropdownSelectorItem[];
  dropdownSelectorValueTwo?: string;
  onDropdownChangeTwo?: (dropdownValue: string) => void;
  primaryAction?: () => void;
  primaryActionText: string;
  primaryActionLoading?: boolean;
  secondaryAction?: () => void;
  secondaryActionText?: string;
  secondaryActionLoading?: boolean;
  thirdAction?: () => void;
  thirdActionText?: string;
  thirdActionLoading?: boolean;
  inventoryStatus?: string;
  safeInventoryStatusMode?: boolean;
}

const HorizontalPageCard = (props: Props) => {
  const {
    image,
    title,
    subtitle,
    boldText,
    dropdownSelectorItems,
    dropdownSelectorValue,
    onDropdownChange,
    dropdownSelectorItemsTwo,
    dropdownSelectorValueTwo,
    onDropdownChangeTwo,
    primaryActionText,
    primaryActionLoading,
    primaryAction,
    secondaryActionText,
    secondaryAction,
    secondaryActionLoading,
    thirdActionText,
    thirdAction,
    thirdActionLoading,
    inventoryStatus,
    safeInventoryStatusMode,
  } = props;

  return (
    <Container data-testid="horizontalPageCardContainer">
      <Data data-testid="horizontalPageCardData">
        {image && (
          <Image
            src={OptimizedImageUrl(image)}
            data-testid="horizontalPageCardImage"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = image;
            }}
          />
        )}
        <TextContainer>
          <Title data-testid="horizontalPageCardTitle">{title}</Title>
          {subtitle && (
            <SubText data-testid="horizontalPageCardSubtitle">
              {subtitle}
            </SubText>
          )}
          {thirdAction && (
            <Button type="ghost" onClick={thirdAction}>
              {thirdActionText}
            </Button>
          )}
          {boldText && (
            <BoldText data-testid="horizontalPageCardBoldText">
              {boldText}
            </BoldText>
          )}
          {onDropdownChange && (
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={onDropdownChange}
              value={dropdownSelectorValue}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropdownSelectorItems?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
          {onDropdownChangeTwo && (
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={onDropdownChangeTwo}
              value={dropdownSelectorValueTwo}
              filterOption={(input, option) =>
                String(option?.children)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={
                safeInventoryStatusMode &&
                inventoryStatus &&
                (inventoryStatus === "Sold" ||
                  inventoryStatus === "PendingSale" ||
                  inventoryStatus === "Paid")
                  ? true
                  : false
              }
            >
              {dropdownSelectorItemsTwo?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
        </TextContainer>
      </Data>
      <Actions>
        {secondaryAction && (
          <SecondayAction
            data-testid="horizontalPageCardSecondaryAction"
            onClick={secondaryAction}
            loading={secondaryActionLoading}
          >
            {secondaryActionText}
          </SecondayAction>
        )}
        {primaryAction && (
          <PrimaryAction
            type="primary"
            onClick={primaryAction}
            data-testid="horizontalPageCardPrimaryAction"
            loading={primaryActionLoading}
          >
            {primaryActionText}
          </PrimaryAction>
        )}
      </Actions>
    </Container>
  );
};

export default HorizontalPageCard;
