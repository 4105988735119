import React, { ReactElement, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import { Spin } from "antd";
import Cookies from "js-cookie";
import firebase from "firebase";
//Redux Stuff
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AuthService from "../../services/auth";
//components
import Topbar from "../../components/Layout/Topbar";
import Footer from "../../components/Layout/Footer";
import LoginCard from "../../components/Auth/LoginCard";
//constants
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { consignerNavItems, navItems } from "../../nav/navData";
import {
  acceptEmployeeInvite,
  getEmployee,
} from "../../redux/actions/employeeActions";
import { EmployeeState } from "../../redux/reducers/EmployeeReducer";
/*
Login Component using Firebase Auth
When a user logges is, firebase sign in is called
User is redirected to secret routes via a firebase onAuthStateChanged listener
onAuthStateChanged fires when user login states is changed from logged in to logged off
The logic for this function is found in ./redux/actions/users/setUserAuthState.ts
*/
//styles
const Container = styled.div`
  background: ${Colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;
const ContentContainer = styled.div`
  width: 30%;
`;

//types
export interface LoginProps {}
interface RouteParams {
  id: string;
}

export default function Login({}: LoginProps): JSX.Element {
  const { id }: RouteParams = useParams();

  //global state / redux
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [isFbTenantSet, setFBTenant] = useState(false);
  const { isLoggedIn } = useAppSelector((state) => state.UserReducer);
  const { employee, employeeLoading }: EmployeeState = useAppSelector(
    (state) => state.EmployeeReducer
  );
  const { store } = useAppSelector((state) => state.StoreReducer);

  const dispatch = useAppDispatch();
  const auth = AuthService();
  useEffect(() => {
    dispatch(getEmployee(id, true));
  }, []);

  useEffect(() => {
    if (employee && employee.store) {
      firebase.auth().tenantId = employee.store.tenantId;
      setFBTenant(true);
    }
  }, [employee]);

  //component
  if (isLoggedIn) return <Redirect data-testid="dashboardRedirect" to="/" />;

  if (!employee || employeeLoading || !isFbTenantSet)
    return (
      <Container>
        <Spin />
      </Container>
    );

  if (employee.fbUserId) return <Redirect to="/login" />;

  //helpers
  const signup = async (password: string) => {
    setLoginLoading(true);
    const email = employee.email;
    Cookies.set("employeeSignupId", id);
    const user = await auth.signup(email, password);
    setLoginLoading(false);
  };

  return (
    <Container data-testid="login">
      <Topbar
        logo={store?.logo!}
        backText={Content.TOPBAR_BACK_TEXT}
        backLink={`https://${store?.redirectURL!}`}
        nav={
          process.env.REACT_APP_TYPE === "consigner"
            ? consignerNavItems
            : navItems
        }
      />
      <ContentContainer>
        <LoginCard
          onLogin={(email, password) => signup(password)}
          email={employee.email}
          password={password}
          onEmailChange={(newEmail: string) => console.log(newEmail)}
          onPasswordChange={(newPassword: string) => setPassword(newPassword)}
          loginLoading={loginLoading}
          title="Signup"
          buttonText="Create Account"
        />
      </ContentContainer>

      <Footer />
    </Container>
  );
}
