import { GET_INVENTORYLOGS } from "../actions/types";

export interface modifiedField {
  variableChanged: string;
  beforeValue: string;
  afterValue: string;
}

export type InventoryLog = any;

export interface InventoryLogState {
  inventoryLogs: InventoryLog[];
  inventoryLogsLoading: boolean;
}

const defaultState = {
  inventoryLogs: [],
  inventoryLogsLoading: false,
};

export const DefaultInventoryLogState = defaultState;

export type InventoryLogAction = {
  type: string;
  inventoryLogs: InventoryLog[];
  inventoryLogsLoading: boolean;
};

export default (
  state: InventoryLogState = defaultState,
  action: InventoryLogAction
) => {
  switch (action.type) {
    case GET_INVENTORYLOGS:
      return {
        ...state,
        inventoryLogs: action.inventoryLogs,
        inventoryLogsLoading: action.inventoryLogsLoading,
      };
    default:
      return state;
  }
};
