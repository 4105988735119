import { ConsignerTypeItem } from "../../components/Common/ConsignerTypeTable";
import { ConsignerType } from "../reducers/ConsignerTypeReducer";

import { AppDispatch } from "../store";
import {
  CREATE_CONSIGNERTYPE,
  GET_CONSIGNERTYPE,
  GET_CONSIGNERTYPES,
  UPDATE_CONSIGNERTYPE,
} from "./types";
import { api } from "../../api";

const getConsignerTypesLoading = () => ({
  type: GET_CONSIGNERTYPES,
  consignerTypes: [],
  consignerTypesLoading: true,
});

const getConsignerTypesError = () => ({
  type: GET_CONSIGNERTYPES,
  consignerTypes: [],
  consignersLoading: false,
});

const getConsignerTypesSuccess = (consignerTypes: ConsignerType[]) => ({
  type: GET_CONSIGNERTYPES,
  consignerTypes,
  consignerTypesLoading: false,
});

const updateConsignerTypeLoading = () => ({
  type: UPDATE_CONSIGNERTYPE,
  updatedConsignerType: null,
  updateConsignerTypeLoading: true,
});

const updateConsignerTypeError = () => ({
  type: UPDATE_CONSIGNERTYPE,
  updatedConsignerType: null,
  updateConsignerTypeLoading: false,
});

const updateConsignerTypeSuccess = (updatedConsignerType: ConsignerType) => ({
  type: UPDATE_CONSIGNERTYPE,
  updatedConsignerType,
  updateConsignerTypeLoading: false,
});

const createConsignerTypeLoading = () => ({
  type: CREATE_CONSIGNERTYPE,
  createdConsignerType: null,
  createConsignerTypeLoading: true,
});

const createConsignerTypeError = () => ({
  type: CREATE_CONSIGNERTYPE,
  createdConsignerType: null,
  createConsignerTypeLoading: false,
});

const createConsignerTypeSuccess = (createdConsignerType: ConsignerType) => ({
  type: CREATE_CONSIGNERTYPE,
  createdConsignerType,
  createConsignerTypeLoading: false,
});
const getConsignerTypeLoading = () => ({
  type: GET_CONSIGNERTYPE,
  consignerType: null,
  consignerTypeLoading: true,
});

const getConsignerTypeError = () => ({
  type: GET_CONSIGNERTYPE,
  consignerType: null,
  consignerTypeLoading: false,
});

const getConsignerTypeSuccess = (consignerType: ConsignerType) => ({
  type: GET_CONSIGNERTYPE,
  consignerType,
  consignerTypeLoading: false,
});

//api
const getConsignerTypesFromAPI = async () => {
  const { data, error } = await api.provide("get", "/api/consignerTypes", {});
  if (error) throw error;
  return (data?.consignerTypes || []) as any;
};

const getConsignerTypeFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/consignerTypes/:id", {
    id,
  });
  if (error) throw error;
  return data;
};

const updateConsignerTypeFromAPI = async (
  id: string,
  consignerType: ConsignerTypeItem
) => {
  const payload = {
    id,
    accountType: consignerType.accountType,
    sellingFee: consignerType.sellingFee,
    minFee: consignerType.minFee,
    flatFee: consignerType.flatFee,
    isDefault: consignerType.isDefault,
    category: consignerType.category,
    option1Value: consignerType.option1Value,
    option2Value: consignerType.option2Value,
    option3Value: consignerType.option3Value,
  };

  const { data, error } = await api.provide(
    "put",
    "/api/consignerTypes/:id",
    payload
  );
  if (error) throw error;
  return data;
};

const createConsignerTypeFromAPI = async (newConsigner: ConsignerTypeItem) => {
  const payload = {
    accountType: newConsigner.accountType,
    sellingFee: newConsigner.sellingFee,
    minFee: newConsigner.minFee,
    flatFee: newConsigner.flatFee,
    isDefault: newConsigner.isDefault,
    category: newConsigner.category,
    option1Value: newConsigner.option1Value,
    option2Value: newConsigner.option2Value,
    option3Value: newConsigner.option3Value,
  };

  const { data, error } = await api.provide(
    "post",
    "/api/consignerTypes",
    payload
  );
  if (error) throw error;
  return data;
};
//actions
export const getConsignerTypes = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignerTypesLoading());
    try {
      dispatch(getConsignerTypesSuccess(await getConsignerTypesFromAPI()));
    } catch (e) {
      dispatch(getConsignerTypesError());
    }
  };
};

export const getConsignerType = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignerTypeLoading());
    try {
      dispatch(getConsignerTypeSuccess(await getConsignerTypeFromAPI(id)));
    } catch (e) {
      dispatch(getConsignerTypeError());
    }
  };
};

export const createConsignerType = (consignerType: ConsignerTypeItem) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createConsignerTypeLoading());
    try {
      dispatch(
        createConsignerTypeSuccess(
          await createConsignerTypeFromAPI(consignerType)
        )
      );
    } catch (e) {
      dispatch(createConsignerTypeError());
    }
  };
};
export const updateConsignerType = (
  id: string,
  consignerType: ConsignerTypeItem
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateConsignerTypeLoading());
    try {
      dispatch(
        updateConsignerTypeSuccess(
          await updateConsignerTypeFromAPI(id, consignerType)
        )
      );
    } catch (e) {
      dispatch(updateConsignerTypeError());
    }
  };
};
