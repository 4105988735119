import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Image, Input, Select } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getStoreProductsForCatalogueFromAPI } from "../redux/actions/productActions";
import ProductList, { CatalogueList } from "../components/Common/ProductList";
import { useParams } from "react-router-dom";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { Link } from "@mui/material";
import Colors from "../constants/Colors";
import RedesignStyling from "../constants/RedesignStyling";
import { inventoryFilterOptions } from "../constants/TestData";
import { getInventoryFilterOptions } from "../redux/actions/inventoryActions";
import { UserOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Option } = Select;

/*
Products Component
- products are fetched from getProducts API (redux/actions/productActions) and added into the Products Reducer 
- products fetch on load and everytime search is updated
Returns Component with
- Search Component
- List of Products
*/

//styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px 40px;
  & > span {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    border-bottom: 1px solid var(--Line, #ddd);
    background: #f9f9f9;
    padding-bottom: 5px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 52px;
  }

  @media (max-width: 768px) {
    padding: 11px 16px;
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
  // width: 100%;
  flex: 1;
  & > span {
    width: 900px;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.CONSIGNER_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;
    // order: 2;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

const BlockSpan = styled.div`
  border-radius: 8px;
  opacity: 0.9;
  background: #0b0937;
  width: 100%;
  padding: 10px;
  display: flex;
  height: 240px;
  padding: 50px;
  gap: 15px;

  .ant-image {
    display: flex;
    background-color: #fff;
    img {
      flex: 0;
      height: auto;
      width: auto;
      max-width: 130px;
      max-height: 130px;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    height: auto;
  }
`;

const TitleBlock = styled.div`
  border-radius: 8px;
  opacity: 0.4;
  background: var(--Black, #000);
  flex: 0 0 450px;
  max-width: 450px;
  padding: 28px;
  h1 {
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 31.5px */
  }
  p {
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }

  @media (max-width: 768px) {
    flex: 1;
    max-widh: 100%;
    padding: 28px 24px 7px;
  }
`;

//types
export interface ProductsProps {}

interface RouteParams {
  handle: string;
}

export default function Catalogue({}: ProductsProps): JSX.Element {
  const { handle }: RouteParams = useParams();
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [products, setProducts] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [signupURL, setSignupURL] = useState<any>();
  const [logo, setLogo] = useState<any>("");

  const onLocationSelect = (value: any) => {
    setSelectedLocation(value);
  };

  const fetchDataForCatalogue = async () => {
    const { products, locations, signUpLink, inventories, logo } =
      await getStoreProductsForCatalogueFromAPI(
        search,
        selectedLocation,
        handle
      );
    const scheme = window.location.protocol;
    const link = scheme + "//" + signUpLink;
    setProducts(products);
    setLocations(locations);
    setSignupURL(link);
    setInventories(inventories);
    setLogo(logo);
  };

  useEffect(() => {
    //get products here any time search updates
    setProducts([]);
    fetchDataForCatalogue();
  }, [search, selectedLocation]);

  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container data-testid="CatalogueScreen">
      <span>
        <UserOutlined
          style={{
            fontSize: 24,
            height: 41,
            width: 41,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Button type="link" href={signupURL}>
          Sign in
        </Button>
        or
        <Button type="link" href={signupURL}>
          Sign up
        </Button>
      </span>
      <BlockSpan>
        <Image src={logo} />
        <TitleBlock>
          <h1>IN DEMAND</h1>
          <p>Best Selling shoes over the last 14 days.</p>
        </TitleBlock>
      </BlockSpan>
      <CatalogueList
        data-testid="productList"
        products={products}
        loading={products?.length === 0}
        productTemplates={[]}
        updatedProduct={{}}
        dispatch={false}
        selectedLocation={selectedLocation}
        storeRequest={true}
        requestedInventories={inventories}
        signupURL={signupURL}
      />
    </Container>
  );
}
