import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import ProductTemplateTable from "../components/Common/ProductTemplateTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { UserState } from "../redux/reducers/UserReducer";
import { Result, Button } from "antd";

import { ProductTemplate } from "../redux/reducers/ProductTemplateReducer";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { getProducts } from "../redux/actions/productActions";
import { Product } from "../redux/reducers/ProductReducer";
import { getInventories } from "../redux/actions/inventoryActions";
import RedesignStyling from "../constants/RedesignStyling";

/**
 * Settings Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const ProductTemplates = () => {
  const dispatch = useAppDispatch();
  const {
    productTemplates,
    productTemplatesLoading,
    createProductTemplateLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  } = useAppSelector((state) => state.InventoryReducer);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    printed: "",
    status: "",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    consigner: "",
  });
  useEffect(() => {
    //get consignerTypes on load
    dispatch(getProductTemplates());
  }, []);

  useEffect(() => {
    //refresh inventory list on filter or search change
    dispatch(getInventories(search, filters));
  }, [search, filters]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Settings")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <ProductTemplateTable
        tableData={productTemplates}
        loading={productTemplatesLoading}
        savedLoading={createProductTemplateLoading}
        inventories={inventories}
      />
      {/* <Result
        status="warning"
        title="Page under construction, come back later..."
      /> */}
    </Container>
  );
};

export default ProductTemplates;
