import { SubLocationItem } from "../../components/Common/SubLocationsTable";
import { SubLocation } from "../reducers/SubLocationReducer";

import { AppDispatch } from "../store";
import {
  CREATE_SUBLOCATION,
  GET_SUBLOCATION,
  GET_SUBLOCATIONS,
  UPDATE_SUBLOCATION,
} from "./types";
import { api } from "../../api";

const getSubLocationsLoading = () => ({
  type: GET_SUBLOCATIONS,
  subLocations: [],
  subLocationsLoading: true,
});

const getSubLocationsError = () => ({
  type: GET_SUBLOCATIONS,
  subLocations: [],
  subLocationsLoading: false,
});

const getSubLocationsSuccess = (subLocations: SubLocation[]) => ({
  type: GET_SUBLOCATIONS,
  subLocations,
  subLocationsLoading: false,
});

const updateSubLocationLoading = () => ({
  type: UPDATE_SUBLOCATION,
  updatedSubLocation: null,
  updatedSubLocationLoading: true,
});

const updateSubLocationError = () => ({
  type: UPDATE_SUBLOCATION,
  updatedSubLocation: null,
  updatedSubLocationLoading: false,
});

const updateSubLocationSuccess = (updatedSubLocation: SubLocation) => ({
  type: UPDATE_SUBLOCATION,
  updatedSubLocation,
  updatedSubLocationLoading: false,
});

const createSubLocationLoading = () => ({
  type: CREATE_SUBLOCATION,
  createdSubLocation: null,
  createdSubLocationLoading: true,
});

const createSubLocationError = () => ({
  type: CREATE_SUBLOCATION,
  createdSubLocation: null,
  createdSubLocationLoading: false,
});

const createSubLocationSuccess = (createdSubLocation: SubLocation) => ({
  type: CREATE_SUBLOCATION,
  createdSubLocation,
  createdSubLocationLoading: false,
});
const getSubLocationLoading = () => ({
  type: GET_SUBLOCATION,
  subLocation: null,
  subLocationLoading: true,
});

const getSubLocationError = () => ({
  type: GET_SUBLOCATION,
  subLocation: null,
  subLocationLoading: false,
});

const getSubLocationSuccess = (subLocation: SubLocation) => ({
  type: GET_SUBLOCATION,
  subLocation,
  subLocationLoading: false,
});

//api
const getSubLocationsFromAPI = async () => {
  const { data, error } = await api.provide("get", "/api/subLocations", {});
  if (error) throw error;
  return (data?.subLocations || []) as any;
};
const getSubLocationFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/subLocations/:id", {
    id,
  });
  if (error) throw error;
  return data;
};
const updateSubLocationFromAPI = async (
  id: string,
  subLocation: SubLocationItem
) => {
  const { data, error } = await api.provide("put", "/api/subLocations/:id", {
    id,
    name: subLocation.name,
  });
  if (error) throw error;
  return data;
};

const createSubLocationFromAPI = async (newSubLocation: SubLocationItem) => {
  const payload = {
    name: newSubLocation.name,
    locationName: newSubLocation.locationName,
  };

  const { data, error } = await api.provide(
    "post",
    "/api/subLocations",
    payload
  );
  if (error) throw error;
  return data;
};
//actions
export const getSubLocations = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getSubLocationsLoading());
    try {
      dispatch(getSubLocationsSuccess(await getSubLocationsFromAPI()));
    } catch (e) {
      console.log(e);
      dispatch(getSubLocationsError());
    }
  };
};

export const getSubLocation = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getSubLocationLoading());
    try {
      dispatch(getSubLocationSuccess(await getSubLocationFromAPI(id)));
    } catch (e) {
      console.log(e);

      dispatch(getSubLocationError());
    }
  };
};

export const createSubLocation = (subLocation: SubLocationItem) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createSubLocationLoading());
    try {
      dispatch(
        createSubLocationSuccess(await createSubLocationFromAPI(subLocation))
      );
    } catch (e) {
      console.log(e);

      dispatch(createSubLocationError());
    }
  };
};

export const updateSubLocation = (id: string, subLocation: SubLocationItem) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateSubLocationLoading());
    try {
      dispatch(
        updateSubLocationSuccess(
          await updateSubLocationFromAPI(id, subLocation)
        )
      );
    } catch (e) {
      console.log(e);

      dispatch(updateSubLocationError());
    }
  };
};
