import React, { useState, useEffect, Dispatch } from "react";
import styled from "styled-components";
import {
  List,
  Card,
  message,
  Modal,
  Button,
  Select,
  Tag,
  Spin,
  Row,
  Col,
  Divider,
  Space,
  InputNumber,
  FormInstance,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getProductTemplate } from "../../redux/actions/productTemplateActions";
import productActions, {
  clearUpdatedProduct,
  updateProduct,
} from "../../redux/actions/productActions";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { AppState } from "../../redux/reducers/AppReducer";

const ProductFlexContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const ImageSmall = styled.img`
  width: 150px;
  height: auto;
  @media (max-width: 768px) {
  }
  object-fit: contain;
  object-position: center;
`;
const ProdContent = styled.div`
  margin-left: 24px;
  width: 100%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
const Brand = styled.p`
  color: ${process.env.REACT_APP_TYPE === "consigner"
    ? Colors.CONSIGNER_GREY
    : "inherit"};
  font-weight: 100;
  margin: 0%;
  width: 100%;
  @media (max-width: 768px) {
    text-align: center;
    display: none;
  }
`;

const ItemDetails = styled.p`
  color: ${process.env.REACT_APP_TYPE === "consigner"
    ? Colors.CONSIGNER_GREY
    : "inherit"};
  font-weight: 100;
  width: 100%;
  @media (max-width: 768px) {
    text-align: center;
    font-family: ${Fonts.CONSIGNER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
    opacity: 0.7;
  }
`;

const ModalTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin: 5px 0%;
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
    font-family: ${Fonts.CONSIGNER};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
  }
`;

const CustomSpace = styled(Space)`
  width: 100%;
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    text-align: center;
    min-width: 0;
    flex-wrap: wrap;
    margin: 32px 0 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .ant-space-item {
      flex: 1 0 100%;
      min-width: 0;
      button {
        width: 100%;
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: auto;
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
    display: flex;
    align-items: center;
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: start;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    .ant-select-item-option-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-item {
      font-size: 16px;
    }
  }
`;

const CategorySelector = (props: any) => {
  const {
    product,
    updatedProduct,
    productTemplates,
    setSelectedProductTemplate,
    selectedProductTemplate,
    setCategory,
    dispatch,
    isNewEntry,
    inventories,
    inventoriesLoading,
    form,
    updateProductFn,
  } = props;

  const { store } = useAppSelector((state) => state.StoreReducer);
  const [filteredTemplates, setFilteredTemplates] = useState<any>([]);
  const { isMobile } = useAppSelector((state): AppState => state.AppReducer);

  useEffect(() => {
    //Find active inventory of this product
    setFilteredTemplates(productTemplates);

    if (
      inventories &&
      inventories.length > 0 &&
      selectedProductTemplate &&
      productTemplates
    ) {
      const activeInventoryCheck = inventories.filter(
        (item: any) => item.productId === product.id && item.status === "Active"
      );

      const productTemplate = productTemplates?.find(
        (item: any) => item.id === product.productTemplateId
      );

      if (activeInventoryCheck.length > 0) {
        const filteredTemplatesEffect = productTemplates.filter(
          (template: any) => {
            if (
              (productTemplate?.option1 !== null &&
                template.option1 !== null &&
                productTemplate?.option2 === null &&
                template.option2 === null &&
                productTemplate.option3 === null &&
                template.option3 === null) ||
              (productTemplate?.option1 !== null &&
                template.option1 !== null &&
                productTemplate?.option2 !== null &&
                template.option2 !== null &&
                productTemplate?.option3 === null &&
                template.option3 === null) ||
              (productTemplate?.option1 !== null &&
                template.option1 !== null &&
                productTemplate?.option2 !== null &&
                template.option2 !== null &&
                productTemplate?.option3 !== null &&
                template.option3 !== null)
            ) {
              return template;
            }
          }
        );
        setFilteredTemplates(filteredTemplatesEffect);
      }
    }
  }, [inventories, productTemplates, selectedProductTemplate]);

  const handleTemplateChange = (e: any) => {
    // if (product && Number(product.id)) {
    let template = productTemplates.filter((item: any) => item.id === e)[0];
    setSelectedProductTemplate(template);
    product["productTemplateId"] = e;
    if (updateProductFn) {
      updateProductFn(product.id, product, store);
    } else {
      dispatch(updateProduct(product.id, product));
    }
    /* } else {
      product["productTemplateId"] = e;
      dispatch(updateProduct(product.id, product));
    } */
  };

  const handleUpdate = () => {
    let template = productTemplates.filter(
      (item: any) => item.id === product.productTemplateId
    )[0];
    setSelectedProductTemplate(template);
    dispatch(updateProduct(product.id, product));
  };

  return (
    <Row align="middle" justify="center">
      <Col flex="auto">
        <ProductFlexContainer>
          <ImageSmall
            data-testid="image"
            src={OptimizedImageUrl(product.image)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = product.image;
            }}
          />
          <ProdContent>
            <Brand data-testid="title">{product.brand}</Brand>
            <ModalTitle>{product.title}</ModalTitle>
            <ItemDetails>
              {product.sku ? `SKU: ${product.sku} |` : ""}
              {product.color ? " Color |" : ""}
              {product.year ? ` ${product.year} |` : ""}
              {product.gender ? ` ${product.gender}` : ""}
            </ItemDetails>
            <CustomSpace>
              <StyledSelect
                placeholder="Choose Category"
                showSearch
                value={
                  product.productTemplateId
                    ? product.productTemplateId
                    : selectedProductTemplate
                }
                dropdownMatchSelectWidth={window.innerWidth < 768}
                maxTagCount="responsive"
                onChange={(value: any) => handleTemplateChange(value)}
              >
                {filteredTemplates &&
                  filteredTemplates.map((item: any, index: any) => (
                    <Select.Option key={index} value={item.id}>
                      {item.title} / {item.category} /{" "}
                      {item.brand ? `${item.brand} / ` : ""}
                      {item.gender ? `${item.gender} / ` : ""}
                      <Tag color={"green"}>{item.option1.toUpperCase()}</Tag>
                      {item.option2 ? (
                        <Tag color={"green"}>{item.option2.toUpperCase()}</Tag>
                      ) : (
                        <div />
                      )}
                      {item.option3 ? (
                        <Tag color={"green"}>{item.option3.toUpperCase()}</Tag>
                      ) : (
                        <div />
                      )}
                    </Select.Option>
                  ))}
              </StyledSelect>
              {/* <Button
                hidden={Number(product && product.id) ? true : false}
                onClick={handleUpdate}
                type={isMobile ? "primary" : "text"}
              >
                Save
              </Button> */}
            </CustomSpace>
          </ProdContent>
        </ProductFlexContainer>
      </Col>
    </Row>
  );
};

export default CategorySelector;
