import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Card,
  Button,
  Upload,
  FormInstance,
  Image,
  Select,
  Popconfirm,
  message,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ProductTemplates from "../../screens/ProductTemplates";
import { useParams } from "react-router";
import { Store } from "../../redux/reducers/StoreReducer";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";
/**
 * Create Product Form
 * Takes in a form instance and onFinish callback
 * Renders a Form to Create Products
 * WARNING: There is no unit test coverage on this componenet b/c couldn't mock useForm instance to pass in. Decided this was fine since it is stricly antd UI library implementation
 * TODO Implementation:
 *  - Upload Image API
 *
 */

export interface DropdownSelectorItem {
  label: string;
  value: string;
}

export interface FormValues {
  title: string;
  sku: string;
  stockXId: string;
  shopifyId: string;
  brand: string;
  category: string;
  images: string;
  image: string;
  productTemplateId: string;
  gender: string;
  GTIN?: {
    size: string;
    gtin: string;
  }[];
}

interface Props {
  form: FormInstance;
  store?: Store;
  onFinish?: (values: FormValues) => void;
  loading?: boolean;
  productTemplates?: any;
  inventories?: any;
  productTemplateId?: any;
}

const ProductForm = (props: Props) => {
  const {
    form,
    store,
    onFinish,
    loading,
    productTemplates,
    inventories,
    productTemplateId,
  } = props;
  // const formValues = form.getFieldsValue();
  // const imagePreview = formValues.images;
  const [imagePreview, setImagePreview] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [activeInventory, setActiveInventory] = React.useState("");
  const [formValues, setFormValues] = React.useState<any>([]);
  const [filteredTemplates, setFilteredTemplates] = React.useState<any>([]);
  const [image_name, setImage_name] = useState("");
  const [msg, setMsg] = React.useState(
    "Are you sure you want to change the template?"
  );

  useEffect(() => {
    let name = `${store?.handle}_${Math.floor(
      100000 + Math.random() * 900000
    )}`;
    setImage_name(name);
  }, [formValues]);

  const uploadImage = async (form: FormInstance) => {
    //uploads to cloudinary and saves url to state
    //@ts-ignore

    if (window && window.cloudinary) {
      //@ts-ignore
      window.cloudinary.openUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDINARY,
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD,
          publicId: image_name,
        },
        (error: ErrorEvent, result: any) => {
          if (result && result.event === "success") {
            //Get Image uploaded
            const secure_url = result.info.secure_url;
            setImagePreview(secure_url);
            //Add contracts to state
            form.setFieldsValue({
              images: secure_url,
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    setFormValues(form.getFieldsValue());
  }, [loading]);

  const productTemplate = productTemplates?.find(
    (item: any) => item.id === productTemplateId
  );

  useEffect(() => {
    //Find active inventory of this product
    if (inventories?.length > 0) {
      let activeInventoryCheck = inventories?.filter(
        (item: any) =>
          item.productId === formValues.id && item.status === "Active"
      );
      setActiveInventory(activeInventoryCheck);

      const filteredTemplatesEffect = productTemplates?.filter(
        (template: any) => {
          if (
            (productTemplate.option1 !== null &&
              template.option1 !== null &&
              productTemplate.option2 === null &&
              template.option2 === null &&
              productTemplate.option3 === null &&
              template.option3 === null) ||
            (productTemplate.option1 !== null &&
              template.option1 !== null &&
              productTemplate.option2 !== null &&
              template.option2 !== null &&
              productTemplate.option3 === null &&
              template.option3 === null) ||
            (productTemplate.option1 !== null &&
              template.option1 !== null &&
              productTemplate.option2 !== null &&
              template.option2 !== null &&
              productTemplate.option3 !== null &&
              template.option3 !== null)
          ) {
            return template;
          }
        }
      );
      setFilteredTemplates(filteredTemplatesEffect);
    } else {
      setFilteredTemplates(productTemplates);
    }
  }, [inventories, loading, productTemplates]);

  const { Text } = Typography;

  return (
    <Card>
      <Form
        data-testid="productForm"
        name="productForm"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="id" label="id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="images" label="Images" hidden={true}>
          <Input />
        </Form.Item>
        {imagePreview ? (
          <div>
            <Image width={200} src={OptimizedImageUrl(imagePreview)} />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}
        <Button onClick={() => uploadImage(form)} icon={<UploadOutlined />}>
          Click to upload
        </Button>
        <br />
        <br />
        <Text type="secondary" style={{ paddingTop: "10px" }}>
          *Images uploaded here will not sync to Shopify. Default images still
          sync.
        </Text>
        <br />
        <br />
        {/* show list of uploaded images */}
        {/* Add a rule to prevent slashes being used in the title */}
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input data-testid="productFormTitle" />
        </Form.Item>
        <Form.Item name="sku" label="Sku" rules={[{ required: true }]}>
          <Input data-testid="productFormSku" />
        </Form.Item>
        <Form.Item
          name="stockXId"
          label="Catalog Id"
          // rules={[{ required: true }]}
        >
          <Input data-testid="productFormStockXId" />
        </Form.Item>

        <Form.Item name="shopifyId" label="Shopify Id">
          <Input data-testid="productFormShopifyId" />
        </Form.Item>

        <Form.Item name="brand" label="Brand" rules={[{ required: true }]}>
          <Input data-testid="productFormBrand" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true }]}
        >
          <Input data-testid="productFormCategory" />
        </Form.Item>
        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
          <Input data-testid="productFormGender" />
        </Form.Item>
        <Form.Item
          name="productTemplateId"
          label="Product Template"
          rules={[{ required: true }]}
        >
          {/* can change template ONLY if NO active inventory */}

          <Select>
            {filteredTemplates?.map((item: any, index: any) => (
              <Select.Option key={index} value={item.id}>
                {item.title} / {item.category} /{" "}
                {item.brand ? `${item.brand} / ` : ""}
                {item.gender ? `${item.gender} / ` : ""}
                <Tag color={"green"}>{item.option1.toUpperCase()}</Tag>
                {item.option2 ? (
                  <Tag color={"green"}>{item.option2.toUpperCase()}</Tag>
                ) : (
                  ""
                )}
                {item.option3 ? (
                  <Tag color={"green"}>{item.option3.toUpperCase()}</Tag>
                ) : (
                  ""
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {onFinish && (
          <Form.Item>
            <Button
              loading={loading}
              onSubmit={() => onFinish(form.getFieldsValue())}
              type="primary"
              htmlType="submit"
              data-testid="productFormSubmitButton"
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ProductForm;
