import React from "react";
import { Form, Radio, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { PayoutFilterOptions } from "../../redux/reducers/PayoutReducer";

/**
 * Payouts Filter
 * Used to filer a list of Payuots
 * has onFilter prop which triggers on filter changes
 * form of filters
 * TODO Test
 *  - render correctly
 *  - onFilter triggers on filter changes
 */
export interface FormValues {
  status: string;
  consigner: string;
}

interface Props {
  formOptions: PayoutFilterOptions;
  onFilter: (values: FormValues) => void;
}

const PayoutFilter = (props: Props) => {
  const [form] = useForm();
  const { formOptions, onFilter } = props;

  let consigners;
  if (formOptions) {
    consigners = formOptions.consigners;
  }

  return (
    <Form
      layout="inline"
      name="PayoutFilter"
      form={form}
      data-testid="PayoutFilter"
      onValuesChange={onFilter}
      style={{ marginTop: 10 }}
    >
      <Form.Item name="status">
        <Radio.Group data-testid="statuses">
          {formOptions.statuses.map((status) => (
            <Radio.Button
              data-testid="status"
              key={status.label}
              value={status.value}
            >
              {status.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name="consigner">
        <Select
          placeholder="Filter by Consigner"
          style={{ width: 200 }}
          showSearch
          filterOption={(input, option) =>
            String(option?.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          data-testid="consigners"
        >
          {consigners?.map((consigner) => (
            <Select.Option
              key={consigner.value.id}
              value={JSON.stringify(consigner.value.id)}
            >
              {consigner.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default PayoutFilter;
