import { SET_IS_MOBILE } from "../actions/types";

export interface AppState {
  isMobile: boolean;
}

const defaultState = {
  isMobile: false,
};

export default (state: AppState = defaultState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};
