import React, { Dispatch, SetStateAction } from "react";
import { Table, Input } from "antd";
import { Product } from "../../redux/reducers/ProductReducer";
import styled from "styled-components";
import Content from "../../constants/Content";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";
const { Search } = Input;
/**
 * Submitted Products Table
 * Renders a table of products that can be autofilled and searched
 */

//styles
const Container = styled.div``;
const Image = styled.img`
  max-width: 50px;
`;
const HeaderContainer = styled.div``;
const HeaderTitle = styled.p``;

//types
interface Props {
  setSearch: Dispatch<SetStateAction<string>>;
  submittedProducts: Product[];
  search: string;
  onFill: (product: Product) => void;
  loading: boolean;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  render: (key: string, data: Product) => JSX.Element;
}
interface HeaderProps {
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
}
const columns = (onFill: (product: Product) => void): Columns[] => [
  {
    title: "image",
    dataIndex: "image",
    key: "image",
    render: (image: string) => <Image src={OptimizedImageUrl(image)} />,
  },
  {
    title: "title",
    dataIndex: "title",
    key: "title",
    render: (text: string) => (
      <span data-testid="submittedProductsTableTitle">{text}</span>
    ),
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    render: (text: string) => (
      <span data-testid="submittedProductsTableSku">{text}</span>
    ),
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
    render: (text: string) => (
      <span data-testid="submittedProductsTableBrand">{text}</span>
    ),
  },
  {
    title: "Action",
    dataIndex: "id",
    key: "id",
    render: (text: string, data: Product) => (
      <a
        data-testid="submittedProductsTableFillLink"
        onClick={() => onFill(data)}
      >
        Fill
      </a>
    ),
  },
];
const Header = (props: HeaderProps): JSX.Element => {
  const { search, setSearch } = props;
  return (
    <HeaderContainer data-testid="submittedProductsTableHeader">
      <HeaderTitle data-testid="submittedProductsTableHeaderTitle">
        {Content.SUBMITTED_PRODUCT_TABLE_HEADER_TITLE}
      </HeaderTitle>
      <Search
        data-testid="submittedProductsTableSearch"
        placeholder={Content.SUBMITTED_PRODUCT_TABLE_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </HeaderContainer>
  );
};
const SubmittedProductsTable = ({
  submittedProducts,
  setSearch,
  search,
  onFill,
  loading,
}: Props) => {
  return (
    <Container data-testid="submittedProductsTableContainer">
      <Table
        data-testid="submittedProductsTableTable"
        title={() => <Header search={search} setSearch={setSearch} />}
        columns={columns(onFill)}
        dataSource={submittedProducts}
        rowKey="id"
        loading={loading}
      />
    </Container>
  );
};

export default SubmittedProductsTable;
