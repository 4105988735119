import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, Redirect } from "react-router";
import { Spin } from "antd";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getEmployee,
  sendEmployeeInvite,
  updateEmployee,
} from "../redux/actions/employeeActions";
import { Employee, EmployeeState } from "../redux/reducers/EmployeeReducer";
import { AppDispatch } from "../redux/store";

import Content from "../constants/Content";
import EmployeeAccessControl from "../components/Common/EmployeeAccessControl";
import { UserState } from "../redux/reducers/UserReducer";
/**
 * Employee Item Screen
 * renders a screen where user can edit a singular employee item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div``;

interface RouteParams {
  id: string;
}

const sendEmployeeEmailInvitation = (
  dispatch: AppDispatch,
  employee: Employee
): void => {
  dispatch(sendEmployeeInvite(employee));
};

const EmployeeItem = () => {
  const { id }: RouteParams = useParams();
  const dispatch = useAppDispatch();
  const [accessControls, setAccessControls] = useState<string[]>([]);
  const { employee, employeeLoading }: EmployeeState = useAppSelector(
    (state) => state.EmployeeReducer
  );

  useEffect(() => {
    //get inventory on load
    dispatch(getEmployee(id));
  }, []);

  useEffect(() => {
    if (employee && employee.accessControls) {
      setAccessControls(employee.accessControls);
    }
  }, [employee]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Employees")
  ) {
    return <Redirect to="/" />;
  }
  if (employeeLoading || !employee) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <HorizontalPageCard
        title={`${employee.firstName} ${employee.lastName}`}
        subtitle={employee.email}
        primaryActionText={Content.EMPLOYEE_ACTION_BUTTON_TEXT}
        primaryAction={
          !employee.fbUserId
            ? () => sendEmployeeEmailInvitation(dispatch, employee)
            : undefined
        }
      />

      <EmployeeAccessControl
        accessControls={accessControls}
        onChange={(values) => setAccessControls(values)}
        onSave={() => dispatch(updateEmployee(id, { accessControls }))}
      />
    </Container>
  );
};

export default EmployeeItem;
