import React from "react";
import styled from "styled-components";
import { InputNumber, Select } from "antd";
import { InventoryFee } from "../../redux/reducers/InventoryReducer";
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { DropdownSelectorItem } from "../Common/HorizontalPageCard";
import { useAppSelector } from "../../redux/hooks";

const { Option } = Select;
/**
 * CheckoutInfo
 * Renders a checkout page where user can input price and quantity and renders the fees for the use
 * TODO Tests:
 */

const Container = styled.div``;
const Price = styled(InputNumber)`
  width: 100%;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  font-family: ${Fonts.PRIMARY};
  font-size: 600;
`;
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const PriceLabelContainer = styled.div`
  padding: 20px;
  height: 60px;
  background: ${Colors.BACKGROUND};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceLabel = styled.p`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 24px;
  font-weight: 600;
`;
const Quantity = styled(InputNumber)``;
const QuantityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const QuantityLabel = styled.p`
  margin-bottom: 0px;
  font-family: ${Fonts.PRIMARY};
  font-size: 18px;
`;
const FeeContainer = styled.div`
  margin-top: 40px;
`;
const FeeItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const FeeItemLabel = styled.p`
  font-family: ${Fonts.PRIMARY};
  margin-bottom: 0px;
`;
const FeeItemValue = styled.p`
  margin-bottom: 0px;
`;
const FeeItemQuantityWarning = styled.p`
  margin-left: 10px;
  font-size: 10px;
  margin-bottom: 0px;
  color: ${Colors.GREY};
`;
const FeeItemValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FeeTitle = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-weight: bold;
  font-size: 16px;
`;

const Seperator = styled.div`
  width: 100%;
  background: ${Colors.INPUT_BORDER};
  height: 1px;
  margin-bottom: 10px;
`;

const PayoutZeroMessage = styled.p`
  text-align: right;
  margin-bottom: 0px;
  font-weight: 800;
  color: ${Colors.BLUE_MAGENTA};
`;

interface Props {
  calculatedFees: InventoryFee | null;
  price: number;
  setPrice: (newPrice: number) => void;
  quantity: number;
  setQuantity: (newQuantity: number) => void;
  cost: number;
  setCost: (newCost: number) => void;
  selectedConsigner: string;
  setSelectedConsigner: (id: string) => void;
  consigners: DropdownSelectorItem[];
}

const CheckoutInfo = (props: Props) => {
  const {
    quantity,
    setQuantity,
    calculatedFees,
    price,
    setPrice,
    setCost,
    cost,
    consigners,
    selectedConsigner,
    setSelectedConsigner,
  } = props;

  const { store } = useAppSelector((state) => state.StoreReducer);

  return (
    <Container>
      <PriceContainer>
        <PriceLabelContainer>
          <PriceLabel>{store?.currency}</PriceLabel>
        </PriceLabelContainer>
        <Price
          value={price}
          onChange={(value) => setPrice(Number(value))}
          size="large"
        />
      </PriceContainer>
      {process.env.REACT_APP_TYPE === "employee" && (
        <QuantityContainer>
          <QuantityLabel>{Content.ADD_INVENTORY_CONSIGNER_LABEL}</QuantityLabel>
          <Select
            showSearch
            style={{ width: "200px" }}
            onChange={setSelectedConsigner}
            value={selectedConsigner}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(node) => node.parentNode}
          >
            {consigners?.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </QuantityContainer>
      )}
      {process.env.REACT_APP_TYPE === "employee" && (
        <QuantityContainer>
          <QuantityLabel>{Content.ADD_INVENTORY_COST_LABEL}</QuantityLabel>
          <Quantity
            value={cost}
            onChange={(value) => setCost(Number(value))}
            size="large"
          />
        </QuantityContainer>
      )}
      <QuantityContainer>
        <QuantityLabel>{Content.ADD_INVENTORY_QUANTITY_LABEL}</QuantityLabel>
        <Quantity
          value={quantity}
          onChange={(value) => setQuantity(Number(value))}
          size="large"
        />
      </QuantityContainer>
      <FeeContainer>
        <FeeTitle>{Content.ADD_INVENTORY_FEE_TITLE}</FeeTitle>
        {calculatedFees && calculatedFees.transcation > 0 && (
          <FeeItem>
            <FeeItemLabel>{Content.ADD_INVENTORY_FEE_ONE_LABEL}</FeeItemLabel>
            <FeeItemValueContainer>
              <FeeItemValue>
                {store?.currency} {calculatedFees?.transcation}
              </FeeItemValue>
              <FeeItemQuantityWarning>
                {Content.ADD_INVENTORY_QUANTITY_WARNING}
              </FeeItemQuantityWarning>
            </FeeItemValueContainer>
          </FeeItem>
        )}
        {calculatedFees && calculatedFees.selling > 0 && (
          <FeeItem>
            <FeeItemLabel>{Content.ADD_INVENTORY_FEE_TWO_LABEL}</FeeItemLabel>
            <FeeItemValueContainer>
              <FeeItemValue>
                {store?.currency} {calculatedFees?.selling}
              </FeeItemValue>
              <FeeItemQuantityWarning>
                {Content.ADD_INVENTORY_QUANTITY_WARNING}
              </FeeItemQuantityWarning>
            </FeeItemValueContainer>
          </FeeItem>
        )}

        {calculatedFees && calculatedFees.negativeBalance > 0 && (
          <>
            <FeeItem>
              <FeeItemLabel>
                {Content.ADD_INVENTORY_FEE_BALANCE_LABEL}
              </FeeItemLabel>
              <FeeItemValueContainer>
                <FeeItemValue>
                  {store?.currency} {calculatedFees?.negativeBalance}
                </FeeItemValue>
                <FeeItemQuantityWarning>
                  {Content.ADD_INVENTORY_QUANTITY_WARNING}
                </FeeItemQuantityWarning>
              </FeeItemValueContainer>
            </FeeItem>
            <Seperator />
          </>
        )}
        <FeeItem>
          <FeeItemLabel>
            {process.env.REACT_APP_TYPE === "employee"
              ? Content.ADD_INVENTORY_COSTS_SUBTOTAL_LABEL
              : `Your Payout (${store?.currency})`}
          </FeeItemLabel>
          <FeeItemValueContainer>
            <FeeItemValue>
              {store?.currency} {calculatedFees?.payout}
            </FeeItemValue>
            <FeeItemQuantityWarning>
              {Content.ADD_INVENTORY_QUANTITY_WARNING}
            </FeeItemQuantityWarning>
          </FeeItemValueContainer>
        </FeeItem>

        <FeeItem>
          <FeeItemLabel style={{ fontWeight: "bold" }}>
            {process.env.REACT_APP_TYPE === "employee"
              ? Content.ADD_INVENTORY_COSTS_TOTAL_LABEL
              : `Total Payout (${store?.currency})`}
          </FeeItemLabel>
          <FeeItemValueContainer>
            <FeeItemValue style={{ fontWeight: "bold" }}>
              {store?.currency} {calculatedFees?.total}
            </FeeItemValue>
            <FeeItemQuantityWarning>
              {Content.ADD_INVENTORY_ALL_QUANTITY_WARNING}
            </FeeItemQuantityWarning>
          </FeeItemValueContainer>
        </FeeItem>
        {Number(calculatedFees?.selling) > 0 &&
          Number(calculatedFees?.payout) < 1 && (
            <PayoutZeroMessage>
              {Content.PAYOUT_IS_ZERO_MESSAGE}
            </PayoutZeroMessage>
          )}
      </FeeContainer>
    </Container>
  );
};

export default CheckoutInfo;
