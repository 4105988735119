import React from "react";
import {
  TagsOutlined,
  ShoppingOutlined,
  ShopOutlined,
  DollarCircleOutlined,
  CopyOutlined,
  EnvironmentOutlined,
  PrinterOutlined,
  LogoutOutlined,
  DollarOutlined,
  ScanOutlined,
  HomeOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import Login, { LoginProps } from "../screens/Auth/Login";
import EmployeeSignup from "../screens/Auth/Signup";
import Dashboard, { DashboardProps } from "../screens/Dashboard";
import Products, { ProductsProps } from "../screens/Products";
import DashboardAdmin from "../screens/DashboardAdmin";
import ProductItem from "../screens/ProductItem";
import ScanGTIN from "../screens/ScanGTIN";
import CreateProduct from "../screens/CreateProduct";
import AddInventory from "../screens/AddInventory";
import Inventory, { InventoryPageTypes } from "../screens/Inventory";
import InventoryItem from "../screens/InventoryItem";
import Consigners from "../screens/Consigners";
// import ConsignerItem from "../screens/ConsignerItem";
import Content from "../constants/Content";
import Employees from "../screens/Employees";
import EmployeeItem from "../screens/EmployeeItem";
import AddEmployee from "../screens/AddEmployee";
import Orders from "../screens/Orders";
import Payouts from "../screens/Payouts";
import OrderItem from "../screens/OrderItem";
import ReadyToPay from "../screens/ReadyToPay";
import PayoutItem from "../screens/PayoutItem";
import AdminSettings from "../screens/Settings";
import ConsignerDashboard, {
  DashboardProps as ConsignerDashboardProps,
} from "../consignerScreens/Dashboard";
import Settings from "../consignerScreens/Settings";
import ConsignerInventory from "../consignerScreens/ConsignerInventory";
import ConsignerProductItem from "../consignerScreens/ProductItem";
// import Signup from "../consignerScreens/Signup";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import VerifyEmail from "../screens/Auth/VerifyEmail";
import DisabledAccount from "../screens/Auth/DisabledAccount";
import Maintenance from "../screens/Auth/Maintenance";
import RequestProduct from "../consignerScreens/RequestProduct";
import Transfers from "../screens/Transfers";
import AcceptTransfers from "../screens/AcceptTransfers";
import Fees from "../screens/Fees";
import ProductTemplates from "../screens/ProductTemplates";
import StoreSignup from "../screens/StoreSignup";
import SubLocations from "../screens/SubLocations";
import AddConsigner from "../screens/AddConsigner";
import Cookies from "js-cookie";
import AuthService from "../services/auth";
import ConsignerSignup from "../consignerScreens/ConsignerSignup";
import ConsignerLogin from "../consignerScreens/ConsignerLogin";
import SplashScreen from "../components/Common/Splash";
import ConsignerProducts, {
  ConsignerProductsProps,
} from "../consignerScreens/ConsignerProducts";
import { ReactComponent as InventorySvg } from "../assets/images/svg/Inventory.svg";
import { ReactComponent as ProductSvg } from "../assets/images/svg/Products.svg";
import { ReactComponent as DropoffSvg } from "../assets/images/svg/DropOff.svg";
import { ReactComponent as HelpSvg } from "../assets/images/svg/Help.svg";
import { ReactComponent as SettingSvg } from "../assets/images/svg/Settings.svg";
import { ReactComponent as WalletSvg } from "../assets/images/svg/Wallet.svg";
import { ReactComponent as CreditCardSvg } from "../assets/images/svg/CreditCard.svg";
import { ReactComponent as User } from "../assets/images/svg/User.svg";
import { ReactComponent as Users } from "../assets/images/svg/Users.svg";
import { ReactComponent as AnalyticSvg } from "../assets/images/svg/ChartPieSlice.svg";
import { ReactComponent as Bag } from "../assets/images/svg/Bag.svg";
import { ReactComponent as Tag } from "../assets/images/svg/Tag.svg";
import ConsignerDropOff from "../consignerScreens/ConsignerDropOff";
import ConsignerPayout from "../consignerScreens/ConsignerPayout";
import ConsignerView from "../screens/ConsignerView";
import Analytics from "../screens/Analytics";
import ConsignerPriceChangeRequests from "../consignerScreens/ConsignerPriceChangeRequests";
import RequestInventory from "../screens/RequestInventory";
import InventoryStoreRequestView from "../screens/InventoryStoreRequestView";
import Catalogue from "../screens/Catalogue";
import ConsignerStoreRequest from "../consignerScreens/ConsignerStoreRequest";
import InStore from "../instore/InStore";
import ProductGrid from "../buyAndSell/ProductGrid";
import { EmailActionHandler } from "../buyAndSell/EmailActionHandler";
import ScanPage from "../scan/Scan";
import GhostPartnershipModal from "../screens/GhostPartnershipModal";
// import { NewAdminDashboard } from "../screens/Auth/Signup/SignUpSteps";

const auth = AuthService();

interface routes {
  path: string;
  component: (
    props?: LoginProps | DashboardProps | ConsignerDashboardProps
  ) => JSX.Element;
  exact: boolean;
}

export interface navItem {
  path: string;
  label: string;
  icon?: JSX.Element;
  onClick?: () => void;
  subMenu?: navItem[];
}
const getLoadedUserRole = (): string | null => {
  const loadedUserCookie = Cookies.get("loadedUser");
  if (loadedUserCookie) {
    try {
      const parsedCookie = JSON.parse(loadedUserCookie);
      return parsedCookie?.name?.split(" ")[1];
    } catch (error) {
      console.error("Failed to parse loadedUser cookie", error);
      return null;
    }
  }
  return null;
};

const userRole = getLoadedUserRole();
console.log("=======userRole======", userRole);

//public routes
export const appRoutes: routes[] = [
  {
    path: "/storeSignup",
    component: (props) => <StoreSignup {...props} />,
    exact: true,
  },
];
export const navRoutes: routes[] = [
  {
    path: "/login",
    component: (props) => <Login {...props} />,
    exact: true,
  },
  {
    path: "/storeSignup",
    component: (props) => <StoreSignup {...props} />,
    exact: true,
  },
  {
    path: "/signup/:id",
    component: (props) => <EmployeeSignup {...props} />,
    exact: true,
  },
  {
    path: "/forgot",
    component: (props) => <ForgotPassword {...props} />,
    exact: true,
  },
  {
    path: "/verify",
    component: (props) => <VerifyEmail {...props} />,
    exact: true,
  },
  {
    path: "/disabledAccount",
    component: (props) => <DisabledAccount {...props} />,
    exact: true,
  },
  {
    path: "/maintenance",
    component: (props) => <Maintenance {...props} />,
    exact: true,
  },
  {
    path: "/catalogue/:handle",
    component: (props) => <Catalogue {...props} />,
    exact: true,
  },
  {
    path: "/instore",
    component: (props) => <InStore {...props} />,
    exact: true,
  },
  {
    path: "/buyandsell",
    component: (props) => <ProductGrid {...props} />,
    exact: true,
  },
  {
    path: "/buyandsell/:action/:code",
    component: (props) => <EmailActionHandler {...props} />,
    exact: true,
  },
];
export const navItems: navItem[] = [
  {
    label: "login",
    path: "/login",
  },
];

export const consignerNavRoutes: routes[] = [
  {
    path: "/login",
    component: (props) => <ConsignerLogin {...props} />,
    exact: true,
  },
  {
    path: "/signup",
    component: (props) => <ConsignerSignup {...props} />,
    exact: true,
  },
  {
    path: "/forgot",
    component: (props) => <ForgotPassword {...props} />,
    exact: true,
  },
  {
    path: "/verify",
    component: (props) => <VerifyEmail {...props} />,
    exact: true,
  },
  {
    path: "/disabledAccount",
    component: (props) => <DisabledAccount {...props} />,
    exact: true,
  },
  {
    path: "/signupSplash",
    component: (props) => <SplashScreen {...props} />,
    exact: true,
  },
];

export const consignerNavItems: navItem[] = [
  {
    label: "login",
    path: "/login",
  },
  {
    label: "signup",
    path: "/signup",
  },
];
export const consignerAuthenticatedRoutes: routes[] = [
  {
    path: "/",
    component: (props) => <ConsignerProducts {...props} />,
    exact: true,
  },
  // {
  //   path: "/products",
  //   component: (props) => <Products {...props} />,
  //   exact: true,
  // },
  {
    path: "/products/create",
    component: (props) => <RequestProduct {...props} />,
    exact: true,
  },
  {
    path: "/products/:id",
    component: (props) => (
      <AddInventory {...props} steps={Content.CONSIGNER_ADD_INVENTORY_STEPS} />
    ),
    exact: true,
  },
  {
    path: "/inventory",
    component: (props) => <ConsignerInventory {...props} />,
    exact: true,
  },
  {
    path: "/dropoff",
    component: (props) => <ConsignerDropOff {...props} status="Requested" />,
    exact: true,
  },
  {
    path: "/dropoffPending",
    component: (props) => <ConsignerDropOff {...props} status="Pending" />,
    exact: true,
  },
  // {
  //   path: "/pricechange",
  //   component: (props) => <ConsignerPriceChangeRequests {...props} />,
  //   exact: true,
  // },
  {
    path: "/storeRequest",
    component: (props) => <ConsignerStoreRequest {...props} />,
    exact: true,
  },
  {
    path: "/productItem/:id",
    component: (props) => <ConsignerProductItem {...props} />,
    exact: true,
  },
  {
    path: "/payouts",
    component: (props) => <ConsignerPayout {...props} />,
    exact: true,
  },
  {
    path: "/settings",
    component: (props) => <Settings {...props} />,
    exact: true,
  },
];
export const consignerAuthenticatedNavItems: navItem[] = [
  {
    label: "Products",
    path: "/",
    icon: <ProductSvg />,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <InventorySvg />,
  },
  {
    label: "Requested",
    path: "/dropoff",
    icon: <DropoffSvg />,
  },
  {
    label: "Drop Off Pending",
    path: "/dropoffPending",
    icon: <DropoffSvg />,
  },
  // {
  //   label: "Price Change Request",
  //   path: "/pricechange",
  //   icon: <WalletSvg />,
  // },
  {
    label: "Store Request",
    path: "/storeRequest",
    icon: <InventorySvg />,
  },
  {
    label: "Payout",
    path: "/payouts",
    icon: <WalletSvg />,
  },
  {
    label: "Settings",
    path: "/settings",
    icon: <SettingSvg />,
  },
  {
    label: "Help",
    path: "",
    onClick: () => {
      window.open(`mailto:${Cookies.get("supportEmail")}`);
    },
    icon: <HelpSvg />,
  },
];
//authenticated
export const authenticatedRoutes: routes[] = [
  ...(userRole === "Admin"
    ? [
        {
          path: "/dashboard",
          component: (props) => <DashboardAdmin {...props} />,
          exact: true,
        },
      ]
    : []),
  {
    path: "/",
    component: (props) => <Products {...props} />,
    exact: true,
  },
  {
    path: "/request",
    component: (props) => <RequestInventory {...props} />,
    exact: true,
  },
  {
    path: "/products/create",
    component: (props) => <CreateProduct {...props} />,
    exact: true,
  },
  {
    path: "/products/:id",
    component: (props) => <ProductItem {...props} />,
    exact: true,
  },
  {
    path: "/scan",
    component: (props) => <ScanGTIN {...props} />,
    exact: true,
  },
  {
    path: "/addInventory/:id",
    component: (props) => (
      <AddInventory {...props} steps={Content.ADD_INVENTORY_STEPS} />
    ),
    exact: true,
  },
  {
    path: "/findInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Find} {...props} />
    ),
    exact: true,
  },
  {
    path: "/inventories/:id",
    component: (props) => <InventoryItem {...props} />,
    exact: true,
  },
  {
    path: "/requestedInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Requested} {...props} />
    ),
    exact: true,
  },
  {
    path: "/incomingInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Incoming} {...props} />
    ),
    exact: true,
  },
  {
    path: "/readyToPrint",
    component: (props) => (
      <Inventory type={InventoryPageTypes.ReadyToPrint} {...props} />
    ),
    exact: true,
  },
  {
    path: "/withdrawnInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Withdraw} {...props} />
    ),
    exact: true,
  },
  {
    path: "/pendingPriceChangeInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.PriceChange} {...props} />
    ),
    exact: true,
  },
  {
    path: "/pendingWithdrawalInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.RequestWithdrawal} {...props} />
    ),
    exact: true,
  },
  {
    path: "/transfers",
    component: (props) => <Transfers {...props} />,
    exact: true,
  },
  {
    path: "/acceptTransfers",
    component: (props) => <AcceptTransfers {...props} />,
    exact: true,
  },
  {
    path: "/requestInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Consignment} {...props} />
    ),
    exact: true,
  },
  {
    path: "/buyingInventory",
    component: (props) => (
      <Inventory type={InventoryPageTypes.Buying} {...props} />
    ),
    exact: true,
  },
  // {
  //   path: "/readyToPrintInventory",
  //   component: (props) => (
  //     <Inventory type={InventoryPageTypes.ReadyToPrint} {...props} />
  //   ),
  //   exact: true,
  // },
  {
    path: "/consigners",
    component: (props) => <Consigners {...props} />,
    exact: true,
  },
  {
    path: "/consigners/:id",
    component: (props) => <ConsignerView {...props} />,
    exact: true,
  },
  {
    path: "/employees",
    component: (props) => <Employees {...props} />,
    exact: true,
  },
  {
    path: "/partnership",
    component: (props) => <GhostPartnershipModal {...props} />,
    exact: true,
  },
  {
    path: "/employees/:id",
    component: (props) => <EmployeeItem {...props} />,
    exact: true,
  },
  {
    path: "/addEmployee",
    component: (props) => <AddEmployee {...props} />,
    exact: true,
  },
  {
    path: "/addConsigner",
    component: (props) => <AddConsigner {...props} />,
    exact: true,
  },
  {
    path: "/findOrders",
    component: (props) => <Orders {...props} />,
    exact: true,
  },
  {
    path: "/orders/:id",
    component: (props) => <OrderItem {...props} />,
    exact: true,
  },
  {
    path: "/pendingPayouts",
    component: (props) => <ReadyToPay {...props} />,
    exact: true,
  },
  {
    path: "/payouts",
    component: (props) => <Payouts {...props} />,
    exact: true,
  },
  {
    path: "/payouts/:id",
    component: (props) => <PayoutItem {...props} />,
    exact: true,
  },
  {
    path: "/settings",
    component: (props) => <AdminSettings {...props} />,
    exact: true,
  },
  {
    path: "/fees",
    component: (props) => <Fees {...props} />,
    exact: true,
  },
  {
    path: "/productTemplates",
    component: (props) => <ProductTemplates {...props} />,
    exact: true,
  },
  {
    path: "/subLocations",
    component: (props) => <SubLocations {...props} />,
    exact: true,
  },
  {
    path: "/scanItems",
    component: (props) => <ScanPage {...props} />,
    exact: true,
  },
  // {
  //   path: "/analytics",
  //   component: (props) => <Analytics {...props} />,
  //   exact: true,
  // },
];

export const authenticatedNavItemsForBuying: navItem[] = [
  // {
  //   label: "Dashboard",
  //   path: "/",
  //   icon: <HomeOutlined />,
  // },
  {
    label: "Add Products",
    path: "/",
    icon: <ProductSvg />,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <InventorySvg />,
    subMenu: [
      {
        label: "Find",
        path: "/findInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Requested",
        path: "/requestedInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Drop Off Pending",
        path: "/incomingInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Ready To Print",
        path: "/readyToPrint",
        icon: <PrinterOutlined />,
      },
      {
        label: "Withdrawn",
        path: "/withdrawnInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Price Change",
        path: "/pendingPriceChangeInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Withdrawal Request",
        path: "/pendingWithdrawalInventory",
        icon: <TagsOutlined />,
      },
      // {
      //   label: "Ready To Print",
      //   path: "/readyToPrintInventory",
      //   icon: <TagsOutlined />,
      // },
    ],
  },
  {
    label: "Request",
    path: "/requests",
    icon: <TagsOutlined />,
    subMenu: [
      {
        label: "Request Items",
        path: "/request",
        icon: <TagsOutlined />,
      },
      {
        label: "Buying",
        path: "/buyingInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Consignment",
        path: "/requestInventory",
        icon: <TagsOutlined />,
      },
    ],
  },
  {
    label: "Transfers",
    path: "/transfer",
    icon: <Tag />,
    subMenu: [
      {
        label: "Transfer",
        path: "/transfers",
        icon: <TagsOutlined />,
      },
      {
        label: "Accept Transfer",
        path: "/acceptTransfers",
        icon: <TagsOutlined />,
      },
      // {
      //   label: "Ready To Print",
      //   path: "/readyToPrintInventory",
      //   icon: <TagsOutlined />,
      // },
    ],
  },
  {
    label: "Sellers",
    path: "/consigners",
    icon: <Users />,
  },
  {
    label: "Employees",
    path: "/employees",
    icon: <User />,
  },
  {
    label: "Orders",
    path: "/orders",
    icon: <Bag />,
    subMenu: [
      {
        label: "Find",
        path: "/findOrders",
        icon: <ShoppingOutlined />,
      },
      {
        label: "Ready to Pay",
        path: "/pendingPayouts",
        icon: <ShoppingOutlined />,
      },
      {
        label: "Payouts",
        path: "/payouts",
        icon: <ShoppingOutlined />,
      },
    ],
  },
  {
    label: "Settings",
    path: "/setting",
    icon: <SettingSvg />,
    subMenu: [
      {
        label: "Store",
        path: "/settings",
        icon: <ShopOutlined />,
      },
      // {
      //   label: "Fees",
      //   path: "/fees",
      //   icon: <DollarCircleOutlined />,
      // },
      {
        label: "Templates",
        path: "/productTemplates",
        icon: <CopyOutlined />,
      },
      {
        label: "Sublocations",
        path: "/subLocations",
        icon: <EnvironmentOutlined />,
      },
      {
        label: "Logout",
        path: "/login",
        onClick: () => {
          Cookies.remove("domain");
          Cookies.remove("supportEmail");
          auth.logout();
        },
        icon: <LogoutOutlined />,
      },
    ],
  },
];

export const authenticatedNavItems: navItem[] = [
  ...(userRole === "Admin"
    ? [
        {
          label: "Dashboard",
          path: "/dashboard",
          icon: <HomeOutlined />,
        },
      ]
    : []),
  {
    label: "Add Products",
    path: "/",
    icon: <ProductSvg />,
  },
  {
    label: "Inventory",
    path: "/inventory",
    icon: <InventorySvg />,
    subMenu: [
      {
        label: "Find",
        path: "/findInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Requested",
        path: "/requestedInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Drop Off Pending",
        path: "/incomingInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Ready To Print",
        path: "/readyToPrint",
        icon: <PrinterOutlined />,
      },
      {
        label: "Withdrawn",
        path: "/withdrawnInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Price Change",
        path: "/pendingPriceChangeInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Withdrawal Request",
        path: "/pendingWithdrawalInventory",
        icon: <TagsOutlined />,
      },
      // {
      //   label: "Ready To Print",
      //   path: "/readyToPrintInventory",
      //   icon: <TagsOutlined />,
      // },
    ],
  },
  {
    label: "Request",
    path: "/requests",
    icon: <TagsOutlined />,
    subMenu: [
      {
        label: "Request Items",
        path: "/request",
        icon: <TagsOutlined />,
      },
      {
        label: "Buying",
        path: "/buyingInventory",
        icon: <TagsOutlined />,
      },
      {
        label: "Consignment",
        path: "/requestInventory",
        icon: <TagsOutlined />,
      },
    ],
  },
  {
    label: "Transfers",
    path: "/transfer",
    icon: <Tag />,
    subMenu: [
      {
        label: "Transfer",
        path: "/transfers",
        icon: <TagsOutlined />,
      },
      {
        label: "Accept Transfer",
        path: "/acceptTransfers",
        icon: <TagsOutlined />,
      },
      // {
      //   label: "Ready To Print",
      //   path: "/readyToPrintInventory",
      //   icon: <TagsOutlined />,
      // },
    ],
  },
  {
    label: "Consigners",
    path: "/consigners",
    icon: <Users />,
  },
  {
    label: "Employees",
    path: "/employees",
    icon: <User />,
  },
  {
    label: "Supply - New",
    path: "/partnership",
    icon: <PartitionOutlined />,
  },
  {
    label: "Orders",
    path: "/orders",
    icon: <Bag />,
    subMenu: [
      {
        label: "Find",
        path: "/findOrders",
        icon: <ShoppingOutlined />,
      },
      {
        label: "Ready to Pay",
        path: "/pendingPayouts",
        icon: <ShoppingOutlined />,
      },
      {
        label: "Payouts",
        path: "/payouts",
        icon: <ShoppingOutlined />,
      },
    ],
  },
  {
    label: "Settings",
    path: "/setting",
    icon: <SettingSvg />,
    subMenu: [
      {
        label: "Store",
        path: "/settings",
        icon: <ShopOutlined />,
      },
      {
        label: "Fees",
        path: "/fees",
        icon: <DollarCircleOutlined />,
      },
      {
        label: "Templates",
        path: "/productTemplates",
        icon: <CopyOutlined />,
      },
      {
        label: "Sublocations",
        path: "/subLocations",
        icon: <EnvironmentOutlined />,
      },
      {
        label: "Logout",
        path: "/login",
        onClick: () => {
          Cookies.remove("domain");
          Cookies.remove("supportEmail");
          auth.logout();
        },
        icon: <LogoutOutlined />,
      },
    ],
  },
];
