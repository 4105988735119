import React, { useEffect, useState } from "react";
import { Card, Input, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { CardProps } from "antd/lib/card";
import Content from "../../constants/Content";
import styled from "styled-components";
import Fonts from "../../constants/Fonts";
import Colors from "../../constants/Colors";

/*
  Login Card
  Used to login the user into the app
  Support Email / Password login and forgot password links
*/
//styles
export const Container = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
`;
const Title = styled.h2`
  font-family: ${Fonts.PRIMARY};
  text-algin: center;
  margin-bottom: 40px;
`;
const AuthInput = styled(Input)`
  margin-bottom: 10px;
  padding: 10px;
  background: ${Colors.INPUT_BACKGROUND};
  border: ${Colors.INPUT_BORDER} solid 1px;
`;
const PassInput = styled(Input.Password)`
  margin-bottom: 10px;
  padding: 10px;

  background: ${Colors.INPUT_BACKGROUND};
  border: ${Colors.INPUT_BORDER} solid 1px;
  input {
    background-color: ${Colors.INPUT_BACKGROUND};
  }
`;

const ForgotPasswordLink = styled.a`
  margin-top 10px;
  margin-bottom: 20px;
`;

//types
interface Props {
  onLogin(email: string, password: string): void;
  email: string;
  password: string;
  onEmailChange(newEmail: string): void;
  onPasswordChange(newPassword: string): void;
  loginLoading: boolean;
  title?: string;
  buttonText?: string;
}

const LoginCard = (props: Props): JSX.Element => {
  const {
    email,
    password,
    onEmailChange,
    onPasswordChange,
    onLogin,
    loginLoading,
    buttonText,
    title,
  } = props;
  useEffect(() => {
    if (window.sessionStorage.getItem("setClick") === "clicked") {
      window.sessionStorage.removeItem("setClick");
    }
  }, []);
  return (
    <Container hoverable>
      <Title data-testid="title">
        {title ? title : Content.LOGIN_CARD_TITLE}
      </Title>
      <AuthInput
        data-testid="email"
        value={email}
        type="email"
        onChange={(e) => onEmailChange(e.target.value)}
        placeholder="Enter Email"
      />
      <PassInput
        className="red"
        data-testid="password"
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
        placeholder="Enter Password"
      />
      <ForgotPasswordLink data-testid="forgot" href="/forgot">
        {Content.LOGIN_CARD_FORGOT_PASSWORD}
      </ForgotPasswordLink>
      <Button
        data-testid="loginBtn"
        onClick={() => onLogin(email, password)}
        type="primary"
        loading={loginLoading}
      >
        {buttonText ? buttonText : "Log in"}
      </Button>
    </Container>
  );
};

export default LoginCard;
