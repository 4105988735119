import {
  GET_CONSIGNERTYPES,
  GET_CONSIGNERTYPE,
  UPDATE_CONSIGNERTYPE,
  CREATE_CONSIGNERTYPE,
} from "../actions/types";

export type ConsignerType = any;

export interface ConsignerTypeState {
  consignerTypes: ConsignerType[];
  consignerTypesLoading: boolean;
  consignerType: ConsignerType | null;
  consignerTypeLoading: boolean;
  updatedConsignerType: ConsignerType | null;
  updatedConsignerTypeLoading: boolean;
  createdConsignerType: ConsignerType | null;
  createConsignerTypeLoading: boolean;
}

const defaultState = {
  consignerTypes: [],
  consignerTypesLoading: false,
  consignerType: null,
  consignerTypeLoading: false,
  updatedConsignerType: null,
  updatedConsignerTypeLoading: false,
  createdConsignerType: null,
  createConsignerTypeLoading: false,
};

export const DefaultConsignerTypeState = defaultState;

export type ConsignerTypeAction = {
  type: string;
  consignerTypes: ConsignerType[];
  consignerTypesLoading: boolean;
  consignerType: ConsignerType | null;
  consignerTypeLoading: boolean;
  updatedConsignerType: ConsignerType | null;
  updatedConsignerTypeLoading: boolean;
  createdConsignerType: ConsignerType | null;
  createConsignerTypeLoading: boolean;
};

export default (
  state: ConsignerTypeState = defaultState,
  action: ConsignerTypeAction
) => {
  switch (action.type) {
    case GET_CONSIGNERTYPES:
      return {
        ...state,
        consignerTypes: action.consignerTypes,
        consignerTypesLoading: action.consignerTypesLoading,
      };
    case GET_CONSIGNERTYPE:
      return {
        ...state,
        consignerType: action.consignerType,
        consignerTypeLoading: action.consignerTypeLoading,
      };
    case UPDATE_CONSIGNERTYPE:
      return {
        ...state,
        updatedConsignerType: action.updatedConsignerType,
        updateConsignerTypeLoading: action.updatedConsignerTypeLoading,
      };
    case CREATE_CONSIGNERTYPE:
      return {
        ...state,
        createdConsignerType: action.createdConsignerType,
        createConsignerTypeLoading: action.createConsignerTypeLoading,
      };
    default:
      return state;
  }
};
