import React from "react";
import { Button, Table } from "antd";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import Content from "../../constants/Content";
import { Product } from "../../redux/reducers/ProductReducer";
import InventoryFilter from "./InventoryFilter";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";

/**
 * Inventory Table
 * Takes a list of inventory items and renders a filterable table
 * - List of inventory
 * - Filter
 * - Action to go to inventory page
 * TODO Test
 * - Filter changes
 */
const Container = styled.div``;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const HeaderTitle = styled.p``;
const ProductImage = styled.img`
  width: 50px;
`;
//types
interface Props {
  products: Product[];
  loading: boolean;
  onProductItemAction: (product: Product) => void;
  onProductMerge: (product: Product) => void;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  render: (key: string, data: Product) => JSX.Element;
}
interface HeaderProps {}

const getColumns = (
  onProductItemAction: (product: Product) => void,
  onProductMerge: (product: Product) => void
): Columns[] => [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (image: string) => <ProductImage src={OptimizedImageUrl(image)} />,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text: string, data: Product) => (
      <a
        onClick={() => onProductItemAction(data)}
        data-testid="DuplicateProductsTableItemCode"
      >
        {text}
      </a>
    ),
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    render: (text: string, record: Product) => <div>{record.sku}</div>,
  },
  {
    title: "Action",
    dataIndex: "id",
    key: "id",
    render: (text: string, data: Product) => (
      <Button
        data-testid="DuplicateProductsTableItemActionBtn"
        onClick={() => onProductMerge(data)}
        type="primary"
        danger
      >
        MERGE
      </Button>
    ),
  },
];

const Header = (props: HeaderProps): JSX.Element => {
  return (
    <HeaderContainer data-testid="DuplicateProductsTableHeader">
      <HeaderTitle data-testid="DuplicateProductsTableHeaderTitle">
        {Content.DUPLICATE_PRODUCTS_TITLE}
      </HeaderTitle>
    </HeaderContainer>
  );
};

const DuplicateProductsTable = (props: Props) => {
  const { products, loading, onProductItemAction, onProductMerge } = props;
  return (
    <Container
      data-testid="DuplicateProductsTableContainer"
      style={{ marginTop: 10 }}
    >
      <Table
        data-testid="DuplicateProductsTable"
        title={() => <Header />}
        columns={getColumns(onProductItemAction, onProductMerge)}
        dataSource={products}
        rowKey="id"
        loading={loading}
      />
    </Container>
  );
};

export default DuplicateProductsTable;
