import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import ConsignerTypeTable from "../components/Common/ConsignerTypeTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { UserState } from "../redux/reducers/UserReducer";
import { Result, Button } from "antd";
import { getProductTemplates } from "../redux/actions/productTemplateActions";

import { getConsignerTypes } from "../redux/actions/consignerTypeActions";
import { ConsignerType } from "../redux/reducers/ConsignerTypeReducer";
import RedesignStyling from "../constants/RedesignStyling";

/**
 * Settings Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const Fees = () => {
  const dispatch = useAppDispatch();
  const { consignerTypes, consignerTypesLoading, createConsignerTypeLoading } =
    useAppSelector((state) => state.ConsignerTypeReducer);
  const {
    productTemplates,
    productTemplatesLoading,
    createProductTemplateLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  const { store } = useAppSelector((state) => state.StoreReducer);

  useEffect(() => {
    //get consignerTypes on load
    dispatch(getConsignerTypes());
  }, []);
  useEffect(() => {
    //get consignerTypes on load
    dispatch(getProductTemplates());
  }, []);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Settings")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <ConsignerTypeTable
        store={store}
        tableData={consignerTypes}
        loading={consignerTypesLoading}
        savedLoading={createConsignerTypeLoading}
        productTemplates={productTemplates}
      />
      {/* <Result
        status="warning"
        title="Page under construction, come back later..."
      /> */}
    </Container>
  );
};

export default Fees;
