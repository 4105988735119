import React from "react";
import { Modal, Button } from "antd";
import InventoryList from "../InventoryList";
import { Inventory } from "../../../redux/reducers/InventoryReducer";

type InventoryListModalProps = {
  inventories: Inventory[];
  isOpen: boolean;
  closeModal: () => void;
  onSelect: (inventory: Inventory) => void;
};

const InventoryListModal = ({
  inventories,
  isOpen,
  closeModal,
  onSelect,
}: InventoryListModalProps) => {
  const handleSelect = (inventory: Inventory) => {
    onSelect(inventory);
    closeModal();
  };

  return (
    <Modal
      title="Inventory Items"
      visible={isOpen}
      onCancel={closeModal}
      footer={null}
    >
      <InventoryList
        inventories={inventories}
        loading={false}
        acceptButtonText="Select"
        onAccept={(inventory: Inventory) => handleSelect(inventory)}
      />
    </Modal>
  );
};

export default InventoryListModal;
