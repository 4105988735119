import React, { useEffect, useState } from "react";
import { Button, FormInstance, Spin, message, Modal } from "antd";
import {
  useHistory,
  useParams,
  RouteComponentProps,
  Redirect,
} from "react-router";
import styled from "styled-components";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";
import {
  clearUpdatedProduct,
  getProduct,
  mergeProduct,
  resyncProduct,
  updateProduct,
} from "../redux/actions/productActions";
import {
  getInventories,
  getInventoryFilterOptions,
  resetCreatedInventory,
  resyncSingleInventory,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Content from "../constants/Content";
import { Product, ProductState } from "../redux/reducers/ProductReducer";
import { useForm } from "antd/lib/form/Form";
import ProductForm from "../components/Common/ProductForm";
import { AppDispatch } from "../redux/store";
import InventoryTable from "../components/Common/InventoryTable";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  getProductTemplate,
  getProductTemplates,
} from "../redux/actions/productTemplateActions";
import DuplicateProductsTable from "../components/Common/DuplicateProductsTable";
import { StoreState } from "../redux/reducers/StoreReducer";
import InventoryAddModal from "../components/Common/InventoryAddModal";
import { getDropDownValuesForConsigner } from "./InventoryItem";
import GtinModal from "../components/Common/GTIN/GTINProductModal";

/**
 * Product Item Screen
 * Used to handle product level update by the use
 * Features:
 *  - Navigate to add inventory for this specific product
 *  - Update values of the product
 *  - View and edit inventory of the product
 * TODO: Testing
 *  - On filter update re calls get Inventory API with correct filter items
 *  - on load inventory filter
 */

//styles
const Container = styled.div``;
interface RouteParams {
  id: any;
}

const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
`;

const goToAddInventory = (
  history: RouteComponentProps["history"],
  id: string
) => {
  history.push(`/addInventory/${id}`);
};

const showModal = (setIsModalVisible: any) => {
  setIsModalVisible(true);
};

const onFormSave = (id: string, form: FormInstance, dispatch: AppDispatch) => {
  const formValues = form.getFieldsValue();
  dispatch(updateProduct(id, formValues));
};

const onInventoryItemAction = (
  inventory: Inventory,
  history: RouteComponentProps["history"]
) => {
  //go to inventory page
  history.push(`/inventories/${inventory.id}`);
};

const onProductItemAction = (
  product: Product,
  history: RouteComponentProps["history"]
) => {
  //go to product page
  history.push(`/products/${product.id}`);
};

const onProductMerge = (
  fromProduct: Product,
  toProduct: Product,
  dispatch: AppDispatch
) => {
  //merge into current product page

  Modal.confirm({
    title: "Do you want to merge this item into the current product?",
    content:
      "This will delete the product selected from the portal and Shopify, and move all inventory into the current product",
    onOk: async () => {
      dispatch(await mergeProduct(fromProduct.id, toProduct.id));
    },
    okType: "danger",
    onCancel() {
      console.log("Cancel");
    },
  });
};

const onProductResync = (id: any, dispatch: AppDispatch) => {
  //merge into current product page

  Modal.confirm({
    title: "Do you want to resync this product?",
    content:
      "Resyncing this product may delete your Shopify product and create a new one. It is important to backup your images, and other Shopify product data prior to syncing.",
    onOk: async () => {
      dispatch(resyncProduct(id));
    },
    okType: "danger",
    onCancel() {
      console.log("Cancel");
    },
  });
};

const ProductItem = () => {
  const { id }: RouteParams = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const consignerId = queryParams.get("consigner");
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newInventory, setNewInventory] = useState(false);
  const [reload, setReload] = useState(false);
  const [GTINModalVisible, setGTINModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    printed: "",
    status: "",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    consigner: consignerId ? consignerId : "",
    productId: id,
  });
  const {
    product,
    productLoading,
    updateProductLoading,
    resyncProductLoading,
    updatedProduct,
    mergeProduct,
  }: ProductState = useAppSelector((state) => state.ProductReducer);

  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptionsLoading,
    inventoryFilterOptions,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  // useEffect(() => {
  //   console.log("new inventory", inventories);
  // }, [inventories]);

  const { productTemplate, productTemplates } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  message.config({ maxCount: 1 });

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
    console.debug("[inventoryFilterOptions]", inventoryFilterOptions);
  }, []);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());

    if (product?.productTemplateId) {
      dispatch(getProductTemplate(product.productTemplateId));
    }
  }, [product]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(resetCreatedInventory());
    dispatch(getProduct(id));
  }, [id, mergeProduct]);

  useEffect(() => {
    //Handle when switch to product page within product page
    if (id !== filters.productId) {
      setFilters({
        ...filters,
        productId: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (reload === true) {
      setFilters({
        ...filters,
        productId: id,
      });
      setReload(false);
    }
  }, [reload]);

  const resyncInventory = async (id: number) => {
    message.info("Resyncing item...");
    const data = await resyncSingleInventory(id);
    if (data.inventory) {
      await dispatch(getInventories("", filters));
    }
  };

  useEffect(() => {
    //get inventory on filter updates

    if (!isNaN(id)) {
      dispatch(getInventories("", filters));
    } else {
      let filtersStockx = {
        printed: "",
        status: "",
        option1Value: "",
        option2Value: "",
        option3Value: "",
        category: "",
        consigner: consignerId || "",
        productId: "0",
      };
      dispatch(getInventories("", filtersStockx));
    }
  }, [filters, product]);

  useEffect(() => {
    //update form values anytime product is updated
    if (product) {
      form.setFieldsValue({
        id: product.id,
        title: product.title,
        brand: product.brand,
        sku: product.sku,
        stockXId: product.stockXId,
        shopifyId: product.shopifyId,
        category: product.category,
        images: product.image,
        productTemplateId: product.productTemplateId,
        gender: product.gender,
      });
    }
  }, [product]);

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }

  if (
    productLoading ||
    !product ||
    !inventoryFilterOptions ||
    inventoryFilterOptionsLoading
  )
    return (
      <Container data-testid="ProductScreenLoadingContainer">
        <Spinner data-testid="ProductScreenLoadingSpinner" />
      </Container>
    );

  return (
    <Container data-testid="ProductItemScreen">
      {product.duplicateProducts && product.duplicateProducts.length > 0 && (
        <DuplicateProductsTable
          onProductItemAction={(product: Product) =>
            onProductItemAction(product, history)
          }
          onProductMerge={(fromProduct: Product) =>
            onProductMerge(fromProduct, product, dispatch)
          }
          products={product.duplicateProducts}
          loading={productLoading}
        />
      )}

      <GtinModal
        sizes={productTemplate?.option1Values || []}
        savedGtinList={product.GTIN}
        onSave={(gtin: { size: string; gtin: string }[]) =>
          dispatch(
            updateProduct(product.id, {
              title: product.title,
              sku: product.sku,
              stockXId: String(product.stockXId),
              shopifyId: String(product.shopifyId),
              brand: product.brand,
              category: product.category,
              images: product.image,
              image: product.image,
              productTemplateId: product.productTemplateId,
              gender: String(product.gender),
              GTIN: gtin,
            })
          )
        }
        onCancel={() => setGTINModalVisible(false)}
        visible={GTINModalVisible}
        loading={updateProductLoading}
      />

      <InventoryAddModal
        id={product.id}
        product={product}
        updatedProduct={updatedProduct}
        productTemplates={productTemplates}
        dispatch={dispatch}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        isNewEntry={false}
        setNewInventory={setNewInventory}
        inventories={inventories}
        form={form}
        setReload={setReload}
        setInventoriesToAdd={null}
      />

      <HorizontalPageCard
        image={product.image}
        title={product.title}
        subtitle={product.sku}
        primaryActionText={Content.PRODUCT_ITEM_SCREEN_PRIMARY_ACTION_TEXT}
        secondaryActionText={Content.PRODUCT_ITEM_SCREEN_SECONDARY_ACTION_TEXT}
        secondaryAction={showModal} //() => setIsModalVisible(true)
        thirdAction={() => setGTINModalVisible(true)}
        thirdActionText={Content.PRODUCT_ITEM_SCREEN_THIRD_ACTION_TEXT}
        primaryAction={() => {
          onFormSave(id, form, dispatch);
          message
            .loading("Saving", 1)
            .then(() =>
              !updateProductLoading
                ? message.success("Saved")
                : message.error("Unknown Error")
            );
        }}
        primaryActionLoading={updateProductLoading}
      />

      <ProductForm
        form={form}
        store={store!}
        productTemplateId={product.productTemplateId}
        productTemplates={productTemplates}
        inventories={inventories}
        loading={inventoriesLoading}
      />

      <InventoryTable
        inventories={inventories}
        loading={inventoriesLoading}
        onFilter={setFilters}
        inventoryFilterOptions={inventoryFilterOptions}
        onInventoryItemAction={(inventory: Inventory) =>
          onInventoryItemAction(inventory, history)
        }
        resyncProduct={() => onProductResync(id, dispatch)}
        resyncProductLoading={resyncProductLoading}
        store={store!}
        resyncInventory={(inventoryId) => resyncInventory(inventoryId)}
      />
    </Container>
  );
};

export default ProductItem;
