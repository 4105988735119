import React, { useState } from "react";
import { useHistory } from "react-router";
import "./SplashScreen.css";
import logo from "../../assets/images/logo.png";
import icon1 from "../../assets/images/Splash1.png";
import icon2 from "../../assets/images/Splash2.png";
import icon3 from "../../assets/images/Splash3.png";
import arrow from "../../assets/images/ArrowRight.svg";
import styled from "styled-components";
import Colors from "../../constants/Colors";

const Splash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${Colors.CONSIGNER_BG};
`;

const Icon = styled.img`
  height: auto;
  width: 110px;
`;

const NextBtn = styled.button`
  padding: 8px 24px;
  background-color: #746bf8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  img {
    width: 25px;
  }
`;
const TopBarGeneric = styled.div`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.WHITE};
  position: fixed;
  top: 0;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const SplashScreen = () => {
  const history = useHistory();
  const [activePage, setActivePage] = useState(0);
  const pages = [
    {
      icon: icon1,
      title: "Add all the items you want to consign",
      buttonText: "Button 1",
    },
    {
      icon: icon2,
      title: "After approval you need to drop off or ship items into the store",
      buttonText: "Button 2",
    },
    {
      icon: icon3,
      title: "Keep track of sales and get paid instantly within the platform",
      buttonText: "Button 3",
    },
  ];

  const handlePageChange = (pageIndex) => {
    if (pageIndex === 3) {
      history.push("/");
    }
    setActivePage(pageIndex);
  };

  return (
    <Splash>
      <TopBarGeneric>
        <Logo src={logo} alt="" />
      </TopBarGeneric>
      <div className="pages">
        {pages.map((page, index) => (
          <div
            key={index}
            className={`page ${activePage === index ? "active" : ""}`}
          >
            <Icon src={page.icon} alt=""></Icon>
            <div className="navigation">
              <div className="ellipses">
                {pages.map((_, index) => (
                  <div
                    key={index}
                    className={`ellipse ${
                      activePage === index ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(index)}
                  ></div>
                ))}
              </div>
            </div>
            <h2>{page.title}</h2>
            <NextBtn onClick={() => handlePageChange(index + 1)}>
              Next <img src={arrow} alt="" />
            </NextBtn>
          </div>
        ))}
      </div>
    </Splash>
  );
};

export default SplashScreen;
