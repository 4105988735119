// helper function that returns the optimized cloudinary url in jpeg instead of png
import React from "react";
// import { Advancedurl } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
// import { Transformation } from "@cloudinary/url-gen";

// Import required actions.
import { thumbnail, scale } from "@cloudinary/url-gen/actions/resize";
// import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
// import { sepia } from "@cloudinary/url-gen/actions/effect";
// import { source } from "@cloudinary/url-gen/actions/overlay";
// import { opacity, brightness } from "@cloudinary/url-gen/actions/adjust";
// import { byAngle } from "@cloudinary/url-gen/actions/rotate"

// Import required qualifiers.
// import { url } from "@cloudinary/url-gen/qualifiers/source";
// import { Position } from "@cloudinary/url-gen/qualifiers/position";
// import { compass } from "@cloudinary/url-gen/qualifiers/gravity";
// import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
// import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn";

export const OptimizedImageUrl = (url: string) => {
  if (!url || url === "") return url;
  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dm5xjgl02",
      apiKey: "164319542214291",
      apiSecret: "kjKaoj95e2TFrogCKR7KOcLkt4I",
    },
  });

  const isShopifyCDN = url.includes("cdn.shopify.com");
  const isStockx = url.includes("images.stockx.com");
  const isCloudinary = url.includes("res.cloudinary.com");

  /* 
    url passed in is Eg: https://res.cloudinary.com/dm5xjgl02/image/upload/v1669828411/4ad056bb-674d-4b1a-9e48-4819002fa9bd.jpg? without optimization
  */

  // if the url starts with https://res.cloudinary.com then remove everything up to /upload/
  if (url.includes("https://res.cloudinary.com")) {
    url = url.replace(/.*\/upload\//, "");
  }

  let cloudinaryImage = cld.image(url);
  cloudinaryImage.quality("auto").format("auto");

  if (isShopifyCDN) {
    return url;
  } else if (isStockx) {
    return url;
  } else if (isCloudinary) {
    return cloudinaryImage.toURL();
  } else {
    return url;
  }
};

export default OptimizedImageUrl;
