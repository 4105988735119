import { Consigner, ConsignerReport } from "../redux/reducers/ConsignerReducer";
import { Employee, ShopReport } from "../redux/reducers/EmployeeReducer";
import { Inventory, InventoryFee } from "../redux/reducers/InventoryReducer";
import { Order } from "../redux/reducers/OrderReducer";
import { Payout } from "../redux/reducers/PayoutReducer";
import { Product } from "../redux/reducers/ProductReducer";
import { ProductTemplate } from "../redux/reducers/ProductTemplateReducer";

export const products: Product[] = [
  {
    id: "1",
    title: "ADIDAS ULTA BOOST 6.0 DNA X PARLEY",
    image:
      "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
    price: "4299.99",
    brand: "Adidas",
    sku: "123",
    category: "Sneakers",
    lastSale: "4299.99",
    currentLowest: "4199.99",
    productTemplateId: "1",
    GTIN: [{ size: "8", gtin: "123" }],
  },
  {
    id: "2",
    title: "NIKE DUNK HIGH PANDA (2021) (W)",
    image:
      "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
    price: "4299.99",
    brand: "Nike",
    sku: "123",
    category: "Sneakers",
    lastSale: "4299.99",
    currentLowest: "4199.99",
    productTemplateId: "1",
    GTIN: [{ size: "8", gtin: "123" }],
  },
  {
    id: "3",
    title: "ADIDAS YEEZY 700 V3 KYANITE",
    image:
      "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
    price: "6799.99",
    brand: "Adidas",
    sku: "123",
    category: "Sneakers",
    lastSale: "4299.99",
    currentLowest: "4199.99",
    productTemplateId: "1",
    GTIN: [{ size: "8", gtin: "123" }],
  },
  {
    id: "4",
    title: "JORDAN 4 RETRO FIRE RED (2020)",
    image:
      "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
    price: "7299.99",
    brand: "Jordan",
    sku: "123",
    category: "Sneakers",
    lastSale: "4299.99",
    currentLowest: "4199.99",
    productTemplateId: "1",
    GTIN: [{ size: "8", gtin: "123" }],
  },
  {
    id: "5",
    title: "JORDAN 11 RETRO LOW LEGEND BLUE",
    image:
      "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
    price: "4499.99",
    brand: "Jordan",
    sku: "123",
    category: "Sneakers",
    lastSale: "4299.99",
    currentLowest: "4199.99",
    productTemplateId: "1",
    GTIN: [{ size: "8", gtin: "123" }],
  },
];

export const productTemplates: ProductTemplate[] = [
  {
    id: "7",
    title: "Nike Sneakers",
    brand: "Nike",
    category: "Sneakers",
    option1: "Size",
    option2: "Condition",
    option3: "",
    gender: "",
    weight: 0,
    option1Values: [],
    option2Values: [],
    option3Values: [],
  },
];

export const updatedProduct = {
  id: "1",
  title: "ADIDAS ULTA BOOST 6.0 DNA X PARLEY",
  image:
    "https://images.stockx.com/360/Air-Jordan-11-Retro-Low-Legend-Blue/Images/Air-Jordan-11-Retro-Low-Legend-Blue/Lv2/img01.jpg?auto=compress&q=90&dpr=2&updated_at=1618932440&fit=clip&fm=webp&ixlib=react-9.1.1&w=1075",
  price: "4299.99",
  brand: "Adidas",
  sku: "123",
  category: "Sneakers",
  lastSale: "4299.99",
  currentLowest: "4199.99",
  productTemplateId: "1",
};

export const employee: Employee = {
  firstName: "Menan",
  lastName: "Sub",
  id: "1",
  email: "bsmenan@gmail.com",
  phone: "6477405813",
  accessControls: [""],
};

export const consigner: Consigner = {
  firstName: "Menan",
  lastName: "Sub",
  id: "1",
  email: "bsmenan@gmail.com",
  phone: "6477405813",
  address: "49 Kenneth Wood Cresent",
  idNumber: "233543526",
  accountName: "Menan Sub",
  accountNumber: "13423",
  bank: "Current (cheque/bond) Account",
  bankType: "Bank Zero",
  bankData: {
    accountName: "consigner 1",
    accountNumber: "123456789",
    swiftCode: "ABCD1234",
    ibanNumber: "123456789",
    routingNumber: "123456789",
    bank: "2321312",
    bankType: "Bank",
    email: "testbank@test.com",
    routingType: "abaNumber",
  },
  branchCode: "1434",
  accountType: "1",
  payoutType: "Paypal",
  payoutData: '{email: "bsmenan@gmail.com"}',
  storeId: "1",
};

export const consigner2: Consigner = {
  firstName: "Bharath",
  lastName: "Sub",
  id: "2",
  email: "bsmenan@gmail.com",
  phone: "6477405813",
  address: "49 Kenneth Wood Cresent",
  idNumber: "233543526",
  accountName: "Bharath Sub",
  accountNumber: "13423",
  bank: "Current (cheque/bond) Account",
  bankType: "Bank Zero",
  branchCode: "1434",
  bankData: {
    accountName: "consigner 2",
    accountNumber: "12345675435",
    swiftCode: "BBB1234",
    ibanNumber: "123456783439",
    routingNumber: "1234567894334",
    bank: "123123",
    bankType: "Bank",
    email: "testconsign2@email.com",
    routingType: "swiftCode",
  },
  accountType: "1",
  payoutType: "Bank",
  payoutData:
    '{accountName: "Bharath Sub",  accountNumber: "13423",   bank: "Current (cheque/bond) Account",  bankType: "Bank Zero",  branchCode: "1434",}',
  storeId: "1",
};

export const consigner3: Consigner = {
  firstName: "Kyle",
  lastName: "LeBlanc",
  id: "2",
  email: "kyle@gmail.com",
  phone: "675645443",
  address: "49 Kenneth Wood Cresent",
  idNumber: "9999999",
  accountName: "Kyle Leblanc",
  accountNumber: "9999",
  bank: "",
  bankType: "PayPal",
  branchCode: "1434",
  bankData: {
    accountName: "consigner 2",
    accountNumber: "12345675435",
    swiftCode: "BBB1234",
    ibanNumber: "123456783439",
    routingNumber: "1234567894334",
    bank: "Bank",
    bankType: "PayPal",
    email: "testconsign2@email.com",
    routingType: "ibanNumber",
  },
  accountType: "1",
  payoutType: "Bank",
  payoutData:
    '{accountName: "Bharath Sub",  accountNumber: "13423",   bank: "Current (cheque/bond) Account",  bankType: "Bank Zero",  branchCode: "1434",}',
  storeId: "1",
};

const orderInventories: Inventory[] = [
  {
    id: "1",
    code: "1234567",
    option1Value: "8",
    option2Value: "New",
    option3Value: "",
    productTemplateId: "1",
    location: "Capetown",
    payout: "100.99",
    consigner: consigner,
    price: "129.99",
    packingSlip: "http://www.africau.edu/images/default/sample.pdf",
    product: products[0],
    requestedPrice: "109.99",
    subLocation: "Shelf abv",
    status: "Active",
    createdAt: new Date("May 25, 2021 23:15:30"),
    productTemplate: {
      brand: "Nike",
      category: "sneakers",
      id: "177",
      option1: "Size",
      option1Values: [],
      option2: "Condition",
      option2Values: [],
      option3: "",
      option3Values: [],
      title: "Nike Sneakers",
      weight: 0,
      gender: "men",
    },
    notes: "",
    requestInventoryWithdrawalAcceptedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalDeclinedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalAcceptedBy: employee,
    requestInventoryWithdrawalDeclinedBy: employee,
  },
];
export const inventories: Inventory[] = [
  {
    id: "1",
    code: "1234567",
    option1Value: "8",
    option2Value: "New",
    option3Value: "",
    productTemplateId: "1",
    location: "Capetown",
    payout: "100.99",
    consigner: consigner,
    price: "129.99",
    packingSlip: "http://www.africau.edu/images/default/sample.pdf",
    product: products[0],
    requestedPrice: "",
    subLocation: "Shelf abv",
    status: "Active",
    createdAt: new Date("May 25, 2021 23:15:30"),
    order: {
      orderDate: "Tuesday May 11, 2021",
      total: 6400,
      lineItems: orderInventories,
      customer: {
        first_name: "John Smith",
        last_name: "John Smith",
        email: "john@gmail.com",
        phone: "",
      },
      id: "1",
      name: "1001",
    },
    notes: "",
    productTemplate: {
      brand: "Nike",
      category: "sneakers",
      id: "177",
      option1: "Size",
      option1Values: [],
      option2: "Condition",
      option2Values: [],
      option3: "",
      option3Values: [],
      title: "Nike Sneakers",
      weight: 0,
      gender: "men",
    },
    requestInventoryWithdrawalAcceptedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalDeclinedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalAcceptedBy: employee,
    requestInventoryWithdrawalDeclinedBy: employee,
  },
  {
    id: "2",
    code: "1234567",
    option1Value: "8",
    option2Value: "New",
    option3Value: "",
    productTemplateId: "1",
    location: "Capetown",
    payout: "100.99",
    consigner: consigner2,
    price: "109.99",
    packingSlip: "http://www.africau.edu/images/default/sample.pdf",
    product: products[0],
    requestedPrice: "",
    subLocation: "Shelf abv",
    status: "Active",
    createdAt: new Date("May 25, 2021 23:15:30"),
    order: {
      orderDate: "Tuesday May 11, 2021",
      total: 6400,
      lineItems: orderInventories,
      customer: {
        first_name: "John Smith",
        last_name: "John Smith",
        email: "john@gmail.com",
        phone: "",
      },
      id: "1",
      name: "1001",
    },
    notes: "",
    productTemplate: {
      brand: "Nike",
      category: "sneakers",
      id: "177",
      option1: "Size",
      option1Values: [],
      option2: "Condition",
      option2Values: [],
      option3: "",
      option3Values: [],
      title: "Nike Sneakers",
      weight: 0,
      gender: "men",
    },
    requestInventoryWithdrawalAcceptedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalDeclinedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalAcceptedBy: employee,
    requestInventoryWithdrawalDeclinedBy: employee,
  },
  {
    id: "3",
    code: "1234567",
    option1Value: "8",
    option2Value: "New",
    option3Value: "",
    productTemplateId: "1",
    location: "Capetown",
    payout: "100.99",
    consigner: consigner,
    price: "129.99",
    packingSlip: "http://www.africau.edu/images/default/sample.pdf",
    product: products[0],
    requestedPrice: "109.99",
    subLocation: "Shelf abv",
    status: "Active",
    createdAt: new Date("May 25, 2021 23:15:30"),
    notes: "",
    order: {
      orderDate: "Tuesday May 11, 2021",
      total: 6400,
      lineItems: orderInventories,
      customer: {
        first_name: "John Smith",
        last_name: "John Smith",
        email: "john@gmail.com",
        phone: "",
      },
      id: "1",
      name: "1001",
    },
    productTemplate: {
      brand: "Nike",
      category: "sneakers",
      id: "177",
      option1: "Size",
      option1Values: [],
      option2: "Condition",
      option2Values: [],
      option3: "",
      option3Values: [],
      title: "Nike Sneakers",
      weight: 0,
      gender: "men",
    },
    requestInventoryWithdrawalAcceptedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalDeclinedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalAcceptedBy: employee,
    requestInventoryWithdrawalDeclinedBy: employee,
  },
  {
    id: "4",
    code: "1234567",
    option1Value: "8",
    option2Value: "New",
    option3Value: "",
    productTemplateId: "1",
    location: "Capetown",
    payout: "100.99",
    consigner: consigner,
    price: "129.99",
    packingSlip: "http://www.africau.edu/images/default/sample.pdf",
    product: products[0],
    requestedPrice: "109.99",
    subLocation: "Shelf abv",
    status: "Active",
    createdAt: new Date("May 25, 2021 23:15:30"),
    notes: "",
    order: {
      orderDate: "Tuesday May 11, 2021",
      total: 6400,
      lineItems: orderInventories,
      customer: {
        first_name: "John Smith",
        last_name: "John Smith",
        email: "john@gmail.com",
        phone: "",
      },
      id: "1",
      name: "1001",
    },
    productTemplate: {
      brand: "Nike",
      category: "sneakers",
      id: "177",
      option1: "Size",
      option1Values: [],
      option2: "Condition",
      option2Values: [],
      option3: "",
      option3Values: [],
      title: "Nike Sneakers",
      weight: 0,
      gender: "men",
    },
    requestInventoryWithdrawalAcceptedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalDeclinedOn: new Date("May 25, 2021 23:15:30"),
    requestInventoryWithdrawalAcceptedBy: employee,
    requestInventoryWithdrawalDeclinedBy: employee,
  },
];

export const inventoryFilterOptions = {
  statuses: [
    { label: "Active", value: "active" },
    { label: "Pending", value: "pending" },
    { label: "Withdrawn", value: "withdrawn" },
    { label: "Sold", value: "sold" },
  ],
  sizes: [
    { label: "5", value: "5" },
    { label: "5.5", value: "5.5" },
    { label: "6", value: "6.5" },
    { label: "7", value: "7.5" },
    { label: "8", value: "8" },
  ],
  conditions: [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
  ],
  locations: [{ label: "Capetown", value: "Capetown" }],
  categories: [{ label: "Sneakers", value: "Sneakers" }],
  consigners: [
    { label: "Menan Sub", value: consigner },
    { label: "Menan2 Sub", value: consigner2 },
  ],
};

export const payoutFilterOptions = {
  statuses: [
    { label: "Active", value: "active" },
    { label: "Pending", value: "pending" },
  ],
};
export const inventoryFee: InventoryFee = {
  transcation: 100,
  selling: 100,
  cleaning: 10,
  box: 20,
  payout: 100,
  total: 200,
  vat: 100,
  negativeBalance: 100,
};

export const consigners = [consigner, consigner2];
export const employees = [employee, employee];
export const orders: Order[] = [
  {
    id: "1",
    name: "1001",
    orderDate: "Tuesday, May 1st 2021",
    total: 6400,
    lineItems: [inventories[0], inventories[1]],
    customer: {
      first_name: "John Smith",
      last_name: "John Smith",
      email: "john@gmail.com",
      phone: "",
    },
  },
  {
    id: "2",
    name: "1002",
    orderDate: "Tuesday, May 1st 2021",
    total: 6400,
    lineItems: [inventories[0], inventories[1]],
    customer: {
      first_name: "John Smith",
      last_name: "John Smith",
      email: "john@gmail.com",
      phone: "",
    },
  },
  {
    id: "3",
    name: "1003",
    orderDate: "Tuesday, May 1st 2021",
    total: 6400,
    lineItems: [inventories[0], inventories[1]],
    customer: {
      first_name: "John Smith",
      last_name: "John Smith",
      email: "john@gmail.com",
      phone: "",
    },
  },
];

export const payouts: Payout[] = [
  {
    id: "1",
    name: "1001",
    total: 6400,
    itemCount: 3,
    inventories,
    status: "Active",
  },
  {
    id: "2",
    name: "1002",
    total: 6400,
    itemCount: 3,
    inventories,
    status: "Active",
  },
  {
    id: "3",
    name: "1003",
    total: 6400,
    itemCount: 3,
    inventories,
    status: "Active",
  },
];

export const consignerReport: ConsignerReport = {
  inventoriesByAge: inventories,
  inventoriesByStatus: inventories,
  inventoriesByCategory: inventories,
  inventoriesByLocation: inventories,
  bestSellingProducts: inventories,
  lowStockProducts: inventories,
  sales: 124114,
  dailySales: [1241, 142, 2424, 242],
  pendingPayouts: 234,
  dailyPendingPayouts: [1241, 142, 2424, 242],
  activeListings: 14,
  dailyActiveListings: [1241, 142, 2424, 242],
  soldListings: 23,
  dailySoldListings: [1241, 142, 2424, 242],
  salesGrowth: 23,
  pendingPayoutsGrowth: 15,
  activeListingsGrowth: -12,
  soldListingsGrowth: 21,
};
export const shopReport: ShopReport = {
  inventoriesByAge: inventories,
  inventoriesByCategory: inventories,
  inventoriesByLocation: inventories,
  inventoriesPendingPayouts: inventories,
  inventoriesPendingPriceRequests: inventories,
  inventoriesPendingWithdrawalRequests: inventories,
  consignersBySalesVolume: [consigner, consigner2],
  // inventoryStats: [],
  sales: 12444,
  salesGrowth: 12,
  dailySales: [1241, 142, 2424, 242],
  profit: 2144,
  profitGrowth: 31,
  dailyProfits: [1241, 142, 2424, 242],
  activeListings: 12,
  activeListingsGrowth: 3,
  totalActiveListings: 12,
  totalActiveListingsGrowth: 3,
  dailyTotalListings: [1241, 142, 2424, 242],
  dailyActiveListings: [1241, 142, 2424, 242],
  newListings: 23,
  newListingsGrowth: 4,
  dailyNewListings: [1241, 142, 2424, 242],
  totalInventoryValue: 12,
  totalInventoryValueGrowth: 3,
  dailyInventoryValue: [1241, 142, 2424, 242],
  totalPendingPayouts: 12,
  totalPendingPayoutsGrowth: 3,
  dailyPendingPayouts: [1241, 142, 2424, 242],
  allConsigners: consigners,
};
