import { AppDispatch } from "../store";
import { GET_CHEQUES } from "./types";
import { Cheque } from "../reducers/ChequeReducer";
import { api } from "../../api";

const getChequesLoading = () => ({
  type: GET_CHEQUES,
  cheques: [],
  chequesLoading: true,
});

const getChequesError = () => ({
  type: GET_CHEQUES,
  cheques: [],
  chequesLoading: false,
});

const getChequesSuccess = (cheques: Cheque[]) => ({
  type: GET_CHEQUES,
  cheques,
  chequesLoading: false,
});

//api
const getChequesFromAPI = async (payoutId: string) => {
  const { data, error } = await api.provide("get", "/api/cheques", {
    payoutId,
  });
  if (error) throw error;
  return (data?.cheques || []) as any;
};

//actions
export const getCheques = (payoutId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getChequesLoading());
    try {
      dispatch(getChequesSuccess(await getChequesFromAPI(payoutId)));
    } catch (e) {
      dispatch(getChequesError());
    }
  };
};
