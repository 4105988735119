import {
  GET_SUBLOCATION,
  GET_SUBLOCATIONS,
  UPDATE_SUBLOCATION,
  CREATE_SUBLOCATION,
} from "../actions/types";

export type SubLocation = any;

export interface SubLocationState {
  subLocations: SubLocation[];
  subLocationsLoading: boolean;
  subLocation: SubLocation | null;
  subLocationLoading: boolean;
  updatedSubLocation: SubLocation | null;
  updatedSubLocationLoading: boolean;
  createdSubLocation: SubLocation | null;
  createdSubLocationLoading: boolean;
}

const defaultState = {
  subLocations: [],
  subLocationsLoading: false,
  subLocation: null,
  subLocationLoading: false,
  updatedSubLocation: null,
  updatedSubLocationLoading: false,
  createdSubLocation: null,
  createdSubLocationLoading: false,
};

export const DefaultSubLocationState = defaultState;

export type SubLocationAction = {
  type: string;
  subLocations: SubLocation[];
  subLocationsLoading: boolean;
  subLocation: SubLocation | null;
  subLocationLoading: boolean;
  updatedSubLocation: SubLocation | null;
  updatedSubLocationLoading: boolean;
  createdSubLocation: SubLocation | null;
  createdSubLocationLoading: boolean;
};

export default (
  state: SubLocationState = defaultState,
  action: SubLocationAction
) => {
  switch (action.type) {
    case GET_SUBLOCATIONS:
      return {
        ...state,
        subLocations: action.subLocations,
        subLocationsLoading: action.subLocationsLoading,
      };
    case GET_SUBLOCATION:
      return {
        ...state,
        subLocation: action.subLocation,
        subLocationLoading: action.subLocationLoading,
      };
    case UPDATE_SUBLOCATION:
      return {
        ...state,
        updatedSubLocation: action.updatedSubLocation,
        updatedSubLocationLoading: action.updatedSubLocationLoading,
      };
    case CREATE_SUBLOCATION:
      return {
        ...state,
        createdSubLocation: action.createdSubLocation,
        createdSubLocationLoading: action.createdSubLocationLoading,
      };
    default:
      return state;
  }
};
