import React from "react";
import { Provider } from "react-redux";
import firebase from "firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "./redux/store";
import Nav from "./nav";
import "antd/dist/antd.less";
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#645aff",
      },
    },
  });
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Nav />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
