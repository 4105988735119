import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  RouteComponentProps,
  useHistory,
  useParams,
  Redirect,
} from "react-router";
import { Card, Spin } from "antd";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getOrder, resyncOrder } from "../redux/actions/orderActions";
import { Order, OrderState } from "../redux/reducers/OrderReducer";

import Content from "../constants/Content";
import { AppDispatch } from "../redux/store";
import InventoryList from "../components/Common/InventoryList";
import { Inventory } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
/**
 * Employee Item Screen
 * renders a screen where user can edit a singular order item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding-bottom: 100px;
`;

interface RouteParams {
  id: string;
}

const goToShopifyOrder = (order: Order, shop: any) => {
  window.open(`https://${shop.shop}/admin/orders/${order.shopifyId}`, "_blank");
};

const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};
const OrderItem = () => {
  const { id }: RouteParams = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { order, orderLoading, resyncingOrderLoading }: OrderState =
    useAppSelector((state) => state.OrderReducer);

  const handleResyncOrder = async (id: string) => {
    await dispatch(resyncOrder(id));
    history.goBack();
  };

  useEffect(() => {
    //get order on load
    dispatch(getOrder(id));
  }, []);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }

  if (orderLoading || !order) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return (
    <Container>
      <HorizontalPageCard
        title={`Order ${order.name}`}
        subtitle={
          order && order.customer && order.customer.first_name
            ? order.customer.first_name
            : ""
        }
        primaryActionText={Content.ORDER_ACTION_BUTTON_TEXT}
        primaryAction={() => goToShopifyOrder(order, store.shop)}
        secondaryActionText={Content.RESYNC_ORDER_ACTION_BUTTON_TEXT}
        secondaryAction={() => handleResyncOrder(order.id)}
        secondaryActionLoading={resyncingOrderLoading}
        boldText={
          order && order.customer
            ? order.customer.email !== ""
              ? order.customer.email
              : order.customer.phone
            : ""
        }
      />
      <Card
        style={{ marginTop: 10 }}
        title={Content.ORDER_ITEM_INVENTORY_LIST_TITLE}
      >
        <InventoryList
          inventories={order.lineItems}
          loading={orderLoading}
          onAccept={(inventory: Inventory) =>
            goToInventoryPage(history, inventory)
          }
          acceptButtonText={Content.ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT}
        />
      </Card>
    </Container>
  );
};

export default OrderItem;
