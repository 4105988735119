import { Scan } from "../reducers/ScanReducer";

import { AppDispatch } from "../store";
import { CREATE_SCAN, GET_SCAN, GET_SCANS, UPDATE_SCAN } from "./types";
import { api } from "../../api";
import { Inventory } from "../reducers/InventoryReducer";

const getScansLoading = () => ({
  type: GET_SCANS,
  scans: [],
  scansLoading: true,
});

const getScansError = () => ({
  type: GET_SCANS,
  scans: [],
  scansLoading: false,
});

const getScansSuccess = (scans: Scan[]) => ({
  type: GET_SCANS,
  scans,
  scansLoading: false,
});

const updateScanLoading = () => ({
  type: UPDATE_SCAN,
  updatedScan: null,
  updatedScanLoading: true,
});

const updateScanError = () => ({
  type: UPDATE_SCAN,
  updatedScan: null,
  updatedScanLoading: false,
});

const updateScanSuccess = (updatedScan: Scan) => ({
  type: UPDATE_SCAN,
  updatedScan,
  updatedScanLoading: false,
});

const createScanLoading = () => ({
  type: CREATE_SCAN,
  createdScan: null,
  createdScanLoading: true,
});

const createScanError = () => ({
  type: CREATE_SCAN,
  createdScan: null,
  createdScanLoading: false,
});

const createScanSuccess = (createdScan: Scan) => ({
  type: CREATE_SCAN,
  createdScan: createdScan.scan,
  createdScanLoading: false,
  inventories: createdScan.inventories,
});
const getScanLoading = () => ({
  type: GET_SCAN,
  scan: null,
  scanLoading: true,
});

const getScanError = () => ({
  type: GET_SCAN,
  scan: null,
  scanLoading: false,
});

const getScanSuccess = (scan: Scan) => ({
  type: GET_SCAN,
  scan: scan.scan,
  scanLoading: false,
  inventories: scan.inventories,
  scannedInventories: scan.scannedInventories,
});

//api
const getScansFromAPI = async () => {
  const { data, error } = await api.provide("get", "/api/scans", {});
  if (error) throw error;
  return (data?.scans || []) as any;
};
const getScanFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/scans/:id", {
    id,
  });
  if (error) throw error;
  return data;
};
const updateScanFromAPI = async (id: string, inventories: Inventory[]) => {
  const { data, error } = await api.provide("put", "/api/scans/:id", {
    id,
    inventories,
  });
  if (error) throw error;
  return data;
};

const createScanFromAPI = async (
  status: string = "new",
  inventories: Inventory[],
  location: string
) => {
  const payload = {
    status,
    inventories,
    location,
  };

  const { data, error } = await api.provide("post", "/api/scans", payload);
  if (error) throw error;
  return data;
};
//actions
export const getScans = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(getScansLoading());
    try {
      dispatch(getScansSuccess(await getScansFromAPI()));
    } catch (e) {
      console.log(e);
      dispatch(getScansError());
    }
  };
};

export const getScan = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getScanLoading());
    try {
      dispatch(getScanSuccess(await getScanFromAPI(id)));
    } catch (e) {
      console.log(e);

      dispatch(getScanError());
    }
  };
};

export const createScan = (
  status: string = "new",
  inventories: Inventory[],
  location: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createScanLoading());
    try {
      dispatch(
        createScanSuccess(
          await createScanFromAPI(status, inventories, location)
        )
      );
    } catch (e) {
      console.log(e);

      dispatch(createScanError());
    }
  };
};

export const updateScan = (id: string, inventories: Inventory[]) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateScanLoading());
    try {
      dispatch(updateScanSuccess(await updateScanFromAPI(id, inventories)));
    } catch (e) {
      console.log(e);

      dispatch(updateScanError());
    }
  };
};
