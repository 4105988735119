import React from "react";
import styled from "styled-components";
import { useParams, Redirect } from "react-router";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { createEmployee } from "../redux/actions/employeeActions";
import { EmployeeState } from "../redux/reducers/EmployeeReducer";
import { AppDispatch } from "../redux/store";
import EmployeeForm, { FormValues } from "../components/Common/EmployeeForm";
import { Form, FormInstance, message } from "antd";
import { UserState } from "../redux/reducers/UserReducer";
/**
 * Employee Item Screen
 * renders a screen where user can edit a singular employee item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div``;

interface RouteParams {
  id: string;
}

const addEmployee = async (
  dispatch: AppDispatch,
  employeeFormValues: FormValues,
  form: FormInstance
) => {
  await dispatch(createEmployee(employeeFormValues));
  form.resetFields();
};

const AddEmployee = () => {
  const { id }: RouteParams = useParams();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const { createEmployeeLoading } = useAppSelector(
    (state) => state.EmployeeReducer
  );

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const employedAdd = (values: any) => {
    addEmployee(dispatch, values, form);
    message.success("Employee added!");
  };

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Employees")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <EmployeeForm
        onFinish={(values) => employedAdd(values)}
        loading={createEmployeeLoading}
        form={form}
      />
    </Container>
  );
};

export default AddEmployee;
