import React from "react";
import styled from "styled-components";
import { Card, Input, Switch, Row, Col, Space } from "antd";
import Colors from "../../constants/Colors";
import { ConsignerDetailTab } from "../../consignerScreens/Settings";
/**
 * Consigner Profile Form
 */

const Container = styled(Card)`
  width: 100%;
`;

const CustomCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--heading-color, #2e2e2e);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
`;

interface Props {
  emailNotifications: any;
  setEmailNotifications: (value: any) => void;
  firstName?: string;
  lastName?: string;
  idNumber?: string;
}

const ConsignerNotificationForm = (props: Props) => {
  const {
    emailNotifications,
    setEmailNotifications,
    firstName,
    lastName,
    idNumber,
  } = props;

  const handleOnInventoryAccept = (checked: any) => {
    setEmailNotifications({
      ...emailNotifications,
      onInventoryAccept: checked,
    });
  };

  const handleOnPayout = (checked: any) => {
    setEmailNotifications({
      ...emailNotifications,
      onPayout: checked,
    });
  };

  const handleOnProductSold = (checked: any) => {
    setEmailNotifications({
      ...emailNotifications,
      onProductSold: checked,
    });
  };

  const handleOnPriceAcceptOrDecline = (checked: any) => {
    setEmailNotifications({
      ...emailNotifications,
      onPriceAcceptOrDecline: checked,
    });
  };

  const handleWithdrawAccepted = (checked: any) => {
    setEmailNotifications({
      ...emailNotifications,
      onWithdrawAcceptOrDecline: checked,
    });
  };

  return (
    <Container>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <ConsignerDetailTab
          firstName={firstName}
          lastName={lastName}
          idNumber={idNumber}
        />
        <Heading>Email notifications</Heading>
        <Row>
          <CustomCol>
            <p>On inventory accepted</p>
            <Switch
              onChange={(checked) => handleOnInventoryAccept(checked)}
              defaultChecked={emailNotifications.onInventoryAccept}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <p>On payout</p>
            <Switch
              onChange={(checked) => handleOnPayout(checked)}
              defaultChecked={emailNotifications.onPayout}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <p>On product sold</p>
            <Switch
              onChange={(checked) => handleOnProductSold(checked)}
              defaultChecked={emailNotifications.onProductSold}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <p>On price request accepted/declined</p>
            <Switch
              onChange={(checked) => handleOnPriceAcceptOrDecline(checked)}
              defaultChecked={emailNotifications.onPriceAcceptOrDecline}
            />
          </CustomCol>
        </Row>
        <Row>
          <CustomCol>
            <p>On withdrawal request accepted/declined</p>
            <Switch
              onChange={(checked) => handleWithdrawAccepted(checked)}
              defaultChecked={emailNotifications.onWithdrawAcceptOrDecline}
            />
          </CustomCol>
        </Row>
      </Space>
    </Container>
  );
};

export default ConsignerNotificationForm;
