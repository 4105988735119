import { InventoryLog } from "../reducers/InventoryLogReducer";

import { AppDispatch } from "../store";
import { GET_INVENTORYLOGS } from "./types";
import { api } from "../../api";

const getInventoryLogsLoading = () => ({
  type: GET_INVENTORYLOGS,
  inventoryLogs: [],
  inventoryLogsLoading: true,
});

const getInventoryLogsError = () => ({
  type: GET_INVENTORYLOGS,
  inventoryLogs: [],
  inventoryLogsLoading: false,
});

const getInventoryLogsSuccess = (inventoryLogs: InventoryLog[]) => ({
  type: GET_INVENTORYLOGS,
  inventoryLogs,
  inventoryLogsLoading: false,
});

//api
const getInventoryLogsFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/inventoryLogs/:id", {
    id,
  });
  if (error) throw error;
  return (data?.inventoryLogs || []) as any;
};

//actions
export const getInventoryLogs = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInventoryLogsLoading());
    try {
      dispatch(getInventoryLogsSuccess(await getInventoryLogsFromAPI(id)));
    } catch (e) {
      console.log(e);
      dispatch(getInventoryLogsError());
    }
  };
};
