import { Button, Modal } from "antd";
import React from "react";
import ghostLogo from "../assets/images/ghost_logo.png";
import streetWear from "../assets/images/svg/streetwear.svg";
import brandsShirts from "../assets/images/svg/brands_shirts.svg";
import essentials from "../assets/images/essentials2.png";
import streetWearList from "../assets/images/svg/streetwear_list.svg";

import styled from "styled-components";
import { useHistory } from "react-router-dom";

const PartnershipModal = styled(Modal)`
  .center-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    img {
      width: 200px;
    }
    h3 {
      font-size: 20px;
    }
  }
  .discounted-streetwear {
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: flex-end;
    h2 {
      text-align: left;
      width: 30%;
    }
    .streetwear-list {
      width: 240px;
      position: relative;
      top: 33px;
      left: 30px;
    }
    .streetwear {
      width: 140px;
      position: relative;
      left: 40px;
    }
  }
  .row {
    display: flex;
    gap: 20px;
    h2 {
      font-size: 16px;
      text-align: left;
      padding: 10px;
    }
  }
  .brands {
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    height: fit-content;
    .brands-shirts {
      width: 200px;
    }
  }
  .essentials {
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .essentials-image {
      width: 250px;
      position: relative;
      left: 45px;
    }
  }
`;

const GhostPartnershipModal = () => {
  const history = useHistory();
  return (
    <div>
      <PartnershipModal
        visible={true}
        footer={null}
        width={820}
        onCancel={() => history.push("/")}
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-content">
          <div className="center-text">
            <h3>Trace Partnership with</h3>
            <img src={ghostLogo} alt="Ghost Logo" />
            <h3>To bring you discounted wholesale</h3>
          </div>
          <div style={{ padding: "30px" }}>
            <div className="discounted-streetwear">
              <h2>Access discounted Streetwear and sneakers</h2>
              <div style={{ display: "flex" }}>
                <img src={streetWear} alt="Streetwear" className="streetwear" />
                <img
                  src={streetWearList}
                  alt="Streetwear List"
                  className="streetwear-list"
                />
              </div>
            </div>
            <div className="row">
              <div className="brands">
                <h2>
                  Brands like Yeezy, Hellstar, Essentials, Jordans, Denim tears,
                  Supreme, Palace & more
                </h2>
                <img
                  src={brandsShirts}
                  alt="Brands Shirts"
                  className="brands-shirts"
                />
              </div>
              <div className="essentials">
                <img
                  src={essentials}
                  alt="Essentials"
                  className="essentials-image"
                />
                <h2>
                  Essentials - around 72% off Supreme, Palace, Stussy, CDG etc
                  -45% off Luxury - 45%
                </h2>
              </div>
            </div>
          </div>
          <h2 style={{ marginTop: "20px" }}>Get Extra 5% off with trace</h2>
          <div
            className="button-container"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <Button
              type="primary"
              style={{
                background: "rgba(83, 83, 255, 1)",
                color: "white",
                fontWeight: 600,
                fontSize: "18px",
                height: "47px",
                padding: "4px 45px",
              }}
              onClick={() =>
                window.open(
                  "https://calendly.com/ben-frische-ghst/30min?preview_source=et_card",
                  "_blank"
                )
              }
            >
              Chat with Ben
            </Button>
          </div>
        </div>
      </PartnershipModal>
    </div>
  );
};

export default GhostPartnershipModal;
