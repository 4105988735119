import {
  GET_PAYOUT_FILTER_OPTIONS,
  GET_PAYOUTS,
  GET_CONSIGNER_PAYOUTS,
  PAYOUT_INVENTORIES,
  GET_PAYOUT,
  EXPORT_PAYOUT_INVENTORIES,
  MARK_PAID_INVENTORIES,
  RETRY_AUTO_PAYOUT,
} from "../actions/types";
import { Consigner } from "./ConsignerReducer";
import { Inventory } from "./InventoryReducer";

export type Payout = any;
export interface PayoutFilterOption {
  label: string;
  value: string;
}

export interface ConsignerFormOption {
  label: string;
  value: Consigner;
}

export interface PayoutFilterOptions {
  statuses: PayoutFilterOption[];
  consigners: ConsignerFormOption[];
}

export interface PayoutState {
  payoutFilterOptionsLoading: boolean;
  payoutFilterOptions: PayoutFilterOptions;
  payouts: Payout[];
  payoutsLoading: boolean;
  payout: Payout | null;
  retryPayout: any;
  payoutLoading: boolean;
  payoutInventories: Inventory[];
  payoutInventoriesLoading: boolean;
  exportedPayoutInventories: Inventory[];
  exportPayoutInventoriesLoading: boolean;
  markedPaidInventories: Inventory[];
  markPaidInventoriesLoading: boolean;
  autoPayoutInventories: any;
  autoPayoutInventoriesLoading: boolean;
  payoutSuccesful: boolean;
  payoutError: boolean;
}

const defaultState = {
  payoutFilterOptionsLoading: false,
  payoutFilterOptions: {
    statuses: [],
    consigners: [],
  },
  payouts: [],
  payoutsLoading: false,
  payout: null,
  retryPayout: null,
  payoutLoading: false,
  payoutInventories: [],
  payoutInventoriesLoading: true,
  exportedPayoutInventories: [],
  exportPayoutInventoriesLoading: false,
  markedPaidInventories: [],
  markPaidInventoriesLoading: true,
  autoPayoutInventories: [],
  autoPayoutInventoriesLoading: true,
  payoutSuccesful: false,
  payoutError: false,
};

export const DefaultPayoutState = defaultState;

export type PayoutAction = {
  type: string;
  payoutFilterOptionsLoading: boolean;
  payoutFilterOptions: PayoutFilterOptions;
  payouts: Payout[];
  payoutsLoading: boolean;
  payoutInventories: Inventory[];
  payoutInventoriesLoading: boolean;
  payout: Payout | null;
  retryPayout: any;
  payoutLoading: boolean;
  exportedPayoutInventories: Inventory[];
  exportPayoutInventoriesLoading: boolean;
  markedPaidInventories: Inventory[];
  markPaidInventoriesLoading: boolean;
  autoPayoutInventories: any;
  autoPayoutInventoriesLoading: boolean;
  payoutSuccesful: boolean;
  payoutError: boolean;
};

export default (state: PayoutState = defaultState, action: PayoutAction) => {
  switch (action.type) {
    case GET_PAYOUT_FILTER_OPTIONS:
      return {
        ...state,
        payoutFilterOptions: action.payoutFilterOptions,
        payoutFilterOptionsLoading: action.payoutFilterOptionsLoading,
      };
    case GET_PAYOUTS:
      return {
        ...state,
        payouts: action.payouts,
        payoutsLoading: action.payoutsLoading,
      };
    case GET_CONSIGNER_PAYOUTS:
      return {
        ...state,
        payouts: action.payouts,
        payoutsLoading: action.payoutsLoading,
      };
    case PAYOUT_INVENTORIES:
      return {
        ...state,
        payoutInventories: action.payoutInventories,
        payoutInventoriesLoading: action.payoutInventoriesLoading,
      };
    case GET_PAYOUT:
      return {
        ...state,
        payout: action.payout,
        payoutLoading: action.payoutLoading,
      };
    case EXPORT_PAYOUT_INVENTORIES:
      return {
        ...state,
        exportPayoutInventoriesLoading: action.exportPayoutInventoriesLoading,
        exportedPayoutInventories: action.exportedPayoutInventories,
      };
    case MARK_PAID_INVENTORIES:
      return {
        ...state,
        markedPaidInventories: action.markedPaidInventories,
        markPaidInventoriesLoading: action.markPaidInventoriesLoading,
      };
    case RETRY_AUTO_PAYOUT:
      return {
        ...state,
        retryPayout: action.retryPayout,
        payoutSuccesful: action.payoutSuccesful,
        payoutError: action.payoutError,
      };
    default:
      return state;
  }
};
