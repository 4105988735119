import React from "react";
import styled from "styled-components";
import { Card, Form, Input, Button, FormInstance } from "antd";
import Content from "../../constants/Content";
/**
 * Employee Bank Form
 */

const Container = styled.div``;

export interface FormValues {
  lastName: string;
  firstName: string;
  email: string;
}
interface Props {
  form: FormInstance;
  onFinish?: (values: FormValues) => void;
  loading?: boolean;
}

const EmployeeForm = (props: Props): JSX.Element => {
  const { form, onFinish, loading } = props;
  return (
    <Card title={Content.EMPLOYEE_FORM_TITLE}>
      <Form
        data-testid="addEmployeeForm"
        name="addEmployeeForm"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter employee first name"
            data-testid="addEmployeeFormFirstName"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[{ required: true }]}
        >
          <Input
            placeholder="Enter employee last name"
            data-testid="addEmployeeFormLastName"
          />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true }]}>
          <Input
            placeholder="Enter employee email"
            data-testid="addEmployeeFormEmail"
          />
        </Form.Item>
        {onFinish && (
          <Form.Item>
            <Button
              loading={loading}
              onSubmit={() => onFinish(form.getFieldsValue())}
              type="primary"
              htmlType="submit"
              data-testid="addEmployeeFormSubmitButton"
            >
              Invite
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default EmployeeForm;
