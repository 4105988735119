import React from "react";
import { Button, Input, Popconfirm, Table } from "antd";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import Content from "../../constants/Content";
import {
  Inventory,
  InventoryFilterOptions,
} from "../../redux/reducers/InventoryReducer";
import InventoryFilter from "./InventoryFilter";
import Fonts from "../../constants/Fonts";
import Colors from "../../constants/Colors";
import { useAppSelector } from "../../redux/hooks";
import RedesignStyling from "../../constants/RedesignStyling";
import getSymbolFromCurrency from "currency-symbol-map";

/**
 * Inventory Table
 * Takes a list of inventory items and renders a filterable table
 * - List of inventory
 * - Filter
 * - Action to go to inventory page
 * TODO Test
 * - Filter changes
 */
const Container = styled.div`
  background-color: ${RedesignStyling.PAGE_BG};
`;

const EditableTable = styled(Table)`
  background: ${RedesignStyling.PAGE_BG};
  .ant-table {
    background: ${RedesignStyling.PAGE_BG};
    .ant-table-thead tr th {
      text-transform: uppercase;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
`;

const HeaderContainer = styled.div`
  background-color: ${RedesignStyling.PAGE_BG};
  @media (max-width: 768px) {
    display: none;
  }
`;
const HeaderTitle = styled.p`
  color: #2e2e2e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    flex: 1;
    padding: 15px 0;
    flex-basis: 100%;
  }
`;

const ItemTitle = styled.p`
  font-family: ${Fonts.CONSIGNER};
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

const ItemText = styled.p`
  font-family: ${Fonts.CONSIGNER};
  font-size: 14px;
  margin: 0;
  color: ${Colors.GREY};
`;

const CustomSearch = styled(Input.Search)`
  height: auto;
  .ant-input-affix-wrapper {
    padding: 0 11px;
    border-color: ${Colors.CONSIGNER_BLUE};
  }
  input,
  button {
    height: 42px;
  }

  @media (max-width: 768px) {
    input.ant-input {
      font-size: 16px;
    }
  }
`;

const CustomButtonGroup = styled(Button.Group)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    flex: 1;
    border-color: ${Colors.CONSIGNER_BLUE};
  }
`;

const ProductMobileSpan = styled.span`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 11px;
  @media (max-width: 768px) {
    svg {
      height: 75px;
      width: 75px;
    }
  }
`;

const MobileOnlyActions = styled.div`
  display: none;
  flex: 1 0 calc(100% - 65px);
  padding-left: 10px;
  flex-wrap: wrap;
  span {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    b {
    }
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

//types
interface Props {
  onFilter: (values: any) => void;
  inventories: Inventory[];
  loading: boolean;
  onInventoryItemPrimaryAction: (
    newPrice: string,
    inventory: Inventory
  ) => void;
  onInventoryItemAction: (inventory: Inventory) => void;
  onInventoryItemDangerAction: (inventory: Inventory) => void;
  inventoryFilterOptions: InventoryFilterOptions;
  onInventoryItemDropoffAction: (inventory: Inventory) => void;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  filters?: any;
  onFilter?: (value: any, record: any) => boolean;
  sorter?: (a: any, b: any) => number;
  render: (key: string, data: Inventory) => JSX.Element;
}
interface HeaderProps {
  onFilter: (values: any) => void;
  inventoryFilterOptions: InventoryFilterOptions;
}

let store: any;

const getColumns = (
  onInventoryItemPrimaryAction: (
    newPrice: string,
    inventory: Inventory
  ) => void,
  onInventoryItemAction: (inventory: Inventory) => void,
  onInventoryItemDangerAction: (inventory: Inventory) => void,
  onInventoryItemDropoffAction: (inventory: Inventory) => void
): Columns[] => {
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  return [
    {
      title: "QR",
      dataIndex: "code",
      key: "qr",
      render: (text: string) => (
        <QRCode
          size={isMobile ? 65 : 100}
          data-testid="inventoryTableItemQR"
          value={text}
        />
      ),
    },
    {
      title: "Mobile Actions",
      dataIndex: "mobileOnly",
      key: "mobileOnly",
      render: (text: string, record: Inventory) => (
        <MobileOnlyActions>
          <span>
            <b>{record.code}</b>
            <b>
              {getSymbolFromCurrency(store.store?.currency)}
              {Number(record.price) - Number(record.discount ?? 0)}
            </b>
          </span>
          <ItemContainer>
            <ItemText>
              {record.sku && record.sku !== "" && `${record.sku} | `}{" "}
              {record.option1Value} | {record.option2Value}{" "}
              {record.option3Value && record.option3Value !== "" && "|"}{" "}
              {record.option3Value}
            </ItemText>
          </ItemContainer>
        </MobileOnlyActions>
      ),
    },
    {
      title: "Item",
      dataIndex: "product",
      key: "product",
      render: (text: string, record: Inventory) => (
        <ItemContainer>
          <ItemTitle>{record.code}</ItemTitle>
          <ItemText>
            {record.option1Value} {record.option2Value} {record.option3Value}
          </ItemText>
          <ItemText>
            {getSymbolFromCurrency(store.store?.currency)}
            {record.price}
          </ItemText>

          {record.discount ? (
            <ItemText>
              Sold at {getSymbolFromCurrency(store.store?.currency)}
              {Number(record.price) - Number(record.discount)}
            </ItemText>
          ) : (
            ""
          )}

          <ItemText>{record.location}</ItemText>
        </ItemContainer>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Paid",
          value: "Paid",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Sold",
          value: "Sold",
        },
        {
          text: "Withdrawn",
          value: "Withdrawn",
        },
      ],
      onFilter: (value: any, record: any) => record.status === value,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string, record: Inventory) => (
        <ItemContainer>
          <ItemTitle>{record.status}</ItemTitle>
        </ItemContainer>
      ),
    },
    {
      title: "Item",
      dataIndex: "requestedPrice",
      key: "requestedPrice",
      render: (text: string, data: Inventory) => (
        <ItemContainer>
          <CustomSearch
            onSearch={(value) => onInventoryItemPrimaryAction(value, data)}
            prefix={getSymbolFromCurrency(store.store?.currency)}
            placeholder={data.price}
            enterButton="Price Change Request"
            type="number"
            disabled={
              data.status === "Active" || data.status === "RequestPriceChange"
                ? false
                : true
            }
          />
          {text && text !== "" ? (
            <ItemText>
              Request for price change to{" "}
              {getSymbolFromCurrency(store.store?.currency)}
              {text} pending
            </ItemText>
          ) : null}
        </ItemContainer>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: string, data: Inventory) => (
        <CustomButtonGroup>
          {/* <Button
            data-testid="inventoryTableItemActionBtn"
            onClick={() => onInventoryItemAction(data)}
            style={{ marginRight: 10, height: 42 }}
          >
            Print
          </Button> */}
          {data.status !== "Withdrawn" &&
            data.status !== "Pending" &&
            data.status !== "Requested" &&
            data.status !== "Declined" &&
            data.status != "RequestWithdrawal" &&
            data.status != "Paid" &&
            data.status != "Sold" && (
              <Button
                data-testid="inventoryTableItemActionDangerBtn"
                onClick={() => onInventoryItemDangerAction(data)}
                // danger
                type="primary"
                style={{ height: 42 }}
              >
                Withdraw
              </Button>
            )}
          {["Requested", "Pending"].includes(data.status) && (
            <Popconfirm
              title="Do you want to cancel this Dropoff?"
              onConfirm={() => onInventoryItemDropoffAction(data)}
              okText="Confirm"
              okButtonProps={{ type: "primary" }}
              cancelButtonProps={{ type: "text" }}
              icon={false}
            >
              <Button danger type="primary" style={{ height: "auto", flex: 1 }}>
                Cancel
              </Button>
            </Popconfirm>
          )}
        </CustomButtonGroup>
      ),
    },
  ];
};

const Header = (props: HeaderProps): JSX.Element => {
  const { onFilter, inventoryFilterOptions } = props;
  return (
    <HeaderContainer data-testid="inventoryTableHeader">
      <HeaderTitle data-testid="inventoryTableHeaderTitle">
        {Content.INVENTORY_TABLE_HEADER_TITLE}
      </HeaderTitle>
      {/*  <InventoryFilter
        onFilter={onFilter}
        formOptions={inventoryFilterOptions}
        showConsignerFilter={false}
        showStatusFilter
      />
    */}
    </HeaderContainer>
  );
};

const ProductMobileView = (props: any) => {
  const {
    inventories,
    onInventoryItemPrimaryAction,
    onInventoryItemAction,
    onInventoryItemDangerAction,
    onInventoryItemDropoffAction,
  } = props;

  return (
    <div>
      {inventories.map((item, index) => {
        const fn = getColumns(
          onInventoryItemPrimaryAction,
          onInventoryItemAction,
          onInventoryItemDangerAction,
          onInventoryItemDropoffAction
        );
        return (
          <ProductMobileSpan key={index}>
            {fn
              .filter((column) =>
                ["code", "mobileOnly", "requestedPrice", "id"].includes(
                  column.dataIndex
                )
              )
              .map((val, idx) => (
                <React.Fragment key={idx}>
                  {val.render(item[val.dataIndex], item)}
                </React.Fragment>
              ))}
          </ProductMobileSpan>
        );
      })}
    </div>
  );
};

const InventoryTable = (props: Props) => {
  const {
    onFilter,
    inventories,
    loading,
    onInventoryItemPrimaryAction,
    onInventoryItemAction,
    onInventoryItemDangerAction,
    onInventoryItemDropoffAction,
    inventoryFilterOptions,
  } = props;
  store = useAppSelector((state) => state.StoreReducer);
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const columnSrc = getColumns(
    onInventoryItemPrimaryAction,
    onInventoryItemAction,
    onInventoryItemDangerAction,
    onInventoryItemDropoffAction
  );
  return (
    <Container data-testid="inventoryTableContainer" style={{ marginTop: 10 }}>
      {isMobile ? (
        <ProductMobileView
          inventories={inventories}
          onInventoryItemAction={onInventoryItemAction}
          onInventoryItemDangerAction={onInventoryItemDangerAction}
          onInventoryItemPrimaryAction={onInventoryItemPrimaryAction}
        />
      ) : (
        <EditableTable
          data-testid="inventoryTable"
          title={() => (
            <Header
              inventoryFilterOptions={inventoryFilterOptions}
              onFilter={onFilter}
            />
          )}
          columns={
            isMobile
              ? columnSrc
              : columnSrc.filter((col) => col.dataIndex !== "mobileOnly")
          }
          dataSource={inventories}
          rowKey="id"
          loading={loading}
        />
      )}
    </Container>
  );
};

export default InventoryTable;
