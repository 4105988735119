import { StoreFormValues } from "../../components/Common/SetupSettings";
import { Store } from "../reducers/StoreReducer";
import { AppDispatch } from "../store";
import {
  CREATE_STORE,
  GET_STORE,
  GET_STORE_FOR_DOMAIN,
  RESYNC_ALL_INVENTORY,
  UPDATE_STORE,
  UPDATE_STORE_SHOP,
} from "./types";
import { message } from "antd";
import { api } from "../../api";

const resyncAllInventoryLoading = () => ({
  type: RESYNC_ALL_INVENTORY,
  resyncAllInventoryLoading: true,
});

const resyncAllInventoryError = () => ({
  type: RESYNC_ALL_INVENTORY,
  resyncAllInventoryLoading: false,
});

const resyncAllInventorySuccess = () => ({
  type: RESYNC_ALL_INVENTORY,
  resyncAllInventoryLoading: false,
});

const updateStoreLoading = () => ({
  type: UPDATE_STORE,
  updateStore: null,
  updateStoreLoading: true,
});

const updateStoreError = () => ({
  type: UPDATE_STORE,
  updateStore: null,
  updateStoreLoading: false,
});

const updateStoreSuccess = (updateStore: Store) => ({
  type: UPDATE_STORE,
  updateStore,
  updateStoreLoading: false,
});

const createStoreLoading = () => ({
  type: CREATE_STORE,
  createStore: null,
  createStoreLoading: true,
  createdSuccess: false,
});

const createStoreError = () => ({
  type: CREATE_STORE,
  createStore: null,
  createStoreLoading: false,
  createdSuccess: false,
});

const createStoreSuccess = () => ({
  type: CREATE_STORE,
  createStoreLoading: false,
  createdSuccess: true,
});
const getStoreLoading = () => ({
  type: GET_STORE,
  store: null,
  storeLoading: true,
});

const getStoreError = () => ({
  type: GET_STORE,
  store: null,
  storeLoading: false,
});

const getStoreSuccess = (store: Store) => ({
  type: GET_STORE,
  store,
  storeLoading: false,
});

const getStoreForDomainLoading = () => ({
  type: GET_STORE_FOR_DOMAIN,
  storeLoading: true,
  store: null,
});

const getStoreForDomainError = () => ({
  type: GET_STORE_FOR_DOMAIN,
  store: null,
  storeLoading: false,
});

const getStoreForDomainSuccess = (store: Store) => ({
  type: GET_STORE_FOR_DOMAIN,
  store,
  storeLoading: false,
});

const updateStoreShopLoading = () => ({
  type: UPDATE_STORE_SHOP,
  updatingStoreShop: true,
});

const updateStoreShopError = () => ({
  type: UPDATE_STORE_SHOP,
  updatingStoreShop: false,
});

const updateStoreShopSuccess = () => ({
  type: UPDATE_STORE_SHOP,
  updatingStoreShop: false,
});

//api
const createStoreFromAPI = async (
  name: string,
  email: string,
  handle: string,
  shop: string | null,
  accessToken: string | null,
  platform: string | null
) => {
  const payload = {
    name,
    email,
    handle,
    shop,
    accessToken,
    platform,
  };
  const { data, error } = await api.provide("post", "/open/stores", payload);
  if (error) throw error;
  return data;
};

const getStoreForDomainFromAPI = async (domain: string) => {
  const { data, error } = await api.provide("get", "/open/stores/:domain", {
    domain,
  });
  if (error) throw error;
  return data;
};

const updateStoreShopFromAPI = async (shop: string) => {
  const { data, error } = await api.provide("post", "/api/stores/connectShop", {
    shop,
  });
  if (error) throw error;
  window.open(`${process.env.REACT_APP_SHOPIFY_INSTALL_URL}?shop=${shop}`);
  return data;
};

const getStoreFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/stores/:id", { id });
  if (error) throw error;
  return data;
};

const updateStoreFromAPI = async (id: string, updateStore: StoreFormValues) => {
  const payload = {
    id,
    name: updateStore.name,
    supportEmail: updateStore.supportEmail,
    domain: updateStore.domain,
    currency: updateStore.currency,
    signupInfo: updateStore.signupInfo,
    payoutOptions: updateStore.payoutOptions,
    prioritizeStore: updateStore.prioritizeStore,
    labelOptions: updateStore.labelOptions,
    labelSize: updateStore.labelSize,
    logo: updateStore.logo,
    consignerUrl: updateStore.consignerUrl,
    inStoreUrl: updateStore.inStoreUrl,
    consignerCname: updateStore.consignerCname,
    shipInstructions: updateStore.shipInstructions,
    emailMsgs: updateStore.emailMsgs,
    marketplaceEmailMsgs: updateStore.marketplaceEmailMsgs,
    termsOfService: updateStore.termsOfService,
    absorbDiscount: updateStore.absorbDiscount,
    safeInventoryStatusMode: updateStore.safeInventoryStatusMode,
    livePriceChanges: updateStore.livePriceChanges,
    redirectURL: updateStore.redirectURL,
    customBarcode: updateStore.customBarcode,
    shopifyTrackingParams: updateStore.shopifyTrackingParams,
    customShopifyUrl: updateStore.customShopifyUrl,
    maxApproval: updateStore.maxApproval,
    minApproval: updateStore.minApproval,
    payoutFee: updateStore.payoutFee,
    autoPreapproval: updateStore.autoPreapproval,
    warnAmount: updateStore.warnAmount,
    warnText: updateStore.warnText,
    chargeTax: updateStore.chargeTax,
    upPrice: updateStore.upPrice,
    isBuyingStore: updateStore.isBuyingStore,
    onlineMarketplace: updateStore.onlineMarketplace,
  };

  const { data, error } = await api.provide("put", "/api/stores/:id", payload);
  if (error) throw error;
  return data;
};
const resyncAllInventoryFromAPI = async () => {
  const { data, error } = await api.provide("post", "/api/stores/resync", {});
  if (error) throw error;
  return data;
};

//actions
export const createStore = (
  name: string,
  email: string,
  handle: string,
  shop: string | null,
  accessToken: string | null,
  platform: string | null
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createStoreLoading());
    try {
      await createStoreFromAPI(
        name,
        email,
        handle,
        shop,
        accessToken,
        platform
      );
      dispatch(createStoreSuccess());
    } catch (error: any) {
      const errorMessage =
        error && error.response && error.response.data
          ? error.response.data
          : String(error);
      message.error(errorMessage);
      dispatch(createStoreError());
    }
  };
};

export const getStoreForDomain = (domain: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getStoreForDomainLoading());
    try {
      dispatch(
        getStoreForDomainSuccess(await getStoreForDomainFromAPI(domain))
      );
    } catch (e) {
      //    message.error(String(e));
      dispatch(getStoreForDomainError());
    }
  };
};

export const updateStoreShop = (shop: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateStoreShopLoading());
    try {
      await updateStoreShopFromAPI(shop);
      dispatch(updateStoreShopSuccess());
    } catch (e) {
      //    message.error(String(e));
      dispatch(updateStoreShopError());
    }
  };
};

export const getStore = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getStoreLoading());
    try {
      dispatch(getStoreSuccess(await getStoreFromAPI(id)));
    } catch (e) {
      dispatch(getStoreError());
    }
  };
};

// export const createStore = (store: StoreFormValues) => {
//   return async (dispatch: AppDispatch) => {
//     dispatch(createStoreLoading());
//     try {
//       dispatch(createStoreSuccess(await createStoreFromAPI(store)));
//     } catch (e) {
//       dispatch(createStoreError());
//     }
//   };
// };
export const updateStore = (id: string, store: StoreFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateStoreLoading());
    try {
      dispatch(updateStoreSuccess(await updateStoreFromAPI(id, store)));
    } catch (e) {
      dispatch(updateStoreError());
    }
  };
};

export const resyncAllInventory = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(resyncAllInventoryLoading());
    try {
      await resyncAllInventoryFromAPI();
      dispatch(resyncAllInventorySuccess());
    } catch (e) {
      dispatch(resyncAllInventoryError());
    }
  };
};
