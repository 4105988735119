export default {
  // CURRENCY: "USD",
  SIZE: "US",
  TOPBAR_BACK_TEXT: "< Go Back To Store",
  // TOPBAR_BACK_LINK: "https://www.shopwithsafe.com/",
  FOOTER_TEXT: `Shop with Trace © ${new Date().getFullYear()}`,
  LOGIN_CARD_TITLE: "Login",
  LOGIN_CARD_FORGOT_PASSWORD: "Forgot Password",
  PRODUCT_SCREEN_SEARCH_PLACEHOLDER: "Search Products",
  SUBMITTED_PRODUCT_TABLE_HEADER_TITLE: "Products Submitted For Review",
  SUBMITTED_PRODUCT_TABLE_SEARCH_PLACEHOLDER: "Search Products",
  CREATE_PRODUCT_SCREEN_TITLE: "Create Product",
  PRODUCT_ITEM_SCREEN_SECONDARY_ACTION_TEXT: "Add Inventory",
  PRODUCT_ITEM_SCREEN_THIRD_ACTION_TEXT: "Manage GTINS",
  PRODUCT_ITEM_SCREEN_PRIMARY_ACTION_TEXT: "Save",
  INVENTORY_TABLE_HEADER_TITLE: "Add Inventory",
  CONSIGNER_SIGNUP_STEPS_TITLE: "SIGNUP",
  CONSIGNER_SIGNUP_STEPS: [
    {
      title: "PROFILE",
      description: "Create your seller profile",
    },
    {
      title: "GET PAID",
      description: "Link your bank",
    },
    {
      title: "TERMS",
      description: "Accept our TOS",
    },
  ],
  ADD_INVENTORY_STEPS: [
    {
      title: "SIZE",
    },
    {
      title: "CONDITION",
    },
    {
      title: "PRICE",
    },
  ],
  CONSIGNER_ADD_INVENTORY_STEPS: [
    {
      title: "SIZE",
    },
    {
      title: "CONDITION",
    },
    {
      title: "PRICE",
    },
    {
      title: "SHIP",
    },
  ],
  WITHDRAW_FEE: 0,
  WITHDRAW_EARLY_AMOUNT_OF_DAYS: 0,
  ADD_INVENTORY_STEPS_TITLE: "CREATE A LISTING",
  ADD_INVENTORY_CONDITION_GRID_TITLE: "What is the condition of the item",
  ADD_INVENTORY_BOX_CONDITION_GRID_TITLE:
    "What is the condition of the box / packaging",
  ADD_INVENTORY_CLEANING_FEE_GRID_TITLE: "Cleaning Fee",
  ADD_INVENTORY_BOX_FEE_GRID_TITLE: "Location",
  ADD_INVENTORY_CLEANING_FEE: "20",
  ADD_INVENTORY_BOX_FEE: "20",
  ADD_INVENTORY_BOX_FEE_SUBTITLE: "REQUIRED",
  ADD_INVENTORY_CLEANING_FEE_SUBTITLE: "REQUIRED",
  ADD_INVENTORY_QUANTITY_LABEL: "Quantity",
  ADD_INVENTORY_FEE_TITLE: "Price Details",
  ADD_INVENTORY_FEE_ONE_LABEL: "Transcation Fee",
  ADD_INVENTORY_FEE_TWO_LABEL: "Selling Fees",
  ADD_INVENTORY_FEE_THREE_LABEL: "Cleaning Fee",
  ADD_INVENTORY_FEE_FOUR_LABEL: "Box Fees",
  ADD_INVENTORY_FEE_BALANCE_LABEL: "Outstanding Withdrawal Fees",
  ADD_INVENTORY_FEE_VAT_LABEL: "VAT",
  // ADD_INVENTORY_FEE_SUBTOTAL_LABEL: "Your Payout (CAD)",
  // ADD_INVENTORY_FEE_TOTAL_LABEL: "Total Payout (CAD)",
  ADD_INVENTORY_QUANTITY_WARNING: "per quantity",
  ADD_INVENTORY_ALL_QUANTITY_WARNING: "all quantity",
  PAYOUT_IS_ZERO_MESSAGE: "*The current price will give a payout of $0.",
  ADD_INVENTORY_SHIP_ONE_LABEL:
    "1. Your listing request has been successfully received.",
  ADD_INVENTORY_SHIP_ONE_PACKING_SLIP_LABEL: "Download Packing Slip",
  ADD_INVENTORY_SHIP_TWO_LABEL: "2. Come dropoff your item in-store.",
  ADD_INVENTORY_SHIP_ADDRESS:
    "Story Cape Town 166 Buitengracht St, Cape Town City Centre, Cape Town, 8000, South Africa",
  ADD_INVENTORY_SHIP_THREE_LABEL:
    "3. When you arrive your item will be authenticated and if successful will be listed for sale online + in store.",
  FIND_INVENTORY_ACCEPT_BUTTON_TEXT: "EDIT",
  FIND_INVENTORY_ACTION_BUTTON_ONE: "Bulk Print",
  FIND_INVENTORY_ACTION_BUTTON_TWO: "Export CSV",
  REQUEST_SELL_INVENTORY_ACCEPT_BUTTON_TEXT: "Accept Dropoff Request",
  REQUEST_SELL_INVENTORY_DECLINE_BUTTON_TEXT: "Decline",
  INCOMING_INVENTORY_ACCEPT_BUTTON_TEXT: "Accept",
  INCOMING_INVENTORY_DECLINE_BUTTON_TEXT: "Decline",
  WITHDRAW_INVENTORY_ACCEPT_BUTTON_TEXT: "Withdraw",
  PRICE_CHANGE_INVENTORY_ACCEPT_BUTTON_TEXT: "Accept",
  PRICE_CHANGE_INVENTORY_DECLINE_BUTTON_TEXT: "Decline",
  INVENTORY_WITHDRAWAL_REQUEST_ACCEPT_BUTTON_TEXT: "Accept Withdrawal",
  INVENTORY_WITHDRAWAL_REQUEST_DECLINE_BUTTON_TEXT: "Decline",
  INVENTORY_WITHDRAWAL_REQUEST_ACTION_BUTTON: "Withdraw",
  READY_TO_PRINT_INVENTORY_ACCEPT_BUTTON_TEXT: "Print",
  INVENTORY_ITEM_ACTION_TEXT: "Print",
  EDIT_INVENTORY_INPUT_ONE: "Size",
  EDIT_INVENTORY_INPUT_TWO: "Condition",
  EDIT_INVENTORY_INPUT_THREE: "Price",
  EDIT_INVENTORY_INPUT_FOUR: "Location",
  EDIT_INVENTORY_INPUT_FIVE: "Box Condition",
  CONSIGNER_ACTION_LOGOUT_BUTTON_TEXT: "Logout",
  EDIT_INVENTORY_DANGER_BUTTON_TEXT: "Delete",
  EDIT_INVENTORY_PRIMARY_BUTTON_TEXT: "Save",
  EDIT_INVENTORY_CARD_TITLE: "Edit",
  CONSIGNER_SCREEN_SEARCH_PLACEHOLDER: "Search Consigners",
  EMPLOYEE_SCREEN_SEARCH_PLACEHOLDER: "Search Employees",
  ORDERS_SCREEN_SEARCH_PLACEHOLDER: "Search Orders",
  PAYOUTS_SCREEN_SEARCH_PLACEHOLDER: "Search Payouts",
  EMPLOYEE_ACTION_BUTTON_TEXT: "Resend Invite",
  CONSIGNER_ACTION_BUTTON_TEXT: "Save",
  BANKS: [
    "FNB/RMB",
    "ABSA Bank",
    "Capitec Bank",
    "Nedbank Limited",
    "Standard Bank",
    "African Bank",
    "Albaraka Bank",
    "BNP Paribas",
    "Bidvest Bank",
    "CitiBank",
    "Bank Zero",
    "Discovery bank",
    "FinBond Mutual Bank",
    "FinBond Net1",
    "GROBANK LTD",
    "Grindrod Bank",
    "HBZ Bank",
    "HSBS Bank",
    "Habib Overseas Bank",
    "Investec Bank",
    "IThala",
    "JP Morgan Chase",
    "Mercantile Bank",
    "Nedbank Incorp FCB",
    "Nedbank LTD BOE",
    "Nedbank PEP Bank",
    "Olympus Mobile",
    "Peoples Bank Ltd Inc NBS",
    "S.A. Reserve Bank",
    "SASFIN Bank",
    "South African Postbank SOC",
    "Standard Chartered Bank",
    "State Bank of India",
    "TymeBank",
    "UBank Limited",
    "Unibank",
    "VBS Mutual Bank",
  ],
  BANKS_TYPES: [
    "Current (cheque/bond) Account",
    "Savings Account",
    "Transmission Account",
    "Bond Account",
    "Subscription Share Account",
    "Fnbcard Account",
    "WesBank",
  ],
  BANKROUTINGTYPE: [
    {
      value: "abaNumber",
      label: "ABA Routing Number",
    },
    {
      value: "swiftCode",
      label: "Swift Code (BIC)",
    },
    {
      value: "ibanNumber",
      label: "IBAN Number",
    },
  ],
  EMPLOYEE_FORM_TITLE: "Add new employee",
  EMPLOYEE_ACCESS_CONTROLS: [
    "Dashboard",
    "Products",
    "Inventory",
    "Consigners",
    "Employees",
    "Orders",
    "Settings",
    "InStoreApp",
  ],
  EMPLOYEE_ACCESS_CONTROLS_TITLE: "Access Controls",
  EMPLOYEE_ACCESS_CONTROLS_BUTTON_TEXT: "Save",
  ORDER_ACTION_BUTTON_TEXT: "View in Shopify",
  RESYNC_ORDER_ACTION_BUTTON_TEXT: "Resync Line Items",
  ORDER_ITEM_INVENTORY_LIST_TITLE: "Line Items",
  ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT: "View",
  FIND_ORDERS_ACTION_BUTTON_TEXT: "Export CSV",
  RESYNC_ALL_ORDERS_ACTION_BUTTON_TEXT: "Resync All Orders",
  READY_TO_PAY_ACTION_BUTTON_TEXT: "Select All",
  READY_TO_PAY_ACTION_BUTTON_TEXT_TWO: "Payout Items",
  PAYOUT_ACTION_BUTTON_TEXT: "Export CSV",
  PAYOUT_ACTION_BUTTON_TEXT_TWO: "Mark as Paid",
  CONSIGNER_BANK_FORM_MESSAGE: "Data secured safely",
  CONSIGNER_BANK_FORM_DESC:
    "Your banking data is encryped prior us storing it. We will never share or use this information for anything other than sending you payouts.",
  CONSIGNER_BANK_FORM_READ_ONLY_DESC:
    "It must be done in person, or by emailing info@shopwithsafe",
  CONSIGNER_BANK_FORM_READ_ONLY_MESSAGE:
    "PLEASE NOTE: Bank info cannot be changed via the consignor dashboard after submission below. ",
  TOS: `
  We’re constantly developing new technologies and features to improve our services. For example, we invest in artificial intelligence that uses machine learning to detect and block spam and malware, and to provide you with innovative features, like simultaneous translations. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.

If we make material changes that negatively impact your use of our services or if we stop offering a service, we’ll provide you with reasonable advance notice and an opportunity to export your content from your Google Account using Google Takeout, except in urgent situations such as preventing abuse, responding to legal requirements, or addressing security and operability issues.

We’re constantly developing new technologies and features to improve our services. For example, we invest in artificial intelligence that uses machine learning to detect and block spam and malware, and to provide you with innovative features, like simultaneous translations. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.

If we make material changes that negatively impact your use of our services or if we stop offering a service, we’ll provide you with reasonable advance notice and an opportunity to export your content from your Google Account using Google Takeout, except in urgent situations such as preventing abuse, responding to legal requirements, or addressing security and operability issues.

We’re constantly developing new technologies and features to improve our services. For example, we invest in artificial intelligence that uses machine learning to detect and block spam and malware, and to provide you with innovative features, like simultaneous translations. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.

If we make material changes that negatively impact your use of our services or if we stop offering a service, we’ll provide you with reasonable advance notice and an opportunity to export your content from your Google Account using Google Takeout, except in urgent situations such as preventing abuse, responding to legal requirements, or addressing security and operability issues.


  `,
  RESET_PASSWORD_TITLE: "Forgot your password?",
  RESET_PASSWORD_MESSAGE:
    "Don't worry. Recovering the password is easy. Just tell us the email you have registered with below.",
  RESET_PASSWORD_BUTTON_TEXT: "Reset Password",
  VERIFY_EMAIL_MESSAGE:
    "We sent a verification email to the account below, please go into that email and click ‘Confirm” to start selling.",
  VERIFY_EMAIL_TITLE: "You need to verify your email.",
  VERIFY_EMAIL_BUTTON_TEXT: "Resend",
  REQUEST_PRODUCT_SCREEN_TITLE: "Submit Product For Review",
  TRANSFER_INVENTORY_ACCEPT_BUTTON_TEXT: "Transfer Items",
  ACCEPT_TRANSFER_BUTTON_TEXT: "Accept Transfer",
  TRANSFER_SEARCH: "Search by product title",
  ADD_INVENTORY_COST_LABEL: "Cost per item",
  ADD_INVENTORY_PAYOUT_LABEL: "Payout",
  ADD_INVENTORY_PRICE_LABEL: "Price",
  ADD_INVENTORY_COSTS_SUBTOTAL_LABEL: "Your Cost",
  ADD_INVENTORY_COSTS_TOTAL_LABEL: "Your Total Cost",
  ADD_INVENTORY_CONSIGNER_LABEL: "Consigner",
  ADD_INVENTORY_CONSIGNER_OR_BUYOUT_LABEL: "Consigner or Buyout",
  ADD_LOCATION_LABEL: "Select location",
  RESYNC_BUTTON_TEXT: "Resync",
  DUPLICATE_PRODUCTS_TITLE: "Duplicate Products with matching SKUs",
};
