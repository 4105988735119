import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Select, Spin } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getPayoutFilterOptions,
  getPayouts,
} from "../redux/actions/payoutActions";
import { getConsigners } from "../redux/actions/consignerActions";
import PayoutFilter from "../components/Common/PayoutFilter";
import OrderTable from "../components/Common/OrderTable";
import { RouteComponentProps, useHistory, Redirect } from "react-router";
import { Order } from "../redux/reducers/OrderReducer";
import { Payout } from "../redux/reducers/PayoutReducer";
import PayoutTable from "../components/Common/PayoutTable";
import { UserState } from "../redux/reducers/UserReducer";
import { getDropDownValuesForConsigner } from "../screens/InventoryItem";
import { Label } from "recharts";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { Consigner } from "../redux/reducers/ConsignerReducer";

const { Search } = Input;

/**
 * Payout Screen
 * Renders table of payouts and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: 16px;
`;

const goToPayoutPage = (
  history: RouteComponentProps["history"],
  payout: Order | Payout
) => {
  history.push(`/payouts/${payout.id}`);
};

const Payouts = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selectedConsigner, setSelectedConsigner] = useState("");
  const [filteredConsigners, setFilteredConsigners] = useState<any>([]);
  const [consignersForDropdown, setConsignersForDropdown] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    consigner: "",
  });
  const [filteredPayouts, setFilteredPayouts] = useState([]);
  //Payouts Reducers
  const {
    payouts,
    payoutsLoading,
    payoutFilterOptions,
    payoutFilterOptionsLoading,
  } = useAppSelector((state) => state.PayoutReducer);
  const { consigners, consignersLoading } = useAppSelector(
    (state) => state.ConsignerReducer
  );
  const { inventoryFilterOptions }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );
  useEffect(() => {
    dispatch(getPayoutFilterOptions());
    setFilteredConsigners(consigners);
  }, []);

  useEffect(() => {
    dispatch(getPayouts(search, filters));
    dispatch(getConsigners(""));
  }, [search, filters]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!payoutFilterOptions || payoutFilterOptionsLoading) return <Spin />;

  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.PAYOUTS_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <PayoutFilter
        onFilter={(values) => setFilters({ ...filters, ...values })}
        formOptions={payoutFilterOptions}
      />
      <PayoutTable
        data={payouts}
        loading={payoutsLoading}
        consigners={consigners}
        onItemAction={(payout) => goToPayoutPage(history, payout)}
      />
    </Container>
  );
};

export default Payouts;
