import React from "react";
import styled from "styled-components";
import { useParams, Redirect } from "react-router";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { createConsignerFromAdmin } from "../redux/actions/consignerActions";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import { AppDispatch } from "../redux/store";
import ConsignerForm, { FormValues } from "../components/Common/ConsignerForm";
import { Form, FormInstance, message } from "antd";
import { UserState } from "../redux/reducers/UserReducer";
/**
 * Employee Item Screen
 * renders a screen where user can edit a singular employee item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div``;

const addConsigner = async (
  dispatch: AppDispatch,
  consignerFormValues: FormValues,
  form: FormInstance
) => {
  await dispatch(createConsignerFromAdmin(consignerFormValues));
  form.resetFields();
};

const AddConsigner = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { createConsignerLoading } = useAppSelector(
    (state) => state.ConsignerReducer
  );

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const consignerAdd = (values: any) => {
    const { bankType, bank, ...rest } = values;
    let bankData = {
      accountName: "",
      accountNumber: "",
      swiftCode: "",
      ibanNumber: "",
      routingNumber: "",
      bank: "",
      bankType: "",
      email: "",
      routingType: "",
    };
    if (bankType === "Bank") {
      bankData.accountName = rest.accountName;
      bankData.accountNumber = rest.accountNumber;
      bankData.swiftCode = rest.swiftCode;
      bankData.ibanNumber = rest.ibanNumber;
      bankData.routingNumber = rest.routingNumber;
      bankData.bank = rest.bank;
      bankData.bankType = rest.bankType;
      bankData.email = rest.email;
      bankData.routingType = rest.routingType;
    } else {
      bankData = {
        accountName: "",
        accountNumber: "",
        swiftCode: "",
        ibanNumber: "",
        routingNumber: "",
        bank: "",
        bankType: "",
        email: "",
        routingType: "",
      };
    }
    const updatedValues = { ...values, bankData };
    addConsigner(dispatch, updatedValues, form);
    message.success("Consigner added!");
  };

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Consigners")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <ConsignerForm
        onFinish={(values) => consignerAdd(values)}
        loading={createConsignerLoading}
        form={form}
      />
    </Container>
  );
};

export default AddConsigner;
