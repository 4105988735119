import styled from "styled-components";
import Colors from "../constants/Colors";

export const ProductDetailsPanel = styled.div`
  display: flex;
  flex: 5 1 auto;
  padding: 4px 16px;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

export const ProductReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
  font-weight: 400;
  position: relative;
  font-size: 11px;
  .anticon-info-circle {
    font-size: 13px;
    margin-left: 5px;
  }
  button {
    border: 0;
    color: ${Colors.CONSIGNER_BLUE};
  }
  .product-features {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap;
    row-gap: 10px;
    .ant-statistic-title {
      color: #989898;
      font-size: 12px;
      text-transform: capitalize;
    }
    .ant-statistic-content {
      font-size: 13px;
      color: black;
      font-weight: 600;
    }
  }
  b {
    font-size: 16px;
  }
  div {
    color: ${Colors.CONSIGNER_GREY};
    text-transform: uppercase;
  }
`;

export const InventoryProductMobileContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 100vw;
    margin-top: 20px;
    order: 5;
    border-radius: 10px;
    .eye-btn {
      margin-left: 5px;
      cursor: pointer;
      color: ${Colors.CONSIGNER_BLUE};
      font-size: 15px;
    }
    .ant-dropdown-trigger {
      margin-top: 5px;
      color: black;
    }
    .sc-jWUzzU {
      width: fit-content;
    }
    .status-image-div {
      display: flex;
      flex-direction: column;
      place-self: flex-start;
      gap: 10px;
      margin: 5px;
      b {
        padding: 6px;
      }
    }
    .ant-input-wrapper {
      margin-bottom: 7px;
    }
    .ant-input-affix-wrapper-sm {
      padding: 5px 10px;
      // margin-bottom: 7px;
    }
    .price-change-request {
      display: flex;
      gap: 20px;
      .ant-input-group-addon {
        background-color: #e4e4e7;
      }
      p {
        color: #747474;
        font-size: 13px;
      }
      .edit-product-icon {
        background-color: #eaeaea;
        border-radius: 5px;
      }
    }
    .ant-image {
      padding: 10px;
      background-color: rgba(221, 221, 221, 0.4);
      border-radius: 7px;
      align-self: center;
      .ant-image-img {
        width: 70px !important;
        height: 70px !important;
      }
    }
    padding-bottom: 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;
