import {
  GET_PRODUCTTEMPLATE,
  UPDATE_PRODUCTTEMPLATE,
  CREATE_PRODUCTTEMPLATE,
  GET_PRODUCTTEMPLATES,
  DELETE_PRODUCTTEMPLATE,
} from "../actions/types";

export type ProductTemplate = any;

export interface ProductTemplateState {
  productTemplate: ProductTemplate | null;
  productTemplateLoading: boolean;
  productTemplates: ProductTemplate[];
  productTemplatesLoading: boolean;
  updateProductTemplate: ProductTemplate | null;
  updateProductTemplateLoading: boolean;
  createProductTemplate: ProductTemplate | null;
  createProductTemplateLoading: boolean;
}

const defaultState = {
  productTemplate: null,
  productTemplateLoading: false,
  productTemplates: [],
  productTemplatesLoading: false,
  updateProductTemplate: null,
  updateProductTemplateLoading: false,
  createProductTemplate: null,
  createProductTemplateLoading: false,
};

// export const DefaultProductTemplateState = defaultState;

export type ProductTemplateAction = {
  type: string;
  productTemplate: ProductTemplate | null;
  productTemplateLoading: boolean;
  productTemplates: ProductTemplate[];
  productTemplatesLoading: boolean;
  updateProductTemplate: ProductTemplate | null;
  updateProductTemplateLoading: boolean;
  createProductTemplate: ProductTemplate | null;
  createProductTemplateLoading: boolean;
};

export default (
  state: ProductTemplateState = defaultState,
  action: ProductTemplateAction
) => {
  switch (action.type) {
    case GET_PRODUCTTEMPLATE:
      return {
        ...state,
        productTemplate: action.productTemplate,
        productTemplateLoading: action.productTemplateLoading,
      };
    case GET_PRODUCTTEMPLATES:
      return {
        ...state,
        productTemplates: action.productTemplates,
        productTemplatesLoading: action.productTemplatesLoading,
      };
    case UPDATE_PRODUCTTEMPLATE:
      return {
        ...state,
        updateProductTemplate: action.updateProductTemplate,
        updateProductTemplateLoading: action.updateProductTemplateLoading,
      };
    case CREATE_PRODUCTTEMPLATE:
      return {
        ...state,
        createProductTemplate: action.createProductTemplate,
        createProductTemplateLoading: action.createProductTemplateLoading,
      };
    case DELETE_PRODUCTTEMPLATE:
      return {
        ...state,
        productTemplate: action.productTemplate,
        productTemplateLoading: action.productTemplateLoading,
      };
    default:
      return state;
  }
};
