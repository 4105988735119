import Cookies from "js-cookie";
import React from "react";
import styled from "styled-components";
import HorizontalPageCard from "../components/Common/HorizontalPageCard";
import SetupSettings from "../components/Common/SetupSettings";
import AuthService from "../services/auth";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { UserState } from "../redux/reducers/UserReducer";
import RedesignStyling from "../constants/RedesignStyling";

/**
 * Settings Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const Settings = () => {
  const auth = AuthService();
  const dispatch = useAppDispatch();

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  let renderSettings;

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Settings")
  ) {
    renderSettings = "";
  } else {
    renderSettings = <SetupSettings />;
  }
  return (
    <div>
      <Container>
        <HorizontalPageCard
          title={"Settings"}
          subtitle={"Edit admin settings here"}
          primaryActionText="Logout"
          primaryAction={() => {
            Cookies.remove("domain");
            Cookies.remove("supportEmail");
            auth.logout();
          }}
        />
      </Container>
      {renderSettings}
    </div>
  );
};

export default Settings;
