import React, { useState } from "react";
import { Spin, List } from "antd";
import { BarcodeOutlined } from "@ant-design/icons";
import { Inventory } from "../../../redux/reducers/InventoryReducer";
import InventoryList from "../InventoryList";

interface ScanBarcodeProps {
  scannedItems: Inventory[];
  onScan: (barcode: string) => void;
  removeScannedItem: (inventory: Inventory) => void;
  selectedConsigner: string | null;
  selectedLocation: string | null;
  setBarcode: (barcode: string) => void;
  barcode: string;
}

const ScanBarcodeStep2: React.FC<ScanBarcodeProps> = ({
  scannedItems,
  onScan,
  removeScannedItem,
  selectedConsigner,
  selectedLocation,
  setBarcode,
  barcode,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        Scanning Barcode for{" "}
        {selectedConsigner ? JSON.parse(selectedConsigner).label : ""} at{" "}
        {selectedLocation}{" "}
      </p>
      {/* <input onChange={(e) => setBarcode(e.target.value)} value={barcode} /> */}
      {/* <button onClick={(e) => onScan(barcode)}>Scan</button> */}
      <InventoryList
        inventories={scannedItems}
        loading={false}
        acceptButtonText="Remove"
        onAccept={(inventory: Inventory) => removeScannedItem(inventory)}
      />
    </div>
  );
};

export default ScanBarcodeStep2;
