import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Input,
  Select,
  Button,
  message,
  Checkbox,
  List,
  Card,
} from "antd";
import { ProductTemplate } from "../../../redux/reducers/ProductTemplateReducer";
import { Consigner } from "../../../redux/reducers/ConsignerReducer";
import { getProductTemplates } from "../../../redux/actions/productTemplateActions";
import { getProducts } from "../../../redux/actions/productActions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { UserState } from "../../../redux/reducers/UserReducer";
import { Redirect } from "react-router-dom";
import Content from "../../../constants/Content";
import { Product } from "../../../redux/reducers/ProductReducer";
import styled from "styled-components";
const { Search } = Input;
interface Props {
  visible: boolean;
  onCancel: () => void;
  onUpdate: (product: Product, option1Value: string) => void;
  loading: boolean;
  gtin: string;
  onCreate: (
    product: Product,
    option1Value: string,
    gtin: { gtin: string; size: string }
  ) => void;
}
const Container = styled(List)`
  margin-top: 10px;
`;

const { Option } = Select;

const AddNewItemModal: FC<Props> = ({
  visible,
  onCancel,
  onUpdate,
  loading,
  gtin,
  onCreate,
}) => {
  const [search, setSearch] = useState("");
  const [option1Value, setOption1Value] = useState("");
  const [newProductTemplate, setNewProductTemplate] = useState<string | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const dispatch = useAppDispatch();
  const { products, updatedProduct, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );
  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  useEffect(() => {
    dispatch(getProducts(search));
    //get products here any time search updates
  }, [search]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  const attachProduct = (selectedProduct: Product | null) => {
    if (!option1Value) {
      message.error("Please select an option");
      return;
    }
    if (!selectedProduct) {
      message.error("Select a product");
      return;
    }

    if (!selectedProduct.productTemplateId && !newProductTemplate) {
      message.error("Select a product template");
      return;
    }

    if (newProductTemplate) {
      onCreate(
        { ...selectedProduct, productTemplateId: newProductTemplate },
        option1Value,
        { gtin, size: option1Value }
      );
    } else {
      onUpdate(selectedProduct, option1Value);
    }

    setSelectedProduct(null);
  };

  let productTemplateForSelectedProduct;
  if (selectedProduct && selectedProduct.productTemplateId) {
    productTemplateForSelectedProduct = productTemplates.find(
      (pt) => pt.id === selectedProduct.productTemplateId
    );
  } else if (newProductTemplate) {
    productTemplateForSelectedProduct = productTemplates.find(
      (pt) => pt.id === newProductTemplate
    );
  }

  return (
    <>
      <Modal
        visible={visible}
        title={`Attach ${gtin} to an existing product`}
        onCancel={onCancel}
        onOk={() => attachProduct(selectedProduct)}
        okText="Attach"
        width={1000}
      >
        <Search
          data-testid="search"
          placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          allowClear
          enterButton="Search"
          size="large"
          onSearch={searchProducts}
        />
        <Container
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          dataSource={products}
          loading={loading}
          renderItem={(product: any, index: any) => {
            return (
              <Card
                key={product.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: 1,
                  borderColor: "black",
                  height: 100,
                  cursor: "pointer",
                }}
                onClick={(e) => setSelectedProduct(product)}
              >
                <Checkbox checked={product.id === selectedProduct?.id} />
                <img
                  style={{ width: 50, objectFit: "contain" }}
                  src={product.image}
                />
                <p>
                  {product.title} {product.sku}
                </p>
              </Card>
            );
          }}
        />
      </Modal>
      <Modal
        visible={selectedProduct ? true : false}
        onCancel={() => setSelectedProduct(null)}
        onOk={() => attachProduct(selectedProduct)}
        okText="Attach"
      >
        {!selectedProduct?.productTemplateId ? (
          <Select
            style={{ width: 200 }}
            showSearch
            value={newProductTemplate}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => setNewProductTemplate(value)}
          >
            {productTemplates.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.title} {item.option1} {item.option2} {item.option3}
              </Option>
            ))}
          </Select>
        ) : null}
        <Select
          style={{ width: 200 }}
          showSearch
          value={option1Value}
          optionLabelProp="label"
          filterOption={(input, option) =>
            String(option?.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => setOption1Value(value)}
        >
          {productTemplateForSelectedProduct?.option1Values?.map(
            (item: any, index: any) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            )
          )}
        </Select>
      </Modal>
    </>
  );
};

export default AddNewItemModal;
