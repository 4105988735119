import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { convertValue } from "../../helpers/common";

import {
  StyledToolbar,
  TitleDiv,
  TotalAmountDiv,
  InflationPercentage,
} from "../../styles/lineChart";
import { useAppSelector } from "../../redux/hooks";

interface LineChartProps {
  title: string;
  previousMonth: number[];
  currentMonth: number[];
  totalAmount: number;
  previousMonthTotalAmount?: number;
  unit?: any;
}

const LineChart = ({
  title,
  previousMonth,
  currentMonth,
  totalAmount,
  previousMonthTotalAmount,
  unit,
}: LineChartProps) => {
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [changeColor, setChangeColor] = useState<string>("");
  const { store } = useAppSelector((state) => state.StoreReducer);

  const options: ApexOptions = {
    series: [
      {
        name: "This Month",
        color: "#685FF9",
        data: currentMonth,
      },
      {
        color: "#685FF9",
        name: "Last Month",
        data: previousMonth,
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 1],
      curve: "smooth",
      dashArray: [0, 1],
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - <strong>" +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          "</strong>"
        );
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: Array.from({ length: 32 }, (_, i) => ""),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          const formattedValue = convertValue(val, store?.currency);
          const data = `${formattedValue}`;
          //  var formattedData = unit ? `${val}` : `${data}`;

          // // Remove trailing zero after decimal point
          // formattedData = formattedData.replace(/\.?0+$/, "");
          return data;
        },
      },
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return unit ? val.toString() : `${store?.currency}${val}`;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#DADADA",
    },
  };

  useEffect(() => {
    const calculatePercentageChange = (current: number, previous: number) => {
      if (previous === 0 && current === 0) return 0;

      const percentage =
        ((current - previous) / (previous === 0 ? current : previous)) * 100;

      return percentage;
    };

    const newPercentageChange = calculatePercentageChange(
      Number(totalAmount ?? 0),
      Number(previousMonthTotalAmount ?? 0)
    );

    setPercentageChange(newPercentageChange);

    const newChangeColor = newPercentageChange < 0 ? "red" : "green";
    setChangeColor(newChangeColor);
  }, [totalAmount, previousMonthTotalAmount]);

  return (
    <div id="chart">
      <TitleDiv>{title}</TitleDiv>
      <StyledToolbar>
        <TotalAmountDiv>
          {/* {store?.currency} */}
          {convertValue(totalAmount, store?.currency)}
        </TotalAmountDiv>
        <InflationPercentage style={{ color: changeColor }}>
          <img
            src={
              percentageChange > 0
                ? "/svg/upArrowIcon.svg"
                : percentageChange < 0
                ? "/svg/downArrowIcon.svg"
                : ""
            }
          />
          {Math.abs(percentageChange).toFixed(0)}%
        </InflationPercentage>
      </StyledToolbar>
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
