import {
  GET_CONSIGNERS,
  GET_CONSIGNER,
  UPDATE_CONSIGNER,
  CREATE_CONSIGNER,
  GET_CONSIGNER_REPORT,
  RESET_UPDATED_CONSIGNER,
} from "../actions/types";
import { Inventory } from "./InventoryReducer";
import { BankData } from "../../screens/ConsignerItem";

export type Consigner = any;

export interface ConsignerReport {
  inventoriesByAge: Inventory[];
  inventoriesByStatus: Inventory[];
  inventoriesByCategory: Inventory[];
  inventoriesByLocation: Inventory[];
  bestSellingProducts: Inventory[];
  lowStockProducts: Inventory[];
  sales: number;
  salesGrowth: number;
  pendingPayoutsGrowth: number;
  activeListingsGrowth: number;
  soldListingsGrowth: number;
  dailySales: number[];
  pendingPayouts: number;
  dailyPendingPayouts: number[];
  activeListings: number;
  dailyActiveListings: number[];
  soldListings: number;
  dailySoldListings: number[];
  activeListingPrice?: number;
  activeListingPriceGrowth?: number;
}

export interface ConsignerState {
  consigners: Consigner[];
  consignersLoading: boolean;
  consigner: Consigner | null;
  consignerLoading: boolean;
  updatedConsigner: Consigner | null;
  updateConsignerLoading: boolean;
  createdConsigner: Consigner | null;
  createConsignerLoading: boolean;
  consignerReport: ConsignerReport;
  consignerReportLoading: boolean;
}

const defaultState = {
  consigners: [],
  consignersLoading: false,
  consigner: null,
  consignerLoading: false,
  updatedConsigner: null,
  updateConsignerLoading: false,
  createdConsigner: null,
  createConsignerLoading: false,
  consignerReport: {
    inventoriesByAge: [],
    inventoriesByStatus: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    bestSellingProducts: [],
    lowStockProducts: [],
    sales: 0,
    dailySales: [0, 0, 0, 0],
    pendingPayouts: 0,
    dailyPendingPayouts: [0, 0, 0, 0],
    activeListings: 0,
    dailyActiveListings: [0, 0, 0, 0],
    soldListings: 0,
    dailySoldListings: [0, 0, 0, 0],
    salesGrowth: 0,
    pendingPayoutsGrowth: 0,
    activeListingsGrowth: 0,
    soldListingsGrowth: 0,
  },
  consignerReportLoading: false,
};

export const DefaultConsignerState = defaultState;

export type ConsignerAction = {
  type: string;
  consigners: Consigner[];
  consignersLoading: boolean;
  consigner: Consigner;
  consignerLoading: boolean;
  updatedConsigner: Consigner | null;
  updateConsignerLoading: boolean;
  createdConsigner: Consigner | null;
  createConsignerLoading: boolean;
  consignerReport: ConsignerReport;
  consignerReportLoading: boolean;
};

export default (
  state: ConsignerState = defaultState,
  action: ConsignerAction
) => {
  switch (action.type) {
    case GET_CONSIGNERS:
      return {
        ...state,
        consigners: action.consigners,
        consignersLoading: action.consignersLoading,
      };
    case GET_CONSIGNER:
      return {
        ...state,
        consigner: action.consigner,
        consignerLoading: action.consignerLoading,
      };
    case UPDATE_CONSIGNER:
      return {
        ...state,
        updatedConsigner: action.updatedConsigner,
        updateConsignerLoading: action.updateConsignerLoading,
      };
    case RESET_UPDATED_CONSIGNER:
      return {
        ...state,
        updatedConsigner: action.updatedConsigner,
      };
    case CREATE_CONSIGNER:
      return {
        ...state,
        createdConsigner: action.createdConsigner,
        createConsignerLoading: action.createConsignerLoading,
      };
    case GET_CONSIGNER_REPORT:
      return {
        ...state,
        consignerReport: action.consignerReport,
        consignerReportLoading: action.consignerReportLoading,
      };
    default:
      return state;
  }
};
