import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../redux/hooks";

import Colors from "../../constants/Colors";
/**
 * Checkout Headers:
 * Creates a header with just the logo
 * TODO Implementation:
 *  - make full width
 *  - add logo component
 * TODO TEST:
 * - renders correctly
 */

const Container = styled(Link)`
  width: 100vw;
  border-bottom: 1px solid ${Colors.BACKGROUND};
  padding: 20px 0px 20px 40px;
`;

const Logo = styled.img`
  width: 100px;
`;

interface Props {}

const CheckoutHeader = (props: Props): JSX.Element => {
  const { store } = useAppSelector((state) => state.StoreReducer);

  return (
    <Container to="/">
      <Logo src={store?.logo} />
    </Container>
  );
};

export default CheckoutHeader;
