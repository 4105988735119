import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Table } from "antd";

interface SizeGtin {
  size: string;
  gtin: string;
}

interface Props {
  sizes: string[];
  savedGtinList: SizeGtin[];
  onSave: (gtinList: SizeGtin[]) => void;
  onCancel: () => void;
  visible: boolean;
  loading?: boolean;
}

const GtinModal: React.FC<Props> = ({
  sizes,
  savedGtinList,
  onSave,
  onCancel,
  visible,
  loading,
}) => {
  const [gtinList, setGtinList] = useState<SizeGtin[]>(() =>
    sizes.map((size) => ({ size, gtin: "" }))
  );

  useEffect(() => {
    if (savedGtinList && savedGtinList.length > 0) {
      setGtinList(savedGtinList);
    }
  }, [savedGtinList]);

  const handleSave = () => {
    onSave(gtinList);
  };

  const columns = [
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "GTIN",
      dataIndex: "gtin",
      key: "gtin",
      render: (text: string, record: SizeGtin, index: number) => (
        <Input
          value={text}
          placeholder={`Enter GTIN for size ${record.size}`}
          onChange={(e) => {
            const newGtinList = [...gtinList];
            const savedGTINValue = newGtinList.find(
              (gtin) => gtin && gtin.size === record.size
            );

            if (newGtinList && savedGTINValue) {
              savedGTINValue.gtin = e.target.value;
            } else {
              newGtinList.push({ size: record.size, gtin: e.target.value });
            }
            setGtinList(newGtinList);
          }}
        />
      ),
    },
  ];

  const data = sizes.map((size) => {
    const savedGTINValue = gtinList.find((gtin) => gtin && gtin.size === size);
    return {
      key: size,
      size,
      gtin: savedGTINValue ? savedGTINValue.gtin : "",
    };
  });

  return (
    <Modal
      title="Add GTINs"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          key="save"
          type="primary"
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
      width={800} // set the width to 800 pixels
    >
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </Modal>
  );
};

export default GtinModal;
