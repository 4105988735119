import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageHeader, Form, FormInstance, message } from "antd";
import { Redirect } from "react-router";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import SubmittedProductsTable from "../components/Common/SubmittedProductsTable";
import { Product } from "../redux/reducers/ProductReducer";
import {
  createProduct,
  getSubmittedProducts,
} from "../redux/actions/productActions";
import ProductForm from "../components/Common/ProductForm";
import Content from "../constants/Content";
import { UserState } from "../redux/reducers/UserReducer";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { StoreState } from "../redux/reducers/StoreReducer";

/**
 * Create Product Screen
 * Renders a table of submitted products that are searchable and that has a fill action that filles create product form automatically
 * Refetchs submitted products on load and search
 * Renders a create product form that sends data to /product/create API
 *
 */

//styles
const Container = styled.div``;

//types
interface Props {
  fillFormWithSubmittedProductData: (
    product: Product,
    form: FormInstance<any>
  ) => void;
}

export const fillFormWithSubmittedProductData = (
  product: Product,
  form: FormInstance<any>
) => {
  form.setFieldsValue({
    title: product.title,
    brand: product.brand,
    sku: product.sku,
    category: product.category,
    images: product.image,
    productTemplateId: product.productTemplateId,
    gender: product.gender,
  });
  form.scrollToField("category"); // scrolls to end of form so it's visible in the screen
};

export const CreateProduct = ({
  fillFormWithSubmittedProductData,
}: Props): JSX.Element => {
  const [search, setSearch] = useState("");
  const [form] = Form.useForm();

  const { productTemplates } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );

  const { submittedProducts, submittedProductsLoading, createProductLoading } =
    useAppSelector((state) => state.ProductReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //fetch submitted products on load and every search update
    dispatch(getSubmittedProducts(search));
  }, [search]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, []);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  return (
    <Container data-testid="CreateProductScreen">
      <PageHeader title={Content.CREATE_PRODUCT_SCREEN_TITLE} />
      <SubmittedProductsTable
        submittedProducts={submittedProducts}
        setSearch={setSearch}
        search={search}
        loading={submittedProductsLoading}
        onFill={(product: Product) =>
          fillFormWithSubmittedProductData(product, form)
        }
      />

      <ProductForm
        form={form}
        store={store!}
        onFinish={(values) => dispatch(createProduct(values))}
        loading={createProductLoading}
        productTemplates={productTemplates}
      />
    </Container>
  );
};

const CreateProductFactory = () => (
  <CreateProduct
    fillFormWithSubmittedProductData={fillFormWithSubmittedProductData}
  />
);

export default CreateProductFactory;
