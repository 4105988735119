import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Input, Spin, message, Modal } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getOrders } from "../redux/actions/orderActions";
import OrderFilter from "../components/Common/OrderFilter";
import {
  exportInventory,
  getInventoryFilterOptions,
  getPayoutInventories,
} from "../redux/actions/inventoryActions";
import { Order } from "../redux/reducers/OrderReducer";
import { RouteComponentProps, useHistory, Redirect } from "react-router";
import { PayoutState } from "../redux/reducers/PayoutReducer";
import InventoryList from "../components/Common/InventoryList";
import { Inventory } from "../redux/reducers/InventoryReducer";
import { AppDispatch } from "../redux/store";
import {
  payoutInventories,
  markPaidInventories,
} from "../redux/actions/payoutActions";
import { UserState } from "../redux/reducers/UserReducer";
import {
  getInventoriesPayoutTotal,
  getInventoriesByConsigner,
} from "../helperFunctions/getInventoriesHelpers";
import Colors from "../constants/Colors";
import OrderFilterV1 from "../components/Common/OrderFilterV1";

const { Search } = Input;

/**
 * Order Screen
 * Renders table of orders and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: 16px;
`;
const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const ActionButtonsContainer = styled.div`
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;

const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
`;

const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};

const ReadyToPay = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [filters, setFilters] = useState({
    consigner: "",
    orderDateRange: [],
    payoutsId: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [itemsToPayout, setItemsToPayout] = useState({});
  const [payoutLoading, setPayoutLoading] = useState(false);

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    inventories,
    inventoriesLoading,
  } = useAppSelector((state) => state.InventoryReducer);

  const { store } = useAppSelector((state) => state.StoreReducer);
  const { payoutSuccesful, payoutError }: PayoutState = useAppSelector(
    (state) => state.PayoutReducer
  );
  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //get products here any time id changes
    if (!filters.orderDateRange) {
      filters["orderDateRange"] = [];
    }
    dispatch(getPayoutInventories(search, filters));
  }, [search, filters]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  let inventoriesWithoutStore;
  try {
    if (inventories) {
      let newArr: any;
      newArr = inventories;
      inventoriesWithoutStore = newArr.filter(
        (inventory: any) => !inventory.consigner.isStoreAccount
      )!;
    }
  } catch (error) {
    console.log(error);
  }

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!inventoryFilterOptions || inventoryFilterOptionsLoading)
    return <Spinner />;

  const sendPayout = async (
    dispatch: AppDispatch,
    history: RouteComponentProps["history"],
    selectedInventories: Inventory[]
  ) => {
    if (selectedInventories.length === 0) {
      message.config({
        duration: 2,
        maxCount: 1,
      });
      message.error("Please select at least 1 item");
      return;
    } else {
      if (!store?.automatedPayout) {
        // flow if not signed up with checkbooks
        const id = await dispatch(payoutInventories(selectedInventories, null));
        history.push(`payouts/${id}`);
      } else {
        // flow if signed up with checkbooks
        const toPayout = getInventoriesByConsigner(selectedInventories);
        setShowModal(true);
        setItemsToPayout(toPayout);
      }
    }
  };

  const autoCheckout = async () => {
    message.loading("Creating your payout, you will be redirected shortly.", 5);
    setPayoutLoading(true);
    const consignersAndInventories =
      getInventoriesByConsigner(selectedInventories);
    const id = await dispatch(
      payoutInventories(selectedInventories, consignersAndInventories)
    );
    history.push(`payouts/${id}`);
  };

  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.ORDERS_SCREEN_SEARCH_PLACEHOLDER}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <ActionBar>
        <OrderFilterV1
          onFilter={(values) => setFilters({ ...filters, ...values })}
          formOptions={inventoryFilterOptions}
        />
        <ActionButtonsContainer>
          <Button
            type="ghost"
            onClick={() => setSelectedInventories(inventories)}
          >
            {Content.READY_TO_PAY_ACTION_BUTTON_TEXT}
          </Button>
          <Button
            type="primary"
            onClick={() => sendPayout(dispatch, history, selectedInventories)}
          >
            {Content.READY_TO_PAY_ACTION_BUTTON_TEXT_TWO}
          </Button>
        </ActionButtonsContainer>
      </ActionBar>
      {showModal && (
        <Modal
          title="You are going to make a payout!"
          confirmLoading={payoutLoading}
          visible={showModal}
          onOk={() => autoCheckout()}
          onCancel={() => setShowModal(false)}
        >
          {Object.values(itemsToPayout).map((item: any, index: any) => (
            <ul
              key={index}
              style={{
                listStyleType: "none",
                margin: "unset",
                padding: "unset",
              }}
            >
              <li
                style={{
                  listStyleType: "none",
                  margin: "unset",
                  padding: "unset",
                  display: "flex",
                }}
              >
                <p style={{ marginTop: "1em" }}>
                  {item.consigner.firstName} {item.consigner.lastName}
                </p>
                <p
                  style={{
                    marginLeft: "auto",
                    marginTop: "1em",
                    fontWeight: "600",
                  }}
                >
                  Total: {store?.currency}
                  &ensp;{getInventoriesPayoutTotal(item.inventories)}
                </p>
              </li>
              <hr
                style={{
                  border: "none",
                  height: "1px",
                  backgroundColor: Colors.BLUE_MAGENTA,
                }}
              />
            </ul>
          ))}
        </Modal>
      )}
      <InventoryList
        inventories={inventoriesWithoutStore}
        loading={inventoriesLoading}
        onAccept={(inventory: Inventory) =>
          goToInventoryPage(history, inventory)
        }
        acceptButtonText={Content.ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT}
        onSelect={(newSelectedInventories) =>
          setSelectedInventories(newSelectedInventories)
        }
        selected={selectedInventories}
      />
    </Container>
  );
};

export default ReadyToPay;
