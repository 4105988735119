import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageHeader, Form, Modal, Button } from "antd";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { createSubmitProduct } from "../redux/actions/productActions";
import ProductForm from "../components/Common/ProductForm";
import Content from "../constants/Content";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { CheckCircleOutlined } from "@ant-design/icons";
import { RouteComponentProps, useHistory } from "react-router-dom";

/**
 * Create Submitted Product Screen
 * Renders a table of submitted products that are searchable and that has a fill action that filles create product form automatically
 * Refetchs submitted products on load and search
 * Renders a create product form that sends data to /product/create API
 *
 */

//styles
const Container = styled.div``;

//types
interface Props {}

export const RequestProduct = (props: Props): JSX.Element => {
  const history = useHistory();

  const [form] = Form.useForm();
  const { createSubmitProductLoading, createdSubmitProduct } = useAppSelector(
    (state) => state.ProductReducer
  );
  const dispatch = useAppDispatch();

  const { productTemplates } = useAppSelector(
    (state) => state.ProductTemplateReducer
  );

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, []);

  const success = (history: RouteComponentProps["history"]) => {
    Modal.success({
      content: "Your product has been submited!",
      okText: "Back",
      onOk: () => {
        history.push("/products/");
      },
    });
  };

  useEffect(() => {
    if (createdSubmitProduct && form.getFieldsValue().productTemplateId) {
      success(history);
    }
  }, [createdSubmitProduct]);

  return (
    <Container data-testid="RequestProductScreen">
      <PageHeader title={Content.REQUEST_PRODUCT_SCREEN_TITLE} />
      <ProductForm
        form={form}
        onFinish={(values) => dispatch(createSubmitProduct(values))}
        loading={createSubmitProductLoading}
        productTemplates={productTemplates}
      />
    </Container>
  );
};

export default RequestProduct;
