import styled from "styled-components";
import Colors from "../constants/Colors";
import RedesignStyling from "../constants/RedesignStyling";
import { Button, Modal, Table } from "antd";

export const CustomStatus = styled.b`
  font-weight: 500;
  font-size: inherit;
  padding: 0 4px;
  padding: 10px;
  border-radius: 20px;
  display: inline-block;
  white-space: nowrap;
  &.bullet::before {
    content: "\u2022";
    margin-right: 5px;
  }
  &.green {
    color: #16a34a;
    background-color: rgba(29, 140, 71, 0.1);
  }
  &.red {
    color: #ff7c1e;
    background-color: rgba(255, 147, 70, 0.1);
  }
`;

export const Stat = styled.div`
  background-color: ${Colors.SMOKE_GREY};
  border: 1px solid rgba(221, 221, 221, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;
  flex-basis: 0;
  span {
    display: flex;
    align-items: flex-start;
    span {
      width: 100px;
      height: 100px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid ${Colors.SMOKE_GREY};
      background-color: ${Colors.WHITE};
      border: 1px solid rgba(221, 221, 221, 0.5);
      svg {
        height: 40px;
        width: 40px;
        margin: 0 16px;
      }
    }
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
    gap: 5px;
    b {
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
      color: #747474;
      font-weight: 300;
    }
    div {
      display: flex;
      flex-direction: row;
      p {
        // padding: 8px;
        margin: 0;
        color: #747474;
        text-transform: lowercase;
        font-size: 12px;
        position: relative;
        bottom: 3px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-basis: 100%;
    div {
      p {
        // display: none;
      }
    }
  }
`;

export const StatNumber = styled.h5`
  font-size: 34px;
  font-weight: 500;
  margin: 0;
  @media (max-width: 768px) {
    text-align: justify;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 105.263% */
    letter-spacing: -0.76px;
    margin-top: 24px;
  }
`;

export const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const PriceChangeBtnMenu = styled.div`
  .ant-btn {
    // color: ${Colors.CONSIGNER_BLUE}
    background-color: ${Colors.CONSIGNER_BLUE};
    width: 100%;
    border: none;
  }
  .ant-btn-dangerous {
    background-color: #f5222d;
  }
`;

export const InventoryListStyled = styled(Table)`
  width: 100%;
  padding: ${process.env.REACT_APP_TYPE === "consigner" ? "8px 16px" : "8px 0"};
  order: ${process.env.REACT_APP_TYPE === "consigner" ? "5" : "initial"};
  .price-accept-btn-dropdown .ant-dropdown-menu > .ant-btn-primary {
    width: 100%;
  }
  .ant-table {
    .ant-table-thead .ant-table-cell {
      background-color: ${process.env.REACT_APP_TYPE === "consigner"
        ? Colors.SMOKE_GREY
        : Colors.ADMIN_TR_HEADER};
      text-transform: uppercase;
      color: ${process.env.REACT_APP_TYPE === "consigner" ? "#989898" : "#333"};
      padding: 10px 15px;
      border-bottom: 1px solid rgba(221, 221, 221, 0.4);
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: blue;
      }
    }
    .ant-table-thead .ant-table-cell::before {
      content: none !important;
    }
    table {
      border: 1px solid rgba(221, 221, 221, 0.4);
    }
    .ant-table-row-expand-icon-cell {
      display: none;
    }
    &.ant-table-middle {
      .ant-table-tbody .ant-table-wrapper:only-child {
        .ant-table {
          margin: 0;
        }
      }
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 13px;
      padding: 10px 15px;
      .ant-table {
        margin: 0;
      }
      .edit-product-icon {
        background-color: #eaeaea;
        border-radius: 5px;
      }
      p {
        margin: 0;
        margin-top: 5px;
        color: #747474;
      }
      .ant-input-group {
        width: 100%;
        max-width: 200px;
        margin-bottom: 10px;
        min-width: 120px;
      }
      .ant-input {
        padding: 10px 11px;
      }
      .eye-btn {
        margin-left: 5px;
        cursor: pointer;
        color: ${Colors.CONSIGNER_BLUE};
        font-size: 15px;
      }
      .ant-dropdown-trigger {
        margin-top: 5px;
        color: black;
      }
      .ant-image {
        padding: 10px;
        background-color: rgba(221, 221, 221, 0.2);
        border-radius: 7px;
        .ant-image-img {
          width: 50px !important;
        }
      }
    }
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin: 8px 16px;
  width: 100%;
  div.searchbox-statuses-filter {
    display: flex;
  }
  svg.product-search-icon {
    width: 25px;
    fill: #747474;
    margin-right: 8px;
  }
  .ant-input-group-wrapper {
    max-width: 100%;
    width: 470px;
    border-radius: 10px;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-group-addon {
      display: none;
    }
    .ant-input-affix-wrapper {
      // max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
      border: 1.5px solid #e2e8f0;
      border-radius: 5px;
      height: 100%;
      .ant-input::placeholder {
        color: #747474;
        font-size: 16px;
        font-weight: 200;
      }
    }
  }

  button.dropdown-btn-label {
    margin-left: 8px;
    height: 50px;
    border-radius: 5px;
    border-color: #e2e8f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #747474;
    }
    svg.statuses-drop-icon-arrow {
      width: 10px;
      height: 15px;
      position: relative;
      top: 2px;
      margin-left: 13px;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 10px;
    button.ant-btn-icon-only {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      svg {
        margin: 0;
        font-size: 25px;
      }
    }
    button.ant-btn-primary {
      background: #746bf8;
      border-color: #746bf8;
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    padding-left: 0;
    gap: 15px;
    margin: 40px 0 8px;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: 100vw;
      width: 100%;
    }

    button.dropdown-btn-label {
      margin-left: 0 !important;
    }

    .ant-input-group-wrapper {
      max-width: 100vw;
      width: 100%;
    }

    span.hamburger-btns-span {
      display: none;
    }

    div.searchbox-statuses-filter {
      display: flex;
      flex-direction: column;
      max-width: 100vw;
      width: 100%;
      gap: 15px;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
    & > span {
      // display: none;
      justify-content: flex-end;
    }
  }
`;

export const StatContainer = styled.div`
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
  width: 100%;
  gap: 10px;
  padding: 16px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const EnableMarketplaceBtn = styled(Button)`
  background-color: #685ff9 !important;
  color: white !important;
  border-radius: 7px;
  position: fixed;
  z-index: 999;
  right: 40px;
  bottom: 60px;
  font-size: 13px;
  height: 37px;
`;

export const EnableMarketplaceModal = styled(Modal)`
  width: 900px;
  border-radius: 10px;
  .ant-modal-close-x {
    width: 35px;
    height: 35px;
    margin: 10px 10px 0 0;
    background: ${Colors.SMOKE_GREY};
    line-height: 0;
    align-content: center;
    border-radius: 5px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    padding: 30px;
    color: #989898;

    .center-text {
      text-align: center;

      h3 {
        font-size: 24px;
        font-weight: 600;
      }

      p {
        font-size: 14px;
      }
    }

    .card-container {
      display: flex;
      gap: 20px;

      .card-container-item {
        flex: 1;
      }

      .card-item {
        // width: 100%;
        height: 130px;
        margin-top: 16px;
        // background: ${Colors.SMOKE_GREY};
        border: white;
      }

      h3 {
        font-weight: 600;
        margin-top: 10px;
      }

      p {
        font-size: 12px;
      }
    }

    .button-container {
      align-self: center;
      margin-top: 15px;
      Button {
        // Add any specific Button styles if needed
        background: #685ff9;
        color: white;
        height: 50px;
        padding: 4px 25px;
        border-radius: 10px;
        font-size: 16px;
      }
    }
  }
`;

export const TrackingCodeModal = styled(Modal)`
  p {
    color: #989898;
    margin-bottom: 5px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 15px;
  }
  .ant-modal-body {
    padding: 35px 24px;
  }
  .ant-input {
    padding: 8px 11px;
    border-radius: 5px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .ant-btn {
    height: 45px;
    padding: 4px 20px;
    border-radius: 5px;
  }
  .ant-btn-default {
    background: ${Colors.SMOKE_GREY};
  }
  .ant-btn-primary {
    background: ${Colors.CONSIGNER_BLUE};
  }
`;
