import React, { Fragment } from "react";
import styled from "styled-components";
import { DownloadOutlined } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import Content from "../../constants/Content";
import Fonts from "../../constants/Fonts";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Store } from "../../redux/reducers/StoreReducer";
import { printLabel } from "../../consignerScreens/ProductItem";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import checkmarkIcon from "../../assets/images/success.gif";
/*
    CheckoutShipInstructions
    - used to give user instructions on how to get sneakers to the store
    - takes in a created inventory to show packing slip to download
    TODO Tests:
     - should render
    - should download packaging slip
*/

//styles
const Container = styled.div``;
const IconContainer = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
`;
const Text = styled.p`
  font-family: ${Fonts.PRIMARY};
  color: ${Colors.GREY};
`;
const Label = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
`;

const Icon = styled(DownloadOutlined)`
  font-size: 40px;
`;
const CheckMarkIcon = styled.img``;

//props
interface Props {
  dbUser: Consigner;
  store: Store;
}

const CheckoutShipIntructions = (props: Props): JSX.Element => {
  const { dbUser, store } = props;
  return (
    <Container>
      <CheckMarkIcon src={checkmarkIcon} />
      <Text>
        {store?.shipInstructions.split("\n").map((item: any, key: any) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Text>
    </Container>
  );
};

export default CheckoutShipIntructions;
