import React from "react";
import styled from "styled-components";
import Fonts from "../../constants/Fonts";
import { Product } from "../../redux/reducers/ProductReducer";
import { OptimizedImageUrl } from "../../helperFunctions/optimizedImageUrl";

/**
 * CheckoutProduct
 * Renders the product data in the Checkout
 * TODO TESTS:
 *  - Renders correctly ( title, stylid, brand, lastsale, current lowest, image)
 *
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
`;
const Info = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-weight: 600;
  margin-bottom: 5px;
`;
const Price = styled.p`
  font-family: ${Fonts.PRIMARY};
  font-size: 12px;
`;
const Image = styled.img`
  width: 70%;
`;

interface Props {
  product: Product;
}
const CheckoutProduct = (props: Props) => {
  const { product } = props;
  return (
    <Container>
      <Title>{product.title}</Title>
      <Info>
        Styled Id:{product.sku} Brand: {product.brand}
      </Info>
      {/*  <Price>
        Last Sale: {product.lastSale} | Current Lowest: {product.currentLowest}
      </Price>  */}
      <Image src={OptimizedImageUrl(product.image)} />
    </Container>
  );
};

export default CheckoutProduct;
