import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

//constants
import Colors from "../../constants/Colors";
import Fonts from "../../constants/Fonts";
import { navItem } from "../../nav/navData";
/*
Topbar Component 
Used on login page
Supports adding more than one nav item
Supports changing back text and back link (passed to href tag not onClick)
*/
//styles
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px 20px 25px;
  background: ${Colors.WHITE};
  height: 60px;
  width: 100%;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
  margin-right: 100px;
`;
const NavContainer = styled.div``;
const NavItem = styled(Link)`
  font-size: 14px;
  color: ${Colors.BLACK};
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${Fonts.PRIMARY};
  margin-left: 10px;
`;
const BackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BackLink = styled.a`
  font-size: 14px;
  color: ${Colors.BLACK};
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${Fonts.PRIMARY};
`;
//types
interface Props {
  backLink: string;
  backText: string;
  nav: navItem[];
  logo: string;
}

const Topbar = (props: Props): JSX.Element => {
  const { backLink, backText, nav, logo } = props;

  return (
    <Container>
      <BackContainer>
        <BackLink data-testid="back" href={backLink}>
          {backText}
        </BackLink>
      </BackContainer>
      <Logo data-testid="logo" src={logo} />
      <NavContainer>
        {nav.map((navItem) => (
          <NavItem data-testid="nav" key={navItem.path} to={navItem.path}>
            {navItem.label}
          </NavItem>
        ))}
      </NavContainer>
    </Container>
  );
};

export default Topbar;
