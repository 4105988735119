import { GET_CHEQUES } from "../actions/types";

export type Cheque = any;

export interface ChequeState {
  cheques: Cheque[];
  chequesLoading: boolean;
  chequeError: boolean;
}

const defaultState = {
  cheques: [],
  chequesLoading: false,
  chequeError: false,
};

export const DefaultPayoutState = defaultState;

export type ChequeAction = {
  type: string;
  cheques: Cheque[];
  chequesLoading: boolean;
  chequeError: boolean;
};

export default (state: ChequeState = defaultState, action: ChequeAction) => {
  switch (action.type) {
    case GET_CHEQUES:
      return {
        ...state,
        cheques: action.cheques,
        chequesLoading: action.chequesLoading,
      };
    default:
      return state;
  }
};
