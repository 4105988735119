import React from "react";
import styled from "styled-components";
/**
 * Checkout Page:
 * Creates a full screen page to "checkout a product"
 * TODO Implementation:
 *  - make full screen width / height
 *  - overrides side bar z index
 *  - white  background
 * TODO TEST:
 * - renders correctly
 * - renders children
 */

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 99999;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const CheckoutPage = (props: Props): JSX.Element => {
  return <Container>{props.children}</Container>;
};

export default CheckoutPage;
