import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import SubLocationsTable from "../components/Common/SubLocationsTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getSubLocations } from "../redux/actions/subLocationActions";
import { SubLocation } from "../redux/reducers/SubLocationReducer";
import { Result, Button } from "antd";
import { UserState } from "../redux/reducers/UserReducer";
import RedesignStyling from "../constants/RedesignStyling";

/**
 * Settings Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

const Fees = () => {
  const dispatch = useAppDispatch();

  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Settings")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <SubLocationsTable
        tableData={subLocations}
        loading={subLocationsLoading}
        savedLoading={updatedSubLocationLoading}
      />
      {/* <Result
        status="warning"
        title="Page under construction, come back later..."
      /> */}
    </Container>
  );
};

export default Fees;
