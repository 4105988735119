import React, { ReactElement, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
//Redux Stuff
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AuthService from "../../services/auth";
//components
import Topbar from "../../components/Layout/Topbar";
import Footer from "../../components/Layout/Footer";
import EmailCollectionCard from "../../components/Auth/EmailCollectionCard";
//constants
import Content from "../../constants/Content";
import Colors from "../../constants/Colors";
import { consignerNavItems, navItems } from "../../nav/navData";
import { message } from "antd";
/*
ResetPassword Component using Firebase Auth
When a user logges is, firebase sign in is called
User is redirected to secret routes via a firebase onAuthStateChanged listener
onAuthStateChanged fires when user resetPassword states is changed from logged in to logged off
The logic for this function is found in ./redux/actions/users/setUserAuthState.ts
*/
//styles
const Container = styled.div`
  background: ${Colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;
const ContentContainer = styled.div`
  width: 30%;
`;

//types
export interface ResetPasswordProps {}

export default function ResetPassword({}: ResetPasswordProps): JSX.Element {
  //global state / redux
  const [email, setEmail] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const { isLoggedIn } = useAppSelector((state) => state.UserReducer);
  const dispatch = useAppDispatch();
  const auth = AuthService();
  const { store } = useAppSelector((state) => state.StoreReducer);

  //helpers
  const resetPassword = async (email: string) => {
    setResetPasswordLoading(true);
    await auth.resetPassword(email);
    setResetPasswordLoading(false);
  };
  //component
  if (isLoggedIn) return <Redirect data-testid="dashboardRedirect" to="/" />;
  return (
    <Container data-testid="resetPassword">
      <Topbar
        logo={store?.logo!}
        backText={Content.TOPBAR_BACK_TEXT}
        backLink={`https://${store?.redirectURL!}`}
        nav={
          process.env.REACT_APP_TYPE === "consigner"
            ? consignerNavItems
            : navItems
        }
      />
      <ContentContainer>
        <EmailCollectionCard
          onSubmit={(email) => resetPassword(email)}
          email={email}
          onEmailChange={(newEmail: string) => setEmail(newEmail)}
          loading={resetPasswordLoading}
          title={Content.RESET_PASSWORD_TITLE}
          message={Content.RESET_PASSWORD_MESSAGE}
          buttonText={Content.RESET_PASSWORD_BUTTON_TEXT}
        />
      </ContentContainer>

      <Footer />
    </Container>
  );
}
