import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BackTop, Button, Input, message, Pagination, Spin } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getInventoryFilterOptions,
  getInventories,
  acceptTransferInventories,
  getPaginatedInventories,
} from "../redux/actions/inventoryActions";
import { RouteComponentProps, useHistory, Redirect } from "react-router";
import InventoryList from "../components/Common/InventoryList";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { AppDispatch } from "../redux/store";
import { UserState } from "../redux/reducers/UserReducer";
import InventoryFilter, {
  FormValues as InventoryFilterFormValues,
} from "../components/Common/InventoryFilter";
import { getSubLocations } from "../redux/actions/subLocationActions";
import RedesignStyling from "../constants/RedesignStyling";

const { Search } = Input;

/**
 * Order Screen
 * Renders table of orders and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  padding-bottom: 100px;
`;
const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const ActionButtonsContainer = styled.div`
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
const goToInventoryPage = (
  history: RouteComponentProps["history"],
  inventory: Inventory
) => {
  history.push(`/inventories/${inventory.id}`);
};

const acceptTransferInventoriesData = async (
  search: string,
  filters: InventoryFilterFormValues,
  dispatch: AppDispatch,
  selectedInventories: Inventory[],
  location: string | undefined
) => {
  if (location && location !== "" && location !== "undefined") {
    //transfer inventories
    await dispatch(acceptTransferInventories(selectedInventories, location));
    dispatch(getInventories(search, { ...filters, status: "InTransit" }));
  } else {
    message.error("Please select a location");
  }
};

const AcceptTransfers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [selectedInventories, setSelectedInventories] = useState<Inventory[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "InTransit",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    consigner: undefined,
    location: undefined,
  });

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    inventories,
    paginatedInventories,
    inventoriesLoading,
    acceptTransferInventoryLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    //get products here any time id changes
    dispatch(
      getPaginatedInventories(
        search,
        { ...filters, status: "InTransit" },
        currentPage,
        pageSize
      )
    );
  }, [search, filters]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);

  useEffect(() => {
    dispatch(getSubLocations());
  }, []);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Inventory")
  ) {
    return <Redirect to="/" />;
  }
  if (!inventoryFilterOptions || inventoryFilterOptionsLoading) return <Spin />;

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container>
      <Search
        data-testid="search"
        placeholder={Content.TRANSFER_SEARCH}
        allowClear
        enterButton="Search"
        size="large"
        onSearch={searchProducts}
      />
      <ActionBar>
        <InventoryFilter
          currentFilters={filters}
          onFilter={(values) => setFilters(values)}
          formOptions={inventoryFilterOptions}
          showConsignerFilter
          subLocations={subLocations}
          option1Values={inventoryFilterOptions.option1Values}
          option2Values={inventoryFilterOptions.option2Values}
        />

        <ActionButtonsContainer>
          <Button
            type="ghost"
            onClick={() => setSelectedInventories(paginatedInventories.rows)}
          >
            {Content.READY_TO_PAY_ACTION_BUTTON_TEXT}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              acceptTransferInventoriesData(
                search,
                filters,
                dispatch,
                selectedInventories,
                filters.location
              )
            }
            loading={acceptTransferInventoryLoading}
          >
            {Content.ACCEPT_TRANSFER_BUTTON_TEXT}
          </Button>
        </ActionButtonsContainer>
      </ActionBar>
      <InventoryList
        inventories={paginatedInventories.rows}
        loading={inventoriesLoading}
        onAccept={(inventory: Inventory) =>
          goToInventoryPage(history, inventory)
        }
        acceptButtonText={Content.ORDER_ITEM_INVENTORY_ITEM_BUTTON_TEXT}
        onSelect={(newSelectedInventories) =>
          setSelectedInventories(newSelectedInventories)
        }
        selected={selectedInventories}
      />
      <Pagination
        current={currentPage}
        total={paginatedInventories.count}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
          dispatch(getPaginatedInventories(search, filters, page, pageSize));
        }}
        pageSizeOptions={[10, 20, 50, 100, 250, 500]}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        showQuickJumper // allows user to type in page number
        showSizeChanger // allows user to change number of items per page
      />
      <BackTop // back to top button
        visibilityHeight={5000} // height to show button
        style={{ right: 50 }}
      />
    </Container>
  );
};

export default AcceptTransfers;
