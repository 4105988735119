import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";
import Fonts from "../constants/Fonts";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
/**
 * Consigner Signup Form
 */

const Container = styled.div`
  width: 100%;
`;

const FormInput = styled(Input)`
  font-family: ${Fonts.CONSIGNER};
  width: 100%;
  height: 65px;
  padding: 16px;
  margin: 10px auto;
  border: 1px solid var(--stroke-color, #e4e4e7);
  background: var(--base-white, #fff);
  font-size: 16px;
`;

interface Props {
  email: string;
  password: string;
  confirm: string;
  phone: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  storeId: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  setConfirm: (value: string) => void;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setPhone: (value: string) => void;
  setIdNumber: (value: string) => void;
  setStoreId: (value: string) => void;
  showPasswordInput: boolean;
}

const ConsignerSignupForm = (props: Props): JSX.Element => {
  const {
    email,
    password,
    confirm,
    firstName,
    lastName,
    phone,
    setEmail,
    setPassword,
    setConfirm,
    setFirstName,
    setLastName,
    setIdNumber,
    setPhone,
    setStoreId,
    showPasswordInput,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <Container>
      <FormInput
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <FormInput
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      <FormInput
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormInput
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      {showPasswordInput && (
        <>
          <FormInput
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            prefix={
              <Button
                type="ghost"
                icon={showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
          />
          <FormInput
            type={showConfirm ? "text" : "password"}
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
            required
            prefix={
              <Button
                type="ghost"
                icon={showConfirm ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={() => setShowConfirm(!showConfirm)}
              />
            }
          />
        </>
      )}
    </Container>
  );
};

export default ConsignerSignupForm;
