import React from "react";
import styled from "styled-components";
import { Steps } from "antd";

/**
 * Steps
 * Renders steps based on a list of steps
 * TODO TESTS:
 * - renders correctly (title, container, steps)
 * - should select current step correctly
 */

//styles
const Container = styled.div`
  padding: 40px 40px 40px 40px;

  background: white;
`;

const Step = styled(Steps.Step)`
  .ant-steps-item-description {
    max-width: 100% !important;
  }
  @media (max-width: 400px) {
    .ant-steps-item-description {
      display: none;
    }
    .ant-steps-item-title {
      font-size: 12px;
    }
    .ant-steps-item-container {
    }
  }
`;

const Title = styled.p`
  margin-bottom: 30px;
`;

interface StepProps {
  title: string;
  description?: string;
  subTitle?: string;
}
interface Props {
  steps: StepProps[];
  current: number;
  title: string;
  onChange: (current: number) => void;
}

const StepsComponent = (props: Props) => {
  const { steps, current, title, onChange } = props;

  return (
    <Container>
      <Title>{title}</Title>
      <Steps onChange={onChange} current={current}>
        {steps?.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={step.description}
            subTitle={step.subTitle}
          />
        ))}
      </Steps>
    </Container>
  );
};

export default StepsComponent;
