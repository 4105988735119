import firebase from "firebase";
import { LOGIN_LOADING, GET_USER } from "../actions/types";
import { Consigner } from "./ConsignerReducer";
import { Employee } from "./EmployeeReducer";

export interface UserState {
  loginLoading: Boolean;
  isLoggedIn: Boolean;
  dbUser: Consigner | Employee | null;
  fbUser: firebase.User | null;
}

const defaultState = {
  loginLoading: true,
  isLoggedIn: false,
  dbUser: null,
  fbUser: null,
};

export type UserAction = {
  type: string;
  loginLoading?: Boolean;
  isLoggedIn?: Boolean;
  dbUser: Consigner | Employee | null;
  fbUser: firebase.User | null;
};

export default (state: UserState = defaultState, action: UserAction) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loginLoading: action.loginLoading,
        isLoggedIn: action.isLoggedIn,
        dbUser: action.dbUser,
        fbUser: action.fbUser,
      };
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.loginLoading,
        isLoggedIn: action.isLoggedIn,
      };
    default:
      return state;
  }
};
