import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { AppState } from "../redux/reducers/AppReducer";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  ConsignerStats,
  CustomB,
  MobileFooter,
  PageContainer,
} from "./ConsignerInventory";
import { getConsignerReport } from "../redux/actions/consignerActions";
import {
  getConsignerPayouts,
  getPayoutFilterOptions,
} from "../redux/actions/payoutActions";
import { Payout } from "../redux/reducers/PayoutReducer";
import { Order } from "../redux/reducers/OrderReducer";
import { DatePicker, Spin, Table, Image, Statistic } from "antd";
import { ReactComponent as Calendar } from "../assets/images/svg/Calendar.svg";
const { RangePicker } = DatePicker;
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import getSymbolFromCurrency from "currency-symbol-map";
import { getPayoutInventories } from "../redux/actions/inventoryActions";
import _ from "lodash";
import {
  ConsignerTable,
  CustomSpan,
  DetailsPanel,
  Listing,
  MobilePayout,
  ReviewSpan,
  StatusSelectorButton,
  StatusSelectorDiv,
} from "../styles/ConsignerPayout";

const goToPayoutPage = (
  history: RouteComponentProps["history"],
  payout: Order | Payout
) => {
  history.push(`/payouts/${payout.id}`);
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    render: (_: any, record: any) => record.code,
  },
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <span>
        {record.product.title}
        <br />
        {record.code}
      </span>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => skuController(record.product.sku),
  },
  {
    title: "Location",
    key: "option3Value",
    dataIndex: "option3Value",
    render: (_: any, record: any) => record.location || record?.option3Value,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  {
    title: "Active Date",
    key: "acceptedOn",
    dataIndex: "acceptedOn",
    render: (_: any, record: any) =>
      moment(record.acceptedOn ?? record.updatedAt).format("YY/MM/DD"),
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
];

const skuController = (sku: any) => {
  if (sku.length > 10) {
    return sku.slice(0, 10) + "...";
  } else {
    return sku;
  }
};

export const ExpandedRowRenderForPayout = (
  record,
  store,
  cols = visibleColumns
) => {
  const { inventories } = record;
  const dataSource = inventories.map((inventory) => ({
    ...inventory,
    currency: store.currency,
    checkId: record.name,
  }));

  return (
    <Table
      dataSource={dataSource}
      columns={cols as ColumnTypes}
      scroll={{ x: "max-content" }}
      rowKey="id"
      pagination={{
        pageSize: 10,
        hideOnSinglePage: true,
      }}
      showHeader={false} //process.env.REACT_APP_TYPE === "employee"
    />
  );
};

export const PayoutListingView = (props: any) => {
  const { allProducts, store, loading, actions } = props;

  if (loading) return <Spin />;
  return (
    <MobilePayout>
      {allProducts?.map((record) => (
        <Listing key={record.id}>
          <Image src={record?.product?.image}></Image>
          <DetailsPanel>
            <ReviewSpan>
              <b>{record?.product?.title}</b>
            </ReviewSpan>
            <ReviewSpan>
              <div className="product-features">
                <Statistic
                  title="SKU"
                  value={skuController(record?.product?.sku)}
                />
                <Statistic title="Size" value={record.option1Value} />
                <Statistic
                  title="Location"
                  value={record.location || record?.option3Value}
                />
                <Statistic
                  title="Price"
                  value={store?.currency + " " + record?.price}
                />
                <Statistic
                  title="Payout"
                  value={store?.currency + " " + record?.payoutAmount}
                />
                <Statistic
                  title="Date Sold"
                  value={moment(record.soldOn).format("LLL") || ""}
                />
              </div>
            </ReviewSpan>
          </DetailsPanel>
        </Listing>
      ))}
    </MobilePayout>
  );
};

export const StatusSelector = (props: any) => {
  const { filters, setFilters, payoutFilterOptions, isMobile } = props;
  const { statuses } = payoutFilterOptions;
  const consignerFilterStatuses = statuses
    .filter(
      (payoutFilter) =>
        payoutFilter.value !== "Failed" && payoutFilter.value !== "Pending"
    )
    .map((payoutFilter) => {
      if (payoutFilter.value === "Buying") {
        return {
          ...payoutFilter,
          value: "Ready to Pay",
          label: "Ready to Pay",
        };
      }
      return payoutFilter;
    });
  // consignerFilterStatuses.map((status)=>status.value==="Buying"? {value})
  const [status, setStatus] = useState(filters.status);

  useEffect(() => {
    setFilters({ ...filters, status });
  }, [status]);

  return (
    <StatusSelectorDiv>
      {consignerFilterStatuses.map((stat, key) => (
        <StatusSelectorButton
          key={key}
          type={status.includes(stat.value) ? "primary" : "ghost"}
          onClick={() => setStatus(stat.value)}
        >
          {stat.label}
        </StatusSelectorButton>
      ))}
    </StatusSelectorDiv>
  );
};

const ConsignerPayout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { consignerReport, consignerReportLoading }: ConsignerState =
    useAppSelector((state) => state.ConsignerReducer);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [search, setSearch] = useState("");
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [readyToPayData, setReadyToPayData] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    printed: "",
    status: "Paid",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    orderDateRange: undefined,
  });
  const {
    payouts,
    payoutsLoading,
    payoutFilterOptions,
    payoutFilterOptionsLoading,
  } = useAppSelector((state) => state.PayoutReducer);

  const { inventories, inventoriesLoading }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  useEffect(() => {
    dispatch(getPayoutFilterOptions());
  }, []);

  useEffect(() => {
    setAllProducts(payouts.map((p) => p.inventories).flat());
  }, [payouts]);

  useEffect(() => {
    //fetch all reports data here
    dispatch(getConsignerReport(filters, filters?.orderDateRange));
  }, [filters.orderDateRange]);

  useEffect(() => {
    if (filters.status === "Ready to Pay") {
      const inventoryFilters = {
        consigner: filters.consigner,
        orderDateRange: [undefined, undefined],
        payoutsId: null,
      };
      dispatch(getPayoutInventories(search, inventoryFilters));
    } else {
      dispatch(getConsignerPayouts(search, filters));
    }
  }, [search, filters]);

  useEffect(() => {
    if (inventories && inventories.length > 0) {
      setReadyToPayData(inventories);
    }
  }, [inventories]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!payoutFilterOptions || payoutFilterOptionsLoading)
    return (
      <PageContainer className="ConsignerPayoutPage">
        <Spin />
      </PageContainer>
    );

  const getColumns = (
    onItemAction: (product: Payout) => void
  ): ColumnsType<any> => [
    {
      title: "Product Image",
      dataIndex: "record?.product?.image",
      key: "image",
      render: (text: string, record: any) => (
        <Image src={record?.product?.image}></Image>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "title",
      render: (text: string, record: any) => (
        <Statistic title="Product Name" value={record.product.title} />
      ),
    },
    {
      title: "SKU",
      dataIndex: "payout.inventories.length",
      render: (_: any, record: any) => (
        <Statistic title="SKU" value={skuController(record.product.sku)} />
      ),
    },
    {
      title: "Size",
      dataIndex: "payout.inventories.length",
      render: (_: any, record: any) => (
        <Statistic title="Size" value={record.option1Value} />
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (_: any, record: any) => (
        <Statistic
          title="Location"
          value={record.location || record.option3Value}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      sorter: (a: any, b: any) => a.total - b.total,
      render: (text: string, record: any) => (
        <Statistic
          title="Price"
          value={(getSymbolFromCurrency(store?.currency), record.price)}
        />
      ),
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      key: "Payout",
      render: (text: string, record: any) => (
        <Statistic
          title="Payout"
          value={(getSymbolFromCurrency(store?.currency), record.payoutAmount)}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text: string, record: any) => (
        <Statistic
          title="Date Sold"
          value={moment(record.soldOn).format("LLL") || ""}
        />
      ),
    },
  ];

  return (
    <PageContainer className="ConsignerPayoutPage">
      <CustomSpan>
        <h3>Payout</h3>
        <span>
          <Calendar />
          <RangePicker
            onChange={(dates, dateString: any) =>
              setFilters({ ...filters, orderDateRange: dateString })
            }
            allowClear={true}
            suffixIcon={false}
          />
        </span>
      </CustomSpan>
      <ConsignerStats consignerReport={consignerReport} store={store} />
      <StatusSelector
        filters={filters}
        setFilters={setFilters}
        payoutFilterOptions={payoutFilterOptions}
        isMobile={isMobile}
      />
      <>
        {isMobile ? (
          <PayoutListingView
            allProducts={
              filters.status === "Ready to Pay" ? readyToPayData : allProducts
            }
            store={store}
            loading={payoutsLoading}
          />
        ) : (
          <ConsignerTable
            data-testid="payoutTable"
            columns={getColumns((payout) =>
              goToPayoutPage(history, allProducts)
            )}
            rowClassName={"bordered-row"}
            showHeader={false}
            dataSource={
              filters.status === "Ready to Pay" ? readyToPayData : allProducts
            }
            rowKey="id"
            loading={payoutsLoading}
          />
        )}
      </>
    </PageContainer>
  );
};

export default ConsignerPayout;
