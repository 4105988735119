import { Button, Card, Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import styled from "styled-components";
import Content from "../../constants/Content";
/**
 * Employee Access Control
 */

const Container = styled(Card)`
  margin-top: 10px;
`;

interface Props {
  onChange: (values: any) => void;
  accessControls: string[];
  onSave: () => void;
}

const EmployeeAccessControl = (props: Props): JSX.Element => {
  const { onChange, accessControls, onSave } = props;
  return (
    <Container
      title={Content.EMPLOYEE_ACCESS_CONTROLS_TITLE}
      extra={
        <Button onClick={onSave} type="primary">
          {Content.EMPLOYEE_ACCESS_CONTROLS_BUTTON_TEXT}
        </Button>
      }
    >
      <Checkbox.Group
        options={Content.EMPLOYEE_ACCESS_CONTROLS}
        value={accessControls}
        onChange={onChange}
      />
    </Container>
  );
};

export default EmployeeAccessControl;
