import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { convertValue } from "../../helpers/common";
import { useAppSelector } from "../../redux/hooks";

const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
`;

const TitleDiv = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  opacity: 0.8;
  color: #333333;
`;

const TotalAmountDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  padding: 0rem 1rem;
`;

const InflationPercentage = styled.div`
  color: #009521;
  font-size: 14px;
  font-weight: semibold;
  font-family: "Inter", sans-serif;
`;
export default function DashboardAdminCard({
  title,
  totalValue,
  previousMonthTotal,
}: {
  title: string;
  totalValue: number;
  previousMonthTotal: number;
}) {
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const [changeColor, setChangeColor] = useState<string>("");
  const { store } = useAppSelector((state) => state.StoreReducer);
  useEffect(() => {
    const calculatePercentageChange = (current: number, previous: number) => {
      if (previous === 0 && current === 0) return 0;

      const percentage =
        ((current - previous) / (previous === 0 ? current : previous)) * 100;

      return percentage;
    };

    const newPercentageChange = calculatePercentageChange(
      totalValue,
      previousMonthTotal || 0
    );

    setPercentageChange(newPercentageChange);

    const newChangeColor = newPercentageChange < 0 ? "red" : "green";
    setChangeColor(newChangeColor);
  }, [totalValue, previousMonthTotal]);
  // const convertValue = (value: any, unit: any) => {
  //   if (value == null) {
  //     return ""; // Or any default value you want to return for null values
  //   }
  //   const million = 1000000;
  //   const billion = 1000000000;
  //   const thousand = 1000;
  //   let formattedValue = "";
  //   if (Math.abs(value) >= billion) {
  //     formattedValue =
  //       (value / billion).toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       }) + "B";
  //   } else if (Math.abs(value) >= million) {
  //     formattedValue =
  //       (value / million).toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       }) + "M";
  //   } else if (Math.abs(value) >= thousand) {
  //     formattedValue =
  //       (value / thousand).toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //       }) + "K";
  //   } else {
  //     formattedValue = "$" + value;
  //   }
  //   console.log(formattedValue, unit);
  //   return unit ? formattedValue.replace("$", "") : formattedValue;
  // };

  return (
    <>
      <StyledToolbar>
        <TitleDiv>{title}</TitleDiv>
        <InflationPercentage style={{ color: changeColor }}>
          <img
            src={
              percentageChange > 0
                ? "/svg/upArrowIcon.svg"
                : percentageChange < 0
                ? "/svg/downArrowIcon.svg"
                : ""
            }
          />
          {Math.abs(percentageChange).toFixed(0)}%
        </InflationPercentage>
      </StyledToolbar>
      <TotalAmountDiv>
        {/* {store?.currency} */}
        {convertValue(totalValue, store?.currency)}
      </TotalAmountDiv>
    </>
  );
}
