import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Select, message } from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getProducts, getStoreProducts } from "../redux/actions/productActions";
import ProductList from "../components/Common/ProductList";
import { Link, Redirect } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import ProductTemplates from "./ProductTemplates";
import { getProductTemplates } from "../redux/actions/productTemplateActions";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { getInventoryFilterOptions } from "../redux/actions/inventoryActions";
import RedesignStyling from "../constants/RedesignStyling";
import Colors from "../constants/Colors";
import { SearchOutlined, ShopFilled } from "@ant-design/icons";
import { Store } from "../redux/reducers/StoreReducer";
import { sendStoreRequestedItemsEmailFromAPI } from "../redux/actions/payoutActions";
const { Search } = Input;
const { Option } = Select;

/*
Products Component
- products are fetched from getProducts API (redux/actions/productActions) and added into the Products Reducer 
- products fetch on load and everytime search is updated
Returns Component with
- Search Component
- List of Products
*/

//styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  margin-top: 24px;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
  }
  span {
    div {
      b {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
    }
  }
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
  // width: 100%;
  flex: 1;
  & > span {
    width: 900px;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }

  button {
    font-size: 15px;
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.CONSIGNER_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
  }

  @media (max-width: 768px) {
    margin: 40px 0 8px;
    // order: 2;

    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;

const SearchBoxWrapper = styled.div`
  position relative;
  width: 420px;
  display: flex;
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
    padding-left: 32px; // Adjust padding to make room for the icon
    &:hover {
      border-color: #40a9ff;
    }

    &:focus,
    &:active {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input {
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    z-index: 2;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    button.ant-btn.ant-btn-ghost {
      display: none;
    }
  }
`;

const SearchInput = styled(Input.Search)`
  width: 100%;
  height: 32px; // Adjust the height as needed to match your screenshot

  .ant-input {
    height: 100%;
  }
`;
const Title = styled.p`
  color: var(--Black, #000);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  width: 100%;
  margin: 27px 0 0;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Counter = styled.span`
  color: var(--Text---Grey, #989898);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

const SearchBoxCustom = ({
  placeholder,
  onSearch,
}: {
  placeholder: string;
  onSearch: any;
}) => {
  const { store }: Store = useAppSelector((state) => state.StoreReducer);
  return (
    <SearchBoxWrapper>
      {/* <SearchOutlined className="search-icon" /> */}
      <SearchInput
        data-testid="search"
        placeholder={placeholder}
        allowClear
        size="large"
        onSearch={(data) => onSearch(data)}
        style={{ flex: 1 }}
      />
      <Button
        type="ghost"
        style={{ height: 40 }}
        target="_blank"
        onClick={() => {
          navigator.clipboard
            .writeText(`${window.location.origin}/catalogue/${store.handle}`)
            .then(() => message.info("Link copied!"));
        }}
      >
        Share
      </Button>
      <Button
        type="primary"
        style={{ height: 40 }}
        target="_blank"
        onClick={async () => {
          await sendStoreRequestedItemsEmailFromAPI();
          message.info("Email sent!");
        }}
      >
        Send Email
      </Button>
    </SearchBoxWrapper>
  );
};
//types
export interface ProductsProps {}

export default function RequestInventory({}: ProductsProps): JSX.Element {
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const { products, updatedProduct, productsLoading } = useAppSelector(
    (state) => state.ProductReducer
  );

  const {
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);

  const onLocationSelect = (value: any) => {
    setSelectedLocation(value);
  };

  const {
    productTemplate,
    productTemplates,
    productTemplateLoading,
    productTemplatesLoading,
  } = useAppSelector((state) => state.ProductTemplateReducer);

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
  }, []);

  useEffect(() => {
    if (!selectedLocation || selectedLocation === "") return;
    dispatch(getStoreProducts(search, selectedLocation));
    //get products here any time search updates
  }, [search, selectedLocation]);

  useEffect(() => {
    //get products here any time id changes
    dispatch(getProductTemplates());
  }, [products]);

  useEffect(() => {
    if (inventoryFilterOptions.locations) {
      setSelectedLocation(inventoryFilterOptions.locations.at(0)?.label);
    }
  }, [inventoryFilterOptions]);

  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Products")
  ) {
    return <Redirect to="/" />;
  }
  //component

  const searchProducts = (value: string) => {
    setSearch(value);
  };

  return (
    <Container data-testid="ProductsScreen">
      <SearchBoxCustom
        placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
        onSearch={searchProducts}
      />
      <span
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          gap: 5,
        }}
      >
        <div>
          <Title>
            Request Items <Counter>{products.length}</Counter>
          </Title>
          <b>These are sizes and inventory you have 0 stock in</b>
        </div>
        {inventoryFilterOptions && !inventoryFilterOptionsLoading && (
          <Select
            allowClear
            placeholder="Select location"
            size={"large"}
            style={{ width: "auto", marginRight: 10, flex: 0 }}
            value={selectedLocation}
            onChange={(value: string) => {
              onLocationSelect(value);
            }}
            filterOption={(input, option) =>
              String(option?.children)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(node) => node.parentNode}
          >
            {/* <Option key={"All"} value="">
              All locations
            </Option> */}
            {inventoryFilterOptions.locations?.map((location: any) => (
              <Option key={location.id} value={location.label}>
                {location.name}
              </Option>
            ))}
          </Select>
        )}
      </span>
      {selectedLocation && selectedLocation !== "" ? (
        <ProductList
          data-testid="productList"
          products={products}
          loading={productsLoading}
          productTemplates={productTemplates}
          updatedProduct={updatedProduct!}
          dispatch={dispatch}
          selectedLocation={selectedLocation}
          storeRequest={true}
        />
      ) : (
        <p>Select a store location to begin</p>
      )}
    </Container>
  );
}
