import React, { useState, useEffect } from "react";
import { Table, Spin, Button, message } from "antd";
import styled from "styled-components";
import { Order } from "../../redux/reducers/OrderReducer";
import { Payout, PayoutState } from "../../redux/reducers/PayoutReducer";
import {
  getInventoriesPayoutTotal,
  getInventoriesByConsigner,
} from "../../helperFunctions/getInventoriesHelpers";
import Content from "../../constants/Content";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks";
import type { ColumnsType } from "antd/es/table";
import { ConsignerBankDetailsModal } from "../../screens/ConsignerView";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import { Store } from "../../redux/reducers/StoreReducer";
/**
 * Payout Table
 * Takes a list of payout items and renders a filterable table
 * - List of payout
 * - Filter
 * - Action to go to payout page
 * TODO Test
 * - Filter changes
 */
const Container = styled.div``;
const Spinner = styled(Spin)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderContainer = styled.div``;
const HeaderTitle = styled.p``;

//types
interface Props {
  data: Payout[];
  loading: boolean;
  consigners?: any;
  onItemAction: (payout: Payout) => void;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  render: (key: string, data: Payout) => JSX.Element;
}

let store: any;

const getColumns = (
  onItemAction: (payout: Payout) => void
): ColumnsType<any> => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <span data-testid="payoutTableItemName">{text}</span>
    ),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    sorter: (a: any, b: any) => a.total - b.total,
    render: (text: string) => (
      <span data-testid="payoutTableItemTotal">
        {store.store?.currency} {Number(text).toFixed(2)}
      </span>
    ),
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a: any, b: any) =>
      moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    render: (text: string) => (
      <span data-testid="payoutTableItemOrderDate">
        {moment(text).format("LLL")}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <span data-testid="payoutTableItemOrderDate">{text}</span>
    ),
  },
  {
    title: "Action",
    dataIndex: "id",
    key: "id",
    render: (text: string, data: Payout) => (
      <a data-testid="payoutTableItemAction" onClick={() => onItemAction(data)}>
        View
      </a>
    ),
  },
];

const expandedRowRender = (
  data: Payout,
  consigners: any,
  handleShowConsignerBank: any,
  store: Store
) => {
  const columns = [
    {
      title: store.isBuyingStore ? "Sellers" : "Consigners",
      dataIndex: "name",
    },
    {
      title: "Payouts",
      dataIndex: "payout",
    },
    {
      title: "Operation",
      dataIndex: "id",
      render: (id: string) => (
        <Button
          type="primary"
          onClick={() =>
            handleShowConsignerBank(
              consigners.find((consigner: Consigner) => consigner.id === id)
            )
          }
        >
          Bank Details
        </Button>
      ),
    },
  ];

  let dat: any[] = [];

  let consignersId = data.inventories.map(
    (inventory: any) => inventory.consignerId
  );

  let uniqueConsignersId: any[] = [];

  consignersId.forEach((c: any) => {
    if (!uniqueConsignersId.includes(c)) {
      uniqueConsignersId.push(c);
    }
  });

  uniqueConsignersId.map((id: any) => {
    consigners
      .filter((consigner: any) => {
        // for buying, the real consigner's id is in the buyingId field
        return Number(data.inventories?.[0]?.buyingId)
          ? consigner.id === data.inventories?.[0]?.buyingId
          : consigner.id === id;
      })
      .map((consigner: any) => {
        dat.push({
          name: `${consigner.firstName} ${consigner.lastName}`,
          payout: `${store.store?.currency} ${getInventoriesPayoutTotal(
            data.inventories.filter(
              (inventory: any) => inventory.consignerId === id
            )
          )}`,
          id: consigner.id,
        });
      });
  });
  return (
    <Table columns={columns} dataSource={dat} rowKey="id" pagination={false} />
  );
};

const PayoutTable = (props: Props) => {
  const { data, loading, onItemAction, consigners } = props;
  const [consigner, setConsigner] = useState<Consigner>();
  const [showConsignerDetailsModal, setShowConsignerDetailsModal] =
    useState(false);

  const handleShowConsignerBank = (consigner: Consigner) => {
    setConsigner(consigner);
    setShowConsignerDetailsModal(true);
  };

  useEffect(() => {
    if (!showConsignerDetailsModal) setConsigner(null);
  }, [handleShowConsignerBank, showConsignerDetailsModal]);

  store = useAppSelector((state) => state.StoreReducer);

  return (
    <Container data-testid="payoutTableContainer" style={{ marginTop: 10 }}>
      {consigner && (
        <ConsignerBankDetailsModal
          consigner={consigner}
          setShowDetailsModal={setShowConsignerDetailsModal}
          showDetailsModal={showConsignerDetailsModal}
        />
      )}
      <Table
        data-testid="payoutTable"
        columns={getColumns(onItemAction)}
        expandable={{
          expandedRowRender: (record) =>
            expandedRowRender(
              record,
              consigners,
              handleShowConsignerBank,
              store
            ), //<p style={{ margin: 0 }}>{data.total}</p>,
          rowExpandable: (data) => data.itemCount > 0,
        }}
        scroll={{ x: "max-content" }}
        dataSource={data}
        rowKey="id"
        loading={loading}
      />
    </Container>
  );
};

export default PayoutTable;
